import React, { useState, useEffect } from "react";
import {
  Table,
  Form,
  Button,
  Row,
  Input,
  Select,
  Modal,
  Card,
  message,
} from "antd";
import axios from "axios";

const { Option } = Select;

const OffLtrTable = () => {
  const [showForm, setShowForm] = useState(false);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [companyData, setCompanyData] = useState([]);
  const [employeeData, setEmployeeData] = useState({
    EmpName: "",
    EmpAddress: "",
    ProS_Date: "",
    ProE_Date: "",
  });
  const [visibleModal, setVisibleModal] = useState(false);
  const [pdfGenerating, setPdfGenerating] = useState(false);

  useEffect(() => {
    const dbName = localStorage.getItem("dbName");
    console.log(dbName, "dbname");
    axios
      .get(`https://myapp.finaran.com:5000/api/company_details/${dbName}`)
      .then((response) => {
        setCompanyData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching company details:", error);
      });
  }, []);

  const handleCompanyClick = (record) => {
    const dbName = localStorage.getItem("dbName");
    console.log(dbName, "db");
    // Fetch company details from backend
    axios
      .get(`https://myapp.finaran.com:5000/api/company_details/${dbName}`)
      .then((response) => {
        setCompanyDetails(response.data);
        setShowForm(true);
      })

      .catch((error) => {
        console.error("Error fetching company details:", error);
      });
  };

  const handleFormSubmit = (values) => {
    console.log("Form values:", values);
    setShowForm(false);
  };

  const handleEmployeeChange = (key, value) => {
    setEmployeeData({ ...employeeData, [key]: value });
  };

  const showModal = () => {
    setVisibleModal(true);
  };

  const handleOk = async () => {
    try {
      setPdfGenerating(true);
      const companyName = localStorage.getItem("companyName");
      console.log(requestData, "req");
      const requestData = { ...employeeData, ...companyData };
      const response = await axios.post(
        `https://myapp.finaran.com:5000/api/generate_pdf/${companyName}`,
        requestData,
        { responseType: "blob" }
      );

      // Create a blob object from the response data
      const blob = new Blob([response.data], { type: "application/pdf" });

      // Create a URL for the blob object
      const url = window.URL.createObjectURL(blob);

      // Create an anchor element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = `offer_letter.pdf`; // Set the default filename here
      document.body.appendChild(link);

      // Programmatically click the anchor element to trigger the download
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      setPdfGenerating(false);
      setVisibleModal(false);
    } catch (error) {
      console.error("Error:", error);
      message.error(
        "Failed to generate offer letter PDF. Please try again later."
      );
      setPdfGenerating(false);
    }
  };

  const handleCancel = () => {
    setVisibleModal(false);
  };

  const columns = [
    {
      title: "Probation",
      dataIndex: "Probation",
      key: "Probation",
      // render: (text, record) => (
      //   <a onClick={() => handleCompanyClick(record)}>{text}</a>
      // ),
    },
    {
      title: "Work Hours",
      dataIndex: "WorkHours",
      key: "WorkHours",
    },
    {
      title: "Termination",
      dataIndex: "Termination",
      key: "Termination",
    },
    {
      title: "Policy",
      dataIndex: "Policy",
      key: "Policy",
    },
    {
      title: "Appendix/Annexure",
      dataIndex: "Appendix/Annexure",
      key: "Appendix/Annexure",
    },
    {
      title: "Terms And Conditions",
      dataIndex: "Conditions",
      key: "Conditions",
    },
  ];

  return (
    <div style={{ zoom: "90%" }}>
      <Button type="primary" onClick={showModal}>
        Add Employee
      </Button>
      <Modal
        key="employeeModal"
        title="Add Employee"
        open={visibleModal}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form>
          <Form.Item label="Employee Name" name="EmpName">
            <Input
              onChange={(e) => handleEmployeeChange("EmpName", e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Employee Address" name="EmpAddress">
            <Input
              onChange={(e) =>
                handleEmployeeChange("EmpAddress", e.target.value)
              }
            />
          </Form.Item>
          <Form.Item label="Job Description" name="Description">
            <Input
              onChange={(e) =>
                handleEmployeeChange("Description", e.target.value)
              }
            />
          </Form.Item>
          <Form.Item label="Salary Details" name="Salary">
            <Input
              onChange={(e) => handleEmployeeChange("Salary", e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Probation Start Date" name="ProS_Date">
            <Input
              type="date"
              onChange={(e) =>
                handleEmployeeChange("ProS.Date", e.target.value)
              }
            />
          </Form.Item>
          <Form.Item label="Probation End Date" name="ProE_Date">
            <Input
              type="date"
              onChange={(e) =>
                handleEmployeeChange("ProE.Date", e.target.value)
              }
            />
          </Form.Item>
        </Form>
      </Modal>
      <Row>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Table
            dataSource={companyData}
            columns={columns}
            size="middle"
            className="custom-table col-lg-12 col-md-12 col-sm-12"
            sticky
          />
        </div>
      </Row>
      {showForm && (
        <Card>
          <div>
            <Form onFinish={handleFormSubmit}>
              {companyDetails &&
                Object.entries(companyDetails).map(([key, value]) => (
                  <Form.Item key={key} name={key} initialValue={value}>
                    <Input.TextArea disabled />
                  </Form.Item>
                ))}
            </Form>
          </div>
        </Card>
      )}
    </div>
  );
};

export default OffLtrTable;
