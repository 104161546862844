import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    Col,
    Row,
    Form,
    Input,
    Upload,
    Select,
    message,
    Modal,
    Avatar,
    Divider,
    Popconfirm,
} from "antd";
import {
    UploadOutlined,
    EditOutlined,
    SaveOutlined,
    PlusCircleFilled,
    PlusOutlined,
    DeleteOutlined,
    FileOutlined,
    PlaySquareOutlined,
    EyeOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { url } from "../../url/url";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment/moment";

const { Option } = Select;

const Hrtraining = () => {
    const [file, setFile] = useState(null);
    const [courseOptions, setCourseOptions] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [form] = Form.useForm();
    const [user, setUser] = useState([]);
    const [image, setImage] = useState();
    const [isImageUploaded, setIsImageUploaded] = useState(false);
    const [editing, setEditing] = useState(null);
    const [fileName, setFileName] = useState("");
    const [image1, setImage1] = useState();
    const [isImageUploaded1, setIsImageUploaded1] = useState(false);
    const [fileName1, setFileName1] = useState("");
    const users = JSON.parse(localStorage.getItem("user"));
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [courseNameError, setCourseNameError] = useState(null);
    const [courses, setCourses] = useState([]);
   // const [startDate, setStartDate] = useState("");
    
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const [documents, setDocuments] = useState([]);
    const [videos, setVideos] = useState([]);
    // const handleStartDateChange = (e) => {
    //     setStartDate(e.target.value);
    // };

    const today = moment().format("YYYY-MM-DD");
    const categoryImages = {
        Developing: require("../../images/web-programming-languages.webp"),
        "Data Science": require("../../images/data-science.jpg"),
        Design: require("../../images/design.png"),
    };

    const handleDocumentChange = ({ fileList }) => {
        setDocuments((prevDocuments) => {
            // Map the new file list and filter out any undefined or duplicate files
            const newDocuments = fileList
                .filter(file => file.originFileObj)
                .map((file, index) => ({
                    uid: file.uid || index.toString(),
                    name: file.name,
                    status: "done",
                    file: file.originFileObj,
                }))
                .filter(newDoc => !prevDocuments.some(prevDoc => prevDoc.name === newDoc.name));

            // Return the accumulated documents
            return [...prevDocuments, ...newDocuments];
        });
    };



    const handleVideoChange = ({ fileList }) => {
        setVideos((prevVideos) => {
            // Map the new file list and filter out any undefined or duplicate files
            const newVideos = fileList
                .filter(file => file.originFileObj)
                .map((file, index) => ({
                    uid: file.uid || index.toString(),
                    name: file.name,
                    status: "done",
                    file: file.originFileObj,
                }))
                .filter(newVid => !prevVideos.some(prevVid => prevVid.name === newVid.name));

            // Return the accumulated videos
            return [...prevVideos, ...newVideos];
        });
    };


    const handleRemoveDocument = (file) => {
        const updatedDocuments = documents.filter((doc) => doc.uid !== file.uid);
        setDocuments(updatedDocuments);
    };

    const handleRemove = (file) => {
        // Filter out the file that needs to be removed
        const updatedVideos = videos.filter((video) => video.uid !== file.uid);
        setVideos(updatedVideos);
    };

    
    const handleEdit = (record) => {
        setEditing(record);
        form.setFieldsValue({
            ...record,
        });
    };
    const handleVideoClick = async (course) => {
        try {
            const documents = await fetchDocuments(course.courseName);

            if (!documents || documents.length === 0 || !documents[0].document || documents[0].document.length === 0) {
                message.warning("No documents found for this course.");
                return;
            }

          
            // Map through the video array and create a button for each video
            const videoButtons = documents[0].video.map((vid, index) => (
                <Button icon={<PlaySquareOutlined/>} key={index} style={{ margin: '5px',background:"#fc8d9e" }}>
                    {vid}
                </Button>
            ));

            // Set both document and video buttons in the modal content
            setModalContent(
                <div>
                    
                    <h5>Videos</h5>
                    {videoButtons}
                </div>
            );
            setModalOpen(true);
        } catch (error) {
            console.error("Error fetching documents:", error);
            message.error("Failed to load documents and videos. Please try again later.");
        }
    };

    const handleDocumentClick = async (course) => {
        try {
            const documents = await fetchDocuments(course.courseName);

            if (!documents || documents.length === 0 || !documents[0].document || documents[0].document.length === 0) {
                message.warning("No documents found for this course.");
                return;
            }

            // Map through the document array and create a button for each document
            const documentButtons = documents[0].document.map((doc, index) => (
                <Button  icon={<FileOutlined />} key={index} style={{ margin: '5px',background:"#e0d070" }}>
                    {doc}
                </Button>
            ));

           

            // Set both document and video buttons in the modal content
            setModalContent(
                <div>
                    <h5>Documents</h5>
                    {documentButtons}
                    
                </div>
            );
            setModalOpen(true);
        } catch (error) {
            console.error("Error fetching documents:", error);
            message.error("Failed to load documents and videos. Please try again later.");
        }
    };

    const fetchDocuments = async (course) => {
        console.log(course, "name");
        const dbName = localStorage.getItem("dbName");
        try {
            const response = await axios.get(
                `${url}/get/document/${course}/${dbName}`
            );

            const allCourses = response.data;
            console.log(allCourses, "responseindocument");

            return allCourses;
        } catch (err) {
            console.log(err.message);
        }
    };
    const handleSave = async (values) => {
        const dbName = localStorage.getItem("dbName");
        const formData = new FormData();

        // Append other form values to formData
        Object.keys(values).forEach(key => {
            formData.append(key, values[key]);
        });

        // Append documents to formData
        documents.forEach((doc) => {
            formData.append("document", doc.file);
        });

        // Append videos to formData
        videos.forEach((video) => {
            formData.append("video", video.file);
        });

        try {
            const response = await axios.put(
                `${url}/update/course/${dbName}/${editing._id}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            setCourses(
                courses.map((record) =>
                    record._id === editing._id ? { ...record, ...response.data } : record
                )
            );
            message.success("Successfully updated");
            setEditing(null);
        } catch (error) {
            console.error("Error updating course:", error);
        }
    };

    const fetchEmployee = async () => {
        const dbName = localStorage.getItem("dbName");
        const response = await fetch(`${url}/api/get/allemplist/${dbName}`);
        const data = await response.json();
        const activeUsers = data.filter((user) => user.empStatus !== "inactive");
        setUser(activeUsers);
        console.log("active users", activeUsers);
    };

    const deleteCourses = async (course) => {
        const dbName = localStorage.getItem("dbName");
        if (course.registered.length > 0) {
            message.warning("This Course is Already Assigned");
        } else {
            try {
                const response = await axios.delete(
                    `${url}/coursedelete/${dbName}/${course.courseName}`
                );
                setCourses(
                    courses.filter((data) => data.courseName != course.courseName)
                );
                message.success("Course Deleted successfully");
            } catch (error) {
                console.error("Error:", error);
                alert("An error occurred while deleting the courses");
            }
        }
    };

    const getAvailableUsers = (course, users) => {
        const completedNames = course.completed?.map((regis) => regis.name);
        const registeredNames = course.registered?.map((item) => item);
        const selfRegisteredNames = course.selfRegistered?.map((item) => item);

        return users?.filter(
            (user) =>
                !completedNames.includes(user.displayName) &&
                !registeredNames.includes(user.displayName) &&
                !selfRegisteredNames.includes(user.displayName)
        );
    };

    const fetchCourses = async () => {
        setLoading(true);
        const dbName = localStorage.getItem("dbName");
        try {
            const response = await axios.get(`${url}/get/courses/${dbName}`);
            setCourses(response.data);
        } catch (err) {
            console.log(err.message);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchCourses();
        fetchEmployee();
    }, [editing, count]);

    const handleNavigate = () => {
        navigate("/home/hrform");
    };
    const handleNavigateAssessment = () => {
        navigate("/home/assessmentform");
    };

    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button type="primary" onClick={handleNavigate} icon={<PlusOutlined />}>
                    New Course
                </Button>
                <Button
                    type="primary"
                    onClick={handleNavigateAssessment}
                    danger
                    icon={<EyeOutlined />}
                >
                    Assessment
                </Button>
            </div>
            <h5 className="text-center">Courses</h5>
            <Divider />
            <Row gutter={[16, 16]} style={{ marginTop: "3px" }}>
                {courses.map((course, index) => (
                    <Col span={8} key={index}>
                        <Card
                            style={{
                                width: "45vh",
                                borderRadius: "0%",
                                border: "2px solid #d6d6c2",
                                boxShadow: "0 6px 8px rgba(0, 0, 0, 0.1)",
                            }}
                            cover={
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    {console.log(course.content, "content")}
                                    <div
                                        onClick={() => {
                                            let url = course.content;
                                            if (url &&
                                                !url?.startsWith("http://") &&
                                                !url?.startsWith("https://")
                                            ) {
                                                url = "https://" + url;
                                            }
                                            window.open(url, "_blank");
                                        }}
                                    >
                                        <img
                                            src={
                                                categoryImages[course.category] ||
                                                require("../../images/Default.jpg")
                                            }
                                            style={{ cursor: "pointer", height: "20vh" }}
                                            alt="Course Cover"
                                        />
                                    </div>
                                </div>
                            }
                            title={<h6 className="text-center">{course.courseName}</h6>}
                        >
                            <h6
                                className="text-center"
                                style={{
                                    fontSize: "13px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    maxWidth: "40vh", 
                                     cursor: "pointer",
                                }}
                                title={course.description} 
                            >
                                {course.description}
                            </h6>

                            <p style={{ fontSize: "12px", textAlign: "center" }}>
                                <strong>{course.completionDate}</strong>
                            </p>
                            <Row justify="space-between">
                                <Link onClick={() => handleEdit(course)}>Edit Courses</Link>
                                <Popconfirm
                                    title="Are you sure you want to delete this course?"
                                    onConfirm={() => deleteCourses(course)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Link style={{ color: "red" }}>Delete Courses</Link>
                                </Popconfirm>
                            </Row>
                        </Card>
                        <br />
                    </Col>
                ))}
            </Row>

            {editing && (
                <Modal
                    title="Edit Courses"
                    width={600}
                    visible={editing !== null}
                    onCancel={() => setEditing(null)}
                    onOk={() => form.submit()}
                    bodyStyle={{ maxHeight: 450, overflowY: "auto", margin: "20px" }}
                    footer={null}
                >
                    <Form initialValues={editing} onFinish={handleSave}>
                        <Form.Item
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 14 }}
                            label="Course Name"
                            name="courseName"
                        >
                            <Input style={{ height: "6vh", fontSize: "14px" }} />
                        </Form.Item>
                        <Form.Item
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 14 }}
                            label="Description"
                            name="description"
                        >
                            <Input style={{ height: "6vh", fontSize: "14px" }} />
                        </Form.Item>
                        <Form.Item
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 14 }}
                            label="Category"
                            name="category"
                        >
                            <Input style={{ height: "6vh", fontSize: "14px" }} />
                        </Form.Item>
                        <Form.Item
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 14 }}
                            label="URL"
                            name="content"
                        >
                            <Input style={{ height: "6vh", fontSize: "14px" }} />
                        </Form.Item>
                        <Form.Item
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 14 }}
                            label="Start Date"
                            name="startDate"
                        >
                            <Input type="date" style={{ height: "6vh", fontSize: "14px" }} />
                        </Form.Item>
                        <Form.Item
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 14 }}
                            label="Completion Date"
                            name="completionDate"
                        >
                            <Input type="date" style={{ height: "6vh", fontSize: "14px" }} />
                        </Form.Item>

                        <Form.Item
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 14 }}
                            label="Assign To"
                            name="registered"
                        >
                            <Select style={{ fontSize: "14px" }} mode="multiple" allowClear>
                                {getAvailableUsers(editing, user).map((item) => (
                                    <Select.Option
                                        key={item.displayName}
                                        value={item.displayName}
                                    >
                                        {item.displayName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 12 }}
                            label="Document"
                            name="document"
                        >
                            <Upload
                                multiple
                                beforeUpload={() => false}
                                onChange={handleDocumentChange}
                                onRemove={handleRemoveDocument}
                                fileList={documents.map((doc) => ({
                                    uid: doc.uid,
                                    name: doc.name,
                                    status: "done",
                                }))}
                            >
                                <Button icon={<UploadOutlined />}>Upload Documents</Button>
                            </Upload>
                            <Col>
                                <Link
                                    onClick={() => handleDocumentClick(editing)}
                                    style={{ color: "red", marginLeft: "3vh" }}
                                >
                                    View documents
                                </Link>
                            </Col>

                        </Form.Item>

                        <Form.Item
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 12 }}
                            label="Videos"
                            name="videos"
                        >
                            <Upload
                                multiple
                                beforeUpload={() => false}
                                onChange={handleVideoChange}
                                onRemove={handleRemove}
                                fileList={videos.map((video) => ({
                                    uid: video.uid,
                                    name: video.name,
                                    status: "done",
                                }))}
                            >
                                <Button icon={<UploadOutlined />}>Upload Videos</Button>
                            </Upload>
                            <Col>
                                <Link
                                    onClick={() => handleVideoClick(editing)}
                                    style={{ color: "red", marginLeft: "3vh" }}
                                >
                                    View videos
                                </Link>
                            </Col>
                        </Form.Item>


                        <Button  type="primary" htmlType="submit">
                            Save
                        </Button>
                    </Form>
                </Modal>
            )}

            <Modal
                visible={modalOpen}
                onCancel={() => setModalOpen(false)}
                width={600}
                footer={null}
            >
                {modalContent}
            </Modal>
        </>
    );
};

export default Hrtraining;
