import React, { useState, useEffect } from "react";
import {
  Table,
  Form,
  Button,
  Row,
  Input,
  Select,
  Modal,
  Card,
  message,
  Descriptions,
  Tooltip,
  notification,
  Popconfirm,
} from "antd";
import axios from "axios";
import { url } from "../../url/url";
import {
  DeleteOutlined,
  EditOutlined,
  FilePdfOutlined,
  MailOutlined,
  QuestionCircleOutlined,
  SaveOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import OffLtrTable from "./OfferLettertable";

const { Option } = Select;

const GenericData = () => {
  const [form] = Form.useForm();
  const [count, setCount] = useState(0);
  const [count1, setCount1] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [companyData, setCompanyData] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [employeeData, setEmployeeData] = useState();
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleModal1, setVisibleModal1] = useState(false);
  const [pdfGenerating, setPdfGenerating] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editedDescription, setEditedDescription] = useState("");
  const [pdfModalContent, setPdfModalContent] = useState([]);
  const [pdfModalVisible, setPdfModalVisible] = useState(false);
  const [selectedPdfIndex, setSelectedPdfIndex] = useState(0);
  const today = new Date().toISOString().slice(0, 10);
  const [date] = useState(today);
  const [signatureBase64, setSignatureBase64] = useState(null);

  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [count]);

  useEffect(() => {
    fetchData1();
  }, [count1]);

  const fetchData = async () => {
    try {
      console.log("insidefetchdata", count);
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(`${url}/get/offerletter/${dbName}`);
      setEmployee(response.data);
      console.log(response.data, "company");
    } catch (error) {
      console.error("Error fetching company details:", error);
    }
  };
  function openPdfInModal(pdfDataUrls) {
    console.log(pdfDataUrls, "pdfDaturls");
    setPdfModalContent(pdfDataUrls);
    setPdfModalVisible(true);
  }

  function closePdfModal() {
    setPdfModalVisible(false);
    setSelectedPdfIndex(0);
  }

  const columns = [
    {
      title: "Employee Name",
      fixed: "left",
      dataIndex: "EmpName",
      key: "EmpName",
      width: "150px",
    },
    {
      title: "Offer Date",
      fixed: "left",
      dataIndex: "offerDate",
      key: "offerDate",
      width: "150px",
    },
    {
      title: "Employee Email",
      width: "150px",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Employee Address",
      dataIndex: "EmpAddress",
      key: "EmpAddress",
    },
    { title: "Salary", width: "150px", dataIndex: "Salary", key: "Salary" },
    {
      title: "Probation Start Date",
      width: "150px",
      dataIndex: "ProSDate",
      key: "ProSDate",
    },
    {
      title: "Probation End Date",
      width: "150px",
      dataIndex: "ProEDate",
      key: "ProEDate",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "5px" }}>
          <Tooltip title="PDF">
            <Button
              icon={<FilePdfOutlined />}
              onClick={() => openPdfInModal(record.pdf)}
              type="link"
            />
          </Tooltip>
          <Tooltip title="Send Email">
            <Popconfirm
              title="Are you sure to send mail?"
              onConfirm={() => handleSendEmail(record)}
              icon={<QuestionCircleOutlined style={{ color: "blue" }} />}
              okText="Yes"
              cancelText="No"
            >
              <Button icon={<MailOutlined />} type="link" />
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure to delete this record?"
              onConfirm={() => handleDelete(record._id)}
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              okText="Yes"
              cancelText="No"
            >
              <Button icon={<DeleteOutlined />} type="link" danger />
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];
  const handleDelete = async (id) => {
    console.log(id, "id");
    try {
      const dbName = localStorage.getItem("dbName");
      console.log("dbName in handleUpdate in project", dbName);

      const response = await axios.delete(`${url}/offerletter/${id}/${dbName}`);
      console.log(response, "response delete");
      if (response.status === 200) {
        setEmployee(employee.filter((record) => record._id !== id));
        notification.success({ message: "Record deleted successfully!" });
      } else {
        // Handle other errors
        message.error("Failed to delete the record.");
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to delete the record.");
    }
  };
  const handleSendEmail = async (record) => {
    try {
      const mailTriggerResponse = await axios.post(`${url}/sendmail`, {
        email: record.email,
        pdf: record.pdf,
        empName: record.EmpName,
      });
      message.success("Email sent successfully");
    } catch (error) {
      console.error(error, "error");
      message.error("Something Went Wrong");
    }
  };
  const fetchData1 = async () => {
    try {
      console.log("inside fetchdata");
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(
        `https://myapp.finaran.com:5000/api/company_details/${dbName}`
      );
      setCompanyData(response.data);
      console.log(companyData, response.data, "companyDetails");
    } catch (error) {
      console.error("Error fetching company details:", error);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedFileTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (allowedFileTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          setSignatureBase64(reader.result); // Set the base64 string in state
        };
      }
    }
  };

  const onFinish = async (values) => {
    try {
      console.log("Form Data:", values);

      const dbName = localStorage.getItem("dbName");

      const formData = {
        ...values,
        image: signatureBase64 ? signatureBase64 : companyData[0]?.image,
      };
      console.log(companyData[0], "comapny");
      // Update existing form data
      await axios.post(
        `https://myapp.finaran.com:5000/api/update_form/${dbName}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      message.success("Data updated successfully!");

      form.resetFields();
      setCount1(count1 + 1);
      setVisibleModal1(false);
    } catch (error) {
      console.error("Error:", error);
      message.error("Failed to submit data. Please try again later.");
    }
  };

  const handleEmployeeChange = (key, value) => {
    setEmployeeData({ ...employeeData, [key]: value });
  };

  const showModal = async () => {
    try {
      setVisibleModal(true);

      const dbName = localStorage.getItem("dbName");
      const res = await axios.get(`${url}/api/masterorg/${dbName}`);
      const combined = await axios.get(`${url}/get/combined/${dbName}`);

      const values = {
        CompanyName: res.data.org.orgName,
        CompanyAddress: res.data.org.companyaddress,
        letterheader: combined.data[0].letterheader,
        letterfooter: combined.data[0].letterfooter,
        CompanyLogo: combined.data[0].Logo,
      };

      console.log(values, "letter");
      setEmployeeData(values); // Set all the values in employeeData
    } catch (error) {
      console.error("Error in showModal:", error);
    }
  };

  const showModal1 = () => {
    setVisibleModal1(true);
  };

  const handleOk = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const formValues = await form.validateFields();

      const updatedEmployeeData = {
        ...employeeData,
        ...formValues,
      };

      setPdfGenerating(true);
      const requestData = { ...companyData[0], ...updatedEmployeeData };

      const response = await axios.post(
        `https://myapp.finaran.com:5000/api/generate_pdf/${dbName}`,
        requestData,
        { responseType: "blob" }
      );

      // Create a blob object from the response data
      const blob = new Blob([response.data], { type: "application/pdf" });
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        console.log(base64data, "base64data");
        const emp = {
          ...employeeData,
          ProSDate: employeeData["ProS.Date"],
          ProEDate: employeeData["ProE.Date"],
          pdf: base64data,
          offerDate: date,
        };
        console.log(emp, "emp");

        // API call inside the onloadend callback
        axios
          .post(`${url}/offerletter/${dbName}`, emp)
          .then((res) => {
            console.log(res);
          })
          .catch((error) => {
            // Handle errors
            console.error("Error:", error);
          });
      };

      // // Create a URL for the blob object
      const Url = window.URL.createObjectURL(blob);

      // Create an anchor element to trigger the download
      const link = document.createElement("a");
      link.href = Url;
      link.download = `offer_letter.pdf`; // Set the default filename here
      document.body.appendChild(link);

      // Programmatically click the anchor element to trigger the download
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(Url);

      setPdfGenerating(false);
      setVisibleModal(false);
      setCount(count + 1);
      form.resetFields();
      message.success("Offer letter generated successfully");
    } catch (error) {
      console.error("Error:", error);
      message.error(
        "Failed to generate offer letter PDF. Please try again later."
      );
      setPdfGenerating(false);
    }
  };

  const handleCancel = () => {
    setVisibleModal(false);
  };

  return (
    <div style={{ padding: "10px", zoom: "90%" }}>
      <div style={{ marginBottom: "10px" }}>
        <h2 style={{ marginBottom: "10px" }}>Manage Offer Letter</h2>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            type="primary"
            onClick={() => {
              showModal1();
              fetchData1();
            }}
          >
            Company Details
          </Button>
          <Button type="primary" onClick={showModal}>
            Generate offer letter
          </Button>
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Table
          className="custom-table col-lg-12 col-md-12 col-sm-12"
          columns={columns}
          dataSource={employee}
          scroll={{ x: true }}
          sticky
        />
      </div>

      <Modal
        // title="Add Employee"
        visible={visibleModal}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form}>
          <Form.Item
            label="Employee Name"
            name="EmpName"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
            rules={[{ required: true, message: "Please input employee name!" }]}
          >
            <Input
              onChange={(e) => handleEmployeeChange("EmpName", e.target.value)}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            label="Employee Email"
            name="email"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
            rules={[
              { required: true, message: "Please input employee email!" },
            ]}
          >
            <Input
              onChange={(e) => handleEmployeeChange("email", e.target.value)}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            label="Employee Address"
            name="EmpAddress"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
            rules={[
              { required: true, message: "Please input employee address!" },
            ]}
          >
            <Input
              onChange={(e) =>
                handleEmployeeChange("EmpAddress", e.target.value)
              }
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item
            label="Job Title"
            name="jobTitle"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
            rules={[{ required: true, message: "Please input job title!" }]}
          >
            <Input
              onChange={(e) => handleEmployeeChange("jobTitle", e.target.value)}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            label="Job Description"
            name="Desc"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
            rules={[
              { required: true, message: "Please input job description!" },
            ]}
          >
            <Input
              onChange={(e) =>
                handleEmployeeChange("Description", e.target.value)
              }
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            label="Salary"
            name="Salary"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
            rules={[
              { required: true, message: "Please input salary details!" },
            ]}
          >
            <Input
              onChange={(e) => handleEmployeeChange("Salary", e.target.value)}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            label="Probation Start Date"
            name="ProS_Date"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
            rules={[
              { required: true, message: "Please input probation start date!" },
            ]}
          >
            <Input
              type="date"
              onChange={(e) =>
                handleEmployeeChange("ProS.Date", e.target.value)
              }
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            label="Probation End Date"
            name="ProE_Date"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
            rules={[
              { required: true, message: "Please input probation end date!" },
            ]}
          >
            <Input
              type="date"
              onChange={(e) =>
                handleEmployeeChange("ProE.Date", e.target.value)
              }
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Edit Company Details"
        visible={visibleModal1}
        onCancel={() => {
          setVisibleModal1(false);
          setEditMode(false);
          setSignatureBase64("");
        }}
        footer={null}
        width={700}
      >
        <Form
          form={form}
          // name={`companyForm-${index}`} // Use a unique name for each form
          onFinish={onFinish}
          wrapperCol={{ span: 18, offset: 3 }}
          labelCol={{ span: 6 }}
          style={{ padding: "10px", width: "80%" }}
        >
          <Form.Item
            label="Introduction"
            name="intro"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 18 }}
            initialValue={companyData[0]?.intro || ""}
          >
            <Input.TextArea disabled={!editMode} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Probation"
            name="Probation"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 18 }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please provide probation details",
            //   },
            // ]}
            initialValue={companyData[0]?.Probation || ""} // Set initial value if available
          >
            <Input.TextArea disabled={!editMode} style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            label="Work Hours"
            name="WorkHours"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 18 }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please provide work hours details",
            //   },
            // ]}
            initialValue={companyData[0]?.WorkHours || ""}
          >
            <Input.TextArea disabled={!editMode} style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            label="Termination"
            name="Termination"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 18 }}
            initialValue={companyData[0]?.Termination || ""}
          >
            <Input.TextArea disabled={!editMode} style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            label="Policy"
            name="Policy"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 18 }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please provide policy details",
            //   },
            // ]}
            initialValue={companyData[0]?.Policy || ""}
          >
            <Input.TextArea disabled={!editMode} style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            label="Salary Details"
            name="SalaryDetails"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 18 }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please provide policy details",
            //   },
            // ]}
            initialValue={companyData[0]?.SalaryDetails || ""}
          >
            <Input.TextArea disabled={!editMode} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Location Details"
            name="location"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 18 }}
            initialValue={companyData[0]?.location || ""}
          >
            <Input.TextArea disabled={!editMode} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Leave Details"
            name="leave"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 18 }}
            initialValue={companyData[0]?.leave || ""}
          >
            <Input.TextArea disabled={!editMode} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Appendix/Annexure"
            name="Appendix"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 18 }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please provide appendix/annexure details",
            //   },
            // ]}
            initialValue={companyData[0]?.Appendix || ""}
          >
            <Input.TextArea disabled={!editMode} style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            label="Terms and Conditions"
            name="Conditions"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 18 }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please provide terms and conditions details",
            //   },
            // ]}
            initialValue={companyData[0]?.Conditions || ""}
          >
            <Input.TextArea disabled={!editMode} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Signatory Name"
            name="signatoryName"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 18 }}
            initialValue={companyData[0]?.signatoryName || ""}
          >
            <Input.TextArea disabled={!editMode} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Department of Signatory"
            name="deptofsignatory"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 18 }}
            initialValue={companyData[0]?.deptofsignatory || ""}
          >
            <Input.TextArea disabled={!editMode} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Signature"
            name="image"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 18 }}
          >
            <Input
              type="file"
              disabled={!editMode}
              onChange={handleFileChange}
            />
          </Form.Item>
          <div style={{ gridColumn: "span 2", textAlign: "center" }}>
            {(signatureBase64 || (companyData[0] && companyData[0].image)) && (
              <img
                src={
                  signatureBase64 ||
                  (companyData[0] && companyData[0].image) ||
                  ""
                }
                alt="Uploaded Signature"
                style={{ maxWidth: "100%" }}
              />
            )}
          </div>

          <Form.Item
            wrapperCol={{ offset: 0, span: 24 }}
            style={{ textAlign: "center" }}
          >
            <div style={{ marginRight: "10px", display: "inline-block" }}>
              <Button
                type="primary"
                onClick={() => setEditMode((prevMode) => !prevMode)}
                style={{ alignSelf: "flex-start" }}
                disabled={editMode}
              >
                Edit
              </Button>
            </div>
            <div style={{ display: "inline-block" }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Documents"
        visible={pdfModalVisible}
        onCancel={closePdfModal}
        footer={null}
        width={1000}
      >
        <>
          <div style={{ textAlign: "center" }}>
            <span>Document</span>
          </div>
          <iframe
            src={pdfModalContent}
            title="Document"
            style={{ width: "100%", height: "500px" }}
          />
        </>
      </Modal>
    </div>
  );
};

export default GenericData;
