import React, { useState } from "react";
import axios from "axios";
import './AI.css';

const ResumeSearchApp = () => {
  const [query, setQuery] = useState("");
  const [resumes, setResumes] = useState([]);
  const [loading, setLoading] = useState(false);

  // Handle search input submission
  const handleSearch = async (e) => {
    e.preventDefault();
    if (!query.trim()) return;

    setLoading(true);
    try {
      const response = await axios.get("https://myapp.finaran.com:5000/api/resumes", {
        params: { query },
      });
      setResumes(response.data);
    } catch (error) {
      console.error("Error fetching resumes:", error);
    }
    setLoading(false);
  };

  return (
    <div className="app">
      <header className="app-header">
        <h1>Resume Search</h1>
        <p>Search resumes by entering skills or keywords</p>
      </header>

      {/* Search input and button */}
      <div className="search-bar">
        <form onSubmit={handleSearch}>
          <input
            type="text"
            value={query}
            placeholder="Enter skills (e.g., Python, Django, React)"
            onChange={(e) => setQuery(e.target.value)}
          />
          <button type="submit">Search</button>
        </form>
      </div>

      {/* Display loading message */}
      {loading ? (
        <div className="loading">Searching resumes...</div>
      ) : (
        // Display resume search results
        <div className="result-list">
          {resumes.length === 0 ? (
            <p>No results found</p>
          ) : (
            resumes.map((resume, index) => (
              <div key={index} className="resume-card">
                <h3>{resume.name}</h3>
                <p><strong>Primary Skill:</strong> {resume.primary_skill}</p>
                <p><strong>Secondary Skills:</strong> {resume.secondary_skills.join(", ")}</p>
                <p><strong>Match Percentage:</strong> {resume.match_percentage}%</p>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default ResumeSearchApp;
