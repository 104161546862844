import { Card, Layout, Steps, message, Button, notification } from 'antd';
import React, { useState } from 'react';
import Setup from './setup';
import Question from './question';
import Applicability from './Applicability';
import axios from 'axios';
import { url } from "../../url/url";

const Stepshome = () => {
  const [current, setCurrent] = useState(0);
  const [formData, setFormData] = useState({
    introduction: '',
    questions: [],
  });

  const [allValues, setAllValues] = useState({
    questions: [],  // Initialize questions as an empty array
  });

  const users = JSON.parse(localStorage.getItem("user"));
  const handleUpdateFormData = (stepData) => {
    setFormData((prevData) => ({
      ...prevData,
      ...stepData,
    }));
  };

  // Move to the next step
  const handleNextStep = (stepData) => {
    handleUpdateFormData(stepData);
    setCurrent(current + 1);
  };

  // Move to the previous step
  const handlePreviousStep = () => {
    setCurrent(current - 1);
  };

  const handlePublish = async (values) => {
    const dbName = localStorage.getItem("dbName");
    const userName = users.displayName; // Get user displayName

    console.log(values, "HandlePublish");

    // Combine form data with values from form submission and add userName
    const combinedData = {
      ...formData,  // formData contains other necessary data
      ...values,    // values contain applicability, notification, surveyLink, etc.
      userName      // Add userName as user.displayName
    };
    console.log(combinedData, "Combined Form Data");

    try {
      const response = await axios.post(`${url}/surveys/${dbName}`, combinedData);
      if (response.status === 200) {
        message.success('Survey published successfully!');
        console.log(response.data, "Surveyform");
      }
    } catch (error) {
      message.error('Failed to publish survey.');
      console.error('Error publishing survey:', error);
    }
  };


  const circleStyle = {
    width: '24px',
    height: '24px',
    lineHeight: '24px',
    borderRadius: '50%',
    backgroundColor: '#1890ff',
    color: '#fff',
    textAlign: 'center',
    fontSize: '14px',
  };
  const items = [
    {
      title: 'Setup',
      content: (
        <Setup
          allValues={allValues} // Pass allValues
          setAllValues={setAllValues} // Pass setAllValues
          onNext={(data) => handleNextStep({ introduction: data })}
        />
      ),
      icon: <div style={circleStyle}>1</div>,
    },
    {
      title: 'Questions',
      content: (
        <Question
          allValues={allValues} // Pass allValues
          setAllValues={setAllValues} // Pass setAllValues
          onNext={(data) => handleNextStep({ questions: data })}
          onPrevious={handlePreviousStep}
        />
      ),
      icon: <div style={circleStyle}>2</div>,
    },
    {
      title: 'Publish',
      content: (
        <Applicability
          allValues={allValues} // Pass allValues
          setAllValues={setAllValues} // Pass setAllValues
          onPrevious={handlePreviousStep}
          onPublish={(data) => handlePublish(data)}
        />
      ),
      icon: <div style={circleStyle}>3</div>,
    },
  ];


  // Handle step change
  const onStepChange = (step) => {
    setCurrent(step);
  };

  return (
    <div>
      <h5 className='text-center'>Add Survey</h5>
      <br />
      <Steps
        current={current}
        labelPlacement="vertical"
        items={items.map((item, index) => ({
          title: item.title,
          icon: item.icon,
          onClick: () => onStepChange(index),
        }))}
      />
      <div style={{ marginTop: 20 }}>
        {items[current].content} {/* Render the content of the current step */}
      </div>
      <div style={{ marginTop: 20, textAlign: 'center' }}>
        {/* {current > 0 && (
          <Button style={{ margin: '0 8px' }} onClick={handlePreviousStep}>
            Previous
          </Button>
        )}
        {current < items.length - 1 && (
          <Button type="primary" onClick={() => handleNextStep()}>
            Next
          </Button>
        )} */}
      </div>
    </div>
  );
}

export default Stepshome;
