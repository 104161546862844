import React, { useState } from "react";
import { useDropzone } from "react-dropzone";

const CVUploader = () => {
  const [file, setFile] = useState(null);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles[0]);
    },
  });

  const handleUpload = async () => {
    if (!file) return;

    // Prepare the form data
    const formData = new FormData();
    formData.append("cv", file);

    try {
      // Send the file to the backend API using fetch
      const response = await fetch("https://myapp.finaran.com:5000/api/uploadcv", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        console.log("CV uploaded and analyzed:", data);
        alert("CV uploaded and analyzed successfully");
      } else {
        console.error("Error uploading CV:", response.statusText);
        alert("Failed to upload CV");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while uploading CV");
    }
  };

  return (
    <div {...getRootProps()} className="dropzone">
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the file here ...</p>
      ) : (
        <p>Drag 'n' drop or click to upload CV</p>
      )}
      {file && (
        <div>
          <p>Selected file: {file.name}</p>
          <button onClick={handleUpload}>Upload and Analyze CV</button>
        </div>
      )}
    </div>
  );
};

export default CVUploader;
