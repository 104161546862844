import React, { useState, useEffect } from "react";
import { Button, message, Steps, theme, notification } from "antd";
import Professional from "./Professional";
import EducationalForm from "./Educational";
import Employment from "./Employment";
import SelfData from "./SelfData";
import axios from "axios";
import DocUpload from "./DocUpload";
import { url } from "../../url/url";
import Signature from "./Signature";

const BGVStepMethod = () => {
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const [formValues, setFormValues] = useState({
    basic: {},
    educational: {},
    employment: {},
    signature: {},
    professional: {},
    documents: {},
  });
  const [jsonId, setJsonId] = useState("");
  const [loading, setLoading] = useState(false);



  const handleSave = (values, step) => {
    // For all steps, save values directly
    setFormValues((prevValues) => ({
        ...prevValues,
        [step]: values,
    }));
    console.log("Handlesave");
};


  useEffect(() => {
    if (jsonId) {
      console.log("Updated jsonId:", jsonId);
    }
  }, [jsonId]);

  const steps = [
    {
      title: "Personal Details",
      content: (
        <SelfData
          onSave={(values) => handleSave(values, "basic")}
          initialValues={formValues.basic}
        />
      ),
    },
    {
      title: "Educational Details",
      content: (
        <EducationalForm
          onSave={(values) => handleSave(values, "educational")}
          initialValues={formValues.educational}
        />
      ),
    },
    {
      title: "Employment Details",
      content: (
        <Employment
          onSave={(values) => handleSave(values, "employment")}
          initialValues={formValues.employment}
        />
      ),
    },
    {
      title: "Signature",
      content: (
        <Signature
          onSave={(values) => handleSave(values, "signature")}
          initialValues={formValues.signature}
        />
      ),
    },
    {
      title: "Professional Details",
      content: (
        <Professional
          onSave={(values) => handleSave(values, "professional")}
          initialValues={formValues.professional}
        />
      ),
    },
    {
      title: "Mandatory Documents",
      content: <DocUpload jsonId={jsonId} />,
    },
  ];

  const handleEdit = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const email = localStorage.getItem("email");
      const value = "Form submitted";
      const res = await axios.put(
        `${url}/api/updatestatus/${email}/${dbName}`,
        { value }
      );
      console.log("res.data.status", res.data.status);
      notification.success({ message: "BGV Initiated" });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const next = async () => {
    const currentStepKey = getCurrentStepKey();
    const currentStepData = formValues[currentStepKey];

    if (currentStepData && Object.keys(currentStepData).length > 0) {
      if (current === 4) {
        try {
          const dbname = localStorage.getItem("dbName");
          console.log(formValues,"form");
          const response = await axios.post(
            "https://myapp.finaran.com:5000/api/convert_pdf",
            {
              basic: formValues.basic,
              educational: formValues.educational,
              employment: formValues.employment,
              professional: formValues.professional,
              signature: formValues.signature,
              dbName: dbname,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
              responseType: "json",
            }
          );

          console.log("Response from convert_pdf API:", response.data);
          setJsonId(response.data._id);
          setLoading(false);
          setCurrent(current + 1);
          handleEdit();
        } catch (error) {
          console.error("Error saving form:", error);
          console.log(
            "Detailed error response from the server:",
            error.response
          );
          message.error("Error saving form. Please try again.");
        }
      } else {
        setCurrent(current + 1);
      }
    } else {
      message.warning(
        `Please fill in the details and click on Save for ${steps[current].title}`
      );
    }
  };

  const done = async () => {
    message.success("Form submitted successfully!");
    setFormValues({
      basic: {},
      educational: {},
      employment: {},
      signature: {},
      professional: {},
      documents: {},
    });
    setJsonId("");
    setCurrent(0);
  };

  const prev = () => {
    if (current > 0) {
      const currentStepKey = getCurrentStepKey();
      setFormValues((prevValues) => ({
        ...prevValues,
        [currentStepKey]: formValues[currentStepKey],
      }));
      setCurrent(current - 1);
    }
  };

  const isLastStep = current === steps.length - 1;
  const isDoneEnabled = isLastStep && formValues.professional;

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const getCurrentStepKey = () => {
    return Object.keys(formValues)[current];
  };

  const contentStyle = {
    lineHeight: "260px",
    textAlign: "center",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 26,
  };

  return (
    <>
      <Steps
        current={current}
        items={items}
        style={{ marginTop: "40px", padding: "10px" }}
      />
      <div style={contentStyle}>
        {loading ? (
          <p>Loading...</p>
        ) : (
          React.cloneElement(steps[current].content, {
            onSave: (values) => handleSave(values, getCurrentStepKey()),
            initialValues: {
              ...formValues[getCurrentStepKey()],
              jsonId: jsonId,
            },
          })
        )}
      </div>
      <div
        style={{
          marginTop: 24,
          justifyItems: "center",
        }}
      >
        {current > 0 && (
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={() => prev()}
          >
            Previous
          </Button>
        )}
        {isLastStep && (
          <Button
            type="primary"
            onClick={() => done()}
            disabled={!isDoneEnabled}
          >
            Done
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
      </div>
    </>
  );
};

export default BGVStepMethod;
