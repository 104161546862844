import React, { useState } from 'react';
import axios from 'axios';

const ResumeForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        jobTitle: '',
        address: '',
        experience: '',
    });

    const [resumeFile, setResumeFile] = useState(null);

    // Handle file upload
    const handleFileChange = (e) => {
        setResumeFile(e.target.files[0]);
    };

    // Submit the resume to the backend for parsing
    const handleResumeUpload = async () => {
        const formData = new FormData();
        formData.append('resume', resumeFile);

        try {
            const response = await axios.post(`https://myapp.finaran.com:5000/api/upload-resume`, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });

            if (response.data) {
                // Extract fields from response data
                const extractedData = parseResponseData(response.data);
                setFormData((prevData) => ({
                    ...prevData,
                    ...extractedData,
                }));
            }
        } catch (error) {
            console.error("Error uploading resume: ", error);
        }
    };

    // Function to parse response data
    const parseResponseData = (data) => {
        const name = data.spacy_entities.find(([text, label]) => label === 'PERSON')?.[0] || '';
        const email = ''; // Assuming you have a way to extract email (not shown in the response)
        const phone = ''; // Assuming you have a way to extract phone (not shown in the response)
        const jobTitle = data.extracted_text.split("\n").find(line => line.includes("ROLE:"))?.replace("ROLE:", "").trim() || '';
        const experience = data.extracted_text.split("PROJECT EXPERIENCE HIGHLIGHTS").pop().trim() || '';

        return {
            name,
            email,
            phone,
            jobTitle,
            address: '', // If you have an address, parse accordingly
            experience,
        };
    };

    // Handle input change in case user wants to edit the pre-filled info
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Submitted Form Data: ", formData);
    };

    return (
        <div className="resume-form">
            <h1>Upload Resume and Fill Information</h1>

            <input type="file" accept=".pdf,.docx,.txt" onChange={handleFileChange} />
            <button onClick={handleResumeUpload}>Upload and Extract Info</button>

            <form onSubmit={handleSubmit}>
                <div>
                    <label>Name:</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <label>Email:</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <label>Phone:</label>
                    <input
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <label>Job Title:</label>
                    <input
                        type="text"
                        name="jobTitle"
                        value={formData.jobTitle}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <label>Address:</label>
                    <input
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <label>Experience:</label>
                    <textarea
                        name="experience"
                        value={formData.experience}
                        onChange={handleInputChange}
                    ></textarea>
                </div>

                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

export default ResumeForm;
