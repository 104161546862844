// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
  }
  
  .app {
    text-align: center;
    padding: 20px;
  }
  
  .app-header {
    background-color: #282c34;
    padding: 20px;
    color: white;
  }
  
  .search-bar {
    margin: 20px 0;
  }
  
  .search-bar input {
    padding: 10px;
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .search-bar button {
    padding: 10px 20px;
    background-color: #007bff;
    border: none;
    color: white;
    cursor: pointer;
    margin-left: 10px;
    border-radius: 4px;
  }
  
  .result-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .resume-card {
    background-color: white;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .loading {
    font-size: 18px;
    margin: 20px;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/AI.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,yBAAyB;IACzB,SAAS;IACT,UAAU;EACZ;;EAEA;IACE,kBAAkB;IAClB,aAAa;EACf;;EAEA;IACE,yBAAyB;IACzB,aAAa;IACb,YAAY;EACd;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,uBAAuB;IACvB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,wCAAwC;IACxC,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,YAAY;EACd","sourcesContent":["body {\n    font-family: Arial, sans-serif;\n    background-color: #f5f5f5;\n    margin: 0;\n    padding: 0;\n  }\n  \n  .app {\n    text-align: center;\n    padding: 20px;\n  }\n  \n  .app-header {\n    background-color: #282c34;\n    padding: 20px;\n    color: white;\n  }\n  \n  .search-bar {\n    margin: 20px 0;\n  }\n  \n  .search-bar input {\n    padding: 10px;\n    width: 300px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  \n  .search-bar button {\n    padding: 10px 20px;\n    background-color: #007bff;\n    border: none;\n    color: white;\n    cursor: pointer;\n    margin-left: 10px;\n    border-radius: 4px;\n  }\n  \n  .result-list {\n    display: flex;\n    flex-direction: column;\n    gap: 15px;\n    max-width: 600px;\n    margin: 0 auto;\n  }\n  \n  .resume-card {\n    background-color: white;\n    padding: 20px;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n    text-align: left;\n  }\n  \n  .loading {\n    font-size: 18px;\n    margin: 20px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
