// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.review-container {
    font-family: 'Arial', sans-serif;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .review-header {
    background-color: #6f8ea4;
    color: white;
    padding: 20px;
    text-align: center;
  }
  
  .employee-info {
    margin: 20px 0;
  }
  
  .info-row {
    display: flex;
    justify-content: space-between;
  }
  
  .info-item {
    width: 45%;
    margin: 10px 0;
  }
  
  .label {
    font-weight: bold;
  }
  
  .performance-metrics {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .metric-item {
    width: 22%;
    background-color: #f0f4f8;
    padding: 20px;
    border-radius: 8px;
  }
  
  .metric-value {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    background-color: #d9e9f2;
    border-radius: 50%;
    padding: 10px;
  }
  
  .comments-section {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .comments {
    width: 45%;
  }
  
  .comment-box {
    width: 100%;
    height: 100px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/employee/Appraisal.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,iBAAiB;IACjB,cAAc;IACd,aAAa;EACf;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,UAAU;IACV,cAAc;EAChB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;EAClB;;EAEA;IACE,UAAU;IACV,yBAAyB;IACzB,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;EACf;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;EAClB;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB","sourcesContent":[".review-container {\n    font-family: 'Arial', sans-serif;\n    max-width: 1000px;\n    margin: 0 auto;\n    padding: 20px;\n  }\n  \n  .review-header {\n    background-color: #6f8ea4;\n    color: white;\n    padding: 20px;\n    text-align: center;\n  }\n  \n  .employee-info {\n    margin: 20px 0;\n  }\n  \n  .info-row {\n    display: flex;\n    justify-content: space-between;\n  }\n  \n  .info-item {\n    width: 45%;\n    margin: 10px 0;\n  }\n  \n  .label {\n    font-weight: bold;\n  }\n  \n  .performance-metrics {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 20px;\n  }\n  \n  .metric-item {\n    width: 22%;\n    background-color: #f0f4f8;\n    padding: 20px;\n    border-radius: 8px;\n  }\n  \n  .metric-value {\n    font-size: 32px;\n    font-weight: bold;\n    text-align: center;\n    margin-bottom: 10px;\n    background-color: #d9e9f2;\n    border-radius: 50%;\n    padding: 10px;\n  }\n  \n  .comments-section {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 20px;\n  }\n  \n  .comments {\n    width: 45%;\n  }\n  \n  .comment-box {\n    width: 100%;\n    height: 100px;\n    border: 1px solid #ccc;\n    margin-bottom: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
