import { ArrowRightOutlined } from "@ant-design/icons";
import React from "react";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import "./chatbox.css";
import customIcon from "/home/finan/FinaranEMS/Finan_EMS-Frontend/src/images/robot-assistant (1).png";

const theme = {
  background: "#f5f8fb",
  fontFamily: "Arial, Helvetica, sans-serif",
  headerBgColor: "#f0f5f5",
  headerFontColor: "black",
  headerFontSize: "15px",
  botBubbleColor: "#007bff",
  botFontColor: "#fff",
  userBubbleColor: "#fff",
  userFontColor: "#4a4a4a",
};
const Chatbox = () => {
  const handleUserMessage = (message) => {
    const lowerCaseMessage = message.toLowerCase();

    if (
      lowerCaseMessage.includes("good morning") ||
      lowerCaseMessage.includes("good afternoon")
    ) {
      return "Hello there! How can I assist you today?";
    } else if (lowerCaseMessage.includes("goodbye")) {
      return "Goodbye! Have a great day!";
    } else if (lowerCaseMessage.includes("help")) {
      return "Sure! What kind of help do you need?";
    } else {
      return "Sorry, I didn't understand that. Could you please clarify?";
    }
  };

  const steps = [
    {
      id: "1",
      message: "Hi! Welcome to Finaran, How can I help you?",
      trigger: "userInput",
    },
    {
      id: "userInput",
      user: true,
      trigger: ({ value }) => {
        if (
          value.toLowerCase().includes("hi") ||
          value.toLowerCase().includes("hello")
        ) {
          return "Options";
        } else if (value.toLowerCase().includes("leave")) {
          return "leaveOptions";
        } else if (value.toLowerCase().includes("claim")) {
          return "claimOptions";
        } else if (value.toLowerCase().includes("timesheet")) {
          return "timesheet";
        } else if (value.toLowerCase().includes("travel")) {
          return "travel";
        } else if (value.toLowerCase().includes("project")) {
          return "project";
        } else if (value.toLowerCase().includes("employee")) {
          return "employee";
        } else if (value.toLowerCase().includes("hrhome")) {
          return "hrhome";
        } else if (value.toLowerCase().includes("projecttracking")) {
          return "projecttracking";
        } else if (value.toLowerCase().includes("appraisal")) {
          return "appraisal";
        } else if (value.toLowerCase().includes("support")) {
          return "support";
        } else {
          return "response";
        }
      },
    },
    {
      id: "Options",
      message:
        "Hi! it is good to see you!. What information are you looking for? Please use the navigation below or ask me anything about Finaran App.",
      trigger: "showOptions",
    },
    {
      id: "showOptions",
      options: [
        { value: "leaveOptions", label: "Leave", trigger: "leaveOptions" },
        { value: "claimOptions", label: "Claim", trigger: "claimOptions" },
        { value: "timesheet", label: "Time Sheet", trigger: "timesheet" },
        { value: "project", label: "Project", trigger: "project" },
        { value: "employee", label: "Employee", trigger: "employee" },
        { value: "hrhome", label: "HR Functions", trigger: "hrhome" },
        {
          value: "projecttracking",
          label: "Project Tracking",
          trigger: "projecttracking",
        },
        { value: "appraisal", label: "Appraisal", trigger: "appraisal" },
        { value: "support", label: "Support", trigger: "support" },
      ],
    },
    {
      id: "leaveOptions",
      message: "Please choose an option:",
      trigger: "showLeaveOptions",
    },
    {
      id: "showLeaveOptions",
      options: [
        { value: "applyLeave", label: "Apply Leave", trigger: "applyLeave" },
        { value: "leaveReport", label: "Leave Report", trigger: "leaveReport" },
        {
          value: "leaveApproval",
          label: "Leave Approval",
          trigger: "leaveApproval",
        },
        { value: "deleteLeave", label: "Delete Leave", trigger: "deleteLeave" },
      ],
    },
    {
      id: "claimOptions",
      message: "Please choose an option:",
      trigger: "showClaimOptions",
    },
    {
      id: "showClaimOptions",
      options: [
        { value: "applyClaim", label: "Apply Claim", trigger: "applyClaim" },
        { value: "claimReport", label: "Claim Report", trigger: "claimReport" },
        {
          value: "claimApproval",
          label: "Claim Approval",
          trigger: "claimApproval",
        },
      ],
    },
    {
      id: "timesheet",
      message: "Please choose an option:",
      trigger: "showTimesheetOptions",
    },
    {
      id: "showTimesheetOptions",
      options: [
        { value: "submit", label: "Submit Timesheet", trigger: "submit" },
        { value: "report", label: "Report Timesheet", trigger: "report" },
        { value: "approve", label: "Approve Timesheet", trigger: "approve" },
        { value: "unlock", label: "Unlock Timesheet", trigger: "unlock" },
        { value: "reject", label: "Reject Timesheet", trigger: "reject" },
      ],
    },
    {
      id: "travel",
      message: "Please choose an option:",
      trigger: "showTravelOptions",
    },
    {
      id: "showTravelOptions",
      options: [
        {
          value: "travelRequest",
          label: "Apply Request",
          trigger: "travelRequest",
        },
        {
          value: "traveReport",
          label: "Travel Report",
          trigger: "travelReport",
        },
        {
          value: "travelStatus",
          label: "Travel Status",
          trigger: "travelStatus",
        },
      ],
    },
    {
      id: "project",
      message: "Please choose an option:",
      trigger: "showProjectOptions",
    },
    {
      id: "showProjectOptions",
      options: [
        { value: "addProject", label: "Add Project", trigger: "addProject" },
        { value: "editProject", label: "Edit Project", trigger: "editProject" },
        { value: "viewProject", label: "View Project", trigger: "viewProject" },
        {
          value: "deleteProject",
          label: "Delete Project",
          trigger: "deleteProject",
        },
        { value: "managetask", label: "Manage Task", trigger: "managetask" },
      ],
    },
    {
      id: "employee",
      message: "Please choose an option:",
      trigger: "showEmployeeOptions",
    },
    {
      id: "showEmployeeOptions",
      options: [
        { value: "addemployee", label: "Add Employee", trigger: "addemployee" },
        {
          value: "editemployee",
          label: "Edit Employee",
          trigger: "editemployee",
        },
        {
          value: "viewemployee",
          label: "View Employee",
          trigger: "viewemployee",
        },
        {
          value: "deleteemployee",
          label: "Delete Employee",
          trigger: "deleteemployee",
        },
      ],
    },
    {
      id: "hrhome",
      message: "Please choose an option:",
      trigger: "showHROptions",
    },
    {
      id: "showHROptions",
      options: [
        {
          value: "manageemployee",
          label: "Manage Employee",
          trigger: "manageemployee",
        },
        {
          value: "performanceupload",
          label: "Performance Upload",
          trigger: "performanceupload",
        },
        { value: "letter", label: "Letters", trigger: "letter" },
        {
          value: "workanniversaries",
          label: "Work Anniversaries",
          trigger: "workanniversaries",
        },
        { value: "birthdays", label: "Birthdays", trigger: "birthdays" },
        {
          value: "lowleavebalance",
          label: "Low Leave Balance",
          trigger: "lowleavebalance",
        },
        { value: "assetreport", label: "Asset Report", trigger: "assetreport" },
        {
          value: "emergencycontact",
          label: "Emergency Contact",
          trigger: "emergencycontact",
        },
        {
          value: "bgverify",
          label: "Background Verification",
          trigger: "bgverify",
        },
        { value: "offerletter", label: "Offer Letter", trigger: "offerletter" },
        {
          value: "inactiveemployee",
          label: "Inactive Employees",
          trigger: "inactiveemployee",
        },
        { value: "postedjobs", label: "Posted Jobs", trigger: "postedjobs" },
        { value: "hrtraining", label: "Training", trigger: "hrtraining" },
      ],
    },
    {
      id: "offerletter",
      message: "Please choose an option:",
      trigger: "showletterOptions",
    },
    {
      id: "showletterOptions",
      options: [
        {
          value: "companydetails",
          label: "Company Details",
          trigger: "companydetails",
        },
        {
          value: "generate",
          label: "Generate Offer Letter",
          trigger: "generate",
        },
      ],
    },
    {
      id: "postedjobs",
      message: "Please choose an option:",
      trigger: "showJobOptions",
    },
    {
      id: "showJobOptions",
      options: [{ value: "addjobs", label: "New Jobs", trigger: "addjobs" }],
    },
    {
      id: "projecttracking",
      message: "Please choose an option:",
      trigger: "showPrjOptions",
    },
    {
      id: "showPrjOptions",
      options: [
        {
          value: "featureboard",
          label: "Future Board",
          trigger: "featureboard",
        },
        {
          value: "activityboard",
          label: "Project Activity Board",
          trigger: "activityboard",
        },
        {
          value: "backlogboard",
          label: "Backlog Board",
          trigger: "backlogboard",
        },
        { value: "report", label: "Report", trigger: "report" },
      ],
    },
    {
      id: "featureboard",
      message: "Please choose an option:",
      trigger: "showFeatureOptions",
    },
    {
      id: "showFeatureOptions",
      options: [
        { value: "addfeature", label: "Add Feature", trigger: "addfeature" },
      ],
    },
    {
      id: "backlogboard",
      message: "Please choose an option:",
      trigger: "showActivityOptions",
    },
    {
      id: "showActivityOptions",
      options: [
        { value: "addactivity", label: "Add Activity", trigger: "addactivity" },
      ],
    },
    {
      id: "support",
      message: "Please choose an option:",
      trigger: "showSupportOptions",
    },
    {
      id: "showSupportOptions",
      options: [
        { value: "job", label: "Job Openings", trigger: "job" },
        { value: "training", label: "Training", trigger: "training" },
      ],
    },
    {
      id: "training",
      message: "Please choose an option:",
      trigger: "showTrainingOptions",
    },
    {
      id: "showTrainingOptions",
      options: [
        { value: "mycourse", label: "My Course", trigger: "mycourse" },
        { value: "allcourse", label: "All Course", trigger: "allcourse" },
      ],
    },
    {
      id: "applyLeave",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! use this{" "}
          <a href="https://myapp.finaran.com/home/leaveapply">link </a>or Go to
          My Request <ArrowRightOutlined /> Leave <ArrowRightOutlined /> Apply
          Leave. Select the apply leave tile and provide details like:\n- Start
          date\n- End date\n- Reason for leave.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "leaveReport",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! use this{" "}
          <a href="https://myapp.finaran.com/home/EmpLeaveReport">link </a>or Go
          to My Report <ArrowRightOutlined /> Leave Report. Here, you may view
          your leave report.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "leaveApproval",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! use this{" "}
          <a href="https://myapp.finaran.com/home/managerapproval">link </a>or
          Go to My Approvals <ArrowRightOutlined /> Leave. Here, you may verify
          the approval of your leave.',
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "deleteLeave",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this{" "}
          <a href="https://myapp.finaran.com/home/managerapproval">link </a>or
          Go to My Report <ArrowRightOutlined /> Leave Report. By clicking the
          delete option, you can remove the leave request you already submitted.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "applyClaim",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this{" "}
          <a href="https://myapp.finaran.com/home/claims">link </a>or Go to My
          Request <ArrowRightOutlined /> Claim. Select the Claim and provide
          details like:\n- Expense Date\n- Expense Description\n- Amount\n-
          Reimbursement Type. Finally Upload the Document for your Claim.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "claimReport",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this{" "}
          <a href="https://myapp.finaran.com/home/claimstatus">link </a>or Go to
          My Report <ArrowRightOutlined /> My Expense Report. Here, you may view
          your Claim report.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "claimApproval",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure!Use this{" "}
          <a href="https://myapp.finaran.com/home/claimapproval">link </a>or Go
          to My Approvals <ArrowRightOutlined /> Claim. Here, you may verify the
          approval of your claim.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "submit",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure!Use this{" "}
          <a href="https://myapp.finaran.com/home/timesheet">link </a>or Go to
          My work <ArrowRightOutlined /> Timesheet. Select the Add row button
          and provide the details like \n - Project \n - Task \n - Fill the
          worked hours and then submit it.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "report",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this{" "}
          <a href="https://myapp.finaran.com/home/timesheetsummary">link </a>or
          Go to My Report <ArrowRightOutlined /> Timesheet. Here, you may verify
          your timesheet report.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "approve",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure!Use this{" "}
          <a href="https://myapp.finaran.com/home/pending">link </a>or Go to My
          Approvals <ArrowRightOutlined /> Timesheet. Here, you may verify the
          approval of your timesheet.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "unlock",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure!Use this{" "}
          <a href="https://myapp.finaran.com/home/timesheet">link </a>or Go to
          My work <ArrowRightOutlined /> Timesheet. After selecting the "Unlock
          Timesheet" button and make the necessary changes, then submit the
          timesheet.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "reject",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure!Use this{" "}
          <a href="https://myapp.finaran.com/home/pending">link </a>or Go to My
          Approvals <ArrowRightOutlined /> Timesheet. In the event that your
          timesheet is denied here, you should unlock timesheet, make the
          necessary adjustments, and then submit it.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "travelRequest",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure!Use this{" "}
          <a href="https://myapp.finaran.com/home/travelform">link </a>or Go to
          My Request <ArrowRightOutlined /> TravelRequest. Select the Raise
          Travel Request button and provide the details like \n- Destination
          Country,State and City \n- Origin State, Country and City \n-
          Departure date \n- Return date \n-Travel purpose and then submit it.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "travelReport",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this{" "}
          <a href="https://myapp.finaran.com/home/travelreport">link </a>or Go
          to My Report <ArrowRightOutlined /> TravelReport. Here, you may verify
          your timesheet report by providing the details like \n- From Date \n-
          To Date.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "travelStatus",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this{" "}
          <a href="https://myapp.finaran.com/home/travelapproval">link </a>or Go
          to My Approval <ArrowRightOutlined /> Travel. Here, you may verify
          your travel report is approved or rejected.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "addProject",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this{" "}
          <a href="https://myapp.finaran.com/home/addproject">link </a>or Go to
          My Work <ArrowRightOutlined /> Project. Select the add project button
          and provide details like:\n- Project Code, Name and Manager\n- Client
          Name\n- Start and end date\n- select employees and then submit it.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "viewProject",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure!Use this{" "}
          <a href="https://myapp.finaran.com/home/project">link </a>or Go to My
          Work <ArrowRightOutlined /> Project. Here, you may view your Project
          report.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "editProject",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure!Use this{" "}
          <a href="https://myapp.finaran.com/home/project">link </a>or Go to My
          Work <ArrowRightOutlined /> Project. By clicking edit option You can
          make the necessary changes and then submit it.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "deleteProject",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure!Use this{" "}
          <a href="https://myapp.finaran.com/home/project">link </a>or Go to My
          Work <ArrowRightOutlined /> Project. By clicking delete option You can
          remove the project detail.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "managetask",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this{" "}
          <a href="https://myapp.finaran.com/home/addtask">link </a>or Go to My
          Work <ArrowRightOutlined /> Project. By clicking Manage Task button
          You can manage the task.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "addemployee",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this{" "}
          <a href="https://myapp.finaran.com/home/empdetails">link </a>or Go to
          My Work <ArrowRightOutlined /> Employee. Select the new employee
          button and provide details like:\n- Personal Information\n-
          Professional Information\n- Contact Information and then submit it.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "viewemployee",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this{" "}
          <a href="https://myapp.finaran.com/home/employee">link </a>or Go to My
          Work <ArrowRightOutlined /> Employee. Here, you may view your employee
          report.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "editemployee",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this{" "}
          <a href="https://myapp.finaran.com/home/employee">link </a>or Go to My
          Work <ArrowRightOutlined /> Employee. By clicking edit option You can
          make the necessary changes and then submit it.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "deleteemployee",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this{" "}
          <a href="https://myapp.finaran.com/home/employee">link </a>or Go to My
          Work <ArrowRightOutlined /> Employee. By clicking delete option You
          can remove the employee detail.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "manageemployee",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this{" "}
          <a href="https://myapp.finaran.com/home/employee">link </a>or Go to My
          Work <ArrowRightOutlined /> HRHome. Select the Manage Employee tile
          and you can view or edit or add or inactive the employee details.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "performanceupload",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this{" "}
          <a href="https://myapp.finaran.com/home/ReviewTable">link </a>or Go to
          My Work <ArrowRightOutlined /> HRHome. Select the Performance Upload
          tile, here you can view your performance report.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "letter",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this{" "}
          <a href="https://myapp.finaran.com/home/letter">link </a>or Go to My
          Work <ArrowRightOutlined /> HRHome. Select the Letter tile, here you
          can view your letters.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "workanniversaries",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this{" "}
          <a href="https://myapp.finaran.com/home/workanni">link </a>or Go to My
          Work <ArrowRightOutlined /> HRHome. Select the Work Anniversary tile,
          here you can view employees anniversary report.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "birthdays",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this{" "}
          <a href="https://myapp.finaran.com/home/birthday">link </a>or Go to My
          Work <ArrowRightOutlined /> HRHome. Select the Birthday tile, here you
          can view employees birthday report.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "lowleavebalance",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this{" "}
          <a href="https://myapp.finaran.com/home/lowleave">link </a>or Go to My
          Work <ArrowRightOutlined /> HRHome. Select the Low Leave Balance tile,
          here you can view employees leavebalance report.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "assetreport",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this{" "}
          <a href="https://myapp.finaran.com/home/asset">link </a>or Go to My
          Work <ArrowRightOutlined /> HRHome. Select the Asset Report tile, here
          you can view your asset report.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "emergencycontact",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this{" "}
          <a href="https://myapp.finaran.com/home/contacts">link </a>or Go to My
          Work <ArrowRightOutlined /> HRHome. Select the Emergency Contact tile,
          here you can view your employees emergency contact numbers.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "bgverify",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this <a href="https://myapp.finaran.com/home/emp">link </a>
          or Go to My Work <ArrowRightOutlined /> HRHome. Select the Background
          Verification tile, here you can view your employees background
          Verification report.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "companydetails",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this{" "}
          <a href="https://myapp.finaran.com/home/offerletter">link </a>or Go to
          My Work <ArrowRightOutlined /> HRHome <ArrowRightOutlined /> Offer
          Letter tile. Select the Company Details button, make the necessary
          changes you need and then submit it.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "generate",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this{" "}
          <a href="https://myapp.finaran.com/home/offerletter">link </a>or Go to
          My Work <ArrowRightOutlined /> HRHome <ArrowRightOutlined /> Offer
          Letter tile. Select the generate offer letter button, provide the
          necessary details and then generate it .
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "letter",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this{" "}
          <a href="https://myapp.finaran.com/home/letter">link </a>or Go to My
          Work <ArrowRightOutlined /> HRHome. Select the Letter tile, here you
          can view your letters.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "inactiveemployee",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this{" "}
          <a href="https://myapp.finaran.com/home/inactive">link </a>or Go to My
          Work <ArrowRightOutlined /> HRHome. Select the Inactive Employee tile,
          here you can view the inactive employee details.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "addjobs",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this <a href="https://myapp.finaran.com/home/post">link </a>
          or Go to My Work <ArrowRightOutlined /> HRHome <ArrowRightOutlined />{" "}
          Posted Jobs tile. Select the new job button, provide the necessary
          details and then post it.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "hrtraining",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this{" "}
          <a href="https://myapp.finaran.com/home/training">link </a>or Go to My
          Work <ArrowRightOutlined /> HRHome. Select the Training tile, then add
          the courses by providing the necessary details.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "addfeature",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this <a href="https://myapp.finaran.com/home/board">link</a>{" "}
          or go to My Work <ArrowRightOutlined /> Project Tracking{" "}
          <ArrowRightOutlined /> Choose Project and Sprint{" "}
          <ArrowRightOutlined /> Select Featureboard tab. Select the Add feature
          button, here you can add the feature by providing the details.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "activityboard",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this <a href="https://myapp.finaran.com/home/board">link</a>{" "}
          or go to My Work <ArrowRightOutlined /> Project Tracking{" "}
          <ArrowRightOutlined /> Choose Project and Sprint. Select the Activity
          Board tab, here you can view your activities.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "addactivity",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this <a href="https://myapp.finaran.com/home/board">link</a>{" "}
          or go to My Work <ArrowRightOutlined /> Project Tracking{" "}
          <ArrowRightOutlined /> Choose Project and Sprint{" "}
          <ArrowRightOutlined /> Select Backlog Board tab. Select the add
          activity button, you can add the activities by providing the necessary
          details.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "report",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this <a href="https://myapp.finaran.com/home/board">link</a>{" "}
          or go to My Work <ArrowRightOutlined /> Project Tracking{" "}
          <ArrowRightOutlined /> Choose Project and Sprint. Select the report
          tab, here you can see the report of your project.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "job",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this{" "}
          <a href="https://myapp.finaran.com/home/jobHome">link</a> or go to
          Support <ArrowRightOutlined /> Job Openings. Here, you can view your
          job openings.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "mycourse",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this{" "}
          <a href="https://myapp.finaran.com/home/Traininghome">link</a> or go
          to Support <ArrowRightOutlined /> Training. Select the My course tab,
          here you can view your my course report.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "allcourse",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this{" "}
          <a href="https://myapp.finaran.com/home/Traininghome">link</a> or go
          to Support <ArrowRightOutlined /> Training. Select the All course tab,
          here you can view your all course report.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "appraisal",
      component: (
        <div
          style={{
            padding: "5px",
            backgroundColor: "#cef5d8",
            borderRadius: "5px",
          }}
        >
          Sure! Use this{" "}
          <a href="https://myapp.finaran.com/home/performancetable">link </a>or
          Go to My Work <ArrowRightOutlined /> Appraisal. Here, you may verify
          your appraisal details by providing the Year and Period.
        </div>
      ),
      trigger: "userInput",
    },
    {
      id: "response",
      message: ({ previousValue }) => handleUserMessage(previousValue),
      trigger: "userInput",
    },
  ];
  return (
    <ThemeProvider theme={theme}>
      <ChatBot
        steps={steps}
        headerTitle="Easy Assist"
        floating={true}
        floatingIcon={
          <img
            src={customIcon}
            alt="ChatBot Icon"
            className="custom-chatbot-icon"
          />
        }
       
      />
    </ThemeProvider>
  );
};
export default Chatbox;
