// import React, { useState, useEffect } from "react";
// import { Card, Row, Col, Table, Progress, message, Select } from "antd";
// import {
//   LineChart,
//   Line,
//   PieChart,
//   Pie,
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   Tooltip,
//   Legend,
// } from "recharts";
// import axios from "axios";
// import { url } from "../../url/url";

// const Dashboard = () => {
//   const [loading, setLoading] = useState(true);
//   const [releases, setReleases] = useState([]);
//   const [project, setProject] = useState([]);
//   const [SelectedProject, setSelectedProject] = useState();
//   const [releaseProgress, setReleaseProgress] = useState([]);
//   const [releaseChallenges, setReleaseChallenges] = useState([]);
//   const user = JSON.parse(localStorage.getItem("user"));

//   useEffect(() => {
//     axios
//       .get("https://myapp.finaran.com:5000/api/releases")
//       .then((response) => {
//         setReleases(response.data);
//         setLoading(false);
//       })
//       .catch((error) => {
//         console.log(error);
//         setLoading(false);
//       });
//     fetchData();
//   }, []);

//   useEffect(() => {
//     if (SelectedProject) {
//       fetchEpic();
//     }
//   }, [SelectedProject]);

//   const fetchEpic = async () => {
//     try {
//       const dbName = localStorage.getItem("dbName");
//       const response = await fetch(`${url}/epic/${SelectedProject}/${dbName}`);
//       if (!response.ok) {
//         throw new Error("Failed to fetch Epic data");
//       }
//       const data = await response.json();
//       console.log(data, "data");
//       setReleases(data);
//     } catch (error) {
//       console.error("Error:", error);
//       throw new Error("An error occurred while fetching sprint details.");
//     }
//   };
//   useEffect(() => {
//     const progressData = [];
//     const challengesData = [];

//     releases.forEach((release) => {
//       progressData.push({
//         name: release.name,
//         progress: release.progress,
//         backgroundColor:
//           "#" + Math.floor(Math.random() * 16777215).toString(16),
//       });

//       challengesData.push({
//         name: release.name,
//         challenges: release.challenges,
//         backgroundColor:
//           "#" + Math.floor(Math.random() * 16777215).toString(16),
//       });
//     });

//     setReleaseProgress(progressData);
//     setReleaseChallenges(challengesData);
//   }, [releases]);

//   const releaseTableColumns = [
//     {
//       title: "Name",
//       dataIndex: "name",
//       key: "name",
//     },
//     {
//       title: "Start Date",
//       dataIndex: "startDate",
//       key: "startDate",
//     },
//     {
//       title: "End Date",
//       dataIndex: "endDate",
//       key: "endDate",
//     },
//     {
//       title: "Deadline",
//       dataIndex: "deadline",
//       key: "deadline",
//     },
//     {
//       title: "Progress",
//       dataIndex: "progress",
//       key: "progress",
//       render: (text, record) => <Progress percent={text} status="active" />,
//     },
//   ];

//   const fetchData = async () => {
//     try {
//       const dbName = localStorage.getItem("dbName");
//       const displayName = `${user.firstName} ${user.lastName}`;
//       const response = await axios.get(
//         `${url}/api/projectsdetails/${user.displayName}/${dbName}`
//       );
//       setProject(response.data);
//     } catch (error) {
//       console.error(error);
//       message.error("Failed to fetch data from the server.");
//     }
//   };
//   const handleProjectchange = (value) => {
//     setSelectedProject(value);
//   };
//   return (
//     <div>
//       <Row gutter={16}>
//         <Col span={8}>
//           <Card
//             title="Release Progress"
//             loading={loading}
//             style={{ justifyContent: "center" }}
//           >
//             <BarChart width={250} height={300} data={releaseProgress}>
//               <XAxis dataKey="name" />
//               <YAxis />
//               <Tooltip />
//               <Legend />
//               <Bar dataKey="progress" fill="#8884d8" />
//             </BarChart>
//           </Card>
//         </Col>
//         <Col span={8}>
//           <Card title="Release Challenges" loading={loading}>
//             <Select
//               placeholder="Select project name"
//               onChange={handleProjectchange}
//               options={project.map((project) => ({
//                 label: project.ProjectName,
//                 value: project.ProjectName,
//               }))}
//             />
//             <PieChart width={230} height={300}>
//               <Pie
//                 dataKey="challenges"
//                 data={releaseChallenges}
//                 fill="#8884d8"
//               />
//               <Tooltip />
//             </PieChart>
//           </Card>
//         </Col>
//         <Col span={8}>
//           <Card title="Release Table" loading={loading}>
//             <Table
//               columns={releaseTableColumns}
//               dataSource={releases}
//               rowKey={(record) => record.id}
//               style={{ overflow: "auto" }}
//             />
//           </Card>
//         </Col>
//       </Row>
//       <Row gutter={16}>
//         <Col span={12}>
//           <Card title="Release Progress Over Time" loading={loading}>
//             <LineChart width={300} height={300} data={releases}>
//               <XAxis dataKey="name" />
//               <YAxis />
//               <Tooltip />
//               <Legend />
//               <Line type="monotone" dataKey="progress" stroke="#8884d8" />
//             </LineChart>
//           </Card>
//         </Col>
//         <Col span={12}>
//           <Card title="Release Challenges Over Time" loading={loading}>
//             <LineChart width={300} height={300} data={releases}>
//               <XAxis dataKey="name" />
//               <YAxis />
//               <Tooltip />
//               <Legend />
//               <Line type="monotone" dataKey="challenges" stroke="#8884d8" />
//             </LineChart>
//           </Card>
//         </Col>
//       </Row>
//     </div>
//   );
// };

// // import React, { useState, useEffect } from "react";
// // import axios from "axios";
// // import { url } from "../url/url";

// // const Dashboard = () => {
// //   const [projects, setProjects] = useState([]);
// //   const [error, setError] = useState(null);
// //   useEffect(() => {
// //     const fetchProjects = async () => {
// //       try {
// //         console.log("About to call fetchprojects");
// //         const authResponse = await axios.post(`${url}/authenticate`, {
// //           username: "bhuvaneshwari.s@financonsulting.co",
// //           password: "Bhuvan@13",
// //         });

// // //         // Step 1: Authenticate with username and password
// // //         const username = "bhuvaneshwari.s@financonsulting.co";
// // //         const password = "Bhuvan@13";
// // //         const response = await axios.post(
// // //           "https://finan-digitaltransformation.atlassian.net/rest/auth/1/session",
// // //           {
// // //             username:"bhuvaneshwari.s@financonsulting.co",

// // //             password:"Bhuvan@13",
// // //           }
// // //         );

// // //         // Step 2: Provide second factor (if needed)
// // // console.log(response,"response");
// //         if (response.data.requiresSecondFactor) {
// //           // Prompt user for second factor (e.g., code from authenticator app)

// //           // Send second factor along with the authentication request

// //           const secondFactorResponse = await axios.post(
// //             "https://finan-digitaltransformation.atlassian.net/rest/auth/1/session",
// //             {
// //               username,

// //               password,

// //               secondFactor,
// //             }
// //           );

// //           // Extract API token from response

// //           const apiToken = secondFactorResponse.data.session.value;

// //           return apiToken;
// //         } else {
// //           // No second factor required, extract API token from response

// //           const apiToken = response.data.session.value;
// //           // const apiToken = authResponse.data.apiToken;
// //           // const apiToken="ATATT3xFfGF0OFVLXm9ILlmEb0TH32lEh2dosE_tWvlEbz6RGKfiEXeWtaNsmqFz2DYrmzyeH4jXbhon18ae_hwSNkear0dnqqTZzYVBV5V6rB79EMLBDAR47dnDy6G9mAFDEjzqPqgm-KEgvwNfVRIP0NWR82tvCHZOT1WAhniw0sxA_odcYlo=2FF4F139";
// //           // Use API token to fetch projects from Jira
// //           const response = await axios.get(
// //             "https://finan-digitaltransformation.atlassian.net/rest/api/3/project",
// //             {
// //               headers: {
// //                 Authorization: `Basic ${Buffer.from(
// //                   `your_username:${apiToken}`
// //                 ).toString("base64")}`,
// //               },
// //             }
// //           );
// //           setProjects(response.data);
// //         }
// //       } catch (error) {
// //         setError(error);
// //       }
// //     };

// //     fetchProjects();
// //   }, []);

// //   if (error) {
// //     return <div>Error: {error.message}</div>;
// //   } else {
// //     return (
// //       <div>
// //         <h1>Jira Projects</h1>
// //         <ul>
// //           {projects.map((project) => (
// //             <li key={project.id}>{project.name}</li>
// //           ))}
// //         </ul>
// //       </div>
// //     );
// //   }
// // };

// // import React, { useState, useEffect } from 'react';
// // import axios from 'axios';

// // const Dashboard = () => {
// //   const [issues, setIssues] = useState([]);

// //   useEffect(() => {

// //     getProjects()
// //   }, []);
// //   const username = "Bhuvanamesi3@gmail.com"
// //   const password = "Bhuvana@1301"
// //   const domain = "finaranems"

// //   const auth = {
// //     username: username,
// //     password: password
// //   };

// //   async function getProjects() {

// //     try {

// //       const baseUrl = 'https://' + domain + '.atlassian.net';

// //       const config = {
// //         method: 'get',
// //         url: baseUrl + '/rest/api/3/project/recent',
// //         headers: { 'Content-Type': 'application/json' },
// //         auth: auth
// //       };
// //       const response = await axios.request(config);
// //       setIssues(response.data);
// //       console.log(response,"res")
// //       return response.data;
// //     } catch (error) {
// //       console.log('error: ')
// //       console.log(error.response.data.errors)
// //     }
// //   }
// //   return (
// //     <div>
// //       <h1>Issues from Jira</h1>
// //       <ul>
// //         {issues.map(issue => (
// //           <li key={issue.key}>{issue.fields.summary}</li>
// //         ))}
// //       </ul>
// //     </div>
// //   );
// // };

// export default Dashboard;
import React, { useEffect, useState } from "react";
import { Bar, Pie } from "react-chartjs-2";
import axios from "axios";
import { url } from "../../url/url";

const Dashboard = () => {
  const [taskData, setTaskData] = useState([]);
  const [issueData, setIssueData] = useState([]);
  const [epicData, setEpicData] = useState([]);
  const [sprintData, setSprintData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (sprintId, epicId) => {
    try {
      const requestBody = { sprintId:"sprint1", epicId:"Tata sky-Epic3" };
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(
        `${url}/api/fetchData/${dbName}`,
        requestBody
      );

      const { tasks, epics, sprints } = response.data;

      setTaskData(tasks ? tasks : null);
      setEpicData(epics ? epics : null);
      setSprintData(sprints ? sprints : null);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  // Example charts
  const taskPriorityData = {
    labels: ["High", "Medium", "Low"],
    datasets: [
      {
        label: "Task Priority",
        data: [
          taskData.filter((task) => task.priority === "High").length,
          taskData.filter((task) => task.priority === "Medium").length,
          taskData.filter((task) => task.priority === "Low").length,
        ],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };

  const epicStatusData = {
    labels: ["To Do", "Doing", "Done"],
    datasets: [
      {
        label: "Issue Status",
        data: [
          epicData.filter((issue) => issue.status === "todo").length,
          epicData.filter((issue) => issue.status === "doing").length,
          epicData.filter((issue) => issue.status === "done").length,
        ],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };

  return (
    <div>
      <h1>Dashboard</h1>
      <div>
        <h2>Task Priority</h2>
        <Bar data={taskPriorityData} />
      </div>
      <div>
        <h2>Epic Status</h2>
        <Pie data={epicStatusData} />
      </div>
      {/* Add more charts for other data */}
    </div>
  );
};

export default Dashboard;
