import React, { useState, useEffect } from "react";
import { Table, Button, message } from "antd";
import {
  FilePdfOutlined,
  FileZipOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import axios from "axios";

const ResultUpload = ({ displayName }) => {
  const [data, setData] = useState([]);

  //   useEffect(() => {
  //     const dbName = localStorage.getItem("dbName");
  //     axios
  //       .get(
  //         `https://myapp.finaran.com:5000/api/get_bson_data/${displayName}/${dbName}`
  //       )
  //       .then((response) => {
  //         const pdfData = new Blob([response.data.pdf_data], { type: "application/pdf" });
  //         console.log(response,pdfData,"response");
  //         setData(pdfData);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching names:", error);
  //         message.error("Error fetching names. Please try again.");
  //       });
  //   }, []);

  const DownloadPDF = async (id) => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(
        `https://myapp.finaran.com:5000/api/get_bson_data/${displayName}/${dbName}`,
        { responseType: "blob" }
      );
      console.log(response,"response");
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = `downloaded_pdf_${displayName}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading PDF:", error);
      message.error("Error downloading PDF. Please try again.");
    }
  };

  const handleDownloadPDF = async (id) => {
    try {
        id="659cf84f4327c5283247f6b7";
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(
        `https://myapp.finaran.com:5000/api/get_pdf/${dbName}/${id}`,
        { responseType: "blob" }
      );
      console.log(response,"response");
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = `downloaded_pdf_${id}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading PDF:", error);
      message.error("Error downloading PDF. Please try again.");
    }
  };

  const handleDownloadZIP = async (id) => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(
        `https://myapp.finaran.com:5000/api/get_zip/${dbName}/${id}`,
        { responseType: "blob" }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = `downloaded_zip_${id}.zip`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading ZIP:", error);
      message.error("Error downloading ZIP. Please try again.");
    }
  };

  const handleDownloadJSON = async (id) => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(
        `https://myapp.finaran.com:5000/api/get_bson_data/${dbName}`
      );

      const entry = response.data.bson_data_list.find(
        (item) => item._id === id
      );

      if (entry) {
        const jsonData = JSON.stringify(entry.bson_data, null, 2);
        const blob = new Blob([jsonData], { type: "application/json" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `downloaded_json_${id}.json`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        console.error("Entry not found for ID:", id);
        message.error("Error downloading JSON. Entry not found.");
      }
    } catch (error) {
      console.error("Error downloading JSON:", error);
      message.error("Error downloading JSON. Please try again.");
    }
  };
  //   const columns = [
  //     // {
  //     //   title: "Name",
  //     //   dataIndex: "name",
  //     //   key: "name",
  //     // },
  //     {
  //       title: "Actions",
  //       key: "actions",
  //       render: (text, record) => (
  //         <div>
  //           {/* <Button
  //             onClick={() => handleDownloadPDF(record.key)}
  //             type="primary"
  //             style={{ marginRight: "8px" }}
  //             icon={<FilePdfOutlined />}
  //           >
  //             PDF
  //           </Button>
  //           <Button
  //             onClick={() => handleDownloadZIP(record.key)}
  //             type="primary"
  //             icon={<FileZipOutlined />}
  //           >
  //             ZIP
  //           </Button>
  //           <Button
  //             onClick={() => handleDownloadJSON(record.key)}
  //             type="primary"
  //             style={{ marginLeft: "8px" }}
  //             icon={<DownloadOutlined />}
  //           >
  //             Data
  //           </Button> */}
  //           <Button
  //           onClick={() => DownloadPDF()}
  //             type="primary"
  //             style={{ marginLeft: "8px" }}
  //             icon={<DownloadOutlined />}

  //           ></Button>
  //         </div>
  //       ),
  //     },
  //   ];

  return (
    <div>
      <Button
        onClick={() => DownloadPDF()}
        type="primary"
        style={{ marginLeft: "8px" }}
        icon={<DownloadOutlined />}
      ></Button>{" "}
    </div>
  );
};

export default ResultUpload;
