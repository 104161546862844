import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  PlusCircleOutlined,
  DeleteFilled,
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Radio,
  Row,
  Col,
  Divider,
  Input,
  message,
  Select,
  Card,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { url } from "../../url/url";

const Assessmentform = () => {
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState([]);
  const [assessment, setAssessment] = useState([]);
  const [optionInput, setOptionInput] = useState("");
  const [answer, setAnswer] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAssessment, setShowAssessment] = useState(false);
  const [showSubmittedQuestions, setShowSubmittedQuestions] = useState(false);
  const [showAddAssessment, setShowAddAssessment] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [courses, setCourses] = useState([]);
  const [form] = Form.useForm();
  const [count, setCount] = useState(0);
  const [selectCourse, setSelectCourse] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const submittedQuestions = selectCourse?.flatMap(
    (course) => course.assessment
  );
  const [minimumMarks, setMinimumMarks] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  // Handle Edit Click
  const handleEdit = () => {
    setIsEditing(true);
    setShowAssessment(true); // Open the form
    form.setFieldsValue({ courseName: selectedCourse }); // Set the selected course to the form
  };

  useEffect(() => {
    fetchCourses();
  }, []);
  const fetchCourses = async () => {
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await axios.get(`${url}/get/courses/${dbName}`);
      setCourses(response.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleCourseChange = (value) => {
    console.log("Selected course:", value);
    setSelectedCourse(value);
    if (value) {
      // Check if value is not null
      fetchSelectCourses(value);
    }
  };

  const fetchSelectCourses = async (course) => {
    const dbName = localStorage.getItem("dbName");
    console.log(selectedCourse, "course");
    try {
      const response = await axios.get(
        `${url}/get/selectedcourses/${course}/${dbName}`
      );
      setSelectCourse(response.data);
      console.log(response.data, "setselect");
    } catch (err) {
      console.log(err.message);
    }
  };

  const questionsPerPage = 3;
  const totalPages = Math.ceil(submittedQuestions.length / questionsPerPage);

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - questionsPerPage);
    }
  };

  const handleNext = () => {
    if (currentIndex + questionsPerPage < submittedQuestions.length) {
      setCurrentIndex(currentIndex + questionsPerPage);
    }
  };

  const handleAssessmentClick = () => {
    setIsEditing(false);
    setShowAssessment(!showAssessment);
  };

  const handleQuestionChange = (e) => {
    setQuestion(e.target.value);
  };

  const handleOptionInputChange = (e) => {
    setOptionInput(e.target.value);
  };

  const handleAddOption = () => {
    const newOptions = [...options, optionInput];
    setOptions(newOptions);
    setOptionInput("");
  };

  const handleDeleteAssessment = async (courseName, question) => {
    const dbName = localStorage.getItem("dbName");
    console.log(courseName, question, "response");
    try {
      const encodedCourseName = encodeURIComponent(courseName);
      const encodedQuestion = encodeURIComponent(question);
      const response = await axios.delete(
        `${url}/remove/assessment/${dbName}/${encodedCourseName}/${encodedQuestion}`
      );
      setSelectCourse((prevSelectCourse) =>
        prevSelectCourse.map((course) => {
          if (course.courseName === courseName) {
            return {
              ...course,
              assessment: course.assessment.filter(
                (assess) => assess.question !== question
              ),
            };
          }
          return course;
        })
      );
      message.success("Assessment deleted successfully");
      window.location.reload();
      // Update the assessment state or reload the data
    } catch (error) {
      message.error("Failed to delete assessment");
    }
  };

  const handleAssessment = async (values) => {
    const dbName = localStorage.getItem("dbName");

    console.log(values.courseName, "id");
    console.log(
      {
        assessment: {
          question: values.question,
          option: options,
          answer: values.answer,
        },
      },
      "Payload"
    );

    try {
      const response = await axios.put(
        `${url}/assessment/${dbName}/${values.courseName}`,
        {
          minimumMarks: values.minimumMarks,
          assessment: {
            question: values.question,
            option: options,
            answer: values.answer,
          },
        }
      );
      const assessment = response.data;
      console.log(assessment, "Assessment");
      setAssessment(assessment);
      form.resetFields();

      message.success("Assessment added successfully");
      setCount(count + 1);
      setQuestion("");
      setOptions([]);
      setAnswer("");
      setIsModalOpen(false);
      setShowSubmittedQuestions(true);
    } catch (error) {
      console.error(error.response.data, "Error Response");
      message.error("Failed to add course");
    }
  };

  return (
    <div>
      <Row justify="space-between">
        <Col>
          <Card
            style={{
              width: "95vh",
              height: "125vh",
              padding: 20,
              borderRadius: 10,
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Row justify="center" style={{ gap: "10vh" }}>
              <h5 style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>
                Submitted Questions and Answers
              </h5>
              {selectedCourse && <EditOutlined onClick={handleEdit} />}
            </Row>
            <br />
            <Form.Item label="Course Name">
              <Select
                onChange={handleCourseChange}
                style={{ width: "60vh", marginTop: "1vh" }}
              >
                {courses.map((course) => (
                  <Select.Option
                    value={course.courseName}
                    key={course.courseName}
                  >
                    {course.courseName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {submittedQuestions.length === 0 ? (
              <h6
                style={{ color: "red", marginTop: "16vh", textAlign: "center" }}
              >
                No Assessment Added
              </h6>
            ) : (
              <>
                {submittedQuestions
                  ?.slice(currentIndex, currentIndex + questionsPerPage)
                  .map((question, index) => (
                    <div
                      key={currentIndex + index}
                      style={{ marginBottom: 20 }}
                    >
                      <Row justify="space-between">
                        <h6
                          style={{
                            margin: "1vh",
                            fontSize: 16,
                            fontWeight: "bold",
                          }}
                        >
                          {currentIndex + index + 1}: {question.question}
                        </h6>
                      </Row>
                      <Row style={{ gap: "3vh" }}>
                        {question.option.map((options, idx) => (
                          <span key={idx} style={{ marginBottom: 10 }}>
                            <Radio
                              value={options}
                              style={{ marginRight: 10 }}
                            />
                            {options}
                          </span>
                        ))}
                      </Row>
                      <Row justify="space-between">
                        <p style={{ fontSize: 14, color: "#5c5959" }}>
                          Answer: {question.answer}
                        </p>

                        <Button
                          type="primary"
                          danger
                          icon={<DeleteFilled />}
                          onClick={() =>
                            handleDeleteAssessment(
                              selectedCourse,
                              question.question
                            )
                          }
                        />
                      </Row>
                      <hr style={{ borderColor: "#ccc", margin: "10px 0" }} />
                    </div>
                  ))}
                {currentIndex > 0 && ( // show previous arrow if not at the beginning
                  <Button
                    type="primary"
                    danger
                    icon={<ArrowLeftOutlined />}
                    onClick={handlePrevious}
                    style={{ float: "left" }}
                  >
                    Previous
                  </Button>
                )}
                {currentIndex + questionsPerPage <
                  submittedQuestions.length && ( // show next arrow if not at the end
                  <Button
                    type="primary"
                    danger
                    icon={<ArrowRightOutlined />}
                    onClick={handleNext}
                    style={{ float: "right" }}
                  >
                    Next
                  </Button>
                )}
              </>
            )}
          </Card>
        </Col>
        <Card
          style={{
            borderRadius: 10,
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            width: "80vh",
            height: "125vh",
          }}
        >
          <Col>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button type="primary" onClick={handleAssessmentClick}>
                {isEditing ? "Edit Questions" : "Add questions"}
              </Button>
            </div>
            <br />

            {showAssessment ? (
              <div>
                <h6 className="text-center">
                  {" "}
                  {isEditing ? "Edit Questions" : "Add questions"}
                </h6>
                <Divider />
                <Form form={form} onFinish={handleAssessment}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 14 }}
                        label="Course Name"
                        name="courseName"
                        rules={[{ required: true }]}
                      >
                        <Select style={{ marginTop: "1vh" }}>
                          {courses.map((course) => (
                            <Select.Option
                              value={course.courseName}
                              key={course.courseName}
                            >
                              {course.courseName}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 14 }}
                        label="Question"
                        name="question"
                        rules={[{ required: true }]}
                      >
                        <Input
                          style={{
                            height: "6vh",
                            fontSize: "14px",
                            marginTop: "1vh",
                          }}
                          onChange={handleQuestionChange}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 14 }}
                        label="Add Options"
                        rules={[{ required: true }]}
                      >
                        <Input
                          style={{
                            height: "6vh",
                            fontSize: "14px",
                            marginTop: "1vh",
                          }}
                          value={optionInput}
                          onChange={handleOptionInputChange}
                        />
                        {optionInput && (
                          <Button
                            icon={<PlusCircleOutlined />}
                            onClick={handleAddOption}
                          >
                            Option
                          </Button>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 14 }}
                        label="Options"
                      >
                        <Row gutter={[16, 16]}>
                          {" "}
                          {/* Adds spacing between options */}
                          {options.map((option, index) => (
                            <Col key={index}>
                              <Radio value={option}>{option}</Radio>
                            </Col>
                          ))}
                        </Row>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 14 }}
                        label="Answer"
                        name="answer"
                        rules={[{ required: true }]}
                      >
                        <Select style={{ marginTop: "1vh" }}>
                          {options.map((option, index) => (
                            <Select.Option value={option}>
                              {option}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    {minimumMarks === null && (
                      <Col span={24}>
                        <Form.Item
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 14 }}
                          label="Minimum Score"
                          name="minimumMarks"
                          rules={[{ required: true }]}
                        >
                          <Input
                            style={{
                              height: "6vh",
                              fontSize: "14px",
                              marginTop: "1vh",
                            }}
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                  <Row justify="space-around">
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Row>
                </Form>
              </div>
            ) : (
              <span style={{ color: "red", marginLeft: "15vh" }}>
                Click Add question button to open form
              </span>
            )}
          </Col>
        </Card>
      </Row>
    </div>
  );
};

export default Assessmentform;
