import React from 'react';
import './Appraisal.css';  
import { Card } from 'antd';

const Appraisal = () => {
  return (
    <Card style={{backgroundColor:"#f2f2f2"}}>
    <div className="review-container">
      {/* Header */}
      <header className="review-header">
        <h1>QUARTERLY PERFORMANCE REVIEW</h1>
      </header>
      
      {/* Employee Information */}
      <section className="employee-info">
        <h2>EMPLOYEE INFORMATION:</h2>
        <div className="info-row">
          <div className="info-item">
            <span className="label">Employee Name:</span>
            <span className="value">Felicia Nyongo</span>
          </div>
          <div className="info-item">
            <span className="label">Department:</span>
            <span className="value">Marketing</span>
          </div>
        </div>
        <div className="info-row">
          <div className="info-item">
            <span className="label">Review Period:</span>
            <span className="value">Q2 2021</span>
          </div>
          <div className="info-item">
            <span className="label">Date of Review:</span>
            <span className="value">July 10, 2021</span>
          </div>
        </div>
      </section>

      {/* Performance Metrics */}
      <section className="performance-metrics">
        <div className="metric-item">
          <div className="metric-value">82%</div>
          <h3>Overall Performance</h3>
          <ul>
            <li>Felicia possesses a disciplined, reliable work ethic.</li>
            <li>She helps team members on projects she's not involved in.</li>
            <li>Felicia makes a strong effort to educate herself and enrich her skills.</li>
          </ul>
        </div>

        <div className="metric-item">
          <div className="metric-value">74%</div>
          <h3>Goals Achieved</h3>
          <ul>
            <li>Felicia achieved 74% of the Q2 traffic and acquisition targets.</li>
            <li>Goals are set high, and 74% is still significant for growth.</li>
            <li>She needs to plan more for press mentions and partnerships.</li>
          </ul>
        </div>

        <div className="metric-item">
          <div className="metric-value">71%</div>
          <h3>Improvement</h3>
          <ul>
            <li>Felicia has demonstrated significant growth as an employee.</li>
            <li>She still has areas to cover, but she’s dedicated to problem-solving.</li>
            <li>She needs to scale experiments and growth hacks.</li>
          </ul>
        </div>

        <div className="metric-item">
          <div className="metric-value">92%</div>
          <h3>Core Values</h3>
          <ul>
            <li>Felicia’s work ethic reflects the company’s core values.</li>
            <li>She demonstrates ownership and active learning.</li>
            <li>She has made significant efforts to learn and make informed decisions.</li>
          </ul>
        </div>
      </section>

      {/* Comments and Signatures */}
      <section className="comments-section">
        <div className="comments">
          <h3>EMPLOYEE COMMENTS:</h3>
          <div className="comment-box"></div>
          <span>Employee Signature:</span>
        </div>
        <div className="comments">
          <h3>REVIEWER COMMENTS:</h3>
          <div className="comment-box"></div>
          <span>Reviewer Signature:</span>
        </div>
      </section>
    </div>
    </Card>
  );
};

export default Appraisal;
