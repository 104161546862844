import React, { useState, useEffect } from "react";
import { Table, Container, Row, Col } from "react-bootstrap";
import {
  Button,
  Input,
  Select,
  Modal,
  message,
  Alert,
  Mentions,
  Badge,
  Descriptions,
  Popconfirm,
  Tooltip,
  notification,
  Checkbox,
} from "antd";
import axios from "axios";
import moment from "moment";
import "./timesheet.css";
import {
  LeftCircleFilled,
  RightCircleFilled,
  DeleteFilled,
  PlusOutlined,
  PlusSquareFilled,
  CopyTwoTone,
  PlusSquareTwoTone,
} from "@ant-design/icons";
import { UnlockOutlined } from "@ant-design/icons";
import { url } from "../../url/url";
import "antd/dist/reset.css";
import Userapprove from "../HR_table/userapprove";
import { Copy16Filled } from "@fluentui/react-icons";

function Timesheet(props) {
  const [status] = useState("Draft");
  const [timestatus, setTimeStatus] = useState("Pending");
  const [billable, setBillable] = useState();
  const [loading, setLoading] = useState(false);
  const [unlockTime, setUnlockTime] = useState("");
  const [currentWeek, setCurrentWeek] = useState(moment());
  const [count, Setcount] = useState(0);
  const [warning, Setwarn] = useState(0);
  const [data, setData] = useState([]);
  const [reason, setReason] = useState("");
  const [entrydata, setEntrydata] = useState([]);
  const weekNumber = currentWeek.clone().isoWeek();
  const startOfWeek = currentWeek.clone().startOf("isoWeek");
  const endOfWeek = currentWeek.clone().endOf("isoWeek");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedSubTask, setSelectedSubTask] = useState(null);
  const [isError, setIsError] = useState(false);
  const [newproject, setNewproject] = useState(null);
  const [subTasks, setSubTasks] = useState([]);
  const isButtonDisabled = !selectedTask || !newproject;
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [days, setDays] = useState([]);
  const [startend, setStartend] = useState([]);
  const locationCheck = localStorage.getItem("switchState");
  const [locationdata, setLocationdata] = useState([]);
  const weekStart = endOfWeek
    .week(weekNumber)
    .startOf("week")
    .add(1, "day");
  const weekStartweek = startOfWeek
    .week(weekNumber)
    .startOf("week")
    .add(1, "day");
  const [tableDays, setTableDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedDayData, setSelectedDayData] = useState(null);
  const [totalhrs, setTotalHours] = useState([]);
  const [copyTimeSheet, setCopyTimeSheet] = useState(false);
  const [allowedPastWeeks, setAllowedPastWeeks] = useState(0);
  const [allowedFutureWeeks, setAllowedFutureWeeks] = useState(0);
  const [managerInfo, setManagerInfo] = useState([]);
  const [hr, setHr] = useState();
  const [email, setEmail] = useState([]);
  const [empCode, setEmpCode] = useState([]);
  const [isTimesheetLocked, setIsTimesheetLocked] = useState(true);
  const [weekend, setWeekend] = useState([]);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [showmodal, setShowmodal] = useState(false);
  const [checkInOutData, setCheckInOutData] = useState([]);

  useEffect(() => {
    const fetchCheckInOutData = async () => {
      const dbName = localStorage.getItem("dbName");

      try {
        const response = await fetch(
          `${url}/checkinout/${dbName}/${user.displayName}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch check-in/out data");
        }
        const data = await response.json();
        setCheckInOutData(data.checkInOutEntries);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchCheckInOutData();
  }, []);

  const getCurrentWeekNumber = () => {
    return currentWeek.clone().isoWeek();
  };

  // Function to get the week number from a specific date
  const getWeekNumber = (date) => {
    return moment(date).isoWeek();
  };

  // Determine if the week is within the allowed range
  const isWeekInAllowedRange = (timesheetDate) => {
    const timesheetWeekNumber = getWeekNumber(timesheetDate);
    const currentWeekNumber = getCurrentWeekNumber();
    const pastTwoWeeks = [timesheetWeekNumber - 1, timesheetWeekNumber - 2];
    const futureTwoWeeks = [
      timesheetWeekNumber + 1,
      timesheetWeekNumber + 2,
      timesheetWeekNumber + 3,
      timesheetWeekNumber + 4,
    ];

    if (
      timesheetWeekNumber === currentWeekNumber ||
      pastTwoWeeks.includes(currentWeekNumber) ||
      futureTwoWeeks.includes(currentWeekNumber) ||
      unlockTime === "Approved"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const timeData = isWeekInAllowedRange(new Date());

  const unlockWeek = (timesheetDate) => {
    const timesheetWeekNumber = getWeekNumber(timesheetDate);
    const currentWeekNumber = getCurrentWeekNumber();

    // Calculate the week number that is allowed (currentWeekNumber - allowedPastWeeks)
    const allowedPastWeekNumber = currentWeekNumber - allowedPastWeeks;

    // Allow unlocking only if the timesheet is within the allowed past weeks and not in the future
    return (
      timesheetWeekNumber <= currentWeekNumber &&
      timesheetWeekNumber >= allowedPastWeekNumber
    );
  };

  // Example usage:
  const unlock = unlockWeek(new Date());

  const parseDate = (dateString) => {
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("/");
    const [hours, minutes, seconds] = timePart.split(":");
    // Note: months are 0-based in JavaScript Date constructor
    return new Date(year, month - 1, day, hours, minutes, seconds);
  };

  const calculateActualHours = (checkInTime, checkOutTime) => {
    const checkInDate = parseDate(checkInTime);
    const checkOutDate = parseDate(checkOutTime);

    const diffInMilliseconds = checkOutDate - checkInDate;

    // Calculate hours and minutes separately
    const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    const remainingMilliseconds = diffInMilliseconds % (1000 * 60 * 60);
    const minutes = Math.floor(remainingMilliseconds / (1000 * 60));

    const formattedHours = String(hours);
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedResult = `${formattedHours}:${formattedMinutes}`;

    return formattedResult;
  };

  const renderActualHours = (startDate) => {
    const checkInOutEntries = checkInOutData;
    const formattedStartDate = startDate.replace(/-/g, "/");

    const checkInOutEntry = checkInOutEntries?.find((entry) => {
      const [datePart] = entry.checkInTime.split(" ");
      return datePart === formattedStartDate;
    });

    if (checkInOutEntry && checkInOutEntry.checkOutTime) {
      // Extract the date part of checkInTime and checkOutTime
      const checkInDate = checkInOutEntry.checkInTime.split(" ")[0];
      const checkOutDate = checkInOutEntry.checkOutTime.split(" ")[0];

      // Compare if the dates are the same
      if (checkInDate === checkOutDate) {
        return calculateActualHours(
          checkInOutEntry.checkInTime,
          checkInOutEntry.checkOutTime
        );
      } else {
        return "0";
      }
    }

    return "0"; // Return 0 if there's no checkInOutEntry or no checkOutTime
  };

  useEffect(() => {
    fetchData2();
    GetHolidays();
  }, []);
  const fetchTasks = async (projcode) => {
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await axios.get(
        `${url}/get/activity/${dbName}/${user.displayName}/${projcode}`
      );
      setSubTasks(response.data);
    } catch (error) {
      console.error("Error fetching tasks:", error.message);
    }
  };

  const GetHolidays = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(`${url}/get/leave/${dbName}`);
      const data = response.data?.officeHolidaysData;

      const formattedEvents = data.map((date) => {
        const [day, month, year] = date.date.split("-");
        const isoDate = `${year}-${month}-${day}`;

        return isoDate;
      });
      setCalendarEvents(formattedEvents);
      setWeekend(response.data.weekend);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchmanager = async () => {
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await axios.get(
        `${url}/api/getUserManager/${user.displayName}/${dbName}`
      );
      const manager = response.data.user.manager;
      const hr = response.data.user.hrName;
      const email = response.data.user.email;
      const empCode = response.data.user.empCode;
      // setManagerInfo(manager);
      setHr(hr);
      setEmail(email);
      setEmpCode(empCode);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const fetchData2 = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(`${url}/get/combined/${dbName}`);
      setTotalHours(response.data[0].totalHours);
      setCopyTimeSheet(response.data[0].copyTimeSheet);
      setAllowedPastWeeks(response.data[0].allowedPastWeeks);
      setAllowedFutureWeeks(response.data[0].allowedFutureWeeks);
    } catch (error) {
      console.error(error);
    }
  };
  const updateDaysAndStartend = () => {
    const updatedDays = [];
    const days = [];
    const updatedStartend = [];

    // Clone the start of the current week to ensure consistency
    let currentWeekStart = startOfWeek.clone();

    for (let i = 0; i < 7; i++) {
      updatedDays.push(
        <div key={i}>
          {currentWeekStart.format("ddd")} {currentWeekStart.format("D")}
        </div>
      );
      days.push(<div key={i}>{currentWeekStart.format("DD")}</div>);
      currentWeekStart.add(1, "day");
    }

    // Reset the cloned start of the week for the second loop
    currentWeekStart = startOfWeek.clone();

    for (let i = 0; i < 7; i++) {
      updatedStartend.push(
        <div key={i}>{currentWeekStart.format("DD-MM-YYYY")}</div>
      );
      currentWeekStart.add(1, "day");
    }

    setDays(updatedDays);
    setStartend(updatedStartend);
    setTableDays(days);
  };
  let messageDisplayed = false;

  // function showMessageOnce() {
  //   if (!messageDisplayed) {
  //     notification.success('Success message');
  //     messageDisplayed = true;
  //   }
  // }

  useEffect(() => {
    fetchdata();
    fetchUnlockTime();
    fetchproject();
    updateDaysAndStartend();
    fetchmanager();
  }, [weekNumber, count]);

  const showModal1 = () => {
    setIsModalOpen1(true);
  };

  const handleOk1 = () => {
    setIsModalOpen1(false);
  };
  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setSelectedTask(null);
    setNewproject(null);
    setEntrydata([
      ...entrydata,
      {
        project: newproject,
        task: selectedTask,
        subtask: selectedSubTask,
        day1: {
          date: "",
          hours: 0,
        },
        day2: {
          date: "",
          hours: 0,
        },
        day3: {
          date: "",
          hours: 0,
        },
        day4: {
          date: "",
          hours: 0,
        },
        day5: {
          date: "",
          hours: 0,
        },
        day6: {
          date: "",
          hours: 0,
        },
        day7: {
          date: "",
          hours: 0,
        },
        TotalHours: 0,
      },
    ]);
  };
  const handleCancel = () => {
    setSelectedTask(null);
    setIsModalOpen(false);
    setNewproject(null);
  };
  const fetchdata = async () => {
    setLoading(true);
    try {
      const dbName = localStorage.getItem("dbName");
      const start = startOfWeek.format("DD-MM-YYYY");
      const end = endOfWeek.format("DD-MM-YYYY");
      const response = await fetch(
        `${url}/timesheet/getinputs/${start}/${user.displayName}/${dbName}`
      );
      const data = await response.json();
      setTimeStatus(data[0].status);
      setManagerInfo(data[0].reportingManager);
      setReason(data[0].reason);
      const dataWithoutIds = await data[0].entrydata.map((entry) => {
        const { _id, ...rest } = entry;
        return rest;
      });
      setData(dataWithoutIds);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setData([]);
      setTimeStatus("");
      setManagerInfo("");
      setLoading(false);
    }
  };
  const fetchUnlockTime = async () => {
    setLoading(true);
    try {
      const dbName = localStorage.getItem("dbName");
      const start = startOfWeek.format("DD-MM-YYYY");
      const end = endOfWeek.format("DD-MM-YYYY");
      const response = await fetch(
        `${url}/unlock/${start}/${user.displayName}/${dbName}`
      );
      const data = await response.json();
      setUnlockTime(data[0].status);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setUnlockTime("");
      setLoading(false);
    }
  };
  const fetchproject = async () => {
    setLoading(true);
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await fetch(
        `${url}/api/projects/${user.displayName}/${dbName}`
      );
      const data = await response.json();
      // Get the current date
      const currentDate = new Date();

      const filteredProjects = data.filter((project) => {
        // Parse the project EndDate as a Date object
        const projectEndDate = new Date(project.EndDate);

        // Include projects where the EndDate is in the future or today
        return projectEndDate >= currentDate;
      });

      setProjects(filteredProjects);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };
  const handleCellChange = (entryIndex, day, value) => {
    // Use a regular expression to check for valid integers
    const validInteger = /^\d+$/;

    // If the input is not a valid integer, reset the value to 0
    if (!validInteger.test(value)) {
      value = "0";
    } else {
      // Ensure the value is a non-negative integer
      let parsedValue = parseInt(value, 10);
      parsedValue = Math.max(parsedValue, 0);

      // Cap the value at 24
      parsedValue = Math.min(parsedValue, 24);

      value = parsedValue.toString();
    }

    const newData = [...data];
    newData[entryIndex][day].hours = Number(value);
    setData(newData);
    setDataWithTotalHours(newData);
    Setwarn(warning + 1);
  };

  const setDataWithTotalHours = (newData) => {
    const updatedData = newData.map((entry) => ({
      ...entry,
      totalhours: TotalForEntryData(entry),
    }));
    setData(updatedData);
  };

  const handleInputComments = (entryIndex, comments, value) => {
    const newValue = value;
    const newData = [...data];
    newData[entryIndex][comments] = newValue;
    setData(newData);
  };

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    // Use a regular expression to check for valid integers
    const validInteger = /^\d+$/;

    if (!validInteger.test(value)) {
      // If the input is not a valid integer, reset the value to 0
      event.target.value = "0";
    } else {
      let parsedValue = parseInt(value, 10);
      if (parsedValue < 0) {
        event.target.value = "0";
      } else if (parsedValue > 24) {
        event.target.value = "24";
      }
    }

    const newEntries = [...entrydata];
    newEntries[index][name].hours = parseInt(event.target.value, 10);
    setEntrydata(newEntries);
    setEntrydataWithTotalHours();
    Setwarn(warning + 1);
  };

  const handleCommentChange = (event, index) => {
    const { name, value } = event.target;
    const newEntries = [...entrydata];
    newEntries[index][name] = value;
    setEntrydata(newEntries);
  };

  const setEntrydataWithTotalHours = () => {
    const updatedEntryData = entrydata.map((row) => ({
      ...row,
      totalhours: TotalForEntryData(row),
    }));
    setEntrydata(updatedEntryData);
  };

  const handleDelete = (index) => {
    const newEntries = [...data];
    newEntries.splice(index, 1);
    Setwarn(warning + 1);
    setData(newEntries);
    setIsError(false);
  };

  const handlenewDelete = (index) => {
    const newEntries = [...entrydata];
    newEntries.splice(index, 1);
    setEntrydata(newEntries);
    setIsError(false);
  };

  const validateEntries = () => {
    for (let i = 0; i < entrydata.length; i++) {
      const row = entrydata[i];
      const allZero = [...Array(7)].every(
        (_, dayIndex) => row[`day${dayIndex + 1}`].hours === 0
      );

      if (allZero) {
        alert(
          "One or more rows have 0 hours entered for all days. Please remove those rows before proceeding."
        );
        return false;
      }
    }
    return true;
  };
  const handleSubmit = async (event) => {
    if (validateEntries()) {
      event.preventDefault();
      const day1TotalData = {
        date: day1date,
        hours: day1Total,
      };

      const day2TotalData = {
        date: day2date,
        hours: day2Total,
      };

      const day3TotalData = {
        date: day3date,
        hours: day3Total,
      };

      const day4TotalData = {
        date: day4date,
        hours: day4Total,
      };

      const day5TotalData = {
        date: day5date,
        hours: day5Total,
      };

      const day6TotalData = {
        date: day6date,
        hours: day6Total,
      };

      const day7TotalData = {
        date: day7date,
        hours: day7Total,
      };

      // Access entry.day1, entry.day2, ..., entry.day7
      const newEntryData = entrydata.map((entry) => ({
        ...entry,
        day1: { date: day1date, hours: entry.day1?.hours },
        day2: { date: day2date, hours: entry.day2?.hours },
        day3: { date: day3date, hours: entry.day3?.hours },
        day4: { date: day4date, hours: entry.day4?.hours },
        day5: { date: day5date, hours: entry.day5?.hours },
        day6: { date: day6date, hours: entry.day6?.hours },
        day7: { date: day7date, hours: entry.day7?.hours },
      }));

      const isProjectTaskSelected = newEntryData.every(
        (entry) => entry.project && entry.task
      );

      if (!isProjectTaskSelected) {
        message.error("Please select project and task for all entries.");
        return;
      }
      if (Finaltotal === 0) {
        message.error("Please enter valid values.");
      } else if (mathsTotal1 > 24) {
        message.error("The total for day 1 is greater than 24!");
      } else if (mathsTotal2 > 24) {
        message.error("The total for day 2 is greater than 24!");
      } else if (mathsTotal3 > 24) {
        message.error("The total for day 3 is greater than 24!");
      } else if (mathsTotal4 > 24) {
        message.error("The total for day 4 is greater than 24!");
      } else if (mathsTotal5 > 24) {
        message.error("The total for day 5 is greater than 24!");
      } else if (mathsTotal6 > 24) {
        message.error("The total for day 6 is greater than 24!");
      } else if (mathsTotal7 > 24) {
        message.error("The total for day 7 is greater than 24!");
      } else {
        try {
          const newdata = [...data, ...newEntryData];
          const updateActualHoursPromises = [];
          newdata.forEach((entry) => {
            const dbName = localStorage.getItem("dbName");
            if (entry.subtask) {
              // Check if subtask is selected
              const ActualhoursPromise = axios.post(
                `${url}/update/actualhours/${dbName}/${entry.subtask}/${user.displayName}/${weekNumber}`,
                { actualhours: entry.totalhours }
              );
              updateActualHoursPromises.push(ActualhoursPromise);
            }
          });

          let start = startend[0].props.children;
          let end = startend[6].props.children;
          const dbName = localStorage.getItem("dbName");
          const response = await axios.post(
            `${url}/monthly/timesheet/${dbName}`,
            {
              weekNumber,
              userName: user.displayName,
              empCode: user.empCode,
              email: email,
              start,
              end,
              newdata,
              status,
              reportingManager: managerInfo || user.manager,
              hrName: hr,
              reason: "",
              weekTotal: Finaltotal,
              day1Total: day1TotalData,
              day2Total: day2TotalData,
              day3Total: day3TotalData,
              day4Total: day4TotalData,
              day5Total: day5TotalData,
              day6Total: day6TotalData,
              day7Total: day7TotalData,
            }
          );
          notification.success({
            message: "Timesheet Saved Successfully",
            style: {
              top: "20px",
              left: "25px",
            },
          });
          Setcount(count + 1);
          Setwarn(0);
          setEntrydata([]);
          setReason("");
          setIsError(false);
        } catch (error) {
          console.error(error);
          message.error("Something Went Wrong");
          setData([]);
          setReason("");
          Setwarn(0);
        }
      }
    }
  };
  const FinalSubmit = async (event) => {
    if (validateEntries()) {
      event.preventDefault();
      handleOk1();
      const day1TotalData = {
        date: day1date,
        hours: day1Total,
      };

      const day2TotalData = {
        date: day2date,
        hours: day2Total,
      };

      const day3TotalData = {
        date: day3date,
        hours: day3Total,
      };

      const day4TotalData = {
        date: day4date,
        hours: day4Total,
      };

      const day5TotalData = {
        date: day5date,
        hours: day5Total,
      };

      const day6TotalData = {
        date: day6date,
        hours: day6Total,
      };

      const day7TotalData = {
        date: day7date,
        hours: day7Total,
      };

      const newEntryData = entrydata.map((entry) => ({
        ...entry,

        day1: { date: day1date, hours: entry.day1?.hours },
        day2: { date: day2date, hours: entry.day2?.hours },
        day3: { date: day3date, hours: entry.day3?.hours },
        day4: { date: day4date, hours: entry.day4?.hours },
        day5: { date: day5date, hours: entry.day5?.hours },
        day6: { date: day6date, hours: entry.day6?.hours },
        day7: { date: day7date, hours: entry.day7?.hours },
      }));

      const isProjectTaskSelected = newEntryData.every(
        (entry) => entry.project && entry.task
      );

      if (!isProjectTaskSelected) {
        message.error("Please select project and task for all entries.");
        return;
      }

      if (mathsTotal1 > 24) {
        message.error("The total for day 1 is greater than 24!");
      } else if (mathsTotal2 > 24) {
        message.error("The total for day 2 is greater than 24!");
      } else if (mathsTotal3 > 24) {
        message.error("The total for day 3 is greater than 24!");
      } else if (mathsTotal4 > 24) {
        message.error("The total for day 4 is greater than 24!");
      } else if (mathsTotal5 > 24) {
        message.error("The total for day 5 is greater than 24!");
      } else if (mathsTotal6 > 24) {
        message.error("The total for day 6 is greater than 24!");
      } else if (mathsTotal7 > 24) {
        message.error("The total for day 7 is greater than 24!");
      } else {
        try {
          setLoading(true);
          const newdata = [...data, ...newEntryData];
          const updateActualHoursPromises = [];
          newdata.forEach((entry) => {
            const dbName = localStorage.getItem("dbName");
            if (entry.subtask) {
              // Check if subtask is selected
              const ActualhoursPromise = axios.post(
                `${url}/update/actualhours/${dbName}/${entry.subtask}/${user.displayName}/${weekNumber}`,
                { actualhours: entry.totalhours }
              );
              updateActualHoursPromises.push(ActualhoursPromise);
            }
          });
          let start = startend[0].props.children;
          let end = startend[6].props.children;
          const dbName = localStorage.getItem("dbName");
          const response = await axios.post(
            `${url}/monthly/timesheet/${dbName}`,
            {
              weekNumber,
              userName: user.displayName,
              empCode: user.empCode,
              email: email,
              start,
              end,
              newdata,
              reportingManager: managerInfo || user.manager,
              hrName: hr,
              status: "Submitted",
              submittedDate: new Date(),
              reason: "",
              weekTotal: Finaltotal,
              day1Total: day1TotalData,
              day2Total: day2TotalData,
              day3Total: day3TotalData,
              day4Total: day4TotalData,
              day5Total: day5TotalData,
              day6Total: day6TotalData,
              day7Total: day7TotalData,
            }
          );
          notification.success({
            message: "Timesheet Sent To Manager Successfully",
            style: {
              top: "20px",
              left: "25px",
            },
          });
          setLoading(false);
          Setcount(count + 1);
          setEntrydata([]);
          setReason("");
          Setwarn(0);
          setIsError(false);
          setIsTimesheetLocked((prev) => !prev);
        } catch (error) {
          console.error(error);
          message.error("Something Went Wrong");
          setData([]);
          setLoading(false);
          setReason("");
          Setwarn(0);
        }
      }
    }
  };
  const handlePreviousWeek = () => {
    const previousWeek = currentWeek.clone().subtract(1, "week");

    if (previousWeek.isBefore(user.joinDate, "week")) {
      return;
    }

    if (warning !== 0) {
      const confirmNavigation = window.confirm(
        "Are you sure you want to navigate without making any changes? Any unsaved changes will be lost."
      );
      if (confirmNavigation) {
        Setcount(count + 1);
        setCurrentWeek(previousWeek);
        setEntrydata([]);
        setReason("");
        Setwarn(0);
        setIsError(false);
      }
    } else {
      Setcount(count + 1);
      setCurrentWeek(previousWeek);
      setEntrydata([]);
      setReason("");
      Setwarn(0);
      setIsError(false);
    }
  };

  const handleNextWeek = () => {
    const nextWeek = currentWeek.clone().add(1, "week");
    const futureWeekLimit = moment().add(allowedFutureWeeks, "weeks");

    // Check if the next week is beyond the allowed future weeks
    if (nextWeek.isAfter(futureWeekLimit, "week")) {
      return;
    }

    if (warning !== 0) {
      const confirmNavigation = window.confirm(
        "Are you sure you want to navigate without making any changes? Any unsaved changes will be lost."
      );
      if (confirmNavigation) {
        Setcount(count + 1);
        setCurrentWeek(nextWeek);
        setEntrydata([]);
        setReason("");
        Setwarn(0);
        setIsError(false);
      }
    } else {
      Setcount(count + 1);
      setCurrentWeek(nextWeek);
      setEntrydata([]);
      setReason("");
      Setwarn(0);
      setIsError(false);
    }
  };

  const copyLastWeekTimesheet = async () => {
    setLoading(true);
    const previousWeeknumber = currentWeek.clone().subtract(1, "week");
    const neweek = previousWeeknumber.isoWeek();
    const startOfPreviousWeek = previousWeeknumber.clone().startOf("isoWeek");
    const start = startOfPreviousWeek.format("DD-MM-YYYY");
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await fetch(
        `${url}/timesheet/getinputs/${start}/${user.displayName}/${dbName}`
      );
      const data = await response.json();
      const dataWithoutLeaveTasks = data[0].entrydata
        .map((entry) => {
          return {
            ...entry,
            day1: { date: day1date, hours: entry.day1?.hours },
            day2: { date: day2date, hours: entry.day2?.hours },
            day3: { date: day3date, hours: entry.day3?.hours },
            day4: { date: day4date, hours: entry.day4?.hours },
            day5: { date: day5date, hours: entry.day5?.hours },
            day6: { date: day6date, hours: entry.day6?.hours },
            day7: { date: day7date, hours: entry.day7?.hours },
          };
        })
        .filter(
          (entry) =>
            entry.task !== "Leave" &&
            entry.task !== "Holiday" &&
            entry.task !== "Permission"
        );
      if (dataWithoutLeaveTasks.length === 0) {
        alert("Previous week timesheet does not contain work entries.");
        setLoading(false);
        return;
      }
      setData(dataWithoutLeaveTasks);
      setTimeStatus("");
      setManagerInfo("");
      Setwarn(warning + 1);
      setIsError(false);
    } catch (error) {
      console.error(error);
      setData([]);
    }
    setLoading(false);
  };

  const TotalForEntryData = (row) => {
    let totalHours = 0;
    for (let dayIndex = 1; dayIndex <= 7; dayIndex++) {
      const dayKey = `day${dayIndex}`;
      totalHours += parseFloat(row[dayKey]?.hours || 0);
    }
    return totalHours;
  };

  const calculateTotal = (day) =>
    data.reduce((total, d) => total + parseFloat(d[day].hours), 0) +
    entrydata.reduce((total, d) => total + parseFloat(d[day].hours), 0);

  const mathsTotal1 = calculateTotal("day1");
  const mathsTotal2 = calculateTotal("day2");
  const mathsTotal3 = calculateTotal("day3");
  const mathsTotal4 = calculateTotal("day4");
  const mathsTotal5 = calculateTotal("day5");
  const mathsTotal6 = calculateTotal("day6");
  const mathsTotal7 = calculateTotal("day7");

  const Finaltotal =
    mathsTotal1 +
    mathsTotal2 +
    mathsTotal3 +
    mathsTotal4 +
    mathsTotal5 +
    mathsTotal6 +
    mathsTotal7;

  const day1Total = calculateTotal("day1");
  const day2Total = calculateTotal("day2");
  const day3Total = calculateTotal("day3");
  const day4Total = calculateTotal("day4");
  const day5Total = calculateTotal("day5");
  const day6Total = calculateTotal("day6");
  const day7Total = calculateTotal("day7");

  const calculateDate = (day, weekStartweek) => {
    // Example: getting the date from weekStartweek for the given day
    const currentDate = weekStartweek
      .clone()
      .add(day - 1, "days")
      .format("DD-MM-YYYY");
    // Do something with currentDate or return it
    return currentDate;
  };

  // You can use day1date, day2date, etc. as needed

  const day1date = calculateDate("1", weekStartweek);
  const day2date = calculateDate("2", weekStartweek);
  const day3date = calculateDate("3", weekStartweek);
  const day4date = calculateDate("4", weekStartweek);
  const day5date = calculateDate("5", weekStartweek);
  const day6date = calculateDate("6", weekStartweek);
  const day7date = calculateDate("7", weekStartweek);

  // Finaltotal will only include the total for day1

  const handleProjectChange = async (value, rowIndex) => {
    const project = projects.find((p) => p.ProjectName === value);
    const updatedData = [...entrydata];
    updatedData[rowIndex].project = value;
    updatedData[rowIndex].task = null;
    updatedData[rowIndex].subtask = null;
    setEntrydata(updatedData);
    setTasks(project.TaskName);
    setNewproject(value);
    await fetchTasks(project.Projectcode);
    setSelectedTask(null);
  };

  const handleTaskChange = async (value, rowIndex) => {
    const updatedData = [...entrydata];
    updatedData[rowIndex].task = value;
    setEntrydata(updatedData);
    setSelectedTask(value);
    const selectedProject = entrydata[rowIndex].project;
    const project = projects.find((p) => p.ProjectName === selectedProject);
    if (project) {
      setTasks(project.TaskName);
    }
  };

  const handlesubTaskChange = async (value, rowIndex) => {
    const updatedData = [...entrydata];
    updatedData[rowIndex].subtask = value;
    setEntrydata(updatedData);
    setSelectedSubTask(value);

    const isSubtaskInData = data.some((entry) => entry.subtask === value);
    const isSubtaskExists = updatedData.some(
      (entry, index) => index !== rowIndex && entry.subtask === value
    );
    if (isSubtaskExists || isSubtaskInData) {
      message.error(
        "Selected Subtask is already in the table. Please update the existing row."
      );
      setIsError(true); // Set isError to true to disable the Select
      return;
    }
    setIsError(false); // Reset isError if no error
  };

  const handleBillable = (e, rowIndex) => {
    const updatedBillable = e.target.checked;
    setBillable(updatedBillable);

    // Make a copy of updatedData to avoid direct state mutation
    const updatedData = [...entrydata];
    updatedData[rowIndex].billable = updatedBillable;
    setEntrydata(updatedData);
  };

  useEffect(() => {
    const fetchData = async () => {
      const dbName = localStorage.getItem("dbName");
      try {
        // const res = await axios.get(
        //   `${url}/api/getUserManager/${user.displayName}/${dbName}`
        // );
        const empCode = user.empCode;
        setEmpCode(empCode);
        const response = await axios.get(
          `${url}/get/employee/location/${user.empCode}/${weekNumber}/${dbName}`
        );
        const data = await response.data.address[0].data;
        setLocationdata(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [weekNumber]);

  const dayssss = tableDays?.map((element) => element.props.children);

  const handleDayClick = (selectedDayData) => {
    setSelectedDayData(selectedDayData);
  };

  const handleModalClose = () => {
    setSelectedDay(null);
  };

  const toggleTimesheetLock = () => {
    setIsTimesheetLocked((prev) => !prev);
    setTimeStatus("Draft");
  };

  const toggleTimesheetLock1 = async () => {
    let start = startend[0].props.children;
    let end = startend[6].props.children;
    const dbName = localStorage.getItem("dbName");
    const response = await axios.post(`${url}/Request/timesheet/${dbName}`, {
      weekNumber,
      userName: user.displayName,
      empCode: user.empCode,
      email: email,
      start,
      end,
      status: "Requested",
      reportingManager: managerInfo || user.manager,
      hrName: hr,
    });
    notification.success({
      message: "Requested Sent to your Manager",
      style: {
        top: "20px",
        left: "25px",
      },
    });
    Setcount(count + 1);
  };
  const sunCellStyle = {
    backgroundColor: "#e6ccff", // Yellow background color
  };

  const holidayCellStyle = {
    backgroundColor: "#ffe6e6",
  };

  // function isDateInCalendarEvents(day, calenderevents) {
  //   if (day && day.props && day.props.children) {
  //     const dayText = day.props.children; // Assuming day is an object with props.children as the date
  //     return calenderevents.includes(dayText);
  //   } else {
  //     // Handle the case where day or its properties are undefined
  //     return false;
  //   }
  // }

  let dayStartDate;

  function isDateInCalendarEvents(dayNumber, calenderevents) {
    const paddedDayNumber = dayNumber < 10 ? `0${dayNumber}` : dayNumber;
    let found = false;
    startend.forEach((day, index) => {
      const date = day.props.children;
      const startDayNumber = date.split("-")[0];
      if (startDayNumber === paddedDayNumber) {
        if (calenderevents.includes(date)) {
          found = true;
          return; // Exit the loop if the date is found
        } else {
          found = false;
          return; // Exit the loop if the date is not found
        }
      }
    });
    return found; // Return the found status after the loop
  }
  const addRow = () => {
    setShowmodal(true);
    setEntrydata([
      ...entrydata,
      {
        project: null,
        task: null,
        day1: {
          date: "",
          hours: 0,
        },
        day2: {
          date: "",
          hours: 0,
        },
        day3: {
          date: "",
          hours: 0,
        },
        day4: {
          date: "",
          hours: 0,
        },
        day5: {
          date: "",
          hours: 0,
        },
        day6: {
          date: "",
          hours: 0,
        },
        day7: {
          date: "",
          hours: 0,
        },
        TotalHours: 0,
      },
    ]);
  };
  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const currentStatus = timestatus || "Pending";

  const alertType =
    {
      Draft: "info",
      Submitted: "info",
      Pending: "warning",
      Approved: "success",
      Rejected: "error",
    }[currentStatus] || "info";

  return (
    <div style={{ zoom: "80%" }}>
      <h3 className="text-center">Timesheet</h3>{" "}
      {loading ? (
        <div className="loaderhead">
          <div className="loader"></div>
        </div>
      ) : (
        <Container fluid>
          <Modal
            title="Confirm "
            open={isModalOpen1}
            onOk={FinalSubmit}
            onCancel={handleCancel1}
          >
            <p>Are you sure you want to send the timesheets to the manager?</p>
          </Modal>
          <Modal
            title="Select Project and Task"
            open={isModalOpen}
            onCancel={handleCancel}
            footer={[
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Button
                    type="primary"
                    disabled={isButtonDisabled}
                    onClick={handleOk}
                  >
                    Ok
                  </Button>
                  <Button onClick={handleCancel}>Cancel</Button>
                </div>
              </div>,
            ]}
          ></Modal>

          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "20px",
            }}
          >
            <div
              className="col-content"
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px",
                borderRadius: "10px",
                background: "#001233",
              }}
            >
              {/* Disable the Previous Week button if we exceed allowed past weeks */}

              <Button
                type="primary"
                onClick={handlePreviousWeek}
                shape="circle"
                icon={<LeftCircleFilled />}
              />

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      backgroundColor: "#e6e6e6",
                      borderRadius: "5px",
                      padding: "8px",
                      marginRight: "10px",
                    }}
                  >
                    {startend[0]}
                  </div>
                  <div
                    style={{
                      backgroundColor: "#e6e6e6",
                      borderRadius: "5px",
                      padding: "8px",
                    }}
                  >
                    {startend[6]}
                  </div>
                </div>
              </div>

              {/* Disable the Next Week button if we exceed allowed future weeks */}
              {currentWeek.isBefore(
                moment()
                  .startOf("week")
                  .add(allowedFutureWeeks, "weeks")
              ) && (
                <Button
                  type="primary"
                  onClick={handleNextWeek}
                  shape="circle"
                  icon={<RightCircleFilled />}
                />
              )}
            </div>
          </Row>

          <Row>
            <Col
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "20px",
              }}
            >
              {currentStatus && (
                <Alert
                  message={`Status: ${currentStatus}`}
                  type={alertType}
                  showIcon
                />
              )}

              {timestatus === "Submitted" && timeData && (
                <Button onClick={toggleTimesheetLock}>
                  {isTimesheetLocked ? <UnlockOutlined /> : null}
                  Unlock Timesheet
                </Button>
              )}
{console.log(unlock,"unlock")}
              {unlock &&
                (timestatus !== "Submitted" || timestatus === "Approved") && (
                  <Button
                    onClick={toggleTimesheetLock1}
                    disabled={unlockTime !== ""}
                    style={{
                      color:
                        unlockTime === "Approved"
                          ? "green"
                          : unlockTime === "Request Denied"
                          ? "#ff704d"
                          : unlockTime === "Requested"
                          ? "blue"
                          : "",
                    }}
                  >
                    {unlockTime === "Approved"
                      ? "Request Approved"
                      : unlockTime === "Requested"
                      ? "Request Sent"
                      : unlockTime === "Request Denied"
                      ? "Request Denied"
                      : "Unlock Past Timesheet"}
                  </Button>
                )}

              <Alert
                className="button"
                message={`Reporting Manager : ${managerInfo || user.manager}`}
                type="info"
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Table responsive striped bordered hover>
                <thead>
                  <tr>
                    <th className="column-project">Project</th>
                    <th className="column-task">Task</th>
                    <th className="column-task">Activity</th>
                    <th className="column-task">Billable</th>
                    {days.map((day, index) => {
                      // (startend.map((startDate, index) => {
                      // Parse the start date into a Date object
                      dayStartDate = day.props.children;

                      const dayParts = day.props.children;
                      const dayString = dayParts[0]; // Day of the week
                      const monthString = dayParts[1]; // Month
                      const yearString = dayParts[2]; // Year

                      // // Construct a valid date string in the format: "Month Day, Year"
                      // const dateString = `${monthString} ${dayString}, ${yearString}`;
                      // Parse the date string into a Date object
                      // const dayStartDate = new Date(dateString);

                      // }))

                      // Function to check if the start date of the day is in calendar events
                      const isHoliday = isDateInCalendarEvents(
                        yearString,
                        calendarEvents
                      );
                      const isSaturdayOff =
                        (index === 5 && weekend === "satoff") ||
                        (index === 4 && weekend === "frioff") ||
                        (index === 6 && weekend === "sunoff") ||
                        ((index === 4 || index === 5) &&
                          weekend === "frisatoff") ||
                        ((index === 5 || index === 6) &&
                          weekend === "satsunoff");

                      const style = isSaturdayOff ? sunCellStyle : null;
                      const holidayStyle = isHoliday ? holidayCellStyle : null;
                      const otherCellStyle =
                        !isSaturdayOff && !isHoliday
                          ? { backgroundColor: "#C0D6E8" }
                          : null;

                      return (
                        <th
                          // className="column-day"
                          key={index}
                          style={{
                            ...style,
                            ...holidayStyle,
                            ...otherCellStyle,
                          }}
                        >
                          {day}
                        </th>
                      );
                    })}
                    <th className="column-total-hours">Total</th>
                    <th className="column-comments">Comments</th>
                    {data && data.length > 0 && timestatus === "Draft" && (
                      <th className="column-action">Action</th>
                    )}
                  </tr>
                </thead>

                <tbody>
                  {locationCheck === "true" && (
                    <tr>
                      <td colSpan="4">Location</td>
                      {startend.map((day, index) => {
                        const locationDetails = locationdata.find(
                          (obj) => obj.date === day.props.children
                        );
                        const locations = locationDetails
                          ? locationDetails.location
                          : [];
                        const latestLocation = locations[locations.length - 1];

                        return (
                          <td key={index}>
                            <div>
                              {locations.length > 0 && ( // Only render the image if there are locations
                                <a
                                  href="#"
                                  onClick={() =>
                                    setSelectedDay(day.props.children)
                                  }
                                  title={latestLocation} // Show the latest location on hover
                                >
                                  <img
                                    src={require("../../images/map.png")}
                                    alt="Location Icon"
                                    style={{ width: "24px", height: "24px" }} // Adjust size as needed
                                  />
                                </a>
                              )}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  )}

                  <Modal
                    width={800}
                    visible={selectedDay !== null}
                    onCancel={() => setSelectedDay(null)}
                    footer={null}
                  >
                    <>
                      <Descriptions title="User Info" bordered>
                        <Descriptions.Item label="UserName">
                          {user.displayName}
                        </Descriptions.Item>
                        <Descriptions.Item label="ID">
                          {empCode}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label="Location Accessed Date"
                          span={3}
                        ></Descriptions.Item>
                        <Descriptions
                          label={`Location as of Day ${selectedDay}`}
                          span={5}
                        >
                          {selectedDay && (
                            <p>
                              {locationdata
                                .find((obj) => obj.date === selectedDay)
                                ?.location.map((location, index) => (
                                  <ul key={index}>{location}</ul>
                                ))}
                            </p>
                          )}
                        </Descriptions>
                      </Descriptions>
                    </>
                  </Modal>

                  {/* {data.length === 0 && entrydata.length === 0 ? ( */}

                  {/* ) : (null)} */}
                  {data.map((entry, entryIndex) => (
                    <tr key={entryIndex}>
                      <td>
                        <Input
                          style={{
                            width: 160,
                            height: "33px",
                            fontSize: "14px",
                          }}
                          value={entry.project}
                          readOnly
                        />
                      </td>

                      <td>
                        <Input
                          style={{
                            width: 160,
                            height: "33px",
                            fontSize: "14px",
                          }}
                          value={entry.task}
                          readOnly
                        />
                      </td>
                      <td>
                        <Tooltip
                          title={entry.subtask}
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                        >
                          <Input
                            style={{
                              width: 160,
                              height: "33px",
                              fontSize: "14px",
                            }}
                            value={entry.subtask}
                            readOnly
                          />
                        </Tooltip>
                      </td>
                      <td>
                        <Checkbox
                          checked={entry.billable}
                          onChange={(e) => handleBillable(e, entryIndex)}
                          disabled
                        />
                      </td>
                      {[1, 2, 3, 4, 5, 6, 7].map((day, dayIndex) => (
                        <td style={{ height: "auto" }} key={dayIndex}>
                          <Input
                            style={{
                              fontSize: "16px",
                              backgroundColor: "#fff",
                              width: "60px",
                              height: "30px",
                              borderRadius: "5%",
                            }}
                            className="disabled-input"
                            type="number"
                            min={0}
                            max={24}
                            disabled={
                              timestatus === "Submitted" ||
                              timestatus === "Approved" ||
                              isError === true
                            }
                            value={entry[`day${day}`].hours}
                            onChange={(e) =>
                              handleCellChange(
                                entryIndex,
                                `day${day}`,
                                e.target.value
                              )
                            }
                          />
                        </td>
                      ))}
                      <td style={{ background: "#d7d4d9" }}>
                        <Input
                          style={{
                            fontSize: "16px",
                            backgroundColor: "#fff",
                            width: "60px",
                            height: "30px",
                          }}
                          className="disabled-input"
                          disabled
                          value={entry.totalhours}
                        />
                      </td>
                      <td style={{ background: "#d7d4d9" }}>
                        {timestatus !== "Submitted" &&
                        timestatus !== "Approved" ? (
                          <Tooltip
                            title={entry.comments}
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                          >
                            <Input
                              style={{
                                fontSize: "16px",
                                backgroundColor: "#fff",
                                width: "60px",
                                height: "30px",
                                fontSize: "16px",
                              }}
                              className="disabled-input"
                              type="text"
                              // disabled={
                              //   timestatus === "Submitted" ||
                              //   timestatus === "Approved"
                              // }
                              value={entry.comments}
                              onChange={(e) =>
                                handleInputComments(
                                  entryIndex,
                                  `comments`,
                                  e.target.value
                                )
                              }
                            />
                          </Tooltip>
                        ) : (
                          <span>{entry.comments}</span>
                        )}
                      </td>
                      {/* {timestatus === "Draft" && ( */}
                      <td>
                        <Popconfirm
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          disabled={
                            timestatus === "Submitted" ||
                            timestatus === "Approved"
                          }
                          title="Are you sure to delete this record?"
                          onConfirm={() => handleDelete(entryIndex)}
                        >
                          <Button
                            type="primary"
                            danger
                            disabled={
                              timestatus === "Submitted" ||
                              timestatus === "Approved"
                            }
                          >
                            <DeleteFilled />
                          </Button>
                        </Popconfirm>
                      </td>
                      {/* )} */}
                    </tr>
                  ))}
                  {entrydata.map((row, index) => (
                    <tr key={index}>
                      <td className="border">
                        {index !== entrydata.length - 1 ? (
                          <Input
                            style={{
                              width: 160,
                              height: "33px",
                              fontSize: "14px",
                            }}
                            value={row.project}
                            readOnly
                          />
                        ) : (
                          <Select
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            placeholder="Select Project"
                            style={{ width: 160 }}
                            onChange={(value) =>
                              handleProjectChange(value, index)
                            }
                            defaultValue={row.project}
                            options={projects.map((project) => ({
                              label: capitalize(project.ProjectName),
                              value: project.ProjectName,
                            }))}
                            disabled={timestatus === "Submitted" || !timeData}
                            dropdownClassName="my-custom-dropdown"
                          />
                        )}
                      </td>
                      <td className="border">
                        {index !== entrydata.length - 1 ? (
                          <Input
                            style={{
                              width: 160,
                              height: "33px",
                              fontSize: "14px",
                            }}
                            value={row.task}
                            readOnly
                          />
                        ) : (
                          <Select
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            placeholder="Select Task"
                            style={{ width: 160 }}
                            options={tasks.map((task) => ({
                              label: capitalize(task),
                              value: task,
                            }))}
                            defaultValue={row.task}
                            onChange={(value) => handleTaskChange(value, index)}
                            disabled={timestatus === "Submitted" || !timeData}
                            dropdownClassName="my-custom-dropdown"
                          />
                        )}
                      </td>

                      <td className="border">
                        {index !== entrydata.length - 1 ? (
                          <Input
                            style={{
                              width: 160,
                              height: "33px",
                              fontSize: "14px",
                            }}
                            value={row.subtask}
                            readOnly
                          />
                        ) : (
                          <Select
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            placeholder="Select Activity"
                            style={{ width: 160 }}
                            options={subTasks?.map((task) => ({
                              label: capitalize(task.activityName),
                              value: task.activityName,
                            }))}
                            defaultValue={row.subtask}
                            disabled={timestatus === "Submitted" || !timeData}
                            onChange={(value) =>
                              handlesubTaskChange(value, index)
                            }
                          />
                        )}
                      </td>
                      <td>
                        <Checkbox onChange={(e) => handleBillable(e, index)} />
                      </td>
                      {[...Array(7)].map((_, dayIndex) => {
                        // Get the date for the current day in the week
                        const currentDate = currentWeek
                          .clone()
                          .startOf("week")
                          .add(dayIndex + 1, "day")
                          .startOf("day");

                        // Get the user join date
                        const userJoinDate = moment(user.joinDate).startOf(
                          "day"
                        );

                        // Determine if the current date is before the join date
                        const isBeforeJoinDate = currentDate.isBefore(
                          userJoinDate,
                          "day"
                        );

                        // Check if the input should be disabled
                        const isDisabled =
                          isBeforeJoinDate &&
                          !currentDate.isSame(userJoinDate, "day");

                        return (
                          <td
                            key={dayIndex}
                            style={{ background: "#d7d4d9", height: "2px" }}
                            className="border"
                          >
                            <Input
                              type="number"
                              style={{
                                fontSize: "16px",
                                backgroundColor: "#fff",
                                width: "60px",
                                height: "30px",
                                borderColor: "#d2b8e0",
                              }}
                              className="dark-input"
                              disabled={isDisabled}
                              min={0}
                              max={24}
                              id={`day${dayIndex + 1}-${index}`}
                              name={`day${dayIndex + 1}`}
                              value={row[`day${dayIndex + 1}`].hours}
                              onChange={(event) =>
                                handleInputChange(event, index)
                              }
                            />
                          </td>
                        );
                      })}

                      <td style={{ background: "#d7d4d9" }}>
                        <Mentions
                          style={{
                            width: "60px",
                            height: "30px",
                            fontSize: "15px",
                            fontWeight: "bold",
                            color: "black",
                          }}
                          placeholder={TotalForEntryData(row)}
                          className="disabled-input"
                          disabled
                        />
                      </td>
                      <td style={{ background: "#d7d4d9" }}>
                        <Tooltip
                          title={row.comments}
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                        >
                          <Input
                            type="text"
                            style={{
                              fontSize: "16px",
                              width: "60px",
                              height: "30px",
                              backgroundColor: "#fff",
                            }}
                            id="comments"
                            name="comments"
                            value={row.comments}
                            onChange={(event) =>
                              handleCommentChange(event, index)
                            }
                          />
                        </Tooltip>
                      </td>
                      <td className="border" style={{ background: "#e6d5f0" }}>
                        <Button
                          danger
                          onClick={() => handlenewDelete(index)}
                          type="primary"
                        >
                          <DeleteFilled />
                        </Button>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td>
                      <Row
                        gutter={[16, 16]}
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        {timestatus !== "Submitted" && (
                          <>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                              }}
                            >
                              <Col span={10}>
                                <Tooltip
                                  getPopupContainer={(triggerNode) =>
                                    triggerNode.parentNode
                                  }
                                  title="Add Row"
                                >
                                  <Button
                                    type="text"
                                    disabled={
                                      (timestatus !== "Draft" &&
                                        timestatus !== "Rejected" &&
                                        timestatus !== "Draft for Save" &&
                                        timestatus !== "") ||
                                      (!timeData && timestatus !== "Rejected")
                                    }
                                    style={{
                                      padding: 0, // Remove padding to reduce space
                                      // height: "auto", // Adjust height based on content
                                      width: "auto",
                                      height: "30px",
                                    }} // Adjust button size based on content
                                    onClick={addRow}
                                  >
                                    <PlusSquareTwoTone
                                      style={{ fontSize: "30px" }}
                                    />
                                  </Button>
                                </Tooltip>
                              </Col>
                              {copyTimeSheet && (
                                <Col span={10}>
                                  <Tooltip
                                    getPopupContainer={(triggerNode) =>
                                      triggerNode.parentNode
                                    }
                                    title="Copy Lastweek Timesheet"
                                  >
                                    {
                                      <Button
                                        type="text"
                                        disabled={
                                          (timestatus !== "Draft" &&
                                            timestatus !== "Rejected" &&
                                            timestatus !== "Draft for Save" &&
                                            timestatus !== "") ||
                                          (!timeData &&
                                            timestatus !== "Rejected")
                                        }
                                        onClick={copyLastWeekTimesheet}
                                        style={{
                                          padding: 0,
                                          width: "auto",
                                          height: "30px",
                                        }}
                                      >
                                        <CopyTwoTone
                                          style={{ fontSize: "30px" }}
                                        />
                                      </Button>
                                    }
                                  </Tooltip>
                                </Col>
                              )}
                            </div>
                          </>
                        )}
                      </Row>
                    </td>
                    <td></td>
                    <td></td>
                    <td>Total</td>
                    {[1, 2, 3, 4, 5, 6, 7].map((day) => (
                      <td style={{ background: "d7d4d9" }}>
                        <Input
                          style={{
                            fontSize: "16px",
                            backgroundColor: "#fff",
                            width: "60px",
                            height: "30px",
                          }}
                          className="disabled-input"
                          disabled
                          value={eval(`mathsTotal${day}`)}
                          readOnly
                        />
                      </td>
                    ))}
                    <td style={{ background: "d7d4d9" }}>
                      <Input
                        style={{
                          fontSize: "16px",
                          backgroundColor: "#fff",
                          width: "60px",
                          height: "30px",
                        }}
                        className="disabled-input"
                        disabled
                        value={Finaltotal}
                        readOnly
                      />
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Actual Logged Hours</td>
                    {startend.map((day) => {
                      // Assuming startDate is available from somewhere
                      const startDate = day.props.children;
                      const actualHours = renderActualHours(startDate);
                      return (
                        <td key={startDate} style={{ background: "d7d4d9" }}>
                          <Input
                            style={{
                              fontSize: "16px",
                              backgroundColor: "#fff",
                              width: "60px",
                              height: "30px",
                            }}
                            className="disabled-input"
                            disabled
                            value={actualHours}
                            readOnly
                          />
                        </td>
                      );
                    })}
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <Col className="col-lg-4">
              {reason !== "" && reason !== undefined && (
                <Alert
                  message={`Reason for Rejected:  ${reason}`}
                  type="error"
                  showIcon
                  width={50}
                />
              )}
            </Col>
            <Col className="col-lg-4">
              <Col
                style={{
                  top: "5vh",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {/* <Button
                    variant="primary"
                    size="large"
                    className="button"
                    disabled={
                      timestatus !== "Draft" &&
                      timestatus !== "Rejected" &&
                      timestatus !== "Draft for Save"
                    }
                    onClick={addRow}
                  >
                    <PlusOutlined /> Add Row
                  </Button> */}
                <Button
                  type="primary"
                  size="large"
                  className="button"
                  disabled={
                    (timestatus !== "Draft" &&
                      timestatus !== "Rejected" &&
                      timestatus !== "Draft for Save" &&
                      timestatus !== "") ||
                    (!timeData && timestatus !== "Rejected")
                  }
                  onClick={handleSubmit}
                >
                  Save
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="button"
                  style={{ left: "3vh" }}
                  disabled={
                    (timestatus !== "Draft" &&
                      timestatus !== "Rejected" &&
                      timestatus !== "Draft for Save" &&
                      timestatus !== "") ||
                    Finaltotal < totalhrs ||
                    status === "disable" ||
                    (!timeData && timestatus !== "Rejected")
                  }
                  onClick={showModal1}
                >
                  Submit
                </Button>
              </Col>
            </Col>
          </Row>
          <br />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                marginRight: "10px",
                backgroundColor: "#ffe6e6",
                padding: "5px",
                width: "3vh",
                height: "3vh",
              }}
            />
            <span style={{ fontSize: "15px", fontWeight: "bold" }}>
              Holiday
            </span>

            <div
              style={{
                marginRight: "10px",
                backgroundColor: "#e6ccff",
                padding: "5px",
                width: "3vh",
                height: "3vh",
                marginLeft: "5vh",
              }}
            />
            <span style={{ fontSize: "15px", fontWeight: "bold" }}>
              Week off
            </span>
          </div>
        </Container>
      )}
    </div>
  );
}

export default Timesheet;
