import React, { useEffect, useState } from "react";
import {
  Layout,
  Menu,
  Form,
  Input,
  Select,
  Button,
  Upload,
  notification,
  Checkbox,
  Row,
  Col,
  List,
  Card,
  Space,
  Switch,
  Tabs,
  Transfer,
  Table,
  Popconfirm,
  Modal,
  Drawer,
  Calendar,
  Divider,
  Segmented,
  Tooltip,
  Flex,
  Radio,
  message,
  Spin,
  DatePicker,
  Skeleton,
  Avatar,
  Pagination,
  InputNumber,
} from "antd";
import { SketchPicker } from "react-color";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  UserOutlined,
  SettingOutlined,
  FileTextOutlined,
  TeamOutlined,
  FileDoneOutlined,
  NotificationOutlined,
  ToolOutlined,
  UploadOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  EnvironmentOutlined,
  GiftOutlined,
  HeartOutlined,
  BookOutlined,
  UserSwitchOutlined,
  UsergroupDeleteOutlined,
  LaptopOutlined,
  SolutionOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  CloseOutlined,
  DeleteFilled,
  BarsOutlined,
  CalendarFilled,
  HourglassOutlined,
  ExclamationOutlined,
  InboxOutlined,
  EuroCircleOutlined,
  CopyTwoTone,
} from "@ant-design/icons";
import axios from "axios";
import { url } from "../../url/url";
import { Calendar12Filled, Mail12Filled } from "@fluentui/react-icons";
import moment from "moment/moment";
const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;
const { Option } = Select;
const { TabPane } = Tabs;
const { Dragger } = Upload;
const AdminConfigPage = () => {
  const [selectedMenu, setSelectedMenu] = useState("organisation-setup");

  const handleMenuClick = (e) => {
    setSelectedMenu(e.key);
  };

  const renderContent = () => {
    switch (selectedMenu) {
      case "organisation-setup":
        return <OrganisationSetupForm />;
      case "TimeSheet":
        return <TimeSheet />;
      case "Employee Options":
        return <EmployeeForm />;
      case "letterpad":
        return <LetterPadForm />;
      case "claim":
        return <Claim />;
      case "card":
        return <CardForm />;
      case "leave":
        return <LeaveForm />;
      case "Holidays":
        return <Holidays />;
      case "policy":
        return <PolicyForm />;
      case "announcement":
        return <AnnouncementForm />;
      case "manage-services":
        return <ManageServicesForm />;
      default:
        return null;
    }
  };

  return (
    <Layout style={{ border: "1px solid #dcdcdc" }}>
      <Sider width={250} style={{ backgroundColor: "#f6f6ee" }}>
        <Menu
          mode="inline"
          defaultSelectedKeys={["organisation-setup"]}
          onClick={handleMenuClick}
          style={{ height: "100%", borderRight: 0 }}
        >
          <Menu.Item key="organisation-setup" icon={<SettingOutlined />}>
            Organisation Setup
          </Menu.Item>
          <Menu.Item key="TimeSheet" icon={<HourglassOutlined />}>
            TimeSheet
          </Menu.Item>
          <SubMenu key="Employee" icon={<UserOutlined />} title="Employee">
            <Menu.Item key="Employee Options" icon={<SolutionOutlined />}>
              Option
            </Menu.Item>

            <Menu.Item key="Asset Setting" icon={<LaptopOutlined />}>
              Asset Setting
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="letter-cards"
            icon={<FileTextOutlined />}
            title="Letter & Cards"
          >
            <Menu.Item key="letterpad">LetterPad</Menu.Item>
            <Menu.Item key="card">Card</Menu.Item>
          </SubMenu>
          <SubMenu
            key="Leave Option"
            icon={<FileDoneOutlined />}
            title="Leave Option"
          >
            <Menu.Item key="leave" icon={<FileDoneOutlined />}>
              Leave
            </Menu.Item>
            <Menu.Item key="Holidays" icon={<NotificationOutlined />}>
              Holidays
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="claim" icon={<EuroCircleOutlined />}>
            Claim
          </Menu.Item>
          <Menu.Item key="policy" icon={<TeamOutlined />}>
            Policy
          </Menu.Item>
          <Menu.Item key="announcement" icon={<NotificationOutlined />}>
            Announcement
          </Menu.Item>
          <Menu.Item key="manage-services" icon={<ToolOutlined />}>
            Manage Services
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout style={{ padding: "0 24px 24px", border: "1px solid #dcdcdc" }}>
        <Content
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
            background: "#f6f6ee",
          }}
        >
          {renderContent()}
        </Content>
      </Layout>
    </Layout>
  );
};

const OrganisationSetupForm = () => {
  const [data, setData] = useState({});
  const [data1, setData1] = useState({});
  const [uploadedLogo, setUploadedLogo] = useState(null);
  const [uploadedSeal, setUploadedSeal] = useState(null);
  const [form] = Form.useForm();
  const [count, setCount] = useState(0);
  const [color, setColor] = useState("");

  const fetchData = async () => {
    try {
      const dbName = localStorage.getItem("dbName");

      // Fetching the first API response
      const response1 = await axios.get(`${url}/api/masterorg/${dbName}`);
      setData(response1.data.org);

      // Fetching the second API response
      const response2 = await axios.get(`${url}/get/admin/images/${dbName}`);
      setData1(response2.data[0]);

      // Setting existing background color
      const existingColor = response2.data[0]?.backgroundColor || "#ffffff";
      setColor(existingColor);

      // Updating the form fields dynamically
      form.setFieldsValue({
        orgName: response1.data.org?.orgName,
        companyaddress: response1.data.org?.companyaddress,
        companyemail: response1.data.org?.companyemail,
        Logo: response2.data[0]?.Logo,
        seal: response2.data[0]?.seal,
        colorPicker: existingColor,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [count]);

  const handleUpload = ({ file, onSuccess, onError, type }) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (type === "logo") {
        setUploadedLogo(reader.result);
      } else if (type === "seal") {
        setUploadedSeal(reader.result);
      }
      onSuccess("ok");
    };
    reader.onerror = (error) => onError(error);
    reader.readAsDataURL(file);
  };

  const handleRemove = (type) => {
    if (type === "logo") {
      setUploadedLogo(null);
    } else if (type === "seal") {
      setUploadedSeal(null);
    }
  };

  const handleColorChange = (color) => {
    setColor(color.hex);
    form.setFieldsValue({ colorPicker: color.hex });
  };

  const onFinish3 = async (values) => {
    const dbName = localStorage.getItem("dbName");
    values.Logo = uploadedLogo;
    values.seal = uploadedSeal;
    values.backgroundColor = color;

    try {
      const [response, res] = await Promise.all([
        axios.post(`${url}/admin/combined/images/${dbName}`, values),
        axios.put(`${url}/api/master-orgs/${data._id}`, {
          companyaddress: values.companyaddress,
          orgName: values.orgName,
          companyemail: values.companyemail,
          color: values.backgroundColor,
        }),
      ]);

      setCount(count + 1);
      form.resetFields();
      notification.success({
        message: `Posted Successfully!`,
        duration: 3,
      });
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  return (
    <>
      <Tabs>
        <TabPane tab="ORGANISATION SETUP" key="1">
          <br />
          <br />
          <Form
            form={form}
            layout="horizontal"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{
              maxWidth: "400px",
              margin: "0 auto",
              textAlign: "left",
            }}
            onFinish={onFinish3}
          >
            <Form.Item label="Company Name" name="orgName" required>
              <Input
                placeholder="Enter company name"
                style={{ height: "40px", fontSize: "14px" }}
              />
            </Form.Item>

            <Form.Item label="Company Address" name="companyaddress" required>
              <Input.TextArea
                rows={4}
                placeholder="Enter company address"
                style={{ fontSize: "14px" }}
              />
            </Form.Item>

            <Form.Item label="Email" name="companyemail" required>
              <Input
                placeholder="Enter company email"
                style={{ height: "40px", fontSize: "14px" }}
              />
            </Form.Item>

            <Form.Item label="Company Logo" name="Logo">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Upload
                  customRequest={({ file, onSuccess, onError }) =>
                    handleUpload({ file, onSuccess, onError, type: "logo" })
                  }
                  maxCount={1}
                  onRemove={() => handleRemove("logo")}
                >
                  <Button icon={<UploadOutlined />}>Upload Logo</Button>
                </Upload>
                {uploadedLogo ? (
                  <img
                    src={uploadedLogo}
                    alt="Uploaded Company Logo"
                    style={{ width: "100px", marginLeft: "10px" }}
                  />
                ) : data1?.Logo ? (
                  <img
                    src={data1?.Logo}
                    alt="Company Logo"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "fallback-logo.png";
                    }}
                    style={{ width: "100px", marginLeft: "10px" }}
                  />
                ) : (
                  <p style={{ marginLeft: "10px" }}>No logo available</p>
                )}
              </div>
            </Form.Item>

            <Form.Item label="Company Seal" name="seal">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Upload
                  customRequest={({ file, onSuccess, onError }) =>
                    handleUpload({ file, onSuccess, onError, type: "seal" })
                  }
                  maxCount={1}
                  onRemove={() => handleRemove("seal")}
                >
                  <Button icon={<UploadOutlined />}>Upload Seal</Button>
                </Upload>
                {uploadedSeal ? (
                  <img
                    src={uploadedSeal}
                    alt="Uploaded Company Seal"
                    style={{ width: "100px", marginLeft: "10px" }}
                  />
                ) : data1?.seal ? (
                  <img
                    src={data1?.seal}
                    alt="Company Seal"
                    style={{ width: "100px", marginLeft: "10px" }}
                  />
                ) : (
                  <p style={{ marginLeft: "10px" }}>No seal available</p>
                )}
              </div>
            </Form.Item>

            <Form.Item label="Color Picker" name="colorPicker">
              <div style={{ textAlign: "center" }}>
                <SketchPicker
                  color={color}
                  onChangeComplete={handleColorChange}
                />
              </div>
            </Form.Item>

            <Form.Item
              wrapperCol={{ span: 24 }}
              style={{ textAlign: "center" }}
            >
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </TabPane>
      </Tabs>
    </>
  );
};

const AnnouncementForm = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [publishOptions, setPublishOptions] = useState([]);
  const [initLoading, setInitLoading] = useState(false);
  const [list, setList] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5;

  useEffect(() => {
    fetchAnnouncements();
  }, [count]);

  const onFinish = async (values) => {
    const dbName = localStorage.getItem("dbName");
    setLoading(true);

    try {
      const response = await axios.post(
        `${url}/admin/announcements/${dbName}`,
        { ...values, description }
      );

      notification.success({
        message: "Announcement Posted Successfully!",
        description: `Title: ${values.title}`,
        duration: 3,
      });

      form.resetFields();
      setDescription("");
      setCount(count + 1);
    } catch (error) {
      notification.error({
        message: "Error Posting Announcement",
        description: error.message || "Something went wrong!",
        duration: 3,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchAnnouncements = async () => {
    const dbName = localStorage.getItem("dbName");
    setInitLoading(true);
    try {
      const response = await axios.get(`${url}/admin/announcements/${dbName}`);
      setList(response.data);
    } catch (error) {
      console.error("Failed to fetch announcements", error);
    } finally {
      setInitLoading(false);
    }
  };

  const handleDelete = async (id) => {
    const dbName = localStorage.getItem("dbName");
    try {
      await axios.delete(`${url}/admin/announcements/${dbName}/${id}`);
      notification.success({
        message: "Announcement Deleted Successfully!",
      });
      setCount(count + 1);
    } catch (error) {
      notification.error({
        message: "Error Deleting Announcement",
        description: error.message || "Something went wrong!",
      });
    }
  };

  const handleCategoryChange = (value) => {
    let options = [];

    switch (value) {
      case "designation":
        options = ["Manager", "Developer", "HR"];
        break;
      case "department":
        options = ["HR", "Engineering", "Sales", "Marketing"];
        break;
      case "location":
        options = ["New York", "San Francisco", "London", "Berlin"];
        break;
      case "grade":
        options = ["Grade A", "Grade B", "Grade C"];
        break;
      case "employeeType":
        options = ["Full-time", "Part-time", "Contract"];
        break;
      default:
        options = ["All"];
        break;
    }

    setPublishOptions(options);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const currentPageData = list.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <div
      style={{
        maxWidth: "800px",
        marginTop: "20px",
        margin: "0 auto",
        padding: "20px",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
        borderRadius: "8px",
      }}
    >
      <Tabs>
        <TabPane style={{ alignItems: "center" }} tab="Post an Announcement">
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please enter the announcement title!",
                },
              ]}
            >
              <Input
                placeholder="Enter your title"
                style={{ fontSize: "16px" }}
              />
            </Form.Item>

            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  required: true,
                  message: "Please enter the announcement description!",
                },
              ]}
            >
              <ReactQuill
                theme="snow"
                value={description}
                onChange={setDescription}
                placeholder="Enter your message"
                style={{ height: "150px" }}
              />
            </Form.Item>
            {/* <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="category"
                  label="Category"
                  style={{ marginTop: "10vh" }}
                >
                  <Select
                    size="medium"
                    placeholder="Select Category"
                    onChange={handleCategoryChange}
                  >
                    <Select.Option value="all">All</Select.Option>
                    <Select.Option value="designation">
                      Designation
                    </Select.Option>
                    <Select.Option value="department">Department</Select.Option>
                    <Select.Option value="location">Location</Select.Option>
                    <Select.Option value="grade">Grade</Select.Option>
                    <Select.Option value="employeeType">
                      Employee Type
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="publishTo"
                  label="Publish To"
                  style={{ marginTop: "10vh" }}
                >
                  <Select size="medium" placeholder="Select Publish To">
                    {publishOptions.map((option, index) => (
                      <Select.Option key={index} value={option}>
                        {option}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row> */}
            <Form.Item
              name="expires"
              label="Expires On"
              rules={[
                {
                  required: true,
                  message: "Please select the expiration date!",
                },
              ]}
              style={{ marginTop: "10vh" }}
            >
              <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item name="Important" valuePropName="checked">
              <Checkbox>
                <ExclamationOutlined />
                Mark as Important
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                style={{ width: "100%" }}
              >
                {loading ? "Posting..." : "Post Announcement"}
              </Button>
            </Form.Item>
          </Form>
        </TabPane>

        <TabPane tab="Announcement List" key="2">
          <List
            className="demo-loadmore-list"
            loading={initLoading}
            itemLayout="horizontal"
            dataSource={currentPageData}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Button
                    type="text"
                    icon={<DeleteOutlined />}
                    onClick={() => handleDelete(item._id)}
                  />, // Delete icon instead of a link
                ]}
              >
                <Skeleton avatar title={false} loading={item.loading} active>
                  <List.Item.Meta
                    title={<a href="#">{item.Title}</a>}
                    description={
                      <div
                        dangerouslySetInnerHTML={{ __html: item.Description }}
                      />
                    }
                  />
                  <div>
                    <strong>Expires on: </strong>
                    {new Date(item.Expires).toLocaleDateString()}
                  </div>
                </Skeleton>
              </List.Item>
            )}
          />
          {/* Pagination */}
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={list.length}
            onChange={handlePageChange}
            style={{ marginTop: "20px", textAlign: "center" }}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

const LetterPadForm = () => {
  const [uploadedHeader, setUploadedHeader] = useState(null);
  const [header, setHeader] = useState(null);
  const [uploadedFooter, setUploadedFooter] = useState(null);
  const [footer, setFooter] = useState(null);
  const [signatories, setSignatories] = useState([]);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [form1] = Form.useForm();
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [count]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(`${url}/get/admin/letterpad/${dbName}`);
      if (response.data) {
        setUploadedHeader(response.data[0].letterheader || null);
        setUploadedFooter(response.data[0].letterfooter || null);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  const handleUpload = (info, type) => {
    if (info.file) {
      const file = info.file.originFileObj || info.file;
      const reader = new FileReader();

      reader.onload = () => {
        if (type === "header") {
          setUploadedHeader(reader.result);
          setHeader(reader.result);
        } else {
          setUploadedFooter(reader.result);
          setFooter(reader.result);
        }
      };

      reader.readAsDataURL(file);
    } else {
      if (type === "header") {
        setUploadedHeader(null);
        setHeader(null);
      } else {
        setUploadedFooter(null);
        setFooter(null);
      }
    }
  };

  const handleRemove = (type) => {
    if (type === "header") {
      setUploadedHeader(null);
      setHeader(null);
    } else {
      setUploadedFooter(null);
      setFooter(null);
    }
  };

  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  const onClose = () => {
    setIsDrawerVisible(false);
  };

  const handleAddSignatory = (values) => {
    setSignatories([...signatories, { ...values, id: signatories.length + 1 }]);
    setIsDrawerVisible(false);
  };

  const onFinish4 = async (values) => {
    const dbName = localStorage.getItem("dbName");
    try {
      const type = "header";
      values.letterheader = uploadedHeader;
      const response = await axios.post(
        `${url}/admin/combined/${type}/${dbName}`,
        values
      );
      setCount(count + 1);
      form1.resetFields();
      notification.success({
        message: `Posted Successfully!`,
        duration: 3,
      });
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  const onFinish5 = async (values) => {
    const dbName = localStorage.getItem("dbName");
    try {
      const type = "footer";
      values.letterfooter = uploadedFooter;
      const response = await axios.post(
        `${url}/admin/combined/${type}/${dbName}`,
        values
      );
      setCount(count + 1);
      form1.resetFields();
      notification.success({
        message: `Posted Successfully!`,
        duration: 3,
      });
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
    },
    {
      title: "Active",
      dataIndex: "active",
      key: "active",
      render: (text) => (text ? "Yes" : "No"),
    },
  ];

  return (
    <>
      <Tabs>
        <TabPane tab="Letterpad" key="1">
          <Row gutter={16}>
            <Col span={12}>
              <Form layout="vertical" onFinish={onFinish4}>
                <Form.Item label="Letter Header" name="letterheader">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {loading ? (
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <Spin size="large" />
                      </div>
                    ) : (
                      <>
                        {uploadedHeader ? (
                          <img
                            src={uploadedHeader}
                            alt="Uploaded Header"
                            style={{
                              width: "40vh",
                              marginLeft: "20px",
                              borderRadius: "10px",
                              height: "10vh",
                              marginBottom: "20px",
                              objectFit: "contain",
                            }}
                          />
                        ) : (
                          <p style={{ marginTop: "10px" }}>
                            No Footer Available
                          </p>
                        )}
                      </>
                    )}

                    <Dragger
                      customRequest={(info) => handleUpload(info, "header")}
                      onRemove={() => handleRemove("header")}
                      accept="image/*"
                      maxCount={1}
                      showUploadList={false}
                      style={{ marginTop: "10px" }}
                    >
                      <p className="ant-upload-drag-icon">
                        <UploadOutlined />
                      </p>
                      <p className="ant-upload-text">Drag & Drop Header Here</p>
                    </Dragger>
                  </div>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginLeft: "100px" }}
                    disabled={!header}
                  >
                    Save Header
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col span={12}>
              <Form layout="vertical" onFinish={onFinish5}>
                <Form.Item label="Letter Footer" name="letterfooter">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {loading ? (
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <Spin size="large" />
                      </div>
                    ) : (
                      <>
                        {uploadedFooter ? (
                          <img
                            src={uploadedFooter}
                            alt="Uploaded Footer"
                            style={{
                              width: "40vh",
                              height: "10vh",
                              marginLeft: "10px",
                              borderRadius: "10px",
                              marginBottom: "20px",
                              objectFit: "contain",
                            }}
                          />
                        ) : (
                          <p style={{ marginTop: "10px" }}>
                            No Footer Available
                          </p>
                        )}
                      </>
                    )}
                    <Dragger
                      customRequest={(info) => handleUpload(info, "footer")}
                      onRemove={() => handleRemove("footer")}
                      accept="image/*"
                      maxCount={1}
                      showUploadList={false}
                      style={{ marginTop: "10px" }}
                    >
                      <p className="ant-upload-drag-icon">
                        <UploadOutlined />
                      </p>
                      <p className="ant-upload-text">Drag & Drop Footer Here</p>
                    </Dragger>
                  </div>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginLeft: "100px" }}
                    disabled={!footer}
                  >
                    Save Footer
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </TabPane>
        {/* <TabPane tab="Signatory" key="2">
          <Row>
            <Button
              type="primary"
              onClick={showDrawer}
              style={{ display: "flex", justifyContent: "end" }}
            >
              Add Signatory
            </Button>
          </Row>
          <Table
            className="custom-table col-lg-12 col-md-12 col-sm-12"
            dataSource={signatories}
            columns={columns}
            rowKey="id"
          />

          <Drawer
            title="Add Signatory"
            width={500}
            onClose={onClose}
            visible={isDrawerVisible}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
              <div
                style={{
                  textAlign: "right",
                }}
              >
                <Button onClick={onClose} style={{ marginRight: 8 }}>
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    form1
                      .validateFields()
                      .then((values) => {
                        handleAddSignatory(values);
                        form1.resetFields();
                      })
                      .catch((info) => {
                        console.log("Validate Failed:", info);
                      });
                  }}
                  type="primary"
                >
                  Submit
                </Button>
              </div>
            }
          >
            <Form layout="vertical" form={form1}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[
                      { required: true, message: "Please enter first name" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[
                      { required: true, message: "Please enter last name" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="designation"
                    label="Designation"
                    rules={[
                      {
                        required: true,
                        message: "Please enter designation",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="active" valuePropName="checked">
                    <Checkbox>Active</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Drawer>
        </TabPane> */}
      </Tabs>
    </>
  );
};

const CardForm = () => {
  const [uploadedAnniversary, setUploadedAnniversary] = useState(null);
  const [anniversaryMessage, setAnniversaryMessage] = useState("");
  const [birthdayMessage, setBirthdayMessage] = useState("");
  const [uploadedBirthday, setUploadedBirthday] = useState(null);
  const [anniversary, setAnniversary] = useState(null);
  const [birthday, setBirthday] = useState(null);
  const [count, setCount] = useState(0);
  const [form1] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [count]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(`${url}/get/wishes/${dbName}`);
      if (response.data) {
        setUploadedAnniversary(response.data[0].anniversary || null);
        setUploadedBirthday(response.data[0].birthday || null);
        setAnniversaryMessage(response.data[0].anniversaryMessage);
        setBirthdayMessage(response.data[0].birthdayMessage);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  const handleUpload = (info, type) => {
    console.log(info, "info");

    // Check if the file exists
    if (info.file) {
      const file = info.file.originFileObj || info.file;
      const reader = new FileReader();

      reader.onload = () => {
        if (type === "anniversary") {
          setUploadedAnniversary(reader.result);
          setAnniversary(reader.result);
        } else {
          setUploadedBirthday(reader.result);
          setBirthday(reader.result);
        }
      };

      reader.readAsDataURL(file);
    } else {
      // Handle file removal
      if (type === "anniversary") {
        setUploadedAnniversary(null);
        setAnniversary(null);
      } else {
        setUploadedBirthday(null);
        setBirthday(null);
      }
    }
  };

  const handleRemove = (type) => {
    if (type === "anniversary") {
      setUploadedAnniversary(null);
      setAnniversary(null);
    } else {
      setUploadedBirthday(null);
      setBirthday(null);
    }
  };

  const onFinish4 = async (values) => {
    const dbName = localStorage.getItem("dbName");
    try {
      const type = "anniversary";
      values.anniversary = uploadedAnniversary;
      values.anniversaryMessage = anniversaryMessage;
      const response = await axios.post(
        `${url}/admin/wishes/${dbName}`,
        values
      );
      setCount(count + 1);
      form1.resetFields();
      notification.success({
        message: `Posted Successfully!`,
        duration: 3,
      });
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  const onFinish5 = async (values) => {
    const dbName = localStorage.getItem("dbName");
    try {
      const type = "birthday";
      values.birthday = uploadedBirthday;
      values.birthdayMessage = birthdayMessage;
      const response = await axios.post(
        `${url}/admin/wishes/${dbName}`,
        values
      );
      setCount(count + 1);
      form1.resetFields();
      notification.success({
        message: `Posted Successfully!`,
        duration: 3,
      });
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  return (
    <>
      <Tabs>
        <TabPane tab="Wishes Card" key="1">
          <Row gutter={16}>
            <Col span={12}>
              <Form layout="vertical" onFinish={onFinish4}>
                <Form.Item label="Anniversary" name="anniversary">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Dragger
                      customRequest={(info) =>
                        handleUpload(info, "anniversary")
                      }
                      onRemove={() => handleRemove("anniversary")}
                      accept="image/*"
                      maxCount={1}
                      showUploadList={false}
                    >
                      <p className="ant-upload-drag-icon">
                        <UploadOutlined />
                      </p>
                      <p className="ant-upload-text">Drag & Drop Image Here</p>
                    </Dragger>
                    {loading ? (
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <Spin size="large" />
                      </div>
                    ) : (
                      <>
                        {uploadedAnniversary ? (
                          <img
                            src={uploadedAnniversary}
                            alt="Uploaded anniversary"
                            style={{
                              width: "20vh",
                              height: "20vh",
                              marginLeft: "10px",
                              marginTop: "10px",
                              borderRadius: "10px",
                            }}
                          />
                        ) : (
                          <p style={{ marginTop: "10px" }}>No Card Available</p>
                        )}
                      </>
                    )}
                  </div>
                </Form.Item>
                <Form.Item>
                  <Input.TextArea
                    value={anniversaryMessage}
                    onChange={(e) => setAnniversaryMessage(e.target.value)}
                    rows={4}
                    type="text"
                    placeholder="Type message content for anniversary wishes"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginLeft: "10px" }}
                    disabled={!anniversary}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col span={12}>
              <Form layout="vertical" onFinish={onFinish5}>
                <Form.Item label="Birthday" name="birthday">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Dragger
                      customRequest={(info) => handleUpload(info, "birthday")}
                      onRemove={() => handleRemove("birthday")}
                      accept="image/*"
                      maxCount={1}
                      showUploadList={false}
                    >
                      <p className="ant-upload-drag-icon">
                        <UploadOutlined />
                      </p>
                      <p className="ant-upload-text">Drag & Drop Image Here</p>
                    </Dragger>
                    {loading ? (
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <Spin size="large" />
                      </div>
                    ) : (
                      <>
                        {uploadedBirthday ? (
                          <img
                            src={uploadedBirthday}
                            alt="Uploaded Birthday"
                            style={{
                              width: "20vh",
                              height: "20vh",
                              marginTop: "10px",
                              marginLeft: "10px",
                              borderRadius: "10px",
                            }}
                          />
                        ) : (
                          <p style={{ marginTop: "10px" }}>No Card Available</p>
                        )}
                      </>
                    )}
                  </div>
                </Form.Item>
                <Form.Item>
                  <Input.TextArea
                    value={birthdayMessage}
                    onChange={(e) => setBirthdayMessage(e.target.value)}
                    rows={4}
                    type="text"
                    placeholder="Type message content for birthday wishes"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginLeft: "10px" }}
                    disabled={!birthday}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </>
  );
};
const LeaveForm = () => {
  const [leaveType, setLeaveType] = useState([]);
  const [formVisible, setFormVisible] = useState(false);
  const [editLeaveType, setEditLeaveType] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(`${url}/get/leave/${dbName}`);
      const fetchedLeaveTypes = response.data.leaveType || [];
      setLeaveType(fetchedLeaveTypes);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteLeave = async (id) => {
    const dbName = localStorage.getItem("dbName");
    try {
      await axios.delete(`${url}/adminleave/remove/${dbName}/${id}`);
      fetchData();
    } catch (error) {
      console.error(error.response.data.message);
    }
  };

  const handleAddOrEditLeaveType = async (values) => {
    const dbName = localStorage.getItem("dbName");
    try {
      const leaveTypeData = {
        type: values.type,
        days: values.days || 0,
        carryForward: {
          type: values.carryForward || false,
          days: values.carryForwardDays || 0,
        },
      };

      const requestPayload = {
        values: leaveTypeData,
      };

      if (editLeaveType) {
        // If editing, include the _id in the payload
        requestPayload._id = editLeaveType._id;

        await axios.post(`${url}/add/leave/${dbName}`, requestPayload);
      } else {
        // Adding new leave type
        await axios.post(`${url}/add/leave/${dbName}`, requestPayload);
      }

      // Refresh data and reset form
      fetchData();
      form.resetFields();
      setFormVisible(false);
      setEditLeaveType(null);
    } catch (error) {
      console.error(error.response.data.message);
    }
  };

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        handleAddOrEditLeaveType(values);
      })
      .catch((info) => {
        console.error("Validate Failed:", info);
      });
  };

  const handleEdit = (item) => {
    form.setFieldsValue({
      type: item.type,
      days: item.days,
      carryForward: item.carryForward.type,
      carryForwardDays: item.carryForward.days,
    });
    setEditLeaveType(item);
    setFormVisible(true);
  };

  return (
    <div style={{ padding: "20px" }}>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Leave Types" key="1">
          {!formVisible && (
            <Button
              type="primary"
              onClick={() => setFormVisible(true)}
              style={{ marginBottom: "16px" }}
            >
              Add Leave Type
            </Button>
          )}
          {formVisible && (
            <Form
              form={form}
              layout="vertical"
              style={{
                marginBottom: "16px",
                maxWidth: "500px",
                fontSize: "18px",
              }}
            >
              <Form.Item
                name="type"
                label="Leave Type"
                rules={[
                  { required: true, message: "Please enter a leave type!" },
                ]}
              >
                <Input placeholder="Enter leave type" />
              </Form.Item>

              <Form.Item
                name="days"
                label="Days"
                rules={[
                  {
                    required: true,
                    message: "Please enter the number of days!",
                  },
                ]}
              >
                <Input type="number" placeholder="Enter number of days" />
              </Form.Item>

              <Form.Item
                name="carryForward"
                label="Carry Forward"
                valuePropName="checked"
              >
                <Checkbox>Allow Carry Forward</Checkbox>
              </Form.Item>

              <Form.Item
                name="carryForwardDays"
                label="Carry Forward Days"
                rules={[
                  {
                    required: false,
                    message: "Please enter carry forward days!",
                  },
                ]}
              >
                <Input type="number" placeholder="Enter carry forward days" />
              </Form.Item>

              <Button type="primary" onClick={handleSave}>
                Save
              </Button>
              <Button
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  form.resetFields();
                  setFormVisible(false);
                  setEditLeaveType(null);
                }}
              >
                <CloseOutlined /> Close
              </Button>
            </Form>
          )}

          <List
            dataSource={leaveType}
            renderItem={(item) => (
              <List.Item>
                <Card
                  style={{
                    width: "50%",
                    borderRadius: "8px",
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span>Leave Type</span>
                      <span>{item?.type}</span>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span>Days</span>
                      <span>{item?.days}</span>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span>Carry Forward</span>
                      <span>
                        {item?.carryForward.type
                          ? `Yes (${item?.carryForward.days} days)`
                          : "No"}
                      </span>
                    </div>
                    <div>
                      <EditOutlined
                        style={{ color: "#1890ff", marginRight: "10px" }}
                        onClick={() => handleEdit(item)}
                      />
                      <DeleteOutlined
                        style={{ color: "#ff4d4f" }}
                        onClick={() => handleDeleteLeave(item?._id)}
                      />
                    </div>
                  </div>
                </Card>
              </List.Item>
            )}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

const Holidays = () => {
  const [view, setView] = useState("calendar");
  const [selectedDate, setSelectedDate] = useState(null);
  const [holidays, setHolidays] = useState([]);
  const [weekOff, setWeekOff] = useState([]);
  const [form] = Form.useForm();
  const [modalOpen, setModalOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [selectedValue, setSelectedValue] = useState("frioff");

  useEffect(() => {
    fetchData();
  }, [count]);

  const fetchData = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(`${url}/get/leave/${dbName}`);
      if (response.data) {
        const fetchedHolidays = response.data.officeHolidaysData.map(
          (holiday) => {
            return {
              title: holiday.title,
              date: holiday.date,
            };
          }
        );
        setHolidays(fetchedHolidays);
        setSelectedValue(response.data.weekend);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDateClick = (date) => {
    const formattedDate = date.format("YYYY-MM-DD");
    const existingHoliday = holidays.find((h) => h.date === formattedDate);

    if (existingHoliday) {
      form.setFieldsValue({ title: existingHoliday.title });
    } else {
      form.resetFields();
    }

    setSelectedDate(date);
    setModalOpen(true);
  };

  const onFinish = async (values) => {
    const title = values.title;
    const date = selectedDate ? selectedDate.format("DD/MM/YYYY") : null;
    const formattedDate = selectedDate
      ? selectedDate.format("YYYY-MM-DD")
      : null;
    values.date = formattedDate;

    try {
      const dbName = localStorage.getItem("dbName");
      const existingHoliday = holidays.find((h) => h.date === formattedDate);
      console.log("exists", existingHoliday, formattedDate, holidays, title);
      await axios.post(`${url}/admin/ofcholiday/${dbName}`, values);
      setModalOpen(false);
      setCount(count + 1);
      form.resetFields();
    } catch (error) {
      console.error("Error saving holiday:", error);
    }
  };

  const handleDeleteHoliday = async (date, title) => {
    const dbName = localStorage.getItem("dbName");
    try {
      // Make a DELETE request to the backend API
      const response = await axios.delete(
        `${url}/remove/combined/${dbName}/${date}/${title}`
      );
      console.log(response.data);

      setCount(count + 1);
    } catch (error) {
      // Handle error response
      console.error(error.response.data.message);
    }
  };

  const handleSelectChange = async (value) => {
    setSelectedValue(value);

    const values = {
      weekend: value,
    };

    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.post(
        `${url}/admin/ofcholiday/${dbName}`,
        values
      );
      message.success(response.data);
    } catch (error) {
      console.error("Error:", error);
      message.error("Failed to update weekend");
    }
  };

  return (
    <Tabs>
      <TabPane tab="Annual Holiday">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Segmented
            options={[
              {
                icon: (
                  <Tooltip title="Calendar View">
                    <CalendarFilled />
                  </Tooltip>
                ),
                value: "calendar",
              },
              {
                icon: (
                  <Tooltip title="List View">
                    <BarsOutlined />
                  </Tooltip>
                ),
                value: "list",
              },
            ]}
            value={view}
            onChange={setView}
          />
          <Flex vertical gap="middle">
            <Form.Item label="Select Weekend" style={{ marginBottom: 0 }}>
              <Select
                value={selectedValue}
                onChange={handleSelectChange}
                size="small"
                style={{ width: 190 }}
                placeholder="Select weekend"
              >
                <Option value="frioff">Friday Off</Option>
                <Option value="frisatoff">Friday Saturday Off</Option>
                <Option value="satoff">Saturday Off</Option>
                <Option value="satsunoff">Saturday Sunday Off</Option>
                <Option value="sunoff">Sunday Off</Option>
              </Select>
            </Form.Item>
          </Flex>
        </div>
        {view === "calendar" && (
          <div>
            <Calendar
              dateCellRender={(date) => {
                const formattedDate = date.format("YYYY-MM-DD");
                const holiday = holidays.find((h) => h.date === formattedDate);
                return (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDateClick(date);
                    }}
                    style={{ height: "100%", cursor: "pointer" }}
                  >
                    <div style={{ backgroundColor: "#ffff", padding: "5px" }}>
                      {holiday ? holiday.title : ""}
                    </div>
                    {console.log(holiday, "holi")}
                  </div>
                );
              }}
            />

            {selectedDate && (
              <Modal
                visible={modalOpen}
                footer={null}
                onCancel={() => {
                  setModalOpen(false);
                  setSelectedDate(null);
                }}
              >
                <Form onFinish={onFinish} form={form}>
                  <Form.Item
                    label="Title"
                    name="title"
                    rules={[
                      { required: true, message: "Please enter a title" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Date">
                    <Input value={selectedDate.format("DD/MM/YYYY")} readOnly />
                  </Form.Item>
                  <Form.Item
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Modal>
            )}
          </div>
        )}

        {view === "list" && (
          <div>
            <div>
              {holidays.length === 0 ? (
                <p>No holidays added yet.</p>
              ) : (
                holidays.map((ofc, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px",
                      margin: "8px 0",
                      borderRadius: "6px",
                      boxShadow: "rgba(100, 100, 111, 0.1) 0px 7px 29px 0px",
                      backgroundColor: "#fff",
                      width: "70%",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <div>
                      <p>
                        {ofc.date} - {ofc.title}
                      </p>
                    </div>
                    <Button
                      type="primary"
                      icon={<DeleteFilled />}
                      danger
                      onClick={() => handleDeleteHoliday(ofc.date, ofc.title)}
                    />
                  </div>
                ))
              )}
            </div>
          </div>
        )}
      </TabPane>
    </Tabs>
  );
};

const PolicyForm = () => {
  const [form] = Form.useForm();
  const [file, setFile] = useState(null);
  const [policies, setPolicies] = useState([]);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onFinish = async (values) => {
    if (!file) {
      message.error("Please upload a policy file");
      return;
    }
    const dbName = localStorage.getItem("dbName");
    const payload = {
      name: values.name,
      date: values.date,
      policy: file,
    };

    try {
      const response = await axios.post(
        `${url}/uploadPolicy/${dbName}`,
        payload
      );
      if (response.status === 201) {
        message.success("Policy uploaded successfully");
        form.resetFields();
        setFile(null);
      }
    } catch (error) {
      message.error("Failed to upload policy");
      console.error(error);
    }
  };

  const handleFileChange = (info) => {
    const file = info.file.originFileObj || info.file;
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFile(reader.result);
      };

      reader.readAsDataURL(file);
    } else {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const fetchPolicies = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(`${url}/getPolicies/${dbName}`);
      setPolicies(response.data);
    } catch (error) {
      message.error("Failed to fetch policies");
      console.error(error);
    }
  };

  const handleDelete = async (policyId) => {
    try {
      const dbName = localStorage.getItem("dbName");
      await axios.delete(`${url}/deletePolicy/${dbName}/${policyId}`);
      message.success("Policy deleted successfully");
      fetchPolicies();
    } catch (error) {
      message.error("Failed to delete policy");
      console.error(error);
    }
  };

  // Fetch policies when the component mounts
  useEffect(() => {
    fetchPolicies();
  }, []);

  // Define columns for the policies table
  const columns = [
    {
      title: "Policy Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Valid From",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Policy File",
      dataIndex: "policy",
      key: "policy",
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          View Policy
        </a>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Popconfirm
          title="Are you sure to delete this policy?"
          onConfirm={() => handleDelete(record._id)}
          okText="Yes"
          cancelText="No"
        >
          <Button danger icon={<DeleteFilled />} />
        </Popconfirm>
      ),
    },
  ];

  return (
    <Tabs>
      <TabPane tab="Upload Policy" key="1">
        <Form
          {...layout}
          form={form}
          onFinish={onFinish}
          style={{ maxWidth: 600, margin: "0 auto" }}
        >
          <Form.Item
            label="Policy Name"
            name="name"
            rules={[
              { required: true, message: "Please enter the policy name" },
            ]}
            style={{ fontSize: "12px" }}
          >
            <Input size="large" placeholder="Enter policy name" />
          </Form.Item>

          <Form.Item
            label="Policy valid from"
            name="date"
            rules={[{ required: true, message: "Please select a valid date" }]}
          >
            <Input type="date" size="large" />
          </Form.Item>

          <Form.Item
            label="Policy"
            name="policy"
            rules={[{ required: true, message: "Please upload a policy file" }]}
            valuePropName="file"
          >
            <Upload.Dragger
              name="file"
              accept="application/pdf"
              customRequest={({ file, onSuccess }) =>
                setTimeout(() => onSuccess("ok"), 0)
              }
              onChange={handleFileChange}
              beforeUpload={() => false}
              maxCount={1}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Drag and drop your policy file here or click to upload
              </p>
              <p className="ant-upload-hint">Only PDF files are accepted</p>
            </Upload.Dragger>
          </Form.Item>

          <Form.Item style={{ display: "flex", justifyContent: "center" }}>
            <Button type="primary" htmlType="submit" className="button--color">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </TabPane>
      <TabPane tab="Policy List" key="2">
        <Table
          className="custom-table col-lg-12 col-md-12 col-sm-12"
          dataSource={policies}
          columns={columns}
          rowKey="_id"
          pagination={{ pageSize: 5 }}
        />
      </TabPane>
    </Tabs>
  );
};

const EmployeeForm = () => {
  const [targetKeys, setTargetKeys] = useState([]);
  const [modules, setModules] = useState([
    {
      id: 1,
      name: "Designation",
    },
    {
      id: 2,
      name: "Department",
    },
    {
      id: 3,
      name: "Location",
    },
    { id: 4, name: "Grade" },
    { id: 5, name: "Employee Type" },
  ]);

  const [dummyData, setDummyData] = useState({
    1: [
      { id: 101, name: "Manager" },
      { id: 102, name: "Developer" },
    ],
    2: [
      { id: 201, name: "HR" },
      { id: 202, name: "Finance" },
    ],
    3: [
      { id: 301, name: "New York" },
      { id: 302, name: "San Francisco" },
    ],
    4: [
      { id: 401, name: "Senior" },
      { id: 402, name: "Junior" },
    ],
    5: [
      { id: 501, name: "Full-time" },
      { id: 502, name: "Part-time" },
    ],
  });

  const data = [
    { key: "1", title: "PAN Number" },
    { key: "2", title: "UAN Number" },
    { key: "3", title: "Bank A/C Number" },
    { key: "4", title: "PAN and DOB" },
    { key: "5", title: "Name and DOB" },
  ];

  const handleChange = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys);
  };
  const [selectedModule, setSelectedModule] = useState(null);

  useEffect(() => {
    setSelectedModule(modules[0].id);
  }, []);

  const handleCardClick = (moduleId) => {
    setSelectedModule(moduleId);
  };

  const handleEdit = (id) => {
    // Add edit logic here
    console.log(`Edit record with ID: ${id}`);
  };
  const handleDelete = (id) => {
    setModules(modules.filter((module) => module.id !== id));
    // Reset selected module if the deleted module was selected
    if (selectedModule === id) {
      setSelectedModule(null);
    }
  };
  const handleAddNew = () => {
    if (!selectedModule) return;

    const newItem = {
      id: Date.now(),
      name: "New Item",
    };

    setDummyData({
      ...dummyData,
      [selectedModule]: [...(dummyData[selectedModule] || []), newItem],
    });
  };

  return (
    <Tabs>
      <TabPane tab="Employee Options" key="1">
        <br />
        <br />
        <Form layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Notice Period:">
                <Input defaultValue="60" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Separation Type">
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Resignation Reason">
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label="Employee Apply Fields">
                <Transfer
                  dataSource={data}
                  titles={["Available", "Selected"]}
                  targetKeys={targetKeys}
                  onChange={handleChange}
                  render={(item) => item.title}
                  listStyle={{
                    width: "100%",
                    height: 200,
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </TabPane>
      <TabPane tab="Position" key="2">
        <div style={{ display: "flex", gap: "24px" }}>
          <Col flex="1 1 200px" style={{ padding: "24px" }}>
            {modules.map((module) => (
              <Row xs={24} sm={12} md={8} lg={6} key={module.id}>
                <Card
                  onClick={() => handleCardClick(module.id)}
                  style={{
                    backgroundColor: "#f2f2f2",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "90px",
                    width: "200px",
                    cursor: "pointer",
                    marginBottom: "8px",
                  }}
                  bordered={false}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    {module.icon}
                    <span>{module.name}</span>
                  </div>
                </Card>
              </Row>
            ))}
          </Col>
          {selectedModule && (
            <Col
              flex="2 1 600px"
              style={{ padding: "24px", position: "relative" }}
            >
              <List
                bordered
                dataSource={dummyData[selectedModule]}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Button
                        icon={<EditOutlined />}
                        onClick={() => handleEdit(item.id)}
                        style={{ marginRight: "8px" }}
                      >
                        Edit
                      </Button>,
                      <Popconfirm
                        title="Are you sure you want to delete this item?"
                        onConfirm={() => handleDelete(item.id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button icon={<DeleteOutlined />} danger>
                          Delete
                        </Button>
                      </Popconfirm>,
                    ]}
                  >
                    {item.name}
                  </List.Item>
                )}
              />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                shape="circle"
                style={{
                  position: "absolute",
                  top: 0,
                  right: "24px",
                }}
                onClick={handleAddNew}
              />
            </Col>
          )}
        </div>
      </TabPane>
    </Tabs>
  );
};

const TimeSheet = () => {
  const [form] = Form.useForm();
  const [count, setCount] = useState(0);

  const fetchData = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(`${url}/get/admin/timesheet/${dbName}`);
      form.setFieldsValue({
        totalHours: response.data[0].totalHours,
        copyTimeSheet: response.data[0].copyTimeSheet ?? false,
        allowedFutureWeeks: response.data[0].allowedFutureWeeks,
        allowedPastWeeks: response.data[0].allowedPastWeeks,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [count]);

  const onFinish3 = async (values) => {
    const dbName = localStorage.getItem("dbName");

    try {
      const response = await axios.post(
        `${url}/admin/combined/others/${dbName}`,
        values
      );

      setCount(count + 1);
      form.resetFields();
      notification.success({
        message: `Posted Successfully!`,
        duration: 3,
      });
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  return (
    <>
      <Tabs>
        <TabPane tab="Timesheet" key="1">
          <br />
          <br />
          <Form
            form={form}
            layout="horizontal"
            labelCol={{ xs: { span: 10 }, sm: { span: 10 } }} 
            wrapperCol={{ xs: { span: 14 }, sm: { span: 16 } }} 
            style={{
              maxWidth: "400px",
              margin: "0 auto", 
              padding: "20px", 
              textAlign: "left",
              backgroundColor: "#f0f2f5",
              borderRadius: "10px", 
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", 
            }}
            onFinish={onFinish3}
          >
            <Form.Item
              label="Total Hours"
              name="totalHours"
              required
              labelAlign="left"
              style={{ marginBottom: "20px" }} // Add space between form items
            >
              <Input
                placeholder="Enter Hours"
                style={{ height: "40px", fontSize: "14px" }}
              />
            </Form.Item>

            <Form.Item
              label="Allowed Past Weeks"
              name="allowedPastWeeks"
              labelAlign="left"
              style={{ marginBottom: "20px" }}
            >
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              label="Allowed Future Weeks"
              name="allowedFutureWeeks"
              labelAlign="left"
              style={{ marginBottom: "20px" }}
            >
              <InputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              name="copyTimeSheet"
              valuePropName="checked"
              label="Copy TimeSheet"
              labelAlign="left"
              wrapperCol={{ offset: 0, span: 24 }} // Center checkbox horizontally
              style={{ textAlign: "center" }}
            >
              <Checkbox />
            </Form.Item>

            <Form.Item
              wrapperCol={{ span: 24 }}
              style={{ textAlign: "center", marginTop: "20px" }}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        </TabPane>
      </Tabs>
    </>
  );
};

const Claim = () => {
  const [form] = Form.useForm();
  const [claims, setClaims] = useState([]);
  const [count, setCount] = useState(0);
  const dbName = localStorage.getItem("dbName");

  // Fetch data (claims) from backend
  const fetchData = async () => {
    try {
      const response = await axios.get(`${url}/get/admin/claims/${dbName}`);
      setClaims(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [count]);

  // Handle form submission to add a new claim
  const onFinish3 = async (values) => {
    try {
      await axios.post(`${url}/admin/claim/${dbName}`, values);
      setCount(count + 1);
      form.resetFields();
      notification.success({
        message: "Posted Successfully!",
        duration: 3,
      });
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  // Handle delete action for claims
  const handleDeleteClaims = async (claimType) => {
    try {
      await axios.delete(`${url}/admin/deleteClaim/${dbName}/${claimType}`);
      setCount(count + 1);
      notification.success({
        message: "Deleted Successfully!",
        duration: 3,
      });
    } catch (error) {
      console.error("Error deleting claim:", error);
    }
  };

  return (
    <>
      <Tabs>
        <TabPane tab="Reimbursement Claims" key="1">
          <br />
          <Form
            form={form}
            layout="horizontal"
            style={{ maxWidth: "400px", margin: "0 auto", textAlign: "left" }}
            onFinish={onFinish3}
          >
            <Form.Item>
              <Row gutter={8}>
                <Col span={16}>
                  <Form.Item
                    name="claimType"
                    rules={[
                      { required: true, message: "Please enter a claim type" },
                    ]}
                    style={{ marginBottom: 0 }}
                  >
                    <Input
                      placeholder="Enter Type"
                      style={{ height: "40px", fontSize: "14px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>

          {/* Claims List Display */}
          <div
            style={{
              overflow: "hidden",
              maxHeight: "200px",
              overflowY: "scroll",
              marginTop: "20px",
            }}
          >
            {claims.length > 0 ? (
              claims.map((item) => (
                <React.Fragment key={item._id}>
                  {Array.isArray(item.claimType) ? (
                    item.claimType.map((claim, index) => (
                      <div key={index}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ margin: 0 }}>{claim}</p>
                          <Button
                            type="primary"
                            danger
                            onClick={() => handleDeleteClaims(claim)}
                            icon={<DeleteOutlined />}
                            style={{ marginLeft: "10px" }}
                          />
                        </div>
                        {index !== item.claimType.length - 1 && <Divider />}
                      </div>
                    ))
                  ) : (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <p style={{ margin: 0 }}>{item.claimType}</p>
                        <Button
                          type="primary"
                          danger
                          onClick={() => handleDeleteClaims(item.claimType)}
                          icon={<DeleteOutlined />}
                          style={{ marginLeft: "10px" }}
                        />
                      </div>
                    </div>
                  )}
                  <Divider />
                </React.Fragment>
              ))
            ) : (
              <p>No claims available</p>
            )}
          </div>
        </TabPane>
      </Tabs>
    </>
  );
};

const ManageServicesForm = () => {
  const modules = [
    {
      id: 1,
      name: "Location",
      icon: <EnvironmentOutlined />,
      color: "#E3F2FD",
    },
    {
      id: 2,
      name: "Birthday Reminder",
      icon: <GiftOutlined />,
      color: "#FFF8E1",
    },
    {
      id: 3,
      name: "Anniversary Reminder",
      icon: <HeartOutlined />,
      color: "#FFEBEE",
    },
    { id: 4, name: "Thirukural", icon: <BookOutlined />, color: "#E8F5E9" },
    { id: 5, name: "Notify mail for Leave", icon: <Mail12Filled />, color: "#FFF8E1" },
  ];

  const [checkedStates, setCheckedStates] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(`${url}/get/admin/services/${dbName}`);
      const res = await axios.get(`${url}/api/masteruser/${dbName}`);
      const switchStates = {
        isActive: response.data[0]?.isActive,
        birthdayReminder: res.data.org.birthdayReminder,
        anniversaryReminder: res.data.org.anniversaryReminder,
        thirukkuralIsActive: response.data[0]?.thirukkuralIsActive,
      };
      const updatedStates = modules.reduce((acc, module) => {
        const backendField = fieldMapping[module.name];
        acc[module.id] = switchStates[backendField];
        return acc;
      }, {});
      setCheckedStates(updatedStates);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fieldMapping = {
    Location: "isActive",
    "Birthday Reminder": "birthdayReminder",
    "Anniversary Reminder": "anniversaryReminder",
    Thirukural: "thirukkuralIsActive",
  };

  const onFinish = async (type, dbName, newChecked) => {
    try {
      const backendField = fieldMapping[type];
      const response = await fetch(
        `${url}/admin/combined/${backendField}/${dbName}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ newChecked }),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        notification.success({
          message: `Posted Successfully!`,
          duration: 3,
        });
      } else {
        throw new Error(
          `Failed to post data: ${response.status} ${response.statusText}`
        );
      }
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  const handleChange = async (module, newChecked) => {
    const updatedStates = {
      ...checkedStates,
      [module.id]: newChecked,
    };
    setCheckedStates(updatedStates);
    if (module.name === "thirukural") {
      localStorage.setItem("thirukural", JSON.stringify(newChecked));
    }
    const dbName = localStorage.getItem("dbName");
    await onFinish(module.name, dbName, newChecked);
  };

  return (
    <Row gutter={[16, 16]} style={{ padding: "24px" }}>
      {modules.map((module) => (
        <Col xs={24} sm={12} md={8} lg={6} key={module.id}>
          <Card
            style={{
              backgroundColor: module.color,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "90px",
            }}
            bordered={false}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              {module.icon}
              <span>{module.name}</span>
            </div>
            <Switch
              checkedChildren="ON"
              unCheckedChildren="OFF"
              checked={checkedStates[module.id]}
              onChange={(checked) => handleChange(module, checked)}
            />
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default AdminConfigPage;
