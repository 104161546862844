import React, { useEffect } from 'react';
import { Form, Input, Button, DatePicker, Checkbox, message, Row, Col, Card, Divider, Radio } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
const SelfData = ({ onSave, initialValues }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }
    }, [initialValues]);

    const onFinish = (values) => {
        console.log('Received values:', values);
        onSave(values);
        message.success('Saved your Details, You can move to the next step');
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };




    return (
        <div>
            <Form
                form={form}
                name="BasicDetails"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 14 }}
            >
                <Row gutter={16}>
                    <Col span={10}>
                        <Card title="Basic Details" style={{ marginBottom: 16 }}>

                            <Form.Item

                                label="First Name"
                                name='FName'
                                wrapperCol={{ span: 9 }}
                                rules={[{ required: true, message: 'Please enter your first Name' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item

                                label="Middle Name"
                                name='MName'
                                wrapperCol={{ span: 9 }}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item

                                label="Last Name"
                                name='LName'
                                wrapperCol={{ span: 9 }}
                                rules={[{ required: true, message: 'Please enter your last Name' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item

                                label="Father's First Name"
                                name='FatherFName'
                                wrapperCol={{ span: 9 }}
                                rules={[{ required: true, message: `Please enter your Father's First Name` }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item

                                label="Father's Last Name"
                                name='FatherLName'
                                wrapperCol={{ span: 9 }}
                                rules={[{ required: true, message: `Please enter your Father's Last Name` }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item

                                label="Nationality"
                                name='Nationality'
                                wrapperCol={{ span: 9 }}
                                rules={[{ required: true, message: `Please enter your Nationality` }]}
                            >
                                <Input />
                            </Form.Item>
                           
                            <Divider />
                            <Form.Item

                                label="Date of birth"
                                name='DOB'
                                wrapperCol={{ span: 9 }}
                                rules={[{ required: true, message: 'Please select your date of birth' }]}
                            >
                                <DatePicker />
                            </Form.Item>
                            <Form.Item

                                label="StandardFormat(Name)"
                                name='StandardFormat'
                                wrapperCol={{ span: 9 }}
                                rules={[{ required: true, message: 'Please enter your Name in Standard Format' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item

                                label="Gender"
                                name='Gender'
                                wrapperCol={{ span: 9 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select a gender',
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    <Radio value="Male">Male</Radio>
                                    <Radio value="Female">Female</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item

                                label="Marital status"
                                name='MaritalStatus'
                                wrapperCol={{ span: 9 }}
                            >
                                <Radio.Group>
                                    <Radio value="Single">Single</Radio>
                                    <Radio value="Married">Married</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                label="Changed Name?"
                                name="ChangedFormat"
                                wrapperCol={{ span: 9 }}
                                initialValue="No" // Set initial value to "No"
                            >
                                <Radio.Group>
                                    <Radio value="Yes">Yes</Radio>
                                    <Radio value="No">No</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, currentValues) => prevValues.ChangedFormat !== currentValues.ChangedFormat}
                            >
                                {({ getFieldValue }) => {
                                    return getFieldValue('ChangedFormat') === 'Yes' ? (
                                        <>
                                            <Form.Item
                                                label="If Yes, Enter Name"
                                                name="ChangedName"
                                                wrapperCol={{ span: 9 }}
                                                rules={[{ required: true, message: 'Please enter your changed Name' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                label="Date of change"
                                                name="DChanged"
                                                wrapperCol={{ span: 9 }}
                                            >
                                                <DatePicker />
                                            </Form.Item>
                                        </>
                                    ) : null;
                                }}
                            </Form.Item>



                            <Divider />
                            <h6 >Complete Current Address and Contact Details</h6>
                            <br />
                            <Form.Item

                                label="Address & Postal Code"
                                name='Address'
                                wrapperCol={{ span: 9 }}
                                rules={[{ required: true, message: 'Please enter your Current Address' }]}
                            >
                                <Input />

                            </Form.Item>
                            <Form.Item

                                label="Land Mark"
                                name='LandMark'
                                wrapperCol={{ span: 9 }}
                                rules={[{ required: true, message: 'Please enter your Current LandMark' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item

                                label="LandLine/Telephone No"
                                name='LandLine/Telephone'
                                wrapperCol={{ span: 9 }}
                                rules={[{ required: true, message: 'Please enter your LandLine/Telephone No.' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item

                                label="Email"
                                name='BDEmail'
                                wrapperCol={{ span: 9 }}
                                rules={[{ required: true, message: 'Please enter your Email' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item

                                label="Mobile Number"
                                name='BDMobile'
                                wrapperCol={{ span: 9 }}
                                rules={[{ required: true, message: 'Please enter your Mobile Number' }]}
                            >
                                <Input />
                            </Form.Item>
                            {/* <Form.Item
                                                            label="Period of Stay:"
                                                        /> */}
                            
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Save
                                </Button>
                            </Form.Item>




                        </Card>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default SelfData