import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Radio,
  Checkbox,
  notification,
  Modal,
  Divider,
  message,
} from "antd";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { url } from "../../url/url";
import axios from "axios";
import { ArrowLeftOutlined } from "@ant-design/icons";

const Assessment = () => {
  const { courseName } = useParams();
  const [score, setScore] = useState(null);
  const [open, setOpen] = useState(false);
  const [allCourses, setAllCourses] = useState([]);
  const [selfCourses, setSelfCourses] = useState([]);
  const [courses, setCourses] = useState([]);
  const users = JSON.parse(localStorage.getItem("user"));
  const [count, setCount] = useState(0);
  const Navigate = useNavigate();
  const location = useLocation();
  const openrating = location.state.openRating;
  const id = location.state.course;
  const key = location.state.key;

  const handleSubmit = async () => {
    const dateObj = new Date();

    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const year = dateObj.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    const formData = {
      personName: users.displayName,
      courseName: courseName,
      date: formattedDate,
    };
    try {
      const response = await fetch(
        "https://myapp.finaran.com:5000/api/generate_certificate",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to generate certificate");
      }

      // Download the PDF certificate
      downloadCertificate();
    } catch (error) {
      console.error("Error generating certificate:", error);
      // Handle error gracefully, e.g., show a message to the user
    }
  };

  const downloadCertificate = async () => {
    try {
      const dbName= localStorage.getItem("dbName");
      const response = await fetch(
        `https://myapp.finaran.com:5000/api/download_certificate/${dbName}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to download certificate");
      }

      // Convert response to blob
      const blob = await response.blob();
      console.log(response, "res");
      // Create download link and trigger click
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "course_completion_certificate.pdf");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading certificate:", error);
      // Handle error gracefully, e.g., show a message to the user
    }
  };
  const fetchCourses = async () => {
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await axios.get(
        `${url}/get/selectedcourses/${courseName}/${dbName}`
      );
      const allCourses = response.data;
      setAllCourses(allCourses);
      // const userCourses = allCourses.filter(course => course.registered.includes(users.displayName));
      // setCourses(userCourses);
      // const selfCourses = allCourses.filter(course => course.selfRegistered.includes(users.displayName));
      // const filteredCourses = selfCourses.filter(course => course.courseName === courseName)
      // setSelfCourses(filteredCourses);
      console.log(allCourses, "selfCourses");
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, [count]);

  const showModal = () => {
    setOpen(true);
  };
  const updateSelfCompleted = async (status, isAssesment, score) => {
    const dbName = localStorage.getItem("dbName");
    try {
      console.log("score in assesment", score, status, isAssesment);
      const response = await fetch(
        `${url}/updateselfcompleted/${dbName}/${courseName}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            displayName: users.displayName,
            isAssessment: "true",
            mark: score,
            Status: status,
          }),
        }
      );

      if (response.ok) {
        console.log(response, "dtata");
        message.success("Course Updated successfully!");
        setCount(count + 1);
      } else {
        throw new Error("Failed to add course");
      }
    } catch (error) {
      message.error("Error adding course: " + error.message);
    }
  };

  const handleCancel = () => {
    setOpen(false);
    goBack();
  };
  const onFinish = (values) => {
    let userScore = 0;
    if (allCourses[0]?.assessment) {
      allCourses[0].assessment.forEach((test, index) => {
        const userAnswer = values[`question${index + 1}`];
        const correctAnswer = test.answer;
        console.log(userAnswer, correctAnswer, "answer");
        // If the correct answer is an array (for multiple answers)

        if (correctAnswer == userAnswer) {
          console.log("inside if");
          userScore += 1;
        }
      });
    } else {
      notification.error({
        message: "Error",
        description: "Assessment data is not available.",
      });
      return;
    }

    setScore(userScore);
    const status =
      userScore >= allCourses[0].minimumMarks
        ? "Completed"
        : "Assessment Inprogress";
    updateSelfCompleted(status, "1", userScore);
    showModal();
  };

  const goBack = () => {
    Navigate("/home/Traininghome", {
      state: { openrating, id, courseName, key },
    });
  };
  return (
    <>
      <Button type="text" onClick={goBack} icon={<ArrowLeftOutlined />} />
      <Form
        layout="vertical"
        onFinish={onFinish}
        style={{ maxWidth: 600, margin: "0 auto" }}
      >
        <h4>Assessment Questions for {courseName}</h4>
        <Divider style={{ background: "#525354" }} />

        {allCourses[0]?.assessment?.map((test, index) => (
          <React.Fragment key={index}>
            <br />
            <Form.Item
              label={`${index + 1}. ${test.question}`}
              name={`question${index + 1}`}
              rules={[{ required: true, message: "Please select an answer!" }]}
            >
              <Radio.Group>
                {test.option.map((option, optionIndex) => (
                  <Radio key={optionIndex} value={option}>
                    {option}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Divider style={{ background: "#525354" }} />
          </React.Fragment>
        ))}
        {console.log(allCourses[0]?.minimumMarks, "marks")}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>

      <Modal
        visible={open}
        onCancel={handleCancel}
        footer={null}
        title={
          score >= allCourses[0]?.minimumMarks ? (
            <img
              src={require("../../images/coursepass.webp")}
              style={{ width: "65vh", height: "50vh", marginLeft: "9vh" }}
            />
          ) : (
            <img
              src={require("../../images/coursefail.jpg")}
              style={{ width: "65vh", height: "50vh", marginLeft: "9vh" }}
            />
          )
        }
      >
        {score >= allCourses[0]?.minimumMarks ? (
          <div className="text-center">
            <h5 style={{ color: "green" }}>
              Congratulations You have passed the {courseName} Course
            </h5>
            <p>
              Your Score: <b style={{ color: "green" }}>{score}</b> /{" "}
              {allCourses[0]?.assessment?.length}
            </p>
          </div>
        ) : (
          <div className="text-center">
            <h6 style={{ color: "red" }}>
              Oops! You have failed the {courseName} Course learn and try again!
            </h6>
            <p>
              Your Score: <b style={{ color: "red" }}>{score}</b> /{" "}
              {allCourses[0]?.assessment?.length}
            </p>
          </div>
        )}
        {score >= allCourses[0]?.minimumMarks && (
          <Button
            type="primary"
            style={{ left: "25vh" }}
            onClick={handleSubmit}
            danger
          >
            Download your Certificate
          </Button>
        )}
      </Modal>
    </>
  );
};

export default Assessment;
