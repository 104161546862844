import React, { useEffect, useState } from 'react';
import { Card, Form, Button, Layout, Radio, Divider, Select, Row, Col, Checkbox } from 'antd';
import axios from 'axios';
import { url } from "../../url/url";
const { Option } = Select;

const Applicability = ({ onPublish, allValues }) => {
  const [showContent, setShowContent] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const EmployeeName = "Viji";
  const [users, setUsers] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [status, setStatus] = useState('');
  const handleSelectChange = (value) => {
    setSelectedValue(value);
  };

  const handleCheckboxChange = (e) => {
    setShowContent(e.target.checked);
  };
  const [selectedOption, setSelectedOption] = useState('Enable Comments');


  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };



  useEffect(() => {
    fetchUsers();
  }, [])
  const fetchUsers = async () => {
    const dbName = localStorage.getItem("dbName");
    const response = await axios.get(`${url}/api/get/allemplist/${dbName}`);
    const activeUsers = response.data.filter(
      (user) => user.empStatus !== "inactive"
    );

    // Extract unique designations and user names
    const uniqueDesignations = [...new Set(activeUsers.map((item) => item.empDesignation))];
    const userNames = activeUsers.map((item) => item.displayName);

    setDesignation(uniqueDesignations);
    setUsers(userNames);
  };



  const handleSubmit = (values) => {
    const updatedValues = { ...values, status };
    console.log(updatedValues, "onPublish");

    onPublish(updatedValues);
  };

  console.log(allValues.setup.period[0].$d, "all");


  const handleSaveClick = () => {
    setStatus('Draft');
  };

  const handlePublishClick = () => {
    setStatus('Ongoing');
  };
  return (
    <div>
      <Layout style={{ minHeight: '90vh' }}>
        <div style={{ maxHeight: '80vh', overflowY: 'auto' }}>
          <Form name="basic" onFinish={handleSubmit} layout="vertical">
            <Card style={{
              marginTop: "1vh",
              border: "1px solid #959696",
              width: "70%",
              margin: "0 auto",
            }}>
              <h5 style={{ textAlign: "left" }}>Applicability</h5>
              <p style={{ textAlign: "left" }}>Select the target set of employees</p>
              <Divider />
              <Form.Item label="Select applicability">
                <Radio.Group value={selectedOption} onChange={handleOptionChange}>
                  <Radio value="All employees">All Employees</Radio>
                  <Radio value="Specific employees">Specific Employees</Radio>
                </Radio.Group>
              </Form.Item>
              {selectedOption === 'All employees' && (
                <Card style={{ backgroundColor: '#bfbfbf', height: '8vh' }}>
                  <p style={{ textAlign: 'left', marginTop: '-2vh' }}>Survey is applicable to all employees of the organization</p>
                </Card>
              )}
              {selectedOption === 'Specific employees' && (
                <>
                  <Form.Item name="specificEmployees" label="Select specific employees">
                    <Select onChange={handleSelectChange}>
                      <Option value="Designation">Designation</Option>
                      <Option value="Users">Users</Option>
                    </Select>
                  </Form.Item>
                </>
              )}

              {selectedValue === 'Designation' && (
                <Form.Item name="applicability" label="Select Designation">
                  <Select placeholder="Select a designation">
                    {designation.map((designation) => (
                      <Option key={designation} value={designation}>
                        {designation}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}

              {selectedValue === 'Users' && (
                <Form.Item name="applicability" label="Select Users">
                  <Select
                    mode="multiple"
                    placeholder="Select users"
                  >
                    {users.map((user) => (
                      <Option key={user} value={user}>
                        {user}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Card>
            <br />
            <Card id="new-survey" style={{
              marginTop: "1vh",
              border: "1px solid #959696",
              width: "70%",
              margin: "0 auto",
            }}>
              <p style={{ textAlign: "left", fontSize: "20px" }}>Notification</p>
              <p style={{ textAlign: "left" }}>Notify applicable employees when a new survey has been added</p>
              <Divider />
              <Form.Item name="notification" valuePropName="checked" style={{ textAlign: "left" }}>
                <Checkbox onChange={handleCheckboxChange}>Enable email notification</Checkbox>
              </Form.Item>
              {showContent && (
                <Card title="Email message" style={{ textAlign: "left" }}>
                  <p style={{ textAlign: "left", fontWeight: "lighter" }}>You are invited to participate in {EmployeeName} survey</p>
                  <Divider />
                  <p style={{ textAlign: "left", fontWeight: "lighter" }}>Hello {EmployeeName},</p>
                  <p style={{ textAlign: "left", fontWeight: "lighter" }}>We have a new survey, your participation in the survey is of great importance to us.</p>
                  <p style={{ textAlign: "left", fontWeight: "lighter" }}>Please take a moment to complete the survey.</p>
                  <p style={{ textAlign: "left", fontWeight: "lighter" }}>Survey Details</p>
                  <p style={{ textAlign: "left", fontWeight: "lighter" }}>Survey Name: Viji</p>
                  <p style={{ textAlign: "left", padding: 0, fontWeight: "lighter" }}>Survey Period: 02-Sep-2024 - 08-Sep-2024</p>
                </Card>
              )}
            </Card>


            <br />
            <Card style={{
              marginTop: "1vh",
              border: "1px solid #959696",
              width: "70%",
              margin: "0 auto",
            }}>
              <h5>Publish</h5>
              <p style={{ textAlign: "left" }}>Review and publish the survey you have created</p>
              <Divider />
              <Card>
                <Row gutter={[16, 16]}>
                  <Col span={4}>
                    <p>Questions</p>
                    {/* Display the list of questions here */}
                    <p>{allValues.questions?.length}</p>
                  </Col>
                  <Col span={2}><Divider type='vertical' style={{ height: "12vh" }} /></Col>
                  <Col span={4}>
                    <p>Start Date</p>
                    {/* Check if period exists and has a valid $d property, then format the date */}
                    <p>{allValues?.setup?.period[0]?.$d ? new Date(allValues.setup.period[0].$d).toLocaleDateString() : 'N/A'}</p>
                  </Col>

                  <Col span={2}>
                    <Divider type="vertical" style={{ height: "12vh" }} />
                  </Col>

                  <Col span={4}>
                    <p>End Date</p>
                    {/* Check if period exists and has a valid $d property, then format the date */}
                    <p>{allValues?.setup?.period[1]?.$d ? new Date(allValues.setup.period[1].$d).toLocaleDateString() : 'N/A'}</p>
                  </Col>

                  <Col span={2}><Divider type='vertical' style={{ height: "12vh" }} /></Col>
                  <Col span={6}>
                    <p>Repeats</p>
                    <p>{allValues?.setup.surveyRepeats}</p>
                  </Col>
                </Row>
              </Card>

            </Card>

            <Form.Item style={{ textAlign: "center", }}>
              <Button style={{ bottom: "10vh" }} type="primary" danger htmlType="submit" onClick={handlePublishClick}>
                Publish
              </Button>
            </Form.Item>
            <Form.Item style={{ textAlign: "right", }}>
              <Button style={{ bottom: "10vh" }} type="primary" htmlType="submit" onClick={handleSaveClick}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Layout>


    </div>
  );
};

export default Applicability;