import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Button, Dropdown, Menu, Segmented, Select, Table, Drawer, Radio, Slider, Form, message, DatePicker, Input, Upload, List, notification, Checkbox } from 'antd';
import { AppstoreOutlined, BarsOutlined, DownOutlined, EditOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { url } from '../../url/url';
import axios from 'axios';
import moment from 'moment';
const { RangePicker } = DatePicker;

const { Option } = Select;
const Survey = () => {
    const [setShowMenuItems] = useState(false);
    const [setSelectedType] = useState(null);
    const [survey, setSurvey] = useState([]);
    const [view, setView] = useState('Folder View');
    const [selectedFilter, setSelectedFilter] = useState('All');
    const [selectedItem, setSelectedItem] = useState(null);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [isEditing, setIsEditing] = useState(false);
    const [size] = useState('default');
    const [editingIndex, setEditingIndex] = useState(null);
    const [currentQuestion, setCurrentQuestion] = useState('');
    const [currentAnswer, setCurrentAnswer] = useState('');
    const [currentType, setCurrentType] = useState('');
    const [uploadedLogo, setUploadedLogo] = useState(null);
    const [showContent, setShowContent] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [newEndDate, setNewEndDate] = useState(null);

    // Function to handle showing the modal
    const showEndDateModal = () => {
        setIsModalVisible(true);
    };

    const handleCheckboxChange = (e) => {
        setShowContent(e.target.checked);
    };
    const handleRemove = () => {
        setUploadedLogo(null);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-CA');
    };
    const handleMenuClick = (e) => {
        setSelectedType(e.key);
        setShowMenuItems(true);
    };

    const handleUpload = ({ file, onSuccess }) => {
        const reader = new FileReader();
        reader.onload = () => {
            setUploadedLogo(reader.result);
            onSuccess("ok");
        };
        reader.readAsDataURL(file);
    };

    useEffect(() => {
        fetchSurvey();
    }, []);

    const fetchSurvey = async () => {
        const dbName = localStorage.getItem("dbName");
        try {
            const response = await axios.get(`${url}/get/surveys/${dbName}`);
            setSurvey(response.data);
        } catch {
            console.log("Error");
        }
    };

    const handleFilterChange = (value) => {
        setSelectedFilter(value);
    };

    const handleClick = () => {
        navigate("/home/surveyhome");
    };

    const handleCardClick = (item) => {
        setSelectedItem(item);
        setDrawerVisible(true);
    };

    const handleTableRowClick = (record) => {
        setSelectedItem(record);
        setDrawerVisible(true);
    };

    const handleCloseDrawer = () => {
        setDrawerVisible(false);
        setSelectedItem(null);
        setIsEditing(false);
    };

    const handleEditClick1 = (item, index) => {
        setEditingIndex(index);
        setCurrentQuestion(item.question);
        setCurrentAnswer(item.answer);
        setCurrentType(item.type);
    };

    const handleEditClick = (item, index) => {
        setIsEditing(true);

        form.setFieldsValue({
            name: selectedItem.introduction.name,

            period: [
                moment(selectedItem.introduction.period[0]),
                moment(selectedItem.introduction.period[1])
            ],

        });

    };
    const calculateDaysLeft = (period) => {
        if (!period || period.length < 2) {
            return 'No period data';
        }

        const endDate = new Date(period[1]);
        const currentDate = new Date();
        const timeDiff = endDate - currentDate;
        const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
        return daysLeft >= 0 ? `${daysLeft} days left` : 'Expired';
    };

    const getFilteredSurveys = () => {
        if (selectedFilter === 'All') {
            return survey;
        }
        return survey.filter(item => item.status === selectedFilter);
    };

    const handlePublish = async () => {
        const dbName = localStorage.getItem("dbName");
        try {
          const imagePath = uploadedLogo || selectedItem.introduction.surveyImage;
      
          const updatedQuestions = selectedItem.questions.map((question) => ({
            ...question,
          }));
      
          // Prepare the updated data and set status to "Ongoing"
          const updatedData = {
            introduction: {
              ...selectedItem.introduction,
              name: selectedItem.introduction.name,
              desc: selectedItem.introduction.desc,
              surveyImage: imagePath || selectedItem.introduction.surveyImage,
              period: selectedItem.introduction.period,
            },
            questions: updatedQuestions,
            notification: selectedItem.notification,
            status: "Ongoing", // Update status to "Ongoing" when publishing
          };
      
          // API call to publish the survey (set status to "Ongoing")
          const response = await axios.put(`${url}/update/surveys/${selectedItem._id}/${dbName}`, updatedData);
      
          if (response.status === 200) {
            message.success('Survey published successfully!');
            setDrawerVisible(false); // Close the drawer after success
            setIsEditing(false); // Exit edit mode
          } else {
            message.error('Failed to publish survey');
          }
        } catch (error) {
          console.error('Failed to publish survey', error);
          message.error('Failed to publish survey');
        }
      };
      


    const handleFormSubmit = async (values) => {
        const dbName = localStorage.getItem("dbName");
        try {
            // Extract only the file name or URL for surveyImage
            const imagePath = uploadedLogo || selectedItem.introduction.surveyImage; // Adjust based on how you manage image storage
            const updatedQuestions = selectedItem.questions.map((question, index) => {
                const updatedQuestion = values[`question_${index}`] || question.question;
                const updatedAnswer = values[`answer_${index}`] || question.answer;
                const updatedType = values[`type_${index}`] || question.type;

                console.log(`Updated Question ${index}:`, updatedQuestion, updatedAnswer, updatedType);

                return {
                    ...question,
                    question: updatedQuestion,
                    answer: updatedAnswer,
                    type: updatedType,
                };
            });

            // Prepare the updated data
            const updatedData = {
                introduction: {
                    ...selectedItem.introduction,
                    name: values.name || selectedItem.introduction.name,
                    desc: values.desc || selectedItem.introduction.desc,
                    surveyImage: imagePath || selectedItem.introduction.surveyImage,
                    period: values.period
                        ? [values.period[0].format('YYYY-MM-DD'), values.period[1].format('YYYY-MM-DD')]
                        : selectedItem.introduction.period,
                },
                questions: updatedQuestions,
                notification: showContent
            };
            console.log('Updated Data:', updatedData);

            // Make an API call using the PUT method
            const response = await axios.put(`${url}/update/surveys/${selectedItem._id}/${dbName}`, updatedData);

            if (response.status === 200) {
                message.success('Survey details updated successfully!');
                setDrawerVisible(false); // Close the drawer after success
                setIsEditing(false); // Exit edit mode after successful submission
            } else {
                message.error('Failed to update survey details');
            }
        } catch (error) {
            console.error('Failed to update survey details', error);
            message.error('Failed to update survey details');
        }
    };

    const handleEndDateSubmit = async () => {
        const dbName = localStorage.getItem("dbName");

        if (!newEndDate) {
            message.error('Please select a new end date');
            return;
        }

        try {
            // Send the updated end date as part of the introduction object
            const updatedData = {
                introduction: {
                    period: [selectedItem.introduction.period[0], newEndDate] // Keep the start date, only update the end date
                }
            };

            // Make an API call using the PUT method
            const response = await axios.put(`${url}/update/surveys/${selectedItem._id}/${dbName}`, updatedData);

            if (response.status === 200) {
                message.success('End date updated successfully!');
                setIsModalVisible(false); // Close the modal after success
            } else {
                message.error('Failed to update the end date');
            }
        } catch (error) {
            console.error('Failed to update the end date', error);
            message.error('Failed to update the end date');
        }
    };



    const columns = [
        {
            title: 'Survey Name',
            dataIndex: 'surveyName',
            key: 'surveyName',
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        src={record.introduction.surveyImage}
                        style={{ height: '6vh', width: '6vh', marginRight: '10px', borderRadius: "20%" }}
                        alt="survey"
                    />
                    <span>{record.introduction.name}</span>
                </div>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                let color;
                switch (record.status) {
                    case 'Ongoing':
                        color = '#f7984a'; // Orange color
                        break;
                    case 'Completed':
                        color = 'green'; // Green color
                        break;
                    case 'Draft':
                        color = 'black'; // Black color
                        break;
                    default:
                        color = 'gray'; // Default color for unknown status
                        break;
                }
                return <span style={{ color }}>{record.status || 'Active'}</span>;
            },
        },
        {
            title: 'Response',
            dataIndex: 'response',
            key: 'response',
            render: (text, record) => <span>0%</span>,
        },
        {
            title: 'Days Left',
            dataIndex: 'period',
            key: 'daysLeft',
            render: (text, record) => (
                <span style={{ color: "#f76a8b" }}>{calculateDaysLeft(record.introduction.period)}</span>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'id',
            render: (text, record) => (
                <Button type="link" onClick={() => handleTableRowClick(record)}>View Details</Button>
            ),
        },
    ];

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="input">Add Using Existing Template</Menu.Item>
        </Menu>
    );

    const filteredSurveys = getFilteredSurveys(); // Get filtered surveys

    return (
        <div>
            <Row gutter={[16, 16]} justify="space-between" style={{ marginBottom: '20px' }}>
                <Col>
                    <Select
                        style={{ width: "40vh", marginTop: "3vh" }}
                        placeholder="Select Filter"
                        value={selectedFilter}
                        onChange={handleFilterChange}
                    >
                        <Select.Option value="All">All</Select.Option>
                        <Select.Option value="Ongoing">Ongoing</Select.Option>
                        <Select.Option value="Draft">Draft</Select.Option>
                        <Select.Option value="Completed">Completed</Select.Option>
                    </Select>
                </Col>

                <Col>
                    <Dropdown overlay={menu} trigger={['click']}>
                        <Button type="primary" onClick={handleClick}>
                            Add Engagement Survey <DownOutlined />
                        </Button>
                    </Dropdown>
                </Col>
                <Col>
                    <Segmented
                        options={[
                            {
                                label: <AppstoreOutlined />,
                                value: 'Folder View'
                            },
                            {
                                label: <BarsOutlined />,
                                value: 'List View'
                            }
                        ]}
                        value={view}
                        onChange={(value) => setView(value)}
                    />
                </Col>
            </Row>

            {/* Conditionally render views */}
            {view === 'Folder View' ? (
                <Row gutter={[16, 16]}>
                    {filteredSurveys.length > 0 ? (
                        filteredSurveys.map((item, index) => (
                            <Col key={index} span={6}>
                                <Card
                                    cover={
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <img
                                                src={item.introduction.surveyImage}
                                                style={{ height: '20vh', width: '40vh', marginTop: '2vh', borderRadius: "10%" }}
                                                alt="survey"
                                            />
                                        </div>
                                    }
                                    onClick={() => handleCardClick(item)} // Handle card click
                                    hoverable
                                >
                                    <h6>{item.introduction.name}</h6>
                                    <p style={{ color: "#666" }}>
                                        {item.questions.length} Questions - 0%
                                    </p>
                                    {item.status === "Ongoing" ? (
                                        <p style={{ color: "#cd5aed", fontWeight: "bold" }}>
                                            {/* <img style={{ width: "4vh", height: "4vh" }} src={require("../../images/daysleft.png")} /> */}
                                            {calculateDaysLeft(item.introduction.period)}
                                        </p>
                                    ) : (
                                        <p style={{ background: "#8b8d8f", width: "8vh" }}>{item.status}</p>
                                    )}
                                </Card>
                            </Col>
                        ))
                    ) : (
                        <Col span={24} style={{ textAlign: 'center', marginTop: "10vh" }}>
                            {/* <img
                                src={require("../../images/No_data_found-removebg-preview.png")} // Path to your "No data" image
                                alt="No data"
                                style={{ width: '40vh', height: '40vh' }}
                            /> */}
                        </Col>
                    )}
                </Row>
            ) : (
                <Table
                    dataSource={filteredSurveys}
                    columns={columns}
                    rowKey={(record) => record.introduction.name}
                    pagination={{ pageSize: 5 }}
                    // locale={{ emptyText: <img src={require("")} alt="No data" style={{ width: '20%', height: 'auto' }} /> }} // Display empty image for table
                    // ../../images/No_data_found-removebg-preview.png
                />
            )}

            <Drawer
                style={{ background: "#e6eaf0" }}
                title={selectedItem?.introduction?.name || 'Details'}
                visible={drawerVisible}
                onClose={handleCloseDrawer}
                placement="top"
                height="100vh"
            >
                {selectedItem ? (
                    <>
                        <Card title={<h5>Survey Details</h5>}>
                            {/* View Mode */}
                            {!isEditing ? (
                                <>
                                    <span style={{ fontSize: "15px" }}>{selectedItem.introduction.name}</span>
                                    <br />
                                    <Row justify="space-between">
                                        <p><strong>Questions:</strong> {selectedItem.questions.length}</p>
                                        <p><strong>Status:</strong> {selectedItem.status}</p> {/* Display status */}
                                        <p><strong>Start Date:</strong> {formatDate(selectedItem.introduction.period[0])}</p>
                                        <p><strong>End Date:</strong> {formatDate(selectedItem.introduction.period[1])} {selectedItem.status === "Ongoing" && (
                                            <EditOutlined onClick={showEndDateModal} style={{ marginLeft: '10px', cursor: 'pointer' }} />
                                        )}
                                            {isModalVisible &&
                                                <>
                                                    <DatePicker
                                                        value={newEndDate ? moment(newEndDate) : null}
                                                        onChange={(date) => setNewEndDate(date ? date.toDate() : null)}
                                                        style={{ width: '100%' }}
                                                    />
                                                    <Button icon={<SaveOutlined />} type='primary' onClick={handleEndDateSubmit} />
                                                </>
                                            }
                                        </p>
                                        <p><strong>Days Left:</strong> {calculateDaysLeft(selectedItem.introduction.period)}</p>
                                        <p><strong>Repeats:</strong> {selectedItem.introduction.surveyRepeats}</p>
                                    </Row>
                                    <br />
                                    <Row style={{ background: "#f7e7d7" }}>
                                        <p>Employee identity preferences: {selectedItem.introduction.empIdentity}</p>
                                        <p>Email: {selectedItem.notification ? 'Enabled' : 'Disabled'}</p>
                                    </Row>
                                    <br />
                                    <h6>Applicable To</h6>
                                    <p style={{ background: "#aaadb3", width: "25vh" }}>{selectedItem.applicability[0]}</p>
                                    <h6>Questions</h6>
                                    {selectedItem.questions.map((questionItem, index) => (
                                        <div key={index} style={{ marginBottom: '2vh' }}>
                                            <span>{index + 1}. {questionItem.question} ?</span>
                                            <div style={{ marginLeft: '2vh' }}>
                                                {questionItem.type === 'scale' && (
                                                    <Slider value={questionItem.answer} min={0} max={10} />
                                                )}
                                                {questionItem.type === 'rating' && (
                                                    <div>
                                                        {Array.from({ length: parseInt(questionItem.answer) || 0 }, (_, idx) => (
                                                            <span key={idx} style={{ color: 'gold', fontSize: '20px' }}></span>
                                                        ))}
                                                    </div>
                                                )}
                                                {questionItem.type === 'choice' && (
                                                    questionItem.answerOptions.map((option, i) => (
                                                        <p key={i}>
                                                            <Radio>{option}</Radio>
                                                        </p>
                                                    ))
                                                )}
                                            </div>
                                         
                                        </div>
                                    ))}
                                    <Row justify="space-around">
                                    {/* Show the Edit button only if the status is 'Draft' */}
                                    {selectedItem.status === 'Draft' && (
                                        <Button type="primary" onClick={handleEditClick} style={{ marginTop: '20px' }}>
                                            Edit <EditOutlined />
                                        </Button>
                                    )}
                                       <Form.Item>
                                                <Button type="primary" danger onClick={handlePublish}>Publish</Button>
                                            </Form.Item>
                                            </Row>
                                </>
                            ) : (
                                <>
                                    {/* Check the status before allowing form editing */}
                                    {selectedItem.status === 'Draft' ? (
                                        <Form
                                            form={form}
                                            layout="vertical"
                                            onFinish={handleFormSubmit}
                                            initialValues={{
                                                name: selectedItem.introduction.name,
                                                period: [
                                                    moment(selectedItem.introduction.period[0]),
                                                    moment(selectedItem.introduction.period[1]),
                                                ],
                                                desc: selectedItem.introduction.desc,
                                                empIdentity: selectedItem.introduction.empIdentity,
                                                surveyImage: selectedItem.introduction.surveyImage,
                                                notification: selectedItem.notification,
                                            }}
                                        >
                                            {/* Form items for editing the survey */}
                                            <Form.Item
                                                name="name"
                                                label="Survey Name"
                                                rules={[{ required: true, message: 'Please enter a survey name' }]}
                                            >
                                                <Input style={{ fontSize: "14px" }} />
                                            </Form.Item>

                                            <Form.Item
                                                name="desc"
                                                label="Description"
                                                rules={[{ required: true, message: 'Please Input a Description' }]}
                                            >
                                                <Input.TextArea />
                                            </Form.Item>

                                            <Form.Item name="empIdentity" label="Employee identity preferences">
                                                <Select>
                                                    <Option value="Maintain anonymity">Maintain anonymity</Option>
                                                    <Option value="identity">Allow employees to choose to reveal their identity</Option>
                                                    <Option value="Mandate identity disclosure">Mandate identity disclosure</Option>
                                                </Select>
                                            </Form.Item>

                                            <Form.Item label="Survey Image" name="surveyImage">
                                                <Upload
                                                    customRequest={({ file, onSuccess, onError }) =>
                                                        handleUpload({ file, onSuccess, onError, type: "logo" })
                                                    }
                                                    maxCount={1}
                                                    onRemove={() => handleRemove("logo")}
                                                >
                                                    <Button icon={<UploadOutlined />}>Upload Image</Button>
                                                </Upload>
                                                <br />
                                                {selectedItem.introduction.surveyImage && (
                                                    <img
                                                        src={selectedItem.introduction.surveyImage}
                                                        style={{ width: "15vh", height: "15vh", marginLeft: "5vh" }}
                                                    />
                                                )}
                                            </Form.Item>

                                            <Form.Item
                                                name="period"
                                                label="Period"
                                                rules={[{ required: true, message: 'Please Input a Period' }]}
                                            >
                                                <RangePicker size={size} />
                                            </Form.Item>

                                            {/* Questions list */}
                                            <List>
                                                {selectedItem.questions.map((item, index) => (
                                                    <div key={index}>
                                                        {editingIndex === index ? (
                                                            <List.Item>
                                                                <Input
                                                                    value={currentQuestion}
                                                                    onChange={(e) => setCurrentQuestion(e.target.value)}
                                                                    placeholder="Edit Question"
                                                                    name={`question_${index}`}
                                                                />
                                                                <Input
                                                                    value={currentAnswer}
                                                                    onChange={(e) => setCurrentAnswer(e.target.value)}
                                                                    placeholder="Edit Answer"
                                                                    name={`answer_${index}`}
                                                                />
                                                                <Input
                                                                    value={currentType}
                                                                    onChange={(e) => setCurrentType(e.target.value)}
                                                                    placeholder="Edit Type"
                                                                    name={`type_${index}`}
                                                                />
                                                                <Button
                                                                    onClick={() =>
                                                                        handleFormSubmit({
                                                                            [`question_${index}`]: currentQuestion,
                                                                            [`answer_${index}`]: currentAnswer,
                                                                            [`type_${index}`]: currentType
                                                                        })
                                                                    }
                                                                    type="primary"
                                                                    style={{ marginTop: '10px' }}
                                                                >
                                                                    Save
                                                                </Button>
                                                            </List.Item>
                                                        ) : (
                                                            <List.Item>
                                                                {index + 1}. {item.question}?
                                                                <EditOutlined
                                                                    style={{ marginLeft: '5vh' }}
                                                                    onClick={() => handleEditClick1(item, index)}
                                                                />
                                                                <p>Answer: {item.answer}</p>
                                                                <p>Type: {item.type}</p>
                                                            </List.Item>
                                                        )}
                                                    </div>
                                                ))}
                                            </List>

                                            <Form.Item name="notification" valuePropName="checked" style={{ textAlign: "left" }}>
                                                <Checkbox onChange={handleCheckboxChange}>Enable email notification</Checkbox>
                                            </Form.Item>

                                            <Form.Item>
                                                <Button type="primary" htmlType="submit">
                                                    Save Changes
                                                </Button>
                                            </Form.Item>
                                            <Form.Item>
                                                <Button type="primary" danger onClick={handlePublish}>Publish</Button>
                                            </Form.Item>
                                        </Form>
                                    ) : (
                                        // If the survey is "Ongoing", prevent editing and show a message
                                        <p>Survey is ongoing and cannot be edited.</p>
                                    )}
                                </>
                            )}

                        </Card>
                    </>
                ) : (
                    <p>No details available</p>
                )}
            </Drawer>


        </div>
    );
};

export default Survey;
