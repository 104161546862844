import React, { useState } from "react";
import {
  Button,
  Row,
  Col,
  Card,
  Divider,
  Modal,
  Form,
  Input,
  notification,
  message,
  Upload,
  Select,
} from "antd";
import "./Job.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import mammoth from "mammoth";
import {
  SaveFilled,
  SendOutlined,
  UploadOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { url } from "../../url/url";
import { Header } from "antd/es/layout/layout";
const { Dragger } = Upload;
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};
const Descriptions = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible1, setModalVisible1] = useState(false);
  const [modalVisible2, setModalVisible2] = useState(false);
  const users = JSON.parse(localStorage.getItem("user"));
  const [selectedFile, setSelectedFile] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const jobs = location.state && location.state.jobs;
  const [self, setSelf] = useState([]);
  const [ref, setRef] = useState([]);
  const [image, setImage] = useState();
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [form] = Form.useForm();
  const [record, setRecord] = useState("");
  const [fileName, setFileName] = useState("");
  const [skills, setSkills] = useState("");
  const [mobile, setMobile] = useState("");
  const [isImage, setIsImage] = useState(false);
  const [isPDF, setIsPDF] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    skills: "",
    yearsOfExp: "",
  });

  let email = "";

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleShare = async (values) => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.post(
        `${url}/api/share/${jobs.role}/${jobs.description}/${dbName}`,
        { email: values.email.toLowerCase() }
      );
      console.log("Response:", jobs); // Log the entire response object
      email = response.data.email;
      setRecord(email);

      // const shareUrl = `https://myapp.finaran.com:3000/des/${email}/${jobs.role}/${jobs.description}/${dbName}`;

      // await navigator.clipboard.writeText(shareUrl);
      message.success("Link has been shared successfully to your email");
      setModalVisible2(false);
    } catch (error) {
      console.error("Error:", error);
      notification.error({ message: "Error copying link" });
    }
  };

  const normFile = (e) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleImageChange = (e, field) => {
    const file = e.target.files[0];
    setFileName(file.name);
    if (file) {
      const allowedFileTypes = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "application/pdf",
      ];

      // Check if the selected file type is in the allowed list
      if (allowedFileTypes.includes(file.type)) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const image = reader.result;
          setImage(image);
          const isAnyImageUploaded = !!image;
          setIsImageUploaded(isAnyImageUploaded);
        };
        reader.readAsDataURL(file);
      } else {
        // Clear the file error message if no file is selected
        form.setFieldsValue({
          profileImage: null,
        });
      }
    }
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const day = d
      .getDate()
      .toString()
      .padStart(2, "0");
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleSubmitSelf = async (values) => {
    console.log(values, "val");
    const dbName = localStorage.getItem("dbName");
    const currentDate = new Date(); // Get current date
    values.date = formatDate(currentDate);
    values.role = jobs.role;
    values.jobId = jobs.jobId;
    values.resume = image;
    values.displayName = users.displayName;
    values.name = users.displayName;
    values.mobile = formData.phone || mobile;
    values.skills = formData.skills || skills;
    values.email = users.personalemail;
    values.status = "Applied";
    values.yearOfExp = users.yearsofexp;
    try {
      const response = await axios.post(
        `${url}/post/Selfreferrals/${dbName}`,
        values
      );
      setSelf(response.data);
      notification.success({ message: "Successfully Posted" });
      setModalVisible(false);
      setModalVisible1(false);
      form.resetFields();
      console.log("Data posted successfully:", response.data);
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  const handleSubmitRef = async (values) => {
    console.log(values, "val");
    const currentDate = new Date(); // Get current date
    values.date = formatDate(currentDate);
    values.role = jobs.role;
    values.jobId = jobs.jobId;
    values.resume = image;
    values.mobile = formData.phone || users.phoneNumber;
    values.skills = formData.skills;
    values.displayName = users.displayName;
    values.status = "Pending with Candidates";
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await axios.post(
        `${url}/post/Selfreferrals/${dbName}`,
        values
      );
      setRef(response.data);
      notification.success({ message: "Successfully Posted" });
      setModalVisible(false);
      setModalVisible1(false);
      console.log("Data posted successfully:", response.data);
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };
  // Function to handle file selection
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    // You can perform additional actions here, such as displaying the selected file name
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // Function to handle file upload
  const handleFileUpload = async (info) => {
    const file = info.file.originFileObj || info.file; 
    console.log("inside", file);

    if (file) {
      console.log("File found", file); // Debugging
      const reader = new FileReader();
      reader.onload = async (e) => {
        const arrayBuffer = e.target.result;
        const result = await mammoth.extractRawText({ arrayBuffer });
        const text = result.value;

        console.log(text); // For debugging

        const parsedData = parseResume(text);
        setFormData(parsedData);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const parseResume = (text) => {
    const lines = text
      .split("\n")
      .map((line) => line.trim())
      .filter((line) => line);

    // Common job title indicators
    const jobTitleIndicators = [
      "engineer",
      "developer",
      "manager",
      "consultant",
      "specialist",
      "coordinator",
      "analyst",
      "administrator",
      "technician",
      "architect",
      "lead",
      "director",
      "executive",
      "officer",
      "representative",
      "curriculum vitae",
      "resume",
      "cv",
      "profile",
      "job title",
      "position",
      "CAREER OBJECTIVE",
    ];
    // Extract name from the top lines
    let name = "";
    for (let i = 0; i < Math.min(5, lines.length); i++) {
      const line = lines[i];
      if (line.match(/^[A-Za-z][A-Za-z\s'-]+[A-Za-z]$/)) {
        // Check if the line contains too many spaces
        if (line.split(" ").length <= 3 && !line.match(/(\s[A-Z]\s)+/)) {
          // Check if the line does not contain job title indicators
          const containsJobTitle = jobTitleIndicators.some((indicator) =>
            line.toLowerCase().includes(indicator)
          );
          if (!containsJobTitle) {
            name = line;
            break;
          }
        }
      }
    }

    // Extract email using regular expression
    const emailMatch = text.match(/\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i);
    const email = emailMatch ? emailMatch[0].replace(/\s/g, "") : "";

    // Extract phone number using regular expression
    const phoneMatch = text.match(/\b\d{10}\b|\b\d{3}[-.]?\d{3}[-.]?\d{4}\b/);
    const phone = phoneMatch ? phoneMatch[0] : "";

    // Extract education details
    const educationSection = extractSection(text, "EDUCATION", [
      "WORK EXPERIENCE",
      "SKILLS",
    ]);
    const education = educationSection.join(" ");

    // Extract skills
    const skillsSection = extractSection(text, "SKILLS", [
      "WORK EXPERIENCE",
      "EDUCATION",
      "PROJECT",
    ]);
    const skills = skillsSection.join(" ");
    console.log(skills, "skills");
    // Extract projects
    const projectsSection = extractSection(text, "PROJECT", [
      "WORK EXPERIENCE",
      "SKILLS",
    ]);
    const projects = projectsSection.join(" ");
    const yearsOfExperience = extractSection(text, "Experience", [
      "WORK EXPERIENCE",
      "Years of experience",
    ]);
    const yearsOfExp = yearsOfExperience.join(" ");
    return {
      name: name || "",
      email: email || "",
      phone: phone || "",
      yearsOfExp: yearsOfExp || "",
      // education: education || "",
      skills: skills || "",
      // projects: projects || "",
      // Parse and set other fields as necessary
    };
  };

  const extractSection = (text, startKeyword, endKeywords) => {
    const lines = text.split("\n");
    let startIndex = -1;
    let endIndex = lines.length;

    for (let i = 0; i < lines.length; i++) {
      if (lines[i].toUpperCase().includes(startKeyword.toUpperCase())) {
        startIndex = i + 1;
        break;
      }
    }

    if (startIndex === -1) {
      return [];
    }

    for (let i = startIndex; i < lines.length; i++) {
      for (const endKeyword of endKeywords) {
        if (lines[i].toUpperCase().includes(endKeyword.toUpperCase())) {
          endIndex = i;
          break;
        }
      }
      if (endIndex !== lines.length) {
        break;
      }
    }

    return lines
      .slice(startIndex, endIndex)
      .map((line) => line.trim())
      .filter((line) => line);
  };
  const handleInterestedClick = () => {
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setFileName("");
  };

  const handleInterestedClick1 = () => {
    setModalVisible1(true);
  };

  const handleModalClose1 = () => {
    setModalVisible1(false);
    setFileName("");
  };

  const handleModalClose2 = () => {
    setModalVisible2(false);
  };
  const handleClick = () => {
    setModalVisible2(true);
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Select.Option value="86">+86</Select.Option>
        <Select.Option value="87">+87</Select.Option>
        <Select.Option value="91">+91</Select.Option>
      </Select>
    </Form.Item>
  );

  // const Copy = () => {
  //     navigator.clipboard.writeText(`https://myapp.finaran.com:3000/des/${record}/${jobs.role}/${jobs.description}`, { state: { jobs } });
  //     notification.success({ message: "Link Copied" });
  //     setModalVisible2(false);
  //     return (
  //         <Copy jobs={[jobs.role, jobs.description]} />
  //     )
  // }
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <h5 className="text-center">Finaran | Full time</h5>
      <Button
        style={{
          display: "flex",
          justifyContent: "flex-start",
          color: "#EA5851",
        }}
        onClick={goBack}
      >
        Back
      </Button>
      <h2>{jobs?.role}</h2>
      <Row justify="center">
        <Button
          type="text"
          style={{ background: "#EA5851", color: "white", fontWeight: "bold" }}
          onClick={handleInterestedClick}
        >
          I'm Interested
        </Button>
        <Button
          style={{ left: "3vh" }}
          type="primary"
          onClick={handleInterestedClick1}
        >
          Refer a Friend
        </Button>
        <img
          src={require("../../images/share.png")}
          onClick={handleClick}
          style={{ width: "5vh", marginLeft: "5vh", height: "5vh" }}
        />
      </Row>
      <br />
      <Card>
        <Row>
          <Col span={15}>
            <div className="job-description">
              <h5 style={{ color: "#EA5851" }}>Job Description</h5>
              <p style={{ color: "#8C8C86" }}>{jobs.description}</p>
            </div>
          </Col>
          <Divider type="vertical" style={{ height: "100%" }} />
          <Col span={6}>
            <h5 style={{ color: "#EA5851" }}>Skills Required</h5>
            <h6 style={{ color: "#8C8C86" }}>{jobs.skills}</h6>
          </Col>
        </Row>
      </Card>
      <Modal
        title="Self Details"
        visible={modalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        <Col span={12}>
          <Form.Item
            rules={[{ required: true }]}
            form={form}
            {...layout}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Upload
              name="file"
              onChange={handleFileUpload}
              accept=".doc,.docx,.pdf"
              multiple={false}
              beforeUpload={() => {
                return false; // Prevent automatic upload
              }}
            >
              <Button> Upload Resume to autofill</Button>
            </Upload>
          </Form.Item>
        </Col>
        <Form {...layout} form={form} onFinish={handleSubmitSelf}>
          <Form.Item label="Name" hasFeedback>
            <Input value={users.displayName} readOnly />
          </Form.Item>
          <Form.Item label="Email" hasFeedback>
            <Input value={users.personalemail} readOnly />
          </Form.Item>
          <Form.Item label="Mobile" hasFeedback>
            <Input value={users.phoneNumber} readOnly />
          </Form.Item>
          <Form.Item label="Years of exp" hasFeedback>
            <Input value={users.yearsofexp} readOnly />
          </Form.Item>
          <Form.Item
            label="Skills"
            hasFeedback
            name="skills"
            // values={formData.skills}
          >
            <Input
              value={skills || formData.skills}
              onChange={(e) => {
                setSkills(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            label="State few reasons how you fit this job?"
            name="reason"
            labelCol={{ width: "2vh" }}
            hasFeedback
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item label="Upload Resume" name="resume">
            <label className="custom-file-upload">
              <UploadOutlined />
              Upload Resume
              <Input
                type="file"
                onChange={(e) => handleImageChange(e, "resume")}
                style={{ display: "none" }} // Hide the input element
              />
            </label>
            {fileName && (
              <div>
                <p>{fileName}</p>
              </div>
            )}
            {console.log(image, "images")}
          </Form.Item>
          <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="primary"
              style={{ background: "#EA5851" }}
              htmlType="submit"
              icon={<SaveFilled />}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Referral Details"
        visible={modalVisible1}
        onCancel={handleModalClose1}
        footer={null}
      >
        <Col span={12}>
          <Form.Item
            rules={[{ required: true }]}
            form={form}
            {...layout}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Upload
              name="file"
              onChange={handleFileUpload}
              accept=".doc,.docx,.pdf"
              multiple={false}
              beforeUpload={() => {
                return false; // Prevent automatic upload
              }}
            >
              <Button> Upload Resume to autofill</Button>
            </Upload>
          </Form.Item>
        </Col>
        <Form {...layout} form={form} onFinish={handleSubmitRef}>
          <Form.Item label="Name" hasFeedback>
            <Input
              placeholder="Enter Name"
              name="name"
              value={formData.name}
              onChange={(e) => {
                handleInputChange(e);
              }}
            />
          </Form.Item>
          <Form.Item label="Email" hasFeedback>
            <Input
              placeholder="Enter Email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </Form.Item>
          <Form.Item label="Mobile" hasFeedback>
            <Input
              placeholder="Enter Mobile"
              name="mobile"
              value={formData.phone}
              onChange={handleInputChange}
            />
          </Form.Item>
          <Form.Item label="Years of exp" hasFeedback>
            <Input
              name="yearsOfExp"
              value={formData.yearsOfExp}
              onChange={handleInputChange}
            />
          </Form.Item>
          <Form.Item label="Skills" hasFeedback>
            <Input
              name="skills"
              value={formData.skills}
              onChange={handleInputChange}
            />
          </Form.Item>

          <Form.Item
            label="State few reasons how you fit in this job?"
            name="reason"
            labelCol={{ width: "2vh" }}
            hasFeedback
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item label="Upload Resume" name="resume">
            <label className="custom-file-upload">
              <UploadOutlined />
              Upload Resume
              <Input
                type="file"
                onChange={(e) => handleImageChange(e, "resume")}
                style={{ display: "none" }} // Hide the input element
              />
            </label>
            {fileName && (
              <div>
                <p>{fileName}</p>
              </div>
            )}
          </Form.Item>
          <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="primary"
              style={{ background: "#EA5851" }}
              htmlType="submit"
              icon={<SaveFilled />}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Friends Details"
        visible={modalVisible2}
        onCancel={handleModalClose2}
        footer={null}
      >
        <Form {...layout} onFinish={handleShare}>
          <Form.Item
            label="Email"
            name="email"
            hasFeedback
            rules={[{ required: true, message: "Enter Your Email" }]}
          >
            <Input placeholder="Enter your Email" />
          </Form.Item>

          <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="primary"
              style={{ background: "#EA5851" }}
              htmlType="submit"
              icon={<SendOutlined />}
            >
              Send
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {/* <Modal
                title='Share'
                visible={modalVisible2}
                onCancel={handleClose}
                footer={null}
            >
                <Row style={{ justifyContent: "space-between" }}>
                    <Col span={18}>
                        <Input value={`https://myapp.finaran.com:3000/des/${record}/${jobs}`} />
                    </Col>
                    <Col span={5}>
                        <Button type='primary' style={{ borderRadius: "15%" }} onClick={Copy}>Copy</Button>
                    </Col>
                </Row>
            </Modal> */}
    </div>
  );
};

export default Descriptions;
