import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Steps,
  Layout,
  Menu,
  Col,
  Row,
  Drawer,
  Select,
  Checkbox,
  DatePicker,
  Radio,
  Slider,
  message,
} from "antd";
import Image from "../../images/No data.png";
import {
  CalendarOutlined,
  CiOutlined,
  CommentOutlined,
  DeleteOutlined,
  FieldNumberOutlined,
  FileAddFilled,
  StarOutlined,
  ToTopOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
const { Option } = Select;
const styles = {
  content: {
    textAlign: "center",
  },
  image: {
    width: "100px",
    height: "auto",
    margin: "20px 0",
  },
};

const Question = ({ onNext, allValues, setAllValues, onPrevious }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [feedbackText, setFeedbackText] = useState(
    "We thank you immensely for your valuable feedback!"
  );
  const [newFeedbackText, setNewFeedbackText] = useState(feedbackText);
  const [visible, setVisible] = useState(false);
  const [enableComments, setEnableComments] = useState(false);
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [showRangePicker, setShowRangePicker] = useState(false);
  const [selectedScore, setSelectedScore] = useState(null);
  const [choices, setChoices] = useState(["", ""]);
  const [inputValue, setInputValue] = useState("");
  const [selectedStar, setSelectedStar] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [formvalues, setFormvalues] = useState([]);
  const [activeCard1, setActiveCard1] = useState("choice");
  const [introText, setIntroText] = useState([
    "Dear employee,",
    "This survey is aimed at capturing your honest feedback on your engagement levels in this organization.",
    "Your responses mean a lot to us, and will help us improve the employee experience in our organization.",
  ]);
  const [newIntroText, setNewIntroText] = useState([...introText]);
  const navigate = useNavigate();



  useEffect(() => {
    if (allValues.questions) {
      setFormvalues(allValues.questions);
    }
  }, [allValues]);
  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setNewFeedbackText(feedbackText);
    setNewIntroText([...introText]);
    setIsEditing(false);
  };

  const handleSaveClick = () => {
    setFeedbackText(newFeedbackText);
    setIntroText([...newIntroText]);
    setIsEditing(false);
    navigate("/home/surveyhome");
  };
  const handleTextChange = (index, value) => {
    const updatedText = [...newIntroText];
    updatedText[index] = value;
    setNewIntroText(updatedText);
  };
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    form.resetFields();
  };
  const addChoice = () => {
    setChoices([...choices, ""]);
  };
  const handleInput = (index, event) => {
    const newChoices = [...choices];
    newChoices[index] = event.target.value;
    setChoices(newChoices);
  };
  const deleteChoice = (index) => {
    const newChoices = choices.filter((_, i) => i !== index);
    setChoices(newChoices);
  };
  const handleEnableCommentsChange = (e) => {
    setEnableComments(e.target.checked);
  };
  const handleSelectChange1 = (value) => {
    const cardMap = {
      choice: "choice",
      comment: "comment",
      scale: "scale",
      rating: "rating",
    };
    console.log("changing", value);
    setActiveCard1(cardMap[value]); // Update the active card based on the selected value
  };
  const handleCheckboxChange = (e) => {
    setShowRangePicker(e.target.checked);
  };
  const handleScoreClick = (score) => {
    setSelectedScore(score);
  };
  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value <= 10) {
      setInputValue(value);
    }
  };
  const handleSelectChange = (value) => {
    setSelectedValue(value);
  };
  const handleStarChange = (value) => {
    setSelectedStar(value); // Update the state with the selected star value
    console.log("Hello", value);
  };
  const createStarRepresentation = () => {
    const starCount = parseInt(selectedStar.replace("star", "")) || 0;
    const stars = [];

    for (let i = 0; i < starCount; i++) {
      stars.push(
        <span key={i} style={{ color: "gold", fontSize: "20px" }}>
          
        </span>
      );
    }
    return stars;
  };

  const handleNext = () => {
    const updatedAllValues = { ...allValues, questions: formvalues };
    setAllValues(updatedAllValues);
    onNext(updatedAllValues);  // Pass updated values to the next step
  };

  const handlePrevious = () => {
    const updatedAllValues = { ...allValues, questions: formvalues };
    setAllValues(updatedAllValues);
    onPrevious();  // Call the previous step
  };

  const onFinish = (values) => {
    const questionType = activeCard1; // Store the type of the question
    let newQuestion = {
      type: questionType,
      question: values.question, // Assuming 'question' is a field in your form
    };

    // Conditionally add choices if the type is 'choice'
    if (questionType === 'choice') {
      newQuestion = {
        ...newQuestion,
        choices: choices.length ? choices : [], // Ensure choices are sent as an array only if type is 'choice'
      };
    }

    // Manually add the new question to allValues.questions array
    const updatedQuestions = [...(allValues.questions || []), newQuestion];

    // Update allValues state with the new list of questions
    setAllValues((prevValues) => ({
      ...prevValues,
      questions: updatedQuestions, // Store questions directly as an array
    }));

    console.log("New Question Added:", newQuestion);
    setVisible(false); // Close the drawer after adding a question
    form.resetFields();
    message.success("New question added");
  };

  const onFinish1 = () => {
    // Ensure all form data is prepared for submission
    const finalAllValues = allValues.questions || [];

    console.log("Final Form Values to Submit:", finalAllValues);

    // Submit the final values
    onNext(finalAllValues);
    message.success("Form submitted, moving to the next step");
  };











  return (
    <div>
      <Layout style={{ minHeight: "90vh" }}>
        <div style={{ maxHeight: "100vh", overflowY: "auto" }}>
          <Card
            id="introduction-section"
            style={{
              marginTop: "1vh",
              border: "1px solid #959696",
              width: "70%",
              margin: "0 auto",
            }}
          >
            <h4 style={{ textAlign: "left" }}>Introduction</h4>
            {isEditing ? (
              <div>
                {newIntroText.map((text, index) => (
                  <Input.TextArea
                    key={index}
                    value={text}
                    onChange={(e) => handleTextChange(index, e.target.value)}
                    rows={3}
                    style={{ marginBottom: "10px" }}
                  />
                ))}
                <Button
                  type="default"
                  onClick={handleCancelClick}
                  style={{ marginRight: "8px" }}
                >
                  Cancel
                </Button>
                <Button type="primary" onClick={handleSaveClick}>
                  Save
                </Button>
              </div>
            ) : (
              <div>
                <p style={{ textAlign: "left", fontSize: "18px" }}>
                  Dear employee,
                </p>
                <p style={{ textAlign: "left" }}>{introText[1]}</p>
                <p style={{ textAlign: "left" }}>{introText[2]}</p>
                <Button type="link" onClick={handleEditClick}>
                  Edit
                </Button>
              </div>
            )}
          </Card>
          <br />
          <Card
            id="question-section"
            style={{
              marginTop: "1vh",
              border: "1px solid #959696",
              width: "70%",
              margin: "0 auto",
            }}
          >
            <h4 style={{ textAlign: "left" }}>Questions</h4>
            <Card>
              <Row>
                <Col span={7}>
                  <span>Total questions: {formvalues?.length}</span>
                </Col>
                <Col span={13}></Col>
                <Col span={4}>
                  <Button type="primary" htmlType="button" onClick={showDrawer}>
                    Add Questions
                  </Button>
                </Col>
              </Row>
            </Card>
            <Drawer
              style={{ background: "#e6eaf0" }}
              title="Add Questions"
              placement="right"
              onClose={onClose}
              visible={visible}
              width={700}
            >
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Form.Item label="Filter" name="type">
                    <Select
                      defaultValue="choice"
                      style={{ width: "30vh", marginTop: "8px" }}
                      onChange={handleSelectChange1}
                    >
                      <Option value="choice">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CiOutlined style={{ marginRight: "8px" }} />
                          Choice
                        </div>
                      </Option>
                      <Option value="comment">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CommentOutlined style={{ marginRight: "8px" }} />
                          Comment
                        </div>
                      </Option>
                      <Option value="scale">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <FieldNumberOutlined style={{ marginRight: "8px" }} />
                          Scale
                        </div>
                      </Option>
                      <Option value="rating">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <StarOutlined style={{ marginRight: "8px" }} />
                          Rating
                        </div>
                      </Option>
                    </Select>
                  </Form.Item>
                </div>
                <div
                  style={{
                    maxHeight: "calc(110vh - 250px)",
                    overflowY: "auto",
                  }}
                >
                  <div style={{ marginLeft: "20px", flex: 1 }}>
                    {activeCard1 === "choice" && (
                      <Card
                        id={activeCard1}
                        style={{
                          marginTop: "1vh",
                          width: "95%",
                          border: "2px solid #d9d9d9",
                        }}
                      >
                        <Form
                          name="question"
                          onFinish={onFinish}
                          form={form}
                          layout="vertical"
                        >
                          <Form.Item
                            label="Question"
                            name="question"
                            rules={[
                              {
                                required: true,
                                message: "Please input the name!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item name="answer" label="Answer">
                            <Card style={{ marginTop: 8, padding: 16 }}>
                              {choices.map((choice, index) => (
                                <Row
                                  key={index}
                                  gutter={8}
                                  style={{ marginBottom: 8 }}
                                >
                                  <Col flex="auto">
                                    <Input
                                      placeholder="Enter choice"
                                      value={choice}
                                      onChange={(e) => handleInput(index, e)}
                                    />
                                  </Col>
                                  <Col flex="none">
                                    <Button
                                      type="Primary"
                                      onClick={() => deleteChoice(index)}
                                      icon={<DeleteOutlined />}
                                    />
                                  </Col>
                                </Row>
                              ))}
                              <Button onClick={addChoice} type="link">
                                Add Choice
                              </Button>
                            </Card>
                          </Form.Item>
                          <Button type="primary" htmlType="submit">
                            Save
                          </Button>
                        </Form>
                      </Card>
                    )}
                    {activeCard1 === "comment" && (
                      <Card
                        id="comment"
                        style={{
                          marginTop: "1vh",
                          width: "95%",
                          border: "2px solid #d9d9d9",
                        }}
                      >
                        <Form
                          name="question"
                          onFinish={onFinish}
                          form={form}
                          layout="vertical"
                        >
                          <Form.Item
                            label="Question"
                            name="question"
                            rules={[
                              {
                                required: true,
                                message: "Please input the name!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <Card title="Advanced Options">
                            <Form.Item>
                              <Checkbox>Mark as mandatory</Checkbox>
                            </Form.Item>
                          </Card>
                          <Form.Item>
                            <Button type="primary" danger htmlType="submit">
                              Save
                            </Button>
                          </Form.Item>
                        </Form>
                      </Card>
                    )}
                    {activeCard1 === "scale" && (
                      <Card
                        id="nps"
                        style={{
                          marginTop: "1vh",
                          width: "95%",
                          border: "2px solid #d9d9d9",
                        }}
                      >
                        <Form
                          name="question"
                          onFinish={onFinish}
                          form={form}
                          layout="vertical"
                        >
                          <Form.Item
                            label="Question"
                            name="question"
                            rules={[
                              {
                                required: true,
                                message: "Please input the name!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item name="answer" label="Answer score">
                            <Slider min={0} max={10} defaultValue={5} />
                            {/* <Row justify="space-between" style={{ marginTop: 8 }}>
                              <Col>Not at all likely</Col>
                              <Col>Extremely likely</Col>
                            </Row> */}
                          </Form.Item>

                          <Card>
                            <Form.Item label="Advanced Options" name="options">
                              <Checkbox onChange={handleEnableCommentsChange}>
                                Enable Comments
                              </Checkbox>
                              {enableComments && (
                                <Card style={{ backgroundColor: "#bfbfbf" }}>
                                  <Row gutter={[16, 16]}>
                                    <Col span={10}>
                                      <Checkbox>
                                        Comment is mandatory if the score is
                                      </Checkbox>
                                    </Col>
                                    <Col span={4}>
                                      <Select
                                        style={{ width: 120 }}
                                        value={selectedValue}
                                        onChange={handleSelectChange}
                                      >
                                        <Option value="less than">
                                          less than
                                        </Option>
                                        <Option value="greater than">
                                          greater than
                                        </Option>
                                        <Option value="equal to">
                                          equal to
                                        </Option>
                                      </Select>
                                    </Col>
                                    <Col span={3}></Col>
                                    <Col span={4}>
                                      <p>or equal to</p>
                                    </Col>
                                    <Col span={3}>
                                      <Input
                                        placeholder="0"
                                        value={inputValue}
                                        onChange={handleInputChange}
                                      />
                                    </Col>
                                  </Row>
                                </Card>
                              )}
                              <br />
                              <Checkbox>Mark as mandatory</Checkbox>
                            </Form.Item>
                            <Form.Item>
                              <Button type="primary" danger htmlType="submit">
                                Save
                              </Button>
                            </Form.Item>
                          </Card>
                        </Form>
                      </Card>
                    )}
                    {activeCard1 === "rating" && (
                      <Card
                        id="star"
                        style={{
                          marginTop: "1vh",
                          width: "95%",
                          border: "2px solid #d9d9d9",
                        }}
                      >
                        <Form
                          name="question"
                          onFinish={onFinish}
                          form={form}
                          layout="vertical"
                        >
                          <Form.Item
                            label="Question"
                            name="question"
                            rules={[
                              {
                                required: true,
                                message: "Please input the name!",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item name="answer" label="Star levels">
                            <Select
                              placeholder="Select Star Level"
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option?.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              onChange={handleStarChange}
                            >
                              <Option value="3 star">3 Star</Option>
                              <Option value="4 Star">4 Star</Option>
                              <Option value="5 Star">5 Star</Option>
                              <Option value="6 Star">6 Star</Option>
                              <Option value="7 Star">7 Star</Option>
                              <Option value="8 Star">8 Star</Option>
                              <Option value="9 Star">9 Star</Option>
                              <Option value="10 Star">10 Star</Option>
                            </Select>
                          </Form.Item>
                          <Form.Item
                            name="representation"
                            label="Representation"
                          >
                            {createStarRepresentation()}
                          </Form.Item>
                          <Card>
                            <Form.Item label="Advanced Options" name="options">
                              <Checkbox onChange={handleEnableCommentsChange}>
                                Enable Comments
                              </Checkbox>
                              {enableComments && (
                                <Card style={{ backgroundColor: "#bfbfbf" }}>
                                  <Row gutter={[16, 16]}>
                                    <Col span={10}>
                                      <Checkbox>
                                        Comment is mandatory if the score is
                                      </Checkbox>
                                    </Col>
                                    <Col span={4}>
                                      <Select
                                        style={{ width: 120 }}
                                        value={selectedValue}
                                        onChange={handleSelectChange}
                                      >
                                        <Option value="less than">
                                          less than
                                        </Option>
                                        <Option value="greater than">
                                          greater than
                                        </Option>
                                        <Option value="equal to">
                                          equal to
                                        </Option>
                                      </Select>
                                    </Col>
                                    <Col span={3}></Col>
                                    <Col span={4}>
                                      <p>or equal to</p>
                                    </Col>
                                    <Col span={3}>
                                      <Input
                                        placeholder="0"
                                        value={inputValue}
                                        onChange={handleInputChange}
                                      />
                                    </Col>
                                  </Row>
                                </Card>
                              )}
                              <br />
                              <Checkbox>Mark as mandatory</Checkbox>
                            </Form.Item>
                            <Form.Item>
                              <Button type="primary" danger htmlType="submit">
                                Save
                              </Button>
                            </Form.Item>
                          </Card>
                        </Form>
                      </Card>
                    )}
                  </div>
                </div>
              </div>

            </Drawer>
            <div style={styles.content}>
              {formvalues.map((question, index) => (
                <Card
                  key={index}
                  style={{ marginTop: "10px", textAlign: "left" }}
                >
                  <p>{`Question ${index + 1}: ${question.question}`}</p>

                  {/* Conditional rendering based on the question type */}
                  {question?.type === "choice" && (
                    <ul>
                      {/* Ensure question.answer is an array before mapping */}
                      {Array.isArray(question?.choices) ? (
                        question.choices.map((choice, idx) => (
                          <li key={idx}>{choice}</li>
                        ))
                      ) : (
                        <li>{question?.answer}</li>
                      )}
                    </ul>
                  )}

                  {question?.type === "comment" && <p>{question?.answer}</p>}

                  {question?.type === "scale" && (
                    <Slider min={0} max={10} value={question?.answer} />
                  )}

                  {question?.type === "rating" && (
                    <div>
                      {/* Extract numeric value from "7 Star" */}
                      {Array.from(
                        { length: parseInt(question?.answer) || 0 },
                        (_, idx) => (
                          <span
                            key={idx}
                            style={{ color: "gold", fontSize: "20px" }}
                          >
                            
                          </span>
                        )
                      )}
                    </div>
                  )}
                </Card>
              ))}
            </div>
          </Card>
          <br />
          <Form onFinish={onFinish1} form={form1}>
            <Card
              id="feedback-section"
              style={{
                marginTop: "1vh",
                border: "1px solid #959696",
                width: "70%",
                margin: "0 auto",
              }}
            >
              <h4 style={{ textAlign: "left" }}>Post feedback message</h4>
              {isEditing ? (
                <div>
                  <Input.TextArea
                    value={newFeedbackText}
                    onChange={(e) => setNewFeedbackText(e.target.value)}
                    rows={4}
                    style={{ marginBottom: "10px" }}
                  />
                  <Button
                    type="default"
                    onClick={handleCancelClick}
                    style={{ marginRight: "8px" }}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" onClick={handleSaveClick}>
                    Save
                  </Button>
                </div>
              ) : (
                <div>
                  <p style={{ textAlign: "left" }}>{feedbackText}</p>
                  <Button type="link" onClick={handleEditClick}>
                    Edit
                  </Button>
                </div>
              )}
            </Card>
            <Form.Item style={{ textAlign: "right", marginTop: "1%" }}>
              <Button onClick={handlePrevious} style={{ marginRight: "10px" }}>
                Previous
              </Button>
              <Button type="primary" htmlType="submit">
                Next
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Layout>
    </div>
  );
};

export default Question;
