import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  Input,
  Avatar,
  Row,
  Col,
  Table,
  Card,
  Button,
  Select,
  Modal,
  Form,
  Tooltip,
  notification,
  message,
  Popconfirm,
  Tabs,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteFilled,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PaperClipOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  SendOutlined,
  EditFilled,
  IssuesCloseOutlined,
  UnorderedListOutlined,
  FolderOpenOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import { url } from "../../url/url";
import axios from "axios";
import AddActivity from "./AddActivity";
const { Option } = Select;
const { TabPane } = Tabs;
const Issueboard = ({ propselectedProject }) => {
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [selectedFeatureName, setSelectedFeatureName] = useState([]);
  const [selectedSprintName, setSelectedSprintName] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [selectedSprint, setSelectedSprint] = useState([]);
  const [base64Image, setBase64Image] = useState(null);
  const [showCard, setShowCard] = useState(false);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [hoveredKey, setHoveredKey] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [newactivity, setNewactivity] = useState("");
  const [priority, setPriority] = useState("activity");
  const [form] = Form.useForm();
  const [description, setDescription] = useState([]);
  const [feature, setFeature] = useState([""]);
  const [Assignee, setAssignee] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedProject, setSelectedProject] = useState(null);
  const users = JSON.parse(localStorage.getItem("user"));
  const [count, setCount] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [editedIssueName, setEditedIssueName] = useState("");
  const [currentIssue, setCurrentIssue] = useState(selectedActivity);
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [issues, setIssues] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [existingSprints, setExistingSprints] = useState([]);
  const [filters, setFilters] = useState({
    featureName: null,
    status: null,
    assignee: null,
    reporter: null,
    priority: null,
    type: null,
  });
  const [showDetails, setShowDetails] = useState("comments");

  const toggleShowDetails = (detailsType) => {
    setShowDetails(detailsType);
  };
  const handleFilterChange = (pagination, filters, sorter) => {
    setFilters(filters);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    setCurrentIssue(selectedActivity);
  }, [selectedActivity, count]);

  useEffect(() => {}, [issues]);

  const fetchIssues = async (project) => {
    const dbName = localStorage.getItem("dbName");

    console.log(project, "selecetdproject");
    try {
      const jobTitle = users.jobTitle;
      const displayName = users.displayName;
      const response = await axios.get(
        `${url}/getByProject/backlog/${dbName}/${project.Projectcode}`,
        {
          params: {
            jobTitle: jobTitle,
            displayName: displayName,
          },
        }
      );
      setIssues(response.data);
    } catch (error) {
      console.error("Error fetching Issues:", error.message);
    }
  };

  const fetchAssigneesByFeatureName = async (featureName) => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await fetch(
        `${url}/feature/${featureName}/featureName/${dbName}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch Feature data");
      }
      const data = await response.json();
      setAssignee(data[0].assignee);
    } catch (error) {
      console.error("Error:", error);
      throw new Error("An error occurred while fetching sprint details.");
    }
  };
  const filteredData = issues.filter((item) => {
    const itemString = JSON.stringify(item).toLowerCase();
    return itemString.includes(searchQuery.toLowerCase());
  });

  const handlePriorityChange = (value) => {
    setCurrentIssue({
      ...currentIssue,
      priority: value,
    });
    selectedActivity({
      ...issues,
      priority: value,
    });
  };

  const handleStatusChange = (value) => {
    setCurrentIssue({
      ...currentIssue,
      status: value,
    });
    selectedActivity({
      ...issues,
      status: value,
    });
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
    setEditedIssueName(selectedActivity.activityName);
  };

  const handleInputChange = (e) => {
    setEditedIssueName(e.target.value);
    console.log(e.target.value, "value");
  };

  useEffect(() => {
    const project = propselectedProject;
    console.log(propselectedProject, "project");
    if (project) {
      setSelectedProject(project);
      fetchIssues(project);
      fetchSprintsByProjectName(project);
    } else {
      console.log("No project found in props or location state.");
    }
  }, [propselectedProject]);

  const fetchSprintsByProjectName = async (selectedproject) => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await fetch(
        `${url}/sprints/${selectedproject.ProjectName}/sprint/${dbName}`
      );
      const sprints = await response.json();
      const sprintDetails = sprints.map((sprint) => ({
        sprintId: sprint.sprintId,
        sprintName: sprint.sprintName,
        startDate: sprint.startDate,
        endDate: sprint.endDate,
      }));
      setExistingSprints(sprintDetails);
      return sprintDetails;
    } catch (error) {
      console.error("Error:", error);
      throw new Error("An error occurred while fetching sprint details.");
    }
  };

  const fetchFeature = async (selectedsprint) => {
    try {
      console.log(selectedsprint, "selectedsprint");
      const dbName = localStorage.getItem("dbName");
      const response = await fetch(
        `${url}/feature/${selectedsprint.sprintId}/sprintid/${dbName}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch Feature data");
      }
      const data = await response.json();
      const filteredData = data.filter((feature) => feature.status !== "done");
      setFeature(filteredData);
    } catch (error) {
      console.error("Error:", error);
      throw new Error("An error occurred while fetching sprint details.");
    }
  };

  const updateIssue = async (values) => {
    const dbName = localStorage.getItem("dbName");
    values.description = selectedActivity.description;
    values.priority = currentIssue.priority;
    values.status = currentIssue.status;
    values.assignee = selectedUsers;
    values.estimatedHours = selectedActivity.estimatedHours;
    values.reporter = selectedActivity.reporter;
    values.updateReporter = users.displayName;

    if (isEditing) {
      values.activityName = editedIssueName;
    } else {
      values.activityName = selectedActivity.activityName;
    }
    try {
      const response = await axios.put(
        `${url}/update/backlog/${dbName}/${selectedActivity.activityId}/update`,
        values
      );
      setIssues(
        issues.map((record) =>
          record.activityId === selectedActivity.activityId
            ? { ...record, ...response.data }
            : record
        )
      );
      notification.success({ message: "Updated successfully!" });
      setIsEditing(false);
      setSelectedActivity(null);
    } catch (error) {
      console.error(error);
      message.error("Failed to update the record.");
    }
  };
  console.log(selectedActivity, "Activity");

  const handleCreateactivityClick = () => {
    setShowInput(true);
  };

  const handleactivityClick = (record) => {
    if (!editingKey) {
      setEditingKey(record.key);
    }
  };
  const handleIssueClick = (record) => {
    setSelectedActivity(record);
    fetchAssigneesByFeatureName(record?.featureName);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64 = reader.result;
      setBase64Image(base64);
    };
    reader.readAsDataURL(file);
  };
  const handleAssignFeature = async () => {
    try {
      console.log("inside assign");
      const dbName = localStorage.getItem("dbName");
      const updatedData = {
        featureId: selectedFeature.featureId,
        featureName: selectedFeature.featureName,
      };
      const response = await axios.put(
        `${url}/update/backlog/${dbName}/${selectedRecord.activityId}/move`,
        updatedData
      );
      setOpen(false);
      fetchIssues(selectedProject);
    } catch (error) {
      console.error("Error Moving record:", error.message);
    }
  };

  const handleselect = (value) => {
    const selectedFeatureItem = feature.find(
      (item) => item.featureName === value
    );
    console.log(selectedFeatureItem, "item");
    setSelectedFeatureName(value);
    setSelectedFeature(selectedFeatureItem);
  };

  const handleselect1 = async (value) => {
    const selectedSprintItem = existingSprints.find(
      (item) => item.sprintName === value
    );
    console.log(selectedSprintItem, "item");
    setSelectedSprintName(value);
    await fetchFeature(selectedSprintItem);

    setSelectedSprint(selectedSprintItem);
  };

  const handleOpen = (record) => {
    setSelectedRecord(record);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.delete(
        `${url}/remove/backlog/${dbName}/${id}`
      );
      setIssues(issues.filter((record) => record._id !== id));
    } catch (error) {
      console.error("Error deleting record:", error.message);
      // Handle error scenarios
    }
  };

  const uniqueStatuses = [...new Set(issues.map((item) => item.status))];
  const uniqueAssignees = [...new Set(issues.map((item) => item.assignee))];
  const uniqueReporters = [...new Set(issues.map((item) => item.reporter))];
  const uniquePriorities = [...new Set(issues.map((item) => item.priority))];
  const uniqueTypes = [...new Set(issues.map((item) => item.type))];

  let columns = [];

  columns.push(
    {
      title: "Activity ID",
      dataIndex: "activityId",
      width: 200,
    },
    {
      title: "Activity Name",
      dataIndex: "activityName",
      width: 250,
      render: (text, record) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          <Link
            onClick={() => {
              handleIssueClick(record);
            }}
          >
            {text}
          </Link>
        </div>
      ),
    },
    // {
    //   title: "Estimated Hrs",
    //   dataIndex: "estimatedHours",
    //   width: 280,
    // },
    {
      title: "Status",
      dataIndex: "status",
      filters: uniqueStatuses.map((status) => ({
        text: status,
        value: status,
      })),
      onFilter: (value, record) => record.status === value,
      filteredValue: filters.status || null,
      width: 100,
    },

    {
      title: "Assignee",
      dataIndex: "assignee",
      filters: uniqueAssignees.map((assignee) => ({
        text: assignee,
        value: assignee,
      })),
      onFilter: (value, record) => record.assignee === value,
      filteredValue: filters.assignee || null,
      width: 200,
    },

    {
      title: "Reporter",
      dataIndex: "reporter",
      filters: uniqueReporters.map((reporter) => ({
        text: reporter,
        value: reporter,
      })),
      onFilter: (value, record) => record.reporter === value,
      filteredValue: filters.reporter || null,
      width: 200,
    },
    {
      title: "Priority",
      dataIndex: "priority",
      filters: uniquePriorities.map((priority) => ({
        text: priority,
        value: priority,
      })),
      onFilter: (value, record) => record.priority === value,
      filteredValue: filters.priority || null,
      width: 100,
    },
    {
      title: "Type",
      dataIndex: "type",
      filters: uniqueTypes.map((type) => ({
        text: type,
        value: type,
      })),
      onFilter: (value, record) => record.type === value,
      filteredValue: filters.type || null,
      width: 100,
      render: (text) => (
        <Tooltip
          title={text}
          getPopupContainer={(triggerNode) =>
            document.getElementById("tooltipContainer")
          }
          placement="top"
          mouseEnterDelay={0.5}
          mouseLeaveDelay={0.1}
        >
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <>
          <Button
            style={{ bottom: "9px" }}
            icon={<FolderOpenOutlined />}
            onClick={() => handleOpen(record)}
            type="primary"
            size="small"
          />
          <Popconfirm
            title="Are you sure to delete this record?"
            onConfirm={() => handleDelete(record._id)}
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            okText="Yes"
            cancelText="No"
          >
            <Button
              style={{ bottom: "9px" }}
              type="primary"
              danger
              size="small"
            >
              <DeleteFilled />
            </Button>
          </Popconfirm>
        </>
      ),
      width: 50,
    }
  );

  const handleCommentSubmit = async () => {
    if (comment.trim() !== "") {
      const dbName = localStorage.getItem("dbName");
      const activityId = selectedActivity.activityId;
      const newComment = {
        user: users.displayName,
        description: comment,
      };
      try {
        await axios.post(
          `${url}/update/comments/${dbName}/${activityId}/backlog`,
          newComment
        );
        setComments((prevComments) => [...prevComments, newComment]);
        setCount(count + 1);
        setComment("");
        setSelectedActivity((previssues) => ({
          ...previssues,
          comments: [...previssues.comments, newComment],
        }));
        notification.success({ message: "Comment added successfully!" });
      } catch (error) {
        console.error("Error updating comments:", error.message);
        message.error("Failed to add comment.");
      }
    }
  };

  const EmployeeInitials = ({ employee, index, colors }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const toggleTooltip = () => {
      setShowTooltip(!showTooltip);
    };

    return (
      <div
        key={index}
        style={{
          position: "relative",
          display: "inline-block",
          margin: "0 5px",
        }}
        onMouseEnter={toggleTooltip} // Show tooltip on hover
        onMouseLeave={toggleTooltip} // Hide tooltip on mouse leave
      >
        <div
          style={{
            borderRadius: "50%",
            width: "30px",
            height: "30px",
            background: colors[index % colors.length],
            textAlign: "center",
            lineHeight: "30px",
            cursor: "pointer",
            color: "white",
          }}
        >
          {employee.initials}
        </div>
        {showTooltip && (
          <div
            style={{
              position: "absolute",
              top: "100%",
              left: "50%",
              transform: "translateX(-50%)",
              padding: "5px",
              background: "#8a8883",
              border: "1px solid #ccc",
              borderRadius: "5px",
              color: "white",
            }}
          >
            {employee.fullName}
          </div>
        )}
      </div>
    );
  };

  const renderEmployeeInitials = () => {
    if (Assignee) {
      const initials = Assignee.map((employee) => {
        const [firstName, lastName] = employee.split(" ");
        return {
          initials: `${firstName[0]}${lastName[0]}`,
          fullName: employee,
        };
      });

      const colors = [
        "red",
        "purple",
        "yellow",
        "blue",
        "orange",
        "green",
        "pink",
      ];

      return (
        <div>
          {initials.map((employee, index) => (
            <EmployeeInitials
              key={index}
              employee={employee}
              index={index}
              colors={colors}
            />
          ))}
        </div>
      );
    }
    return null;
  };

  const renderProjectEmployeeInitials = () => {
    if (selectedProject) {
      const initials = selectedProject.TotalEmp.map((employee) => {
        const [firstName, lastName] = employee.split(" ");
        return {
          initials: `${firstName[0]}${lastName[0]}`,
          fullName: employee,
        };
      });

      const colors = [
        "red",
        "purple",
        "yellow",
        "blue",
        "orange",
        "green",
        "pink",
      ];

      return (
        <div>
          {initials.map((employee, index) => (
            <EmployeeInitials
              key={index}
              employee={employee}
              index={index}
              colors={colors}
            />
          ))}
        </div>
      );
    }
    return null;
  };

  const goBack = () => {
    navigate("/home/board", {
      state: {
        selectedProject,
        selectedSprint,
      },
    });
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSelect = (selectedValues) => {
    setSelectedUsers(selectedValues);
    setSelectedActivity({
      ...selectedActivity,
      assignee: selectedValues,
    });
  };

  const handleFormSubmit = async (values) => {
    try {
      console.log(values, "values");
      const { featureName, ...restValues } = values;
      const dbName = localStorage.getItem("dbName");
      restValues.reporter = users.displayName;
      restValues.status = "todo";

      console.log(selectedProject, "project12");

      const response = await fetch(
        `${url}/post/backlog/${dbName}/${selectedProject.Projectcode}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...restValues,
            featureName,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit form");
      }
      fetchIssues(selectedProject);
      notification.success({ message: "Issue submitted successfully" });
      setCount(count + 1);
      form.resetFields();
      setModalVisible(false);
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error, show error message to the user, etc.
    }
  };

  return (
    <div
      style={{
        transform: "scale(0.9)",
        transformOrigin: "0 0",
      }}
    >
      {selectedProject && (
        <Row justify="space-between" align="middle">
          <Col>
            <Button
              type="primary"
              style={{ marginLeft: "16px" }}
              onClick={() => {
                setModalVisible(true);
              }}
              icon={<UnorderedListOutlined />}
            >
              Add Activity
            </Button>
          </Col>
          <Col>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Input
                suffix={<SearchOutlined style={{ marginBottom: "10px" }} />}
                placeholder="Search Activity Name"
                value={searchQuery}
                onChange={handleSearchQueryChange}
              />
            </div>
          </Col>
        </Row>
      )}

      {modalVisible && (
        <AddActivity
          featureactivity="Backlog"
          editingfeature="Backlog"
          selectedProject={selectedProject}
          handleSelect={handleSelect}
          modalVisible2={modalVisible}
          setModalVisible2={setModalVisible}
          handleFormSubmit={handleFormSubmit}
        />
      )}
      {/* <Modal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        width={700}
        footer={null}
      >
        <Form form={form} onFinish={handleFormSubmit}>
          <Form.Item>
            <img
              src={require("../../images/backlog.png")}
              alt=""
              style={{ width: "4vh" }}
            />
            <span style={{ marginBottom: "4vh", marginLeft: "1vh" }}>
              {"Add to Backlog"}
            </span>
          </Form.Item>
          <Form.Item
            label="Issue Name"
            name="activityName"
            rules={[{ required: true, message: "Please enter Issue Name" }]}
          >
            <Input placeholder="Enter Issue Name..." />
          </Form.Item>
          <Form.Item
            label="Feature Name"
            name="featureName"
            rules={[
              { required: true, message: "Please select a Feature Name" },
            ]}
          >
            <Select
              placeholder="Select Feature Name..."
              onChange={(value, option) => {
                form.setFieldsValue({
                  featureName: option.children, // Update featureName field
                });
              }}
            >
              {feature.map((feature, index) => (
                <Select.Option key={index} value={feature.featureName}>
                  {feature.featureName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Row gutter={[16, 16]}>
            <Col span={14}>
              <Form.Item label="Assigned To" name="assignee">
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  allowClear
                  style={{ width: "30vh" }}
                  name="assignee"
                  placeholder="Please select"
                  onChange={handleSelect}
                >
                  {selectedProject &&
                    selectedProject.TotalEmp.map((employee, index) => (
                      <Select.Option key={index} value={employee}>
                        {employee}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={10}>
              <Form.Item label="Description" name="description">
                <Input.TextArea
                  rows={2}
                  placeholder="Click to add Description"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item label="Priority" name="priority">
                <Select
                  style={{ width: "100%" }}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  placeholder="Priority"
                >
                  <Select.Option value="High">High</Select.Option>
                  <Select.Option value="Medium">Medium</Select.Option>
                  <Select.Option value="Low">Low</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label="Estimated Hours" name="estimatedHours">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item style={{ alignItems: "center" }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal> */}
      <Modal
        title="Move to Feature"
        visible={open}
        footer={null}
        onCancel={() => {
          setOpen(false);
          setSelectedSprintName("");
          setSelectedFeatureName("");
          form.resetFields();
        }}
      >
        <Form form={form} onFinish={handleAssignFeature}>
          <Form.Item
            label="Select Sprint"
            name="sprint"
            rules={[{ required: true, message: "Please select a sprint" }]}
          >
            <Select
              style={{ width: "100%" }}
              onChange={handleselect1}
              value={selectedSprintName}
            >
              {existingSprints.map((sprint) => (
                <Option key={sprint.sprintName} value={sprint.sprintName}>
                  {sprint.sprintName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {selectedSprintName && (
            <Form.Item
              label="Select Feature"
              name="feature"
              rules={[{ required: true, message: "Please select a feature" }]}
            >
              <Select
                style={{ width: "100%" }}
                onChange={handleselect}
                value={selectedFeatureName}
              >
                {feature.map((featureItem) => (
                  <Option
                    key={featureItem.featureName}
                    value={featureItem.featureName}
                  >
                    {featureItem.featureName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item>
            <Button type="primary" htmlType="submit" className="submit-button">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <br />
      {selectedActivity && selectedProject ? (
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div style={{ maxHeight: "500px", overflowY: "auto" }}>
              <Table
                dataSource={filteredData}
                columns={columns}
                pagination={{
                  pageSize: pageSize,
                  current: currentPage,
                  onChange: handlePageChange,
                }}
                scroll={{ x: true, y: true }}
                sticky
                className="custom-table col-lg-12 col-md-12 col-sm-12"
                onChange={handleFilterChange}
              />
            </div>
          </Col>
          <Col span={12}>
            <div style={{ maxHeight: "500px", overflowY: "auto" }}>
              <Card
                title={`${selectedActivity.activityId}`}
                extra={
                  <Button
                    onClick={() => setSelectedActivity(null)}
                    type="text"
                    shape="circle"
                    icon={<CloseOutlined />}
                  />
                }
              >
                <Form onFinish={updateIssue}>
                  <Row>
                    {isEditing ? (
                      <>
                        <Input
                          value={editedIssueName}
                          onChange={handleInputChange}
                          autoFocus
                        />
                        <Button
                          style={{ left: "3vh" }}
                          icon={<CloseOutlined />}
                          onClick={() => setIsEditing(false)}
                        />
                      </>
                    ) : (
                      <>
                        <p>{selectedActivity.activityName}</p>
                        <Button
                          style={{ left: "3vh" }}
                          icon={<EditFilled />}
                          onClick={handleEditClick}
                          disabled={users.jobTitle === "employee"}
                        />
                      </>
                    )}
                  </Row>
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    onChange={handleFileUpload}
                    accept="image/*"
                  />
                  <label htmlFor="fileInput">
                    <PaperClipOutlined
                      style={{ fontSize: 24, cursor: "pointer" }}
                    />
                  </label>
                  {base64Image && (
                    <img
                      src={base64Image}
                      alt="Uploaded"
                      style={{ maxWidth: "50%", marginTop: "10px" }}
                    />
                  )}
                  <br />
                  <Row
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Form.Item
                      label="Status"
                      // name="status"
                      // initialValue={selectedActivity.status}
                    >
                      <Select
                        value={currentIssue?.status}
                        style={{ width: "20vh" }}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        onChange={handleStatusChange}
                      >
                        <Select.Option value="todo">To Do</Select.Option>
                        <Select.Option value="doing">Doing</Select.Option>
                        <Select.Option value="done">Done</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="Priority">
                      <Select
                        value={currentIssue?.priority}
                        style={{ width: "20vh" }}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        onChange={handlePriorityChange}
                        disabled={users.jobTitle === "employee"}
                      >
                        <Select.Option value="Low">Low</Select.Option>
                        <Select.Option value="High">High</Select.Option>
                        <Select.Option value="Medium">Medium</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item label="Type">
                      <Select
                        disabled={users.jobTitle === "employee"}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        onChange={(value) => {
                          setSelectedActivity({
                            ...selectedActivity,
                            type: value.toString(),
                          });
                        }}
                        style={{ width: "150px" }}
                        value={selectedActivity.type}
                      >
                        <Select.Option value="Bug">Bug</Select.Option>
                        <Select.Option value="Enhancement">
                          Enhancement
                        </Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item label="Estimated Hrs">
                      <Input
                        disabled={users.jobTitle === "employee"}
                        onChange={(e) => {
                          setSelectedActivity({
                            ...selectedActivity,
                            estimatedHours: e.target.value,
                          });
                        }}
                        style={{ width: "150px" }}
                        value={selectedActivity.estimatedHours}
                      />
                    </Form.Item>
                  </Row>
                  <p style={{ fontSize: "16px", marginTop: "5vh" }}>
                    Description
                  </p>
                  <Form.Item>
                    <Input.TextArea
                      rows={4}
                      onChange={(e) => {
                        setSelectedActivity({
                          ...selectedActivity,
                          description: e.target.value.toString(),
                        });
                      }}
                      value={selectedActivity.description}
                    />
                  </Form.Item>

                  <Card title="Details" style={{ marginTop: "5vh" }}>
                    <Form.Item label="Assignee">
                      <Select
                        disabled={users.jobTitle === "employee"}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        allowClear
                        name="assignee"
                        placeholder="Please select"
                        onChange={handleSelect}
                        value={selectedActivity.assignee}
                      >
                        {Assignee.map((employee, index) => (
                          <Select.Option key={index} value={employee}>
                            {employee}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item label="Reporter">
                      <Input
                        value={selectedActivity.reporter}
                        disabled
                        style={{ height: "40px" }}
                      />
                    </Form.Item>
                  </Card>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      icon={<EditOutlined />}
                    >
                      Update
                    </Button>
                  </Form.Item>
                </Form>
                <Card
                  title="Activity"
                  style={{
                    marginTop: "1vh",
                    overflow: "auto",
                    maxHeight: "350px",
                  }}
                >
                  <Tabs
                    defaultActiveKey="comments"
                    onChange={toggleShowDetails}
                  >
                    <TabPane tab="Comments" key="comments">
                      <div>
                        <p>Comments:</p>
                        <Form onFinish={handleCommentSubmit}>
                          <Row>
                            <Col span={14}>
                              <Form.Item>
                                <Input.TextArea
                                  placeholder="Add a Comment"
                                  onChange={(e) => setComment(e.target.value)}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={5}>
                              <Form.Item>
                                <Button
                                  type="text"
                                  htmlType="submit"
                                  icon={<SendOutlined />}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form>
                        {selectedActivity.comments.map((comment, index) => (
                          <div key={index} style={{ marginTop: "10px" }}>
                            <Avatar size="large" style={{ background: "blue" }}>
                              {comment.user && comment.user.length >= 2
                                ? `${comment.user[0]}${
                                    comment.user.split(" ")[1][0]
                                  }`
                                : comment.user}
                            </Avatar>
                            <span style={{ marginLeft: "10px" }}>
                              {comment.description}
                            </span>
                          </div>
                        ))}
                      </div>
                    </TabPane>
                    <TabPane tab="History" key="history">
                      <div>
                        <p>History:</p>
                        {selectedActivity.history.map((entry, index) => {
                          const userInitials = entry.changedBy
                            ? entry.changedBy
                                .split(" ")
                                .map((namePart, idx) =>
                                  idx === 0 || idx === 1 ? namePart[0] : null
                                )
                                .join("")
                            : "";
                          const formattedTimestamp = moment(
                            entry.timestamp
                          ).format("MMMM D, YYYY [at] h:mm A");
                          return (
                            <div key={index} style={{ marginTop: "10px" }}>
                              <Row>
                                <Col span={2}>
                                  <Avatar
                                    size="large"
                                    style={{ background: "blue" }}
                                  >
                                    {userInitials}
                                  </Avatar>
                                </Col>
                                <Col span={22}>
                                  <div>
                                    <span>{entry.changedBy}</span>
                                    <span style={{ color: "gray" }}>
                                      {` ${formattedTimestamp}`}
                                    </span>
                                  </div>
                                  {entry.changes && entry.changes.length > 0 ? (
                                    entry.changes.map((change, idx) => (
                                      <div
                                        key={idx}
                                        style={{ marginLeft: "34px" }}
                                      >
                                        {change.field === "created" ? (
                                          <p>{` activity created`}</p>
                                        ) : (
                                          <p>
                                            <span>{change.field}</span>
                                            {` changed from `}
                                            <span
                                              style={{
                                                textDecoration: "line-through",
                                                color: "red",
                                              }}
                                            >
                                              {change.oldValue}
                                            </span>
                                            {` to `}
                                            <span style={{ color: "green" }}>
                                              {change.newValue}
                                            </span>
                                          </p>
                                        )}
                                      </div>
                                    ))
                                  ) : (
                                    <div style={{ marginLeft: "34px" }}>
                                      <p>
                                        <span>{entry.field}</span>
                                        {` created`}
                                      </p>
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          );
                        })}
                      </div>
                    </TabPane>
                  </Tabs>
                </Card>
              </Card>
            </div>
          </Col>
        </Row>
      ) : selectedProject ? (
        <div style={{ maxHeight: "500px", width: "110%", overflowY: "auto" }}>
          <Table
            dataSource={filteredData}
            columns={columns}
            pagination={{
              pageSize: pageSize,
              current: currentPage,
              onChange: handlePageChange,
            }}
            scroll={{ x: true, y: true }}
            sticky
            className="custom-table col-lg-12 col-md-12 col-sm-12"
            onChange={handleFilterChange}
          />
        </div>
      ) : (
        <h6
          style={{ marginTop: "6vh", color: "#e69660" }}
          className="text-center"
        >
          Select Project First to View the Issue Board
        </h6>
      )}
    </div>
  );
};

export default Issueboard;
