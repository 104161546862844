import React, { useState } from 'react';
import { Card, Form, Input, Button, Layout, Checkbox, Select, DatePicker, Row, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const { Option } = Select;
const { RangePicker } = DatePicker;

const Setup = ({ onNext,setAllValues }) => {
  const [form] = Form.useForm();
  const [size] = useState('default');

  const [uploadedLogo, setUploadedLogo] = useState(null);

  const handleRemove = () => {
    setUploadedLogo(null);
  };



  const onFinish = (values) => {
    values.surveyImage = uploadedLogo;
    console.log(values, "Formvalues");
    setAllValues((prevValues) => ({
      ...prevValues,
      setup: values, // Update the setup values in allValues
    }));
    message.success('Saved your Details');
    onNext(values); // Move to the next step
  };

  const handleUpload = ({ file, onSuccess }) => {
    const reader = new FileReader();
    reader.onload = () => {
      setUploadedLogo(reader.result);
      onSuccess("ok");
    };
    reader.readAsDataURL(file);
  };

  return (
    <div>
      <Layout style={{ minHeight: '90vh' }}>
        <Form
          form={form}
          name="basic"
          layout="vertical"
          onFinish={onFinish}
        >
          <div style={{ maxHeight: '100vh', overflowY: 'auto' }}>
            <Card id="details-section" style={{
              marginTop: "1vh",
              border: "1px solid #959696",
              width: "70%",
              margin: "0 auto",  // This centers the card horizontally
            }}>
              <h3 style={{ textAlign: "left" }}>Details</h3>
              <p style={{ textAlign: "left" }}>Give your survey a name and a description</p>
              <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input the name!' }]}>
                <Input />
              </Form.Item>
              <Form.Item label="Description" name="desc">
                <Input.TextArea />
              </Form.Item>
              <Form.Item name="requiredCheck" valuePropName="checked" style={{ textAlign: "left" }}>
                <Checkbox>Make this survey mandatory for applicable employees.</Checkbox>
              </Form.Item>
              <Form.Item name="empIdentity" label="Employee identity preferences">
                <Select>
                  <Option value="Maintain anonymity">Maintain anonymity</Option>
                  <Option value="identity">Allow employees to choose to reveal their identity</Option>
                  <Option value="Mandate identity disclosure">Mandate identity disclosure</Option>
                </Select>
              </Form.Item>
              <Form.Item label="Survey Image" name="surveyImage">
                <Upload
                  customRequest={({ file, onSuccess, onError }) =>
                    handleUpload({ file, onSuccess, onError, type: "logo" })
                  }
                  maxCount={1}
                  onRemove={() => handleRemove("logo")}
                >
                  <Button icon={<UploadOutlined />}>Upload Image</Button>
                </Upload>
              </Form.Item>
            </Card>
            <br />
            <Card id="schedule-section" style={{
              border: "1px solid #959696",
              width: "70%",
              margin: "0 auto",
            }}>
              <h3 style={{ textAlign: "left" }}>Schedule</h3>
              <p style={{ textAlign: "left" }}>Schedule your survey occurrence</p>

              <Row justify="space-between">
                <Form.Item label="Select Period" name="period" rules={[{ required: true, message: 'Please input the Date!' }]}>
                  <RangePicker size={size} />
                </Form.Item>
                <Form.Item name="surveyRepeats" label="Survey Repeats">
                  <Select placeholder="--None--" style={{ width: "30vh" }}>
                    <Option value="Monthly">Monthly</Option>
                    <Option value="Quarterly">Quarterly</Option>
                    <Option value="Half-yearly">Half-yearly</Option>
                    <Option value="Annually">Annually</Option>
                  </Select>
                </Form.Item>
              </Row>
            </Card>
            <br />

          </div>
          <Form.Item style={{ textAlign: "right", marginTop: "1%" }}>
            <Button type="primary" htmlType="submit">
              Next
            </Button>
          </Form.Item>
        </Form>
      </Layout>


    </div>
  );
};

export default Setup;