import React, { useState, useEffect } from "react";
import axios from "axios";
import "./claims.css";
import {
  Button,
  Input,
  Form,
  message,
  notification,
  Select,
  Layout,
  Image,
  Tooltip,
} from "antd";
import { UploadOutlined, DeleteFilled, PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { url } from "../../url/url";
import { Content } from "antd/es/layout/layout";
const layout = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    span: 14,
  },
};

function Claims(props) {
  function handlechange(event) {
    const { name, value } = event.target;
    setClaimData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    console.log(ClaimData);
  }
  const handlelist = (selectedValues) => {
    console.log(selectedValues);
    setClaimData((prevState) => ({
      ...prevState,
      claimType: selectedValues,
    }));
  };
  const status = localStorage.getItem("status");
  const user = JSON.parse(localStorage.getItem("user"));
  const today = new Date().toISOString().slice(0, 10);
  const [date] = useState(today);
  const [managerInfo, setManagerInfo] = useState([]);
  const [empInfo, setEmpInfo] = useState([]);
  const [form] = Form.useForm();
  const [ClaimData, setClaimData] = useState({
    empName: user.displayName,
    EmpID: empInfo,
    reportingmanager: managerInfo,
    hrName:user.hrName,
    expenseDescription: "",
    amount: "",
    dateOfExpense: "",
    claimType: [],
    status: "submitted",
    dateOfApply: date,
    images: [],
    email: user.userPrincipalName,
  });
  const [fileType, setFileType] = useState("image");
  const [documentList, setDocumentList] = useState([
    {
      image: null,
      fileName: "",
    },
  ]);
  const [maxDocumentCount] = useState(4);

  useEffect(() => {
    fetchmanager();
    fetchType();
  }, []);
  const fetchmanager = async () => {
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await axios.get(
        `${url}/api/getUserManager/${user.displayName}/${dbName}`
      );
      const { manager, empCode,hrName } = response.data.user;
      setManagerInfo(manager);
      setEmpInfo(empCode);
      console.log(manager);
      setClaimData((prevClaimData) => ({
        ...prevClaimData,
        reportingmanager: manager,
        EmpID: empCode,
        hr:hrName,
      }));
      console.log(manager);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };
  const fetchType = async () => {
    const dbName = localStorage.getItem("dbName");
    const res = await axios.get(`${url}/get/combined/${dbName}`);
    console.log(res.data[0].reimbustmentType, "res");
    setClaimData((prevClaimData) => ({
      ...prevClaimData,
      claimType: res.data[0].reimbustmentType,
    }));
  };
  const formRef = React.useRef(null);
  const handleDelete = (index) => {
    const updatedDocumentList = [...documentList];
    updatedDocumentList[index] = {
      image: null,
      fileName: "",
    };
    setDocumentList(updatedDocumentList);

    // Update the ClaimData images array
    const updatedClaimData = { ...ClaimData };
    updatedClaimData.images[index] = null;
    setClaimData(updatedClaimData);
  };

  const handleDocumentImageChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const allowedFileTypes = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "application/pdf",
      ];
      const maxSize = 5 * 1024 * 1024;

      if (allowedFileTypes.includes(file.type) && file.size <= maxSize) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const updatedDocumentList = [...documentList];
          updatedDocumentList[index] = {
            image: reader.result, // Store the data URL
            fileName: file.name, // Store the file name
          };
          setDocumentList(updatedDocumentList);
          setFileType(file.type === "application/pdf" ? "pdf" : "image");

          // Update ClaimData's images array
          const updatedClaimData = { ...ClaimData };
          updatedClaimData.images[index] = reader.result;
          setClaimData(updatedClaimData);
        };
        reader.readAsDataURL(file);
      } else {
        // Do not update the document if it's an invalid file
        if (file.size > maxSize) {
          message.error("File size exceeds the 5MB limit.");
        } else {
          message.error(
            "Invalid file type. Please select a valid image or PDF file."
          );
        }
      }
    }
  };

  const handleSubmit = async () => {
    try {
      console.log(ClaimData);
      console.log("started");
      const dbName = localStorage.getItem("dbName");
      const amount = parseFloat(ClaimData.amount, 10);
      ClaimData.amount = amount;

      // Make a POST request to submit the claim
      const claimResponse = await axios.post(`${url}/Claims`, {
        ClaimData,
        images: ClaimData.images,
        dbName,
      });

      console.log(claimResponse);
      notification.success({ message: "Claims applied successfully" });
      handleReset();
      fetchType();
      // If the claim submission was successful, invoke the mail trigger API
      const managerEmail = user.managerEmail;

     
    } catch (error) {
      console.error(error, "error");
      message.error("Something Went Wrong");
    }
  };

  function handleReset() {
    // Clear the images in documentList
    const clearedDocumentList = [
      {
        image: null,
        fileName: "",
      },
    ];

    // Clear the image in ClaimData
    setClaimData((prevClaimData) => ({
      ...prevClaimData,
      images: [null],
    }));

    // Reset the form
    formRef.current?.resetFields();

    // Update the documentList
    setDocumentList(clearedDocumentList);
  }

  function addDocument() {
    if (documentList.length < maxDocumentCount) {
      setDocumentList([
        ...documentList,
        {
          image: null,
          fileName: "",
        },
      ]);
    } else {
      message.warning(`You can upload up to ${maxDocumentCount} documents.`);
    }
  }
  const validateYearFormat = (rule, value, callback) => {
    // Check if the value is a valid date with a 4-digit year
    const regex = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD format
    if (!regex.test(value)) {
      callback("Please enter a valid date in DD-MM-YYYY format.");
    } else {
      callback();
    }
  };
  // const handleDateChange = (e) => {
  //   const newDate = e.target.value;
  //   const parsedDate = new Date(newDate);

  //   if (isNaN(parsedDate.getTime())) {
  //     setError("Invalid date format");
  //     message: "Invalid date format..!";
  //   } else {
  //     setError("");

  //     const day = parsedDate.getDate();
  //     const month = parsedDate.getMonth() + 1; // Months are zero-based
  //     const year = parsedDate.getFullYear();

  //     if (day < 1 || day > 30 || month < 1 || month > 12 || year > 9999) {
  //       setError("Invalid date");
  //     } else {
  //       setInputDate(newDate);
  //     }
  //   }
  // };
  return (
    <Content
      style={{
        padding: 20,
        boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.35)",
        minHeight: "5vh",
      }}
    >
      <Layout style={{ zoom: "100%" }}>
        <h4 className="text-center"> Submit Claims </h4>
        <Form
          {...layout}
          onFinish={handleSubmit}
          ref={formRef}
          style={{ paddingTop: "30px" }}
        >
          <div className="row mb-1">
            <div className="col-lg-6 col-sm-12">
              <Form.Item label="Employee Name">
                <Input
                  style={{ height: "40px", fontSize: "14px" }}
                  name="Employee Name"
                  className="disabled-input"
                  disabled
                  value={user.displayName}
                />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-sm-12">
              <Form.Item label="Employee ID">
                <Input
                  value={ClaimData.EmpID}
                  disabled
                  className="disabled-input"
                  name="Employee ID"
                  style={{ height: "40px", fontSize: "14px" }}
                />
              </Form.Item>
            </div>
          </div>
          <div className="row mb-1">
            <div className="col-lg-6 col-sm-12">
              <Form.Item label="Date of Apply">
                <Input
                  value={date}
                  name="dateOfApply"
                  type="date"
                  style={{ height: "40px" }}
                  disabled
                  className="disabled-input"
                />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-sm-12 ">
              <Form.Item label="Reporting Manager">
                <Input
                  value={ClaimData.reportingmanager}
                  disabled
                  className="disabled-input"
                  name="reportingmanager"
                  style={{ height: "40px", fontSize: "14px" }}
                />
              </Form.Item>
            </div>
          </div>
          <div className="row mb-1">
            <div className="col-lg-6 col-sm-12 ">
              <Form.Item
                label="Date of Expense"
                name="dateOfExpense"
                rules={[
                  {
                    required: true,
                    validator: validateYearFormat,
                  },
                ]}
              >
                <Input
                  type="date"
                  value={ClaimData.dateOfExpense}
                  onChange={handlechange}
                  size="middle"
                  name="dateOfExpense"
                  style={{ height: "40px", fontSize: "14px" }}
                  min={moment().subtract(2, "months").format("YYYY-MM-DD")}
                  max={moment().format("YYYY-MM-DD")}
                />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-sm-12 ">
              <Form.Item
                label="Expense Description"
                name="expenseDescription"
                rules={[
                  {
                    required: true,
                    message: "Please enter Expense Description..!",
                  },
                ]}
              >
                <Input
                  value={ClaimData.expenseDescription}
                  onChange={handlechange}
                  name="expenseDescription"
                  style={{ height: "40px", fontSize: "14px" }}
                />
              </Form.Item>
            </div>
          </div>
          <div className="row mb-1">
            <div className="col-lg-6 col-sm-12">
              <Form.Item
                label="Amount"
                name="amount"
                rules={[
                  {
                    required: true,
                    message: "",
                  },

                  {
                    validator: (rule, value) => {
                      if (isNaN(value)) {
                        return Promise.reject("Enter a valid amount!");
                      }

                      if (value < 0 || value == 0 || value > 10000) {
                        return Promise.reject("Enter a valid amount!");
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  value={ClaimData.amount}
                  onChange={handlechange}
                  name="amount"
                  style={{ height: "40px", fontSize: "14px" }}
                />
              </Form.Item>
            </div>

            <div className="col-lg-6 col-sm-12 ">
              <Form.Item
                label="Reimbursement Type"
                name="claimType"
                rules={[
                  {
                    required: true,
                    message: "Please select Reimbursement Type..!",
                  },
                ]}
              >
                <Select
                  size="large"
                  name="claimType"
                  placeholder="Please select the Type"
                  onChange={handlelist}
                  required
                  style={{ fontSize: "14px" }}
                >
                  {Array.isArray(ClaimData?.claimType) && (
                  ClaimData?.claimType.map((type, index) => (
                    <Select.Option
                      key={index}
                      value={type}
                      style={{ fontSize: "14px" }}
                    >
                      {type}
                    </Select.Option>
                  )))}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <Form.Item label="Documents" name="images">
              <div
                className="document-upload-container"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px",
                }}
              >
                {documentList.map((document, index) => (
                  <div
                    key={index}
                    className="document-upload"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <div
                      className="upload-container"
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <Tooltip title="Click to Upload/Update the selected file below">
                        <label className="custom-file-upload">
                          <UploadOutlined /> Upload
                          <Input
                            type="file"
                            accept="image/jpeg, image/png, image/jpg, application/pdf"
                            onChange={(e) =>
                              handleDocumentImageChange(e, index)
                            }
                            style={{ display: "none" }}
                          />
                        </label>
                      </Tooltip>
                      {document.image && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: "10px",
                          }}
                        >
                          <div className="document-name" style={{ flex: 1 }}>
                            {document.fileName}
                          </div>
                          <div>
                            <Button
                              danger
                              size="medium"
                              icon={<DeleteFilled />}
                              onClick={() => handleDelete(index)}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                <div>
                  {documentList.length < maxDocumentCount && (
                    <div className="add-document">
                      <Tooltip title="Click to add another">
                        <Button
                          type="dashed"
                          onClick={addDocument}
                          icon={<PlusOutlined />}
                        />
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>
            </Form.Item>
          </div>
          <div className="col-lg-12 col-sm-12 d-flex  justify-content-between">
            <Form.Item>
              <Button type="primary" danger onClick={handleReset}>
                Reset
              </Button>
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={status === "disable"}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Layout>
    </Content>
  );
}

export default Claims;
