import { Button, Card, Divider, message, notification, Radio, Rate, Slider, Modal, Checkbox } from 'antd';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import background1 from '../../images/background1.webp'; 
import { url } from '../../url/url';
import axios from 'axios';
import TextArea from 'antd/es/input/TextArea';
const Empsurvey = () => {
    const { name } = useParams();
    const [scaleValue, setScaleValue] = useState(0);
    const [survey, setSurvey] = useState([]);
    const [selectedChoice, setSelectedChoice] = useState("");
    const [ratingValue, setRatingValue] = useState(0);
    const user = JSON.parse(localStorage.getItem("user"));
    const [isSubmitVisible, setIsSubmitVisible] = useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [isIdentityChecked, setIsIdentityChecked] = useState(false);
    const [isIdentityModalVisible, setIsIdentityModalVisible] = useState(false);
    const [additionalComments, setAdditionalComments] = useState("");
    const [empSurvey, setEmpSurvey] = useState([]);

    const fetchEmpSurvey = async () => {
        const dbName = localStorage.getItem("dbName");
        try {
          const response = await axios.get(`${url}/get/empsurveys/${dbName}`);
          setEmpSurvey(response.data);
          console.log(response.data, "empsurvey");
    
        } catch {
          console.log("Error");
        }
      };
    // To track if checkbox is checked
    const handleNext = () => {
        if (currentQuestionIndex < survey[0]?.questions?.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    };

    const handlePrevious = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };

    const currentQuestion = survey[0]?.questions?.[currentQuestionIndex];

    // Close the modal
    const handleCancelSubmit = () => {
        setIsSubmitVisible(false); // Set modal visibility to false to close it
    };

    const handleFormSubmit = async () => {

        // Scenario 1: If empIdentity is "Maintain anonymity"
        if (survey[0].introduction.empIdentity === "Maintain anonymity") {
            console.log("anonomous");
            setIsIdentityChecked(false);
            postSurveyForm();
        }

        // Scenario 2: If empIdentity is "Identity"
        else if (survey[0].introduction.empIdentity === "identity") {
            console.log("identity");

            setIsIdentityModalVisible(true);
        }

        // Scenario 3: If empIdentity is "Mandate identity disclosure"
        else if (survey[0].introduction.empIdentity === "Mandate identity disclosure") {
            console.log("disclosure");
            setIsIdentityChecked(true);
            postSurveyForm();
        }
    };

    // Function to post the survey form
    const postSurveyForm = async () => {
        const dbName = localStorage.getItem("dbName")
        const formValues = {
            userName: user.displayName, // Depending on the condition
            manager: user.manager,
            hr: user.hr,
            surveyName: survey[0].introduction.name,
            questions: survey[0].questions.map((data) => ({
                question: data.question,
                answer:
                    data.type === "scale"
                        ? [scaleValue]
                        : data.type === "choice"
                            ? [selectedChoice]
                            : data.type === "rating"
                                ? [ratingValue]
                                :
                                data.type === 'comment'
                                    ? [additionalComments] : [],
            })),
            isIdentity: isIdentityChecked,
        };

        try {
            const response = await axios.post(
                `${url}/post/empsurveys/${dbName}`,
                formValues
            );
            notification.success({
                message: 'Feedback posted successfully!',
                description: 'Thank you for your valuable feedback!',
                placement: 'topRight', // Optional: You can specify the placement of the notification
            });
            setIsSubmitVisible(true);
            console.log(response.data, "Surveyform");
        } catch (error) {
            console.error("Error publishing survey:", error);
        }
    };



    useEffect(() => {
        fetchSurvey();
        fetchEmpSurvey();
    }, [])
    const fetchSurvey = async () => {
        const dbName = localStorage.getItem("dbName");
        try {
            const response = await axios.get(`${url}/get/surveys/${dbName}`);
            const filter = response.data.filter((item) => item.introduction.name === name);
            setSurvey(filter);
            console.log(filter, "surveyfil");

        } catch {
            console.log("Error");
        }
    };

    const hasResponded = empSurvey.some(
        (response) =>
            response.surveyName === survey[0]?.introduction.name &&
            response.userName === user.displayName
    );


    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundImage: `url(${survey[0]?.introduction?.surveyImage
                    ? survey[0].introduction.surveyImage // Use surveyImage if available
                    : background1 // Otherwise, get a random default image
                    })`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                minHeight: "470px",
            }}
        >

            <Card
                style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                    width: "90%",
                    height: "70vh",
                }}
            >
                {hasResponded ? (
                    <div style={{marginTop:"12vh"}}>
                    <div style={{display:"flex",justifyContent:"center"}}>
                        <img
                            src={require("../../images/feedback1.avif")}
                            style={{ width: "30vh", height: "30vh" }}
                        />
                        </div>
                        <h5 className='text-center' style={{color:"green"}}>Your feedback has been submitted</h5 >
                    </div>
                ) : (
                    <>
                        <h4>{survey[0]?.introduction?.name}</h4>
                        <Divider />

                        {currentQuestion && (
                            <div key={currentQuestionIndex}>
                                <p>
                                    Q{currentQuestionIndex + 1}. {currentQuestion.question}
                                </p>

                                {currentQuestion.type === "scale" && (
                                    <Slider
                                        min={0}
                                        max={currentQuestion.answer?.length > 0 ? currentQuestion.answer[0] : 10}
                                        defaultValue={0}
                                        onChange={(value) => setScaleValue(value)}
                                    />
                                )}

                                {currentQuestion.type === "choice" && (
                                    <Radio.Group
                                        onChange={(e) => setSelectedChoice(e.target.value)}
                                    >
                                        {currentQuestion.choices?.map((ans, aIndex) => (
                                            <Radio key={aIndex} value={ans}>
                                                {ans}
                                            </Radio>
                                        ))}
                                    </Radio.Group>
                                )}

                                {currentQuestion.type === "rating" && (
                                    <Rate
                                        defaultValue={0}
                                        count={5}
                                        onChange={(value) => setRatingValue(value)}
                                    />
                                )}
                            </div>
                        )}

                        <br />
                        <TextArea
                            placeholder="Your additional comments"
                            onChange={(e) => setAdditionalComments(e.target.value)}
                        />
                        <br />
                        <br />

                        <div style={{ textAlign: "center" }}>
                            <Button
                                type="primary"
                                htmlType="button"
                                danger
                                onClick={handlePrevious}
                                disabled={currentQuestionIndex === 0} // Disable the button for the first question
                                style={{ marginRight: 10 }}
                            >
                                Previous
                            </Button>
                            {currentQuestionIndex < survey[0]?.questions?.length - 1 ? (
                                <Button
                                    type="primary"
                                    danger
                                    htmlType="button"
                                    onClick={handleNext}
                                >
                                    Next
                                </Button>
                            ) : (
                                <Button
                                    type="primary"
                                    htmlType="button"
                                    onClick={handleFormSubmit}
                                >
                                    Submit
                                </Button>
                            )}
                        </div>
                    </>
                )
                }
            </Card >

            <Modal
                visible={isSubmitVisible}
                onCancel={handleCancelSubmit}
                footer={null}
                width={1000}
                bodyStyle={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    minHeight: "400px",
                }}
            >
                <Card
                    style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        display: "block",
                    }}
                >
                    <img
                        src={require("../../images/thumbsup-removebg-preview.png")}
                        style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "block",
                        }}
                        alt="Thank You"
                    />
                    <br />
                    <br />
                    <h4 style={{ textAlign: "center" }}>
                        We thank you immensely for your valuable feedback!
                    </h4>
                    <p style={{ textAlign: "center" }}>
                        We will use them to improve your experience.
                    </p>
                </Card>
            </Modal>
            <Modal
                visible={isIdentityModalVisible}
                onCancel={() => setIsIdentityModalVisible(false)}
                onOk={postSurveyForm}
                okText="Submit"
                cancelText="Cancel"
            >
                <p>
                    Do you want to disclose your identity while submitting the feedback?
                </p>
                <Checkbox
                    onChange={(e) => setIsIdentityChecked(e.target.checked)}
                >
                    Yes, I want to reveal my identity.
                </Checkbox>
            </Modal>
        </div >

    )
}

export default Empsurvey