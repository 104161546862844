import React, { useEffect, useState } from "react";
import "./thirukural.css";
import kuralData from "./kurals.json";
import { Button, Spin} from "antd";
import {PlayCircleOutlined, PauseCircleOutlined} from "@ant-design/icons";
const Thirukural = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentKuralIndex, setCurrentKuralIndex] = useState(0);
  const [audio, setAudio] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  
  useEffect(() => {
    const startDate = new Date("2024-04-18");
    const currentDate = new Date();
    const timeDiff = Math.abs(currentDate.getTime() - startDate.getTime());
    const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    const newIndex = dayDiff % kuralData.length;
    setCurrentKuralIndex(newIndex);
    console.log(newIndex,"new");
  }, []);


  const displayKuralData = () => {
    const kural = kuralData[currentKuralIndex];
    return (
      <div className="background-container">
        <div style={{ marginBottom: "60vh" }}>
          <h5>இன்று ஒரு திருக்குறள்</h5>
          <span className="close1" onClick={() => setShowModal(false)}>
            &times;
          </span>
        </div>
        <div className="kural-details">
          <div className="label-container">
            <p className="label">தமிழ்</p>
          </div>
          <div className="content-container">
            <div id="kuralBox" title={kural.meaning}>
              <p>{kural.kural1}</p>
              <p>{kural.kural2}</p>
            </div>
            <div className="label-container">
              <p className="label">English</p>
            </div>
            <div
              className="transliteration"
              id="kuralBox"
              title={kural.meaning}
            >
              <p>{kural.transliterate1}</p>
              <p>{kural.transliterate2}</p>
            </div>
            <div className="label-container">
              <p className="label">Meaning</p>
            </div>
            <div className="explanation" id="kuralBox" title={kural.meaning}>
              <p>{kural.Explanation}</p>
              <p
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  left: "20vh",
                }}
              >
                - திருவள்ளுவர்
              </p>
            </div>
          </div>
        </div>
        <Button onClick={toggleAudio} style={{marginBottom:"5vh"}} type="primary" icon={isLoading ? <Spin /> : (isPlaying ? <PauseCircleOutlined/> : <PlayCircleOutlined />)}></Button>{" "}
      </div>
    );
  };

  //   const toggleAudio = () => {
  //     const kural = kuralData[currentKuralIndex];
  //     if (audio && !audio.paused) {
  //         audio.pause();
  //         setIsPlaying(false);
  //     } else {
  //         speakKural(kural);
  //     }
  // };

  // const speakKural = (kural) => {
  //     const utterance = new SpeechSynthesisUtterance();
  //     utterance.lang = 'ta-IN';
  //     utterance.voice = getTamilVoice();
  //     utterance.text = `${kural.kural1} ${kural.kural2} ${kural.meaning} ${kural.Explanation}`;

  //     utterance.onstart = () => setIsPlaying(true);
  //     utterance.onend = () => setIsPlaying(false);

  //     window.speechSynthesis.speak(utterance);
  // };

  // const getTamilVoice = () => {
  //     const voices = window.speechSynthesis.getVoices();
  //     // Find the Tamil voice by name
  //     return voices.find(voice => voice.name === 'ta-IN-PallaviNeural') || voices.find(voice => voice.lang === 'ta-IN');
  // };

  function toggleAudio() {
    const kural = kuralData[currentKuralIndex];
    if (audio && !audio.paused) {
      audio.pause();
      setIsPlaying(false);
    } else {
      setIsLoading(true);
      fetchTTS(kural);
    }
  }

  function fetchTTS(kural) {
    fetch(`https://myapp.finaran.com:5000/api/tts`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(kural),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        if (!response.headers.get("content-type")?.includes("audio")) {
          throw new Error("Response not audio content");
        }
        return response.blob();
      })
      .then((blob) => {
        const audioURL = URL.createObjectURL(blob);
        const audioElement = new Audio(audioURL);
        audioElement.play();
        setAudio(audioElement);
        setIsLoading(false);
        setIsPlaying(true);
        audioElement.addEventListener("ended", () => setIsPlaying(false));
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
      });
  }
  return (
    <div style={{ zoom: "75%" }}>
      <div style={{ position: "fixed", bottom: "160px", right: "10px" }}>
        <img
          width={40}
          height={40}
          src={require("../../images/thirukural.png")}
          onClick={() => setShowModal(true)}
          className="jumping-icon"
          alt="logo"
        />
        {showModal && <div className="modal">{displayKuralData()}</div>}
        {/* {showModal && <div className="modal" > <img
          // width={700}
          src={require("../../images/oldpaper.png")}
          alt="logo"
          style={{
            width: "200vh",
            height: "110vh",
            marginTop: "4vh",
            objectFit: "inherit",
          }}
        /></div>} */}
      </div>
    </div>
  );
};

export default Thirukural;
