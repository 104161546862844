import {
  ApartmentOutlined,
  BranchesOutlined,
  CalendarOutlined,
  ContactsOutlined,
  DashboardOutlined,
  FieldTimeOutlined,
  LikeOutlined,
  MailOutlined,
  MessageOutlined,
  MoneyCollectOutlined,
  PlusOutlined,
  RightOutlined,
  SendOutlined,
  UserDeleteOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Drawer,
  Input,
  List,
  Progress,
  Row,
  Select,
  Tabs,
  Timeline,
  Modal,
  Slider,
  Radio,
  Rate,
  Checkbox,
  notification,
} from "antd";
import Badge from "@mui/material/Badge";
import React, { useEffect, useState } from "react";
import "./dashboard.css";
import { url } from "../url/url";
import axios from "axios";
import moment from "moment";
import background1 from "../images/background1.webp"; // Assuming these are your images
import background3 from "../images/background3.jpg";
import { format, startOfWeek, endOfWeek, isSameDay, parse } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
const { TabPane } = Tabs;

const Managerdashboard = () => {
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [showReportees, setShowReportees] = useState(false);
  const [reportees, setReportees] = useState([]);
  const [subReportees, setSubReportees] = useState([]);
  const [name, setName] = useState([]);
  const [profile, setProfile] = useState([]);
  const [empcode, setEmpCode] = useState([]);
  const [department, setDepartment] = useState([]);
  const [Users, setUsers] = useState([]);
  const [employees, setEmployees] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [balance1, setAnnualbalance] = useState([]);
  const [balance2, setCasualbalance] = useState([]);
  const [balance3, setCompoffbalance] = useState([]);
  const [leaveType, setLeaveType] = useState([]);
  const [Casual, setCasual] = useState([]);
  const [Annual, setAnnual] = useState([]);
  const [compoff, setCompoff] = useState([]);
  const Navigate = useNavigate();
  const [showComments, setShowComments] = useState(false);
  const [messageindex, setMessageIndex] = useState();
  const [liked, setLiked] = useState(false);
  const [likesCount, setLikesCount] = useState(0);
  const [commentInput, setCommentInput] = useState("");
  const [comments, setComments] = useState([]);
  const [getComments, setGetComments] = useState([]);
  const [getMessages, setGetMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [text, setText] = useState(false);
  const [check, setCheck] = useState([]);
  const [checkInOut, setCheckInOut] = useState([]);
  const [holiday, setHoliday] = useState([]);
  const [isCheckedIn, setIsCheckedIn] = useState(false);
  const [checkedIn, setCheckedIn] = useState([]);
  const [count, setCount] = useState(0);
  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmitModalVisible, setIsSubmitModalVisible] = useState(false);
  const [isSubmitVisible, setIsSubmitVisible] = useState(false);
  const [survey, setSurvey] = useState([]);
  const [show, setShow] = useState([]);
  const [question, setQuestion] = useState([]);
  const [scaleValue, setScaleValue] = useState(0);
  const [selectedChoice, setSelectedChoice] = useState("");
  const [ratingValue, setRatingValue] = useState(0);
  const [additionalComments, setAdditionalComments] = useState("");
  const [claims, setClaims] = useState([]);
  const [Approval, setApproval] = useState([]);
  const [EmployeeData, setEmployeeData] = useState([]);
  const [disablecheckIn, setDisableCheckIn] = useState(false);
  const [disablecheckOut, setDisableCheckOut] = useState(false);
  const [checkInTime, setCheckInTime] = useState(null);
  const [checkOutTime, setCheckOutTime] = useState(null);
  const users = JSON.parse(localStorage.getItem("user"));

  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const [audience, setAudience] = useState("allEmployees"); // Default selection is "All employees"
  const [selectedEmployees, setSelectedEmployees] = useState([]); // For specific employee selection
  const [isIdentityChecked, setIsIdentityChecked] = useState(false); // To track if checkbox is checked
  const [isIdentityModalVisible, setIsIdentityModalVisible] = useState(false);
  const [empSurvey, setEmpSurvey] = useState([]);
  const fetchEmpSurvey = async () => {
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await axios.get(`${url}/get/empsurveys/${dbName}`);
      setEmpSurvey(response.data);
      console.log(response.data, "empsurvey");
    } catch {
      console.log("Error");
    }
  };

  const defaultImages = [background1, background1, background3];

  const getRandomBackgroundImage = () => {
    // Get a random index between 0 and the length of defaultImages array
    const randomIndex = Math.floor(Math.random() * defaultImages.length);
    return defaultImages[randomIndex];
  };

  // Open the modal when "+ Message" is clicked
  const showModal1 = () => {
    setIsModalVisible1(true);
  };

  // Handle modal close
  const handleCancel1 = () => {
    setIsModalVisible1(false);
    setMessage(""); // Reset the message
    setSelectedDepartment(null); // Reset the department selection
    setSelectedEmployees([]); // Reset employee selection
    setAudience("allEmployees"); // Reset audience to default
  };

  const monthsInYear = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const fetchleave = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const res = await axios.get(
        `${url}/leave/approval/${user.displayName}/manager/${dbName}`
      );
      setEmployeeData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchApproval = () => {
    const dbName = localStorage.getItem("dbName");
    fetch(
      `${url}/timesheet/manager/Submitted/${user.displayName}/manager/${dbName}`
    )
      .then((response) => response.json())
      .then((data) => setApproval(data))
      .catch((error) => console.error(error));
  };

  const fetchClaims = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const res = await axios.get(
        `${url}/claim/approval/${user.displayName}/manager/${dbName}`
      );
      setClaims(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const formatDateTime = (currentDate) => {
    if (!currentDate || !(currentDate instanceof Date)) {
      return "Invalid Date"; // Or handle the case appropriately
    }
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = currentDate.getFullYear();
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    // Format the date and time as desired
    const formattedDate = `${day}/${month}/${year}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;
    const formattedDateTime = `${formattedDate} ${formattedTime}`;
    return formattedDateTime;
  };

  console.log(question, "idn");

  const handleFormSubmit = async () => {
    // Scenario 1: If empIdentity is "Maintain anonymity"
    if (question.introduction.empIdentity === "Maintain anonymity") {
      console.log("anonomous");
      setIsIdentityChecked(false);
      postSurveyForm(); // Post as "Anonymous"
    }

    // Scenario 2: If empIdentity is "Identity"
    else if (question.introduction.empIdentity === "identity") {
      console.log("identity");

      setIsIdentityModalVisible(true); // Show the modal before submitting
    }

    // Scenario 3: If empIdentity is "Mandate identity disclosure"
    else if (
      question.introduction.empIdentity === "Mandate identity disclosure"
    ) {
      console.log("disclosure");
      setIsIdentityChecked(true);
      postSurveyForm(); // Post as user's display name
    }
  };

  // Function to post the survey form
  const postSurveyForm = async () => {
    const dbName = localStorage.getItem("dbName");
    const formValues = {
      userName: user.displayName, // Depending on the condition
      manager: user.manager,
      hr: user.hr,
      surveyName: question.introduction.name,
      questions: question.questions.map((data) => ({
        question: data.question,
        answer:
          data.type === "scale"
            ? [scaleValue]
            : data.type === "choice"
            ? [selectedChoice]
            : data.type === "rating"
            ? [ratingValue]
            : data.type === "comment"
            ? additionalComments
            : [],
      })),
      isIdentity: isIdentityChecked,
    };

    try {
      const response = await axios.post(
        `${url}/post/empsurveys/${dbName}`,
        formValues
      );
      notification.success({
        message: "Feedback posted successfully!",
        description: "Thank you for your valuable feedback!",
        placement: "topRight",
      });
      isIdentityModalVisible(false);
      setIsModalVisible(false);
      setIsSubmitModalVisible(false);
      setIsSubmitVisible(true);
      console.log(response.data, "Surveyform");
    } catch (error) {
      console.error("Error publishing survey:", error);
    }
  };

  const fetchSurvey = async () => {
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await axios.get(`${url}/get/surveys/${dbName}`);
      setSurvey(response.data);
    } catch {
      console.log("Error");
    }
  };
  const showModal = (survey) => {
    setIsModalVisible(true);
    setShow(survey);
    console.log(survey, "surveysmodal");
  };

  const handleSubmit = (survey) => {
    // setIsModalVisible(false);
    setIsSubmitModalVisible(true);
    setQuestion(survey);
  };

  const handleCloseSubmitModal = () => {
    setIsSubmitModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Close the modal
  const handleCancelSubmit = () => {
    setIsSubmitVisible(false); // Set modal visibility to false to close it
  };

  const tooltipStyle = {
    position: "absolute",
    top: "100%",
    left: 0,
    padding: "8px",
    backgroundColor: "#333",
    color: "#fff",
    borderRadius: "4px",
    boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
    zIndex: 10,
    whiteSpace: "nowrap",
    maxWidth: "200px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  const holidays2024 = holiday.filter((holiday) => {
    return holiday.date.startsWith("2024");
  });

  const holidaysByMonth = holidays2024?.reduce((acc, holiday) => {
    const month = new Date(holiday.date).toLocaleString("default", {
      month: "short",
    });
    if (!acc[month]) {
      acc[month] = [];
    }
    acc[month].push(holiday);
    return acc;
  }, {});

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };
  const fetchHolidays = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      const response = await axios.get(`${url}/get/leave/${dbName}`);
      console.log(response.data, "admindata");
      const Holidays = response.data.officeHolidaysData;
      setHoliday(Holidays);
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
    }
  };

  const checkForHoliday = (date) => {
    const Publicholiday = holiday.find((govt) =>
      moment(govt.date, "YYYY-MM-DD").isSame(moment(date, "YYYY-MM-DD"))
    );
    console.log(Publicholiday, "Public");

    return Publicholiday ? Publicholiday.title : null;
  };

  const currentDate1 = new Date();
  const currentMonth = currentDate1.getMonth() + 1; // Months are 0-indexed, so add 1
  const currentYear = currentDate1.getFullYear();
  console.log(holiday, "holiday");
  // Filter holidays for the current month
  const currentMonthHolidays = holiday?.filter((item) => {
    const holidayDate = new Date(item.date);
    const holidayMonth = holidayDate.getMonth() + 1;
    const holidayYear = holidayDate.getFullYear();

    return holidayMonth === currentMonth && holidayYear === currentYear;
  });

  const isHoliday = (dateStr) => {
    const date = parse(dateStr, "dd/MM/yyyy", new Date());
    return holiday.some((holidayItem) => {
      const holidayDate = parse(holidayItem.date, "dd/MM/yyyy", new Date());
      return isSameDay(date, holidayDate);
    });
  };

  const processCheckData = () => {
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const weekData = {};

    check?.forEach((entry) => {
      const checkInDate = new Date(
        entry.checkInTime
          .split(" ")[0]
          .split("/")
          .reverse()
          .join("-")
      ); // Convert to Date object
      const day = daysOfWeek[checkInDate.getDay()]; // Get the day of the week
      weekData[day] = entry;
    });

    return weekData;
  };

  const weekData = processCheckData();

  const handlePost = async () => {
    if (!message) {
      alert("Message cannot be empty");
      return;
    }

    let applicable = [];

    // Determine the value for the "Applicable" field based on the selected audience
    if (audience === "allEmployees") {
      applicable = ["All employees"]; // Set to "All employees"
    } else if (audience === "department") {
      if (!selectedDepartment) {
        alert("Please select a department.");
        return;
      }
      applicable = [selectedDepartment]; // Set to the selected department
    } else if (audience === "specificEmployees") {
      if (selectedEmployees.length === 0) {
        alert("Please select at least one employee.");
        return;
      }
      applicable = selectedEmployees; // Set to the selected employees
    }

    const dbName = localStorage.getItem("dbName");

    try {
      const response = await axios.post(`${url}/message/posts/${dbName}`, {
        message,
        user: user.displayName,
        applicable, // Add the applicable field to the request payload
      });

      setMessage("");
      setCount(count + 1);
      setText(!text);
      setIsModalVisible1(false); // Close the modal after successful post
    } catch (error) {
      console.error("Error posting message:", error);
      alert("Failed to post the message");
    }
  };

  const showMessage = () => {
    setText(!text);
  };
  const fetchLikes = async () => {
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await axios.get(
        `${url}/get/likes/${dbName}/${user.displayName}`
      );
      console.log("likes:", response.data);
      const count = response.data ? 1 : 0;
      setLikesCount(count);
      console.log(count, "count");
    } catch (error) {
      console.error("Error fetching likes:", error);
    }
  };

  const fetchMessages = async () => {
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await axios.get(`${url}/get/messages/${dbName}`);
      setGetMessages(response.data);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  const fetchComments = async () => {
    const dbName = localStorage.getItem("dbName");
    try {
      // Get an array of message IDs
      const messageIds = getMessages.map((message) => message._id);

      // Initialize an array to hold all comments
      let allComments = [];

      // Loop through each message ID and fetch comments
      for (const messageId of messageIds) {
        const response = await axios.get(
          `${url}/get/comment/${dbName}/${messageId}`
        );
        console.log(response.data, `comments for messageId: ${messageId}`);

        // Add fetched comments to the allComments array
        allComments = allComments.concat(response.data);
      }

      // Filter comments that match the message IDs (this might not be necessary if your API returns only relevant comments)
      const filteredComments = allComments.filter((comment) =>
        messageIds.includes(comment.messageId)
      );

      // Update the state with the filtered comments
      setGetComments(filteredComments);
      console.log(filteredComments, "filteredComments");
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };
  const handleLikeClick = async (message) => {
    console.log("liked");

    const newLikeState = !liked;
    const dbName = localStorage.getItem("dbName");
    const encodedMessage = encodeURIComponent(message);
    try {
      const response = await axios.post(
        `${url}/api/posts/like/${dbName}/${encodedMessage}`,
        {
          likes: newLikeState,
          userName: user.displayName,
        }
      );

      console.log(response.data, "likescount");
      setLiked(newLikeState);
      setCount(count + 1);
    } catch (error) {
      console.error("Error toggling like:", error);
    }
  };

  const handleCommentSubmit = async (message) => {
    const dbName = localStorage.getItem("dbName");
    if (commentInput.trim()) {
      const newComment = {
        userName: user.displayName,
        comment: commentInput,
        createdAt: new Date().toISOString(),
      };
      const encodedMessage = encodeURIComponent(message);
      try {
        const response = await axios.post(
          `${url}/api/posts/comment/${dbName}/${encodedMessage}`,
          newComment
        );
        setComments(response.data.comments);
        setCommentInput("");
        setCount(count + 1);
      } catch (error) {
        console.error("Error posting comment:", error);
      }
    }
  };

  const getWeekDates = () => {
    const startOfWeek = moment().startOf("week"); // Start of current week (Sunday)
    const weekDates = [];

    for (let i = 0; i < 7; i++) {
      weekDates.push(
        startOfWeek
          .clone()
          .add(i, "days")
          .format("DD/MM/YYYY")
      );
    }

    return weekDates;
  };
  const handleComments = (index) => {
    setShowComments(!showComments);
    setMessageIndex(index);
    console.log(showComments, "comments");
  };
  const handleReporteeClick = (name, image, empcode) => {
    handleSubreportees(name);
    setName(name);
    setProfile(image);
    setEmpCode(empcode);
    setShowReportees(true);
  };
  useEffect(() => {
    fetchReportees();
    fetchDepartment();
    fetchData();
    fetchLikes();
    fetchHolidays();
    fetchApproval();
    fetchleave();
    fetchClaims();
  }, []);

  useEffect(() => {
    fetchSurvey();
    fetchEmpSurvey();
    fetchMessages();
    fetchComments();
  }, [count]);

  const fetchReporteeCheckInData = async (reporteeName) => {
    const dbName = localStorage.getItem("dbName");
    const currentDate = new Date().toISOString().slice(0, 10);
    console.log(reporteeName, "reportee");

    try {
      const response = await fetch(
        `${url}/api/checkinout/${dbName}/${currentDate}/${reporteeName}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch check-in/out data for reportee");
      }
      const data = await response.json();
      if (data.checkInOutEntries && data.checkInOutEntries.length > 0) {
        setIsCheckedIn(true);
        setCheckedIn((prevState) => ({
          ...prevState,
          [reporteeName]: data.checkInOutEntries,
        }));
      } else {
        setIsCheckedIn(false);
        setCheckedIn((prevState) => ({
          ...prevState,
          [reporteeName]: [],
        }));
      }
      console.log(data.checkInOutEntries, "reporteeCheck");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchReportees();
  }, []);
  useEffect(() => {
    const fetchData = (employees) => {
      employees.forEach((emp) => {
        fetchReporteeCheckInData(emp.displayName);
      });
    };
    console.log(reportees, "reportess");

    if (reportees && reportees.length > 0) {
      console.log(reportees, "reportess");
      fetchData(reportees);
    } else if (hrEmployees && hrEmployees.length > 0) {
      fetchData(hrEmployees);
    } else if (teamLeaderEmployees && teamLeaderEmployees.length > 0) {
      fetchData(teamLeaderEmployees);
    } else if (managerEmployees && managerEmployees.length > 0) {
      fetchData(managerEmployees);
    } else {
      fetchReporteeCheckInData(user.displayName);
    }
  }, [reportees]);

  const fetchReportees = async () => {
    const dbName = localStorage.getItem("dbName");
    const response = await axios.get(
      `${url}/api/get/emplist/${user.displayName}/manager/${dbName}`
    );
    const activeUsers = response.data.filter(
      (user) => user.empStatus !== "inactive"
    );
    setReportees(activeUsers);
    console.log(activeUsers, "activeusers");
  };

  const handleSubreportees = async (name) => {
    const dbName = localStorage.getItem("dbName");
    const response = await axios.get(
      `${url}/api/get/emplist/${name}/manager/${dbName}`
    );
    const activeUsers = response.data.filter(
      (user) => user.empStatus !== "inactive"
    );
    setSubReportees(activeUsers);
  };

  const fetchDepartment = async () => {
    const dbName = localStorage.getItem("dbName");
    const response = await axios.get(`${url}/api/get/allemplist/${dbName}`);
    const activeUsers = response.data.filter(
      (user) => user.empStatus !== "inactive"
    );

    const uniqueDepartments = [
      ...new Set(activeUsers.map((user) => user.department)),
    ];
    const userNames = activeUsers.map((item) => item.displayName);
    setDepartment(uniqueDepartments);
    setUsers(userNames);
    setEmployees(activeUsers); // Store all active employees
  };

  const filteredEmployees = employees.filter(
    (employee) => employee.department === user.department
  );

  const hrEmployees = filteredEmployees.filter(
    (employee) => employee.jobTitle === "hr"
  );
  const managerEmployees = filteredEmployees.filter(
    (employee) =>
      employee.jobTitle === "manager" || employee.jobTitle === "admin"
  );
  const teamLeaderEmployees = filteredEmployees.filter(
    (employee) => employee.jobTitle === "employee"
  );
  console.log(
    filteredEmployees,
    hrEmployees,
    managerEmployees,
    teamLeaderEmployees,
    "department"
  );

  const fetchData = async () => {
    try {
      const dbName = localStorage.getItem("dbName");
      console.log("dbName in empleavereport in empleaveform", dbName);

      // Fetch user manager information
      const response = await axios.get(
        `${url}/api/getUserManager/${user.displayName}/${dbName}`
      );
      const empCode = response.data.user.empCode;

      // Fetch leave report data
      const leaveRes = await axios.get(
        `${url}/leave/repo/${empCode}/2024/${dbName}`
      );

      // Filter annual and casual leaves
      const annual = leaveRes.data.filter((item) => item.type === "annual");
      const casual = leaveRes.data.filter((item) => item.type === "casual");

      // Calculate total leave days for annual and casual leaves
      const totalAnnualDays = annual.reduce((sum, leave) => {
        return sum + leave.leavedays.reduce((a, b) => a + parseFloat(b, 10), 0);
      }, 0);

      const totalCasualDays = casual.reduce((sum, leave) => {
        return sum + leave.leavedays.reduce((a, b) => a + parseFloat(b, 10), 0);
      }, 0);

      console.log("Total Annual Days:", totalAnnualDays);
      console.log("Total Casual Days:", totalCasualDays);

      // Fetch leave balances
      const leaveBalanceRes = await axios.get(
        `${url}/send/Leavebalance/${empCode}/${dbName}`
      );
      const availableAnnualLeave = leaveBalanceRes.data.annualLeaveBal;
      const availableCasualLeave = leaveBalanceRes.data.casualLeaveBal;
      const availableCompoffLeave = leaveBalanceRes.data.compoffBal;

      console.log("Available Annual Leave:", availableAnnualLeave);
      console.log("Available Casual Leave:", availableCasualLeave);
      console.log("Available Compoff Leave:", availableCompoffLeave);

      // Fetch compoff data
      const compoffRes = await axios.get(
        `${url}/compoff/leavebalance/${empCode}/2024/${dbName}`
      );
      const compoffLeave = compoffRes.data.CompLeave;

      console.log("Comp off Leave:", compoffLeave.length);

      // Fetch leave types
      const leaveTypeResponse = await axios.get(`${url}/get/leave/${dbName}`);
      const fetchedLeaveTypes = leaveTypeResponse.data.leaveType.map(
        (leave) => {
          let available = 0;
          let booked = 0;

          // Determine available and booked values based on the leave type
          switch (leave.type) {
            case "casual":
              available = availableCasualLeave;
              booked = totalCasualDays;
              break;
            case "annual":
              available = availableAnnualLeave;
              booked = totalAnnualDays;
              break;
            case "compoff":
              available = availableCompoffLeave;
              booked = compoffLeave.length;
              break;
            default:
              break;
          }

          // Return the fetched leave object with the calculated available and booked values
          return {
            type: leave.type,
            name: leave.type,
            icon: (
              <CalendarOutlined
                style={{
                  color: "#fa077d",
                  fontSize: "25px",
                  border: "1px solid #ccc",
                  padding: "10px",
                  borderRadius: "20%",
                  background: "pink",
                }}
              />
            ),
            available,
            booked,
            borderColor: "#fa077d",
          };
        }
      );

      // Set leave type with both hardcoded and fetched data
      setLeaveType([...fetchedLeaveTypes]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLeave = (name) => {
    Navigate(`/home/empleaveapplyform?type=${name}`);
  };

  const handleCheckIn = async () => {
    // setCheckedIn(true);
    const currentDate = new Date();
    setCheckInTime(currentDate);
    setCheckOutTime(null);
    await postCheckInData(currentDate);
  };

  const handleCheckOut = async () => {
    const currentDate = new Date();
    setCheckOutTime(currentDate);
    await postCheckOutData(currentDate);
  };

  const postCheckInData = async (checkInTime) => {
    const formattedCheckInTime = formatDateTime(checkInTime);
    console.log(formattedCheckInTime, "formattedCheckInTime");
    const postData = {
      userName: users.displayName,
      checkInTime: formattedCheckInTime,
    };
    const dbName = localStorage.getItem("dbName");
    console.log(postData, "post");
    try {
      const response = await fetch(`${url}/api/checkin/${dbName}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      });
      if (!response.ok) {
        throw new Error("Failed to save check-in time");
      }
      const data = await response.json();
      console.log(data.message);
      setCount(count + 1);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const postCheckOutData = async (checkOutTime) => {
    const formattedCheckOutTime = formatDateTime(checkOutTime);
    const postData = {
      userName: users.displayName,
      checkOutTime: formattedCheckOutTime,
    };
    const dbName = localStorage.getItem("dbName");
    try {
      const response = await fetch(`${url}/api/checkout/${dbName}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      });
      if (!response.ok) {
        throw new Error("Failed to save check-out time");
      }
      const data = await response.json();
      console.log(data.message); // Log success message
      setCount(count + 1);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    // Function to fetch check-in and check-out times for the current date
    const fetchCheckInOutData = async () => {
      const dbName = localStorage.getItem("dbName");
      const currentDate = new Date().toISOString().slice(0, 10);
      try {
        const response = await fetch(
          `${url}/api/checkinout/${dbName}/${currentDate}/${user.displayName}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch check-in/out data");
        }
        const data = await response.json();
        console.log(data.checkInOutEntries, "dat");
        setCheckInOut(data.checkInOutEntries);

        // Check if data exists for the current date and user
        if (data.checkInOutEntries[0].checkInTime) {
          setDisableCheckIn(true);
        } else {
          setDisableCheckIn(false);
        }
        if (data.checkInOutEntries[0].checkOutTime) {
          setDisableCheckOut(true);
        } else {
          setDisableCheckOut(false);
        }
      } catch (error) {
        console.error("Error:", error);
        setCheckInOut([]);
      }
    };

    fetchCheckInOutData();
  }, [count]);

  useEffect(() => {
    const fetchCheckInOutData = async () => {
      const dbName = localStorage.getItem("dbName");
      const currentDate = new Date();
      const firstDayOfWeek = new Date(
        currentDate.setDate(currentDate.getDate() - currentDate.getDay())
      );
      const lastDayOfWeek = new Date(
        currentDate.setDate(firstDayOfWeek.getDate() + 6)
      );

      const startDate = firstDayOfWeek.toISOString().slice(0, 10);
      const endDate = lastDayOfWeek.toISOString().slice(0, 10);

      try {
        const response = await fetch(
          `${url}/checkinoutByCurrentweek/${dbName}/${startDate}/${endDate}/${user.displayName}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch check-in/out data");
        }
        const data = await response.json();
        console.log(data.checkInOutEntries, "week data");
        setCheck(data.checkInOutEntries);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchCheckInOutData();
  }, []);

  const currentDate = new Date();

  // Calculate the start of the week (Monday)
  const startOfWeek = new Date(currentDate);
  const dayOfWeek = startOfWeek.getDay();
  const diffToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1; // Adjust if Sunday
  startOfWeek.setDate(currentDate.getDate() - diffToMonday);

  // Calculate the end of the week (Sunday)
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);
  const weekDates = getWeekDates();

  console.log(weekDates[0], "week");
  const getTabStyle = (key) => ({
    border: activeTabKey === key ? "2px solid #ff5733" : "1px solid #a17af5",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    borderRadius: "20%",
    padding: "5px",
  });
  return (
    <div>
      <>
        <Card style={{ zoom: "90%", marginTop: "10vh" }}>
          <Row style={{ gap: "20vh" }}>
            <Col>
              {user.profileImage ? (
                <img
                  src={user.profileImage}
                  alt={`${user.firstName} ${user.lastName}`}
                  style={{
                    width: "120px",
                    height: "120px",
                    borderRadius: "20%",
                    objectFit: "cover",
                    top: "-90px",
                    right: "-110px",
                    position: "absolute",
                  }}
                />
              ) : (
                <img
                  src={
                    user.gender === "female"
                      ? require("../images/femalegeneric.png")
                      : require("../images/malegenric.jpg")
                  }
                  style={{
                    width: "140px",
                    height: "140px",
                    borderRadius: "20%",
                    objectFit: "cover",
                    top: "-90px",
                    right: "-110px",
                    position: "absolute",
                  }}
                />
              )}
            </Col>
            <Col>
              <h6 style={{ fontWeight: "600" }}>
                {user?.empCode} - {user?.displayName}
              </h6>
              <p style={{ color: "#666" }}>
                {user?.empDesignation?.charAt(0).toUpperCase() +
                  user?.empDesignation?.slice(1).toLowerCase()}
              </p>
            </Col>
            <Col>
              {/* {checkedIn[user.displayName]?.length > 0 ? (
                                  <>
                                      <p style={{ color: "green" }}>Checked In</p>
                                      <p style={{ color: "#666" }}>
                                          {checkedIn[user.displayName][0]?.checkInTime}
                                      </p>
                                  </>
                              ) : (
                                  <p style={{ color: "red" }}>Yet to check-in</p>
                              )} */}
              <Col>
                {/* Show Check-in Button if no check-in for today */}
                {!checkInOut[0]?.checkInTime ? (
                  <Row>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        onClick={handleCheckIn}
                        disabled={disablecheckIn}
                        style={{ color: disablecheckIn ? "gray" : "red" }}
                      >
                        Check-in
                      </Button>
                    </div>
                  </Row>
                ) : (
                  <Row>
                    {console.log(checkInOut, "check")}
                    <h7 style={{ color: "green" }}>Checked In</h7>
                    <p style={{ alignItems: "center" }}>
                      {checkInOut[0]?.checkInTime?.split(" ")[1]}
                    </p>
                  </Row>
                )}

                {/* Show Check-out Button if user has checked in but not yet checked out for today */}
                {!checkInOut[0]?.checkOutTime && checkInOut[0]?.checkInTime ? (
                  <Row>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        onClick={handleCheckOut}
                        disabled={disablecheckOut}
                        style={{ color: disablecheckOut ? "gray" : "red" }}
                      >
                        Check-out
                      </Button>
                    </div>
                  </Row>
                ) : (
                  checkInOut[0]?.checkInTime && (
                    <Row>
                      <h7 style={{ color: "green" }}>Checked Out</h7>
                      <p style={{ alignItems: "center" }}>
                        {checkInOut[0]?.checkOutTime?.split(" ")[1]}
                      </p>
                    </Row>
                  )
                )}
              </Col>
            </Col>
            {/* <Col>
  
                              <span>Good Evening Emily jones</span>
                              <p>Have a productive day!</p>
                              <div style={{ fontFamily: 'Arial, sans-serif' }}>
                                  <p>
                                      "Develop success from failures. Discouragement and failure are two of the surest stepping stones to success."
                                      <br />
                                      <span style={{ fontStyle: 'italic' }}>- Dale Carnegie</span>
                                  </p>
                              </div>
  
                          </Col> */}
            <Col style={{ marginLeft: "30vh" }}>
              <h6>Reporting To</h6>
              <p>{user.manager}</p>
            </Col>
          </Row>
          <Divider />
          <Tabs defaultActiveKey="1" type="card" onChange={setActiveTabKey}>
            <TabPane
              tab={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginLeft: "15vh",
                  }}
                >
                  <div style={getTabStyle("1")}>
                    <img
                      src={require("../images/quicklinks.png")}
                      style={{ width: "3vh", height: "3vh" }}
                    />
                  </div>
                  <span style={{ marginTop: "1vh" }}>Quick links</span>
                </div>
              }
              key="1"
            />
            <TabPane
              tab={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginLeft: "15vh",
                  }}
                >
                  <div style={getTabStyle("2")}>
                    <img
                      src={require("../images/user.png")}
                      style={{ width: "3vh", height: "3vh" }}
                    />
                  </div>
                  <span style={{ marginTop: "1vh" }}>Profile</span>
                </div>
              }
              key="2"
            />
            {user.jobTitle !== "employee" && (
              <TabPane
                tab={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginLeft: "15vh",
                    }}
                  >
                    <div style={getTabStyle("3")}>
                      <img
                        src={require("../images/reportees.png")}
                        style={{ width: "3vh", height: "3vh" }}
                      />
                    </div>
                    <span style={{ marginTop: "1vh" }}>Reportees</span>
                  </div>
                }
                key="3"
              />
            )}
            <TabPane
              tab={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginLeft: "15vh",
                  }}
                >
                  <div style={getTabStyle("4")}>
                    <img
                      src={require("../images/supervisor.png")}
                      style={{ width: "3vh", height: "3vh" }}
                    />
                  </div>
                  <span style={{ marginTop: "1vh" }}>Department</span>
                </div>
              }
              key="4"
            />
            <TabPane
              tab={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginLeft: "15vh",
                  }}
                >
                  <div style={getTabStyle("5")}>
                    <img
                      src={require("../images/employeeleave.png")}
                      style={{ width: "3vh", height: "3vh" }}
                    />
                  </div>
                  <span style={{ marginTop: "1vh" }}>Leave</span>
                </div>
              }
              key="5"
            />
            <TabPane
              tab={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginLeft: "15vh",
                  }}
                >
                  <div style={getTabStyle("6")}>
                    <img
                      src={require("../images/activity.png")}
                      style={{ width: "3vh", height: "3vh" }}
                    />
                  </div>
                  <span style={{ marginTop: "1vh" }}>Activity</span>
                </div>
              }
              key="6"
            />

            <TabPane
              tab={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginLeft: "15vh",
                  }}
                >
                  <div style={getTabStyle("7")}>
                    <img
                      src={require("../images/chat.png")}
                      style={{ width: "3vh", height: "3vh" }}
                    />
                  </div>
                  <span style={{ marginTop: "1vh" }}>Post</span>
                </div>
              }
              key="7"
            />
            <TabPane
              tab={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginLeft: "15vh",
                  }}
                >
                  <div style={getTabStyle("8")}>
                    <img
                      src={require("../images/promotion.png")}
                      style={{ width: "5vh", height: "5vh" }}
                    />
                  </div>
                  <span style={{ marginTop: "1vh" }}>Announcement</span>
                </div>
              }
              key="8"
            />
          </Tabs>
          {/* </Card>
          <Card style={{ marginTop: "5px", background: "#f2f5f3" }}> */}
          {activeTabKey === "1" && (
            <>
              <Row justify="center" style={{ gap: "7vh" }}>
                {user.jobTitle !== "employee" && (
                  <Col>
                    <h6 className="text-center" style={{ color: "#9a1f91" }}>
                      Approvals
                    </h6>
                    <Card
                      style={{
                        marginBottom: "2vh",
                        width: "36vh",
                        height: "33vh",
                        border: "1px solid #fa7a92",
                      }}
                    >
                      <Row
                        gutter={[16, 16]}
                        justify="center"
                        style={{ gap: "4vh" }}
                      >
                        <div style={{ textAlign: "center" }}>
                          <Col>
                            <Link to="/home/claimapproval">
                              <Badge
                                color="error"
                                badgeContent={claims.length}
                                max={5}
                                style={{ marginBottom: "8px" }}
                              >
                                <img
                                  src={require("../images/report.png")}
                                  style={{
                                    width: "8vh",
                                    height: "8vh",
                                    border: "2px solid #ccc",
                                    padding: "5px",
                                    boxShadow: "0 3px 3px #9a1f91",
                                    borderRadius: "50%",
                                  }}
                                  alt="Claims Approval"
                                />
                              </Badge>
                              <p style={{ fontSize: "12px", margin: 0 }}>
                                Claims
                              </p>
                            </Link>
                          </Col>
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <Col>
                            <Link to="/home/managerapproval">
                              <Badge
                                color="error"
                                badgeContent={
                                  EmployeeData.length > 0
                                    ? EmployeeData.length
                                    : 0
                                }
                                max={5}
                                style={{ marginBottom: "8px" }}
                              >
                                <img
                                  src={require("../images/logout.png")}
                                  style={{
                                    width: "8vh",
                                    height: "8vh",
                                    border: "2px solid #ccc",
                                    padding: "5px",
                                    boxShadow: "0 3px 3px #9a1f91",
                                    borderRadius: "50%",
                                  }}
                                  alt="Leave Approval"
                                />
                              </Badge>
                              <p style={{ fontSize: "12px", margin: 0 }}>
                                Leave
                              </p>
                            </Link>
                          </Col>
                        </div>
                      </Row>
                      <Row
                        gutter={[16, 16]}
                        justify="center"
                        style={{ gap: "1vh", marginTop: "1vh" }}
                      >
                        <div style={{ textAlign: "center" }}>
                          <Col>
                            <Link to="/home/pending">
                              <Badge
                                color="error"
                                badgeContent={Approval.length}
                                max={5}
                                style={{ marginBottom: "8px" }}
                              >
                                <img
                                  src={require("../images/timetable.png")}
                                  style={{
                                    width: "8vh",
                                    height: "8vh",
                                    border: "2px solid #ccc",
                                    padding: "5px",
                                    boxShadow: "0 3px 3px #9a1f91",
                                    borderRadius: "50%",
                                  }}
                                  alt="Timesheet Approval"
                                />
                              </Badge>
                              <p style={{ fontSize: "11px", margin: 0 }}>
                                Timesheet
                              </p>
                            </Link>
                          </Col>
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <Col>
                            <Link to="/home/compoff">
                              <Badge
                                color="error"
                                badgeContent={compoff.length}
                                max={5}
                                style={{ marginBottom: "8px" }}
                              >
                                <img
                                  src={require("../images/late.png")}
                                  style={{
                                    width: "8vh",
                                    height: "8vh",
                                    border: "2px solid #ccc",
                                    padding: "5px",
                                    boxShadow: "0 3px 3px #9a1f91",
                                    borderRadius: "50%",
                                  }}
                                  alt="Compoff Approval"
                                />
                              </Badge>
                              <p style={{ fontSize: "12px", margin: 0 }}>
                                Compoff
                              </p>
                            </Link>
                          </Col>
                        </div>
                      </Row>
                    </Card>
                  </Col>
                )}
                <Col>
                  <h6 className="text-center" style={{ color: "#4287f5" }}>
                    Requests
                  </h6>
                  <Card
                    style={{
                      marginBottom: "2vh",
                      width: "36vh",
                      height: "32vh",
                      border: "1px solid #fa7a92",
                    }}
                  >
                    <Row
                      gutter={[16, 16]}
                      justify="center"
                      style={{ gap: "4vh" }}
                    >
                      <div style={{ textAlign: "center" }}>
                        <Col>
                          <Link to="/home/claims">
                            <img
                              src={require("../images/claims1.png")}
                              style={{
                                width: "8vh",
                                height: "8vh",
                                border: "2px solid #ccc",
                                padding: "5px",
                                boxShadow: "0 3px 3px #4287f5",
                                borderRadius: "50%",
                              }}
                              alt="Claims Approval"
                            />
                            <p style={{ fontSize: "12px", margin: 0 }}>
                              Claims
                            </p>
                          </Link>
                        </Col>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <Col>
                          <Link to="/home/empleaveapplyform">
                            <img
                              src={require("../images/leave1.png")}
                              style={{
                                width: "8vh",
                                height: "8vh",
                                border: "2px solid #ccc",
                                padding: "5px",
                                boxShadow: "0 3px 3px #4287f5",
                                borderRadius: "50%",
                              }}
                              alt="Leave Approval"
                            />
                            <p style={{ fontSize: "12px", margin: 0 }}>Leave</p>
                          </Link>
                        </Col>
                      </div>
                    </Row>
                    <Row
                      gutter={[16, 16]}
                      justify="center"
                      style={{ gap: "1vh", marginTop: "1vh" }}
                    >
                      <div style={{ textAlign: "center" }}>
                        <Col>
                          <Link to="/home/timesheet">
                            <img
                              src={require("../images/server.png")}
                              style={{
                                width: "8vh",
                                height: "8vh",
                                border: "2px solid #ccc",
                                padding: "5px",
                                boxShadow: "0 3px 3px #4287f5",
                                borderRadius: "50%",
                              }}
                              alt="Timesheet Approval"
                            />
                            <p style={{ fontSize: "11px", margin: 0 }}>
                              Timesheet
                            </p>
                          </Link>
                        </Col>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <Col>
                          <Link to="/home/empleavebalrequest">
                            <img
                              src={require("../images/compoff3.png")}
                              style={{
                                width: "8vh",
                                height: "8vh",
                                border: "2px solid #ccc",
                                padding: "5px",
                                boxShadow: "0 3px 3px #4287f5",
                                borderRadius: "50%",
                              }}
                              alt="Compoff Approval"
                            />
                            <p style={{ fontSize: "12px", margin: 0 }}>
                              Compoff
                            </p>
                          </Link>
                        </Col>
                      </div>
                    </Row>
                  </Card>
                </Col>
                <Col>
                  <h6 className="text-center" style={{ color: "#9563ff" }}>
                    Reports
                  </h6>
                  <Card
                    style={{
                      marginBottom: "2vh",
                      width: "36vh",
                      height: "32vh",
                      border: "1px solid #fa7a92",
                    }}
                  >
                    <Row
                      gutter={[16, 16]}
                      justify="center"
                      style={{ gap: "4vh" }}
                    >
                      <div style={{ textAlign: "center" }}>
                        <Col>
                          <Link to="/home/claimstatus">
                             
                              <img
                                src={require("../images/claimreport1.png")}
                                style={{
                                  width: "8vh",
                                  height: "8vh",
                                  border: "2px solid #ccc",
                                  padding: "5px",
                                  boxShadow: "0 3px 3px #9563ff",
                                  borderRadius: "50%",
                                }}
                                alt="Claims"
                              />
                            <p style={{ fontSize: "12px", margin: 0 }}>
                              Claims
                            </p>
                          </Link>
                        </Col>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <Col>
                          <Link to="/home/EmpLeaveReport">
                           
                              <img
                                src={require("../images/leavereport1.png")}
                                style={{
                                  width: "8vh",
                                  height: "8vh",
                                  border: "2px solid #ccc",
                                  padding: "7px",
                                  boxShadow: "0 3px 3px #9563ff",
                                  borderRadius: "50%",
                                }}
                                alt="Leave Approval"
                              />
                            <p style={{ fontSize: "12px", margin: 0 }}>Leave</p>
                          </Link>
                        </Col>
                      </div>
                    </Row>
                    <Row
                      gutter={[16, 16]}
                      justify="center"
                      style={{ gap: "1vh", marginTop: "1vh" }}
                    >
                      <div style={{ textAlign: "center" }}>
                        <Col>
                          <Link to="/home/timesheetsummary">
                           
                              <img
                                src={require("../images/image19.png")}
                                style={{
                                  width: "8vh",
                                  height: "8vh",
                                  border: "2px solid #ccc",
                                  padding: "5px",
                                  boxShadow: "0 3px 3px #9563ff",
                                  borderRadius: "50%",
                                }}
                                alt="Timesheet Approval"
                              />
                            <p style={{ fontSize: "11px", margin: 0 }}>
                              Timesheet
                            </p>
                          </Link>
                        </Col>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <Col>
                          <Link to="/home/EmpLeaveReport">
                           
                            <img
                              src={require("../images/image18.png")}
                              style={{
                                width: "8vh",
                                height: "8vh",
                                border: "2px solid #ccc",
                                padding: "5px",
                                boxShadow: "0 3px 3px #9563ff",
                                borderRadius: "50%",
                              }}
                              alt="Compoff Approval"
                            />
                            <p style={{ fontSize: "12px", margin: 0 }}>
                              Compoff
                            </p>
                          </Link>
                        </Col>
                      </div>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </>
          )}
          {activeTabKey === "2" && (
            <>
              <Card style={{ marginTop: "5vh" }}>
                <h6>Basic Information</h6>
                <Row justify="space-between" style={{ marginTop: "6vh" }}>
                  <Col span={11}>
                    <Row
                      style={{
                        gap: "27vh",
                        borderBottom: "1px solid #ccc", // Define the border thickness, style, and color
                        alignItems: "center",
                      }}
                    >
                      <h6 style={{ color: "#666" }}>Employee ID</h6>
                      <h6>{user.empCode}</h6>
                    </Row>
                  </Col>
                  <Col span={11}>
                    <Row
                      style={{
                        gap: "28vh",
                        borderBottom: "1px solid #ccc", // Define the border thickness, style, and color
                        alignItems: "center",
                      }}
                    >
                      <h6 style={{ color: "#666" }}>Email</h6>
                      <h6>{user.email}</h6>
                    </Row>
                  </Col>
                </Row>
                <Row justify="space-between" style={{ marginTop: "5vh" }}>
                  <Col span={11}>
                    <Row
                      style={{
                        gap: "30vh",
                        borderBottom: "1px solid #ccc", // Define the border thickness, style, and color
                        alignItems: "center",
                      }}
                    >
                      <h6 style={{ color: "#666" }}>FirstName</h6>
                      <h6>{user.firstName}</h6>
                    </Row>
                  </Col>
                  <Col span={11}>
                    <Row
                      style={{
                        gap: "22vh",
                        borderBottom: "1px solid #ccc", // Define the border thickness, style, and color
                        alignItems: "center",
                      }}
                    >
                      <h6 style={{ color: "#666" }}>LastName</h6>
                      <h6>{user.lastName}</h6>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col></Col>
                  <Col></Col>
                </Row>
              </Card>
              <Card style={{ marginTop: "5vh" }}>
                <h6>Work Information</h6>
                <Row justify="space-between" style={{ marginTop: "6vh" }}>
                  <Col span={11}>
                    <Row
                      style={{
                        gap: "29vh",
                        borderBottom: "1px solid #ccc", // Define the border thickness, style, and color
                        alignItems: "center",
                      }}
                    >
                      <h6 style={{ color: "#666" }}>Department</h6>
                      <h6>{user.department}</h6>
                    </Row>
                  </Col>
                  <Col span={11}>
                    <Row
                      style={{
                        gap: "36vh",
                        borderBottom: "1px solid #ccc", // Define the border thickness, style, and color
                        alignItems: "center",
                      }}
                    >
                      <h6 style={{ color: "#666" }}>Role</h6>
                      <h6>{user.jobTitle}</h6>
                    </Row>
                  </Col>
                </Row>
                <Row justify="space-between" style={{ marginTop: "5vh" }}>
                  <Col span={11}>
                    <Row
                      style={{
                        gap: "25vh",
                        borderBottom: "1px solid #ccc", // Define the border thickness, style, and color
                        alignItems: "center",
                      }}
                    >
                      <h6 style={{ color: "#666" }}>EmployeeType</h6>
                      <h6>{user.empType}</h6>
                    </Row>
                  </Col>
                  <Col span={11}>
                    <Row
                      style={{
                        gap: "18vh",
                        borderBottom: "1px solid #ccc", // Define the border thickness, style, and color
                        alignItems: "center",
                      }}
                    >
                      <h6 style={{ color: "#666" }}>Company Laptop</h6>
                      <h6>{user.companyLaptop}</h6>
                    </Row>
                  </Col>
                </Row>
                <Row justify="space-between" style={{ marginTop: "5vh" }}>
                  <Col span={11}>
                    <Row
                      style={{
                        gap: "23vh",
                        borderBottom: "1px solid #ccc", // Define the border thickness, style, and color
                        alignItems: "center",
                      }}
                    >
                      <h6 style={{ color: "#666" }}>EmployeeStatus</h6>
                      <h6>{user.empStatus}</h6>
                    </Row>
                  </Col>
                  <Col span={11}>
                    <Row
                      style={{
                        gap: "22vh",
                        borderBottom: "1px solid #ccc", // Define the border thickness, style, and color
                        alignItems: "center",
                      }}
                    >
                      <h6 style={{ color: "#666" }}>Date of Joining</h6>
                      <h6>{user.joinDate}</h6>
                    </Row>
                  </Col>
                </Row>
                <Row justify="space-between" style={{ marginTop: "5vh" }}>
                  <Col span={11}>
                    <Row
                      style={{
                        gap: "42vh",
                        borderBottom: "1px solid #ccc", // Define the border thickness, style, and color
                        alignItems: "center",
                      }}
                    >
                      <h6 style={{ color: "#666" }}>HR</h6>
                      <h6>{user.hrName}</h6>
                    </Row>
                  </Col>
                  <Col span={11}>
                    <Row
                      style={{
                        gap: "26vh",
                        borderBottom: "1px solid #ccc", // Define the border thickness, style, and color
                        alignItems: "center",
                      }}
                    >
                      <h6 style={{ color: "#666" }}>Designation</h6>
                      <h6>{user.empDesignation}</h6>
                    </Row>
                  </Col>
                </Row>
              </Card>
              <Card style={{ marginTop: "5vh" }}>
                <h6>Hierarchy Information</h6>
                <Row justify="space-between" style={{ marginTop: "6vh" }}>
                  <Col span={24}>
                    <Row
                      style={{
                        gap: "78vh",
                        borderBottom: "1px solid #ccc", // Define the border thickness, style, and color
                        alignItems: "center",
                      }}
                    >
                      <h6 style={{ color: "#666" }}>Reporting Manager</h6>
                      <h6>{user.manager}</h6>
                    </Row>
                  </Col>
                  <Col span={24} style={{ marginTop: "6vh" }}>
                    <Row
                      style={{
                        gap: "101vh",
                        borderBottom: "1px solid #ccc", // Define the border thickness, style, and color
                        alignItems: "center",
                      }}
                    >
                      <h6 style={{ color: "#666" }}>HR</h6>
                      <h6>{user.hrName}</h6>
                    </Row>
                  </Col>
                </Row>
              </Card>

              <Card style={{ marginTop: "5vh" }}>
                <h6>Personal Details</h6>
                <Row justify="space-between" style={{ marginTop: "6vh" }}>
                  <Col span={7}>
                    <Row
                      style={{
                        gap: "20vh",
                        borderBottom: "1px solid #ccc", // Define the border thickness, style, and color
                        alignItems: "center",
                      }}
                    >
                      <h6 style={{ color: "#666" }}>Date of Birth</h6>
                      <h6>{user.dob}</h6>
                    </Row>
                  </Col>
                  <Col span={7}>
                    <Row
                      style={{
                        gap: "26vh",
                        borderBottom: "1px solid #ccc", // Define the border thickness, style, and color
                        alignItems: "center",
                      }}
                    >
                      <h6 style={{ color: "#666" }}>Gender</h6>
                      <h6>{user.gender}</h6>
                    </Row>
                  </Col>
                  <Col span={7}>
                    <Row
                      style={{
                        gap: "25vh",
                        borderBottom: "1px solid #ccc", // Define the border thickness, style, and color
                        alignItems: "center",
                      }}
                    >
                      <h6 style={{ color: "#666" }}>Blood group</h6>
                      <h6>{user.bloodGroup}</h6>
                    </Row>
                  </Col>
                </Row>
                <Row style={{ marginTop: "6vh" }}>
                  <Col span={21}>
                    <Row
                      style={{
                        width: "197vh",
                        gap: "26vh",
                        borderBottom: "1px solid #ccc", // Define the border thickness, style, and color
                        alignItems: "center",
                      }}
                    >
                      <h6 style={{ color: "#666" }}>Address</h6>
                      <h6>{user.address}</h6>
                    </Row>
                  </Col>
                </Row>
              </Card>
              <Card style={{ marginTop: "5vh" }}>
                <h6>Bank Information</h6>
                <Row justify="space-between" style={{ marginTop: "6vh" }}>
                  <Col span={11}>
                    <Row
                      style={{
                        gap: "29vh",
                        borderBottom: "1px solid #ccc", // Define the border thickness, style, and color
                        alignItems: "center",
                      }}
                    >
                      <h6 style={{ color: "#666" }}>Account No</h6>
                      <h6>{user.accountNumber}</h6>
                    </Row>
                  </Col>
                  <Col span={11}>
                    <Row
                      style={{
                        gap: "33vh",
                        borderBottom: "1px solid #ccc", // Define the border thickness, style, and color
                        alignItems: "center",
                      }}
                    >
                      <h6 style={{ color: "#666" }}>Bank Name</h6>
                      <h6>{user.bankName}</h6>
                    </Row>
                  </Col>
                </Row>
                <Row justify="space-between" style={{ marginTop: "6vh" }}>
                  <Col span={11}>
                    <Row
                      style={{
                        gap: "31vh",
                        borderBottom: "1px solid #ccc", // Define the border thickness, style, and color
                        alignItems: "center",
                      }}
                    >
                      <h6 style={{ color: "#666" }}>IFSC Code</h6>
                      <h6>{user.ifscCode}</h6>
                    </Row>
                  </Col>
                  <Col span={11}>
                    <Row
                      style={{
                        gap: "31vh",
                        borderBottom: "1px solid #ccc", // Define the border thickness, style, and color
                        alignItems: "center",
                      }}
                    >
                      <h6 style={{ color: "#666" }}>Account Type</h6>
                      <h6>{user.accountType}</h6>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </>
          )}
          {activeTabKey === "3" && (
            <>
              {!showReportees && (
                <Row>
                  <Col>
                    {user.profileImage ? (
                      <img
                        src={user.profileImage}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "20%",
                          objectFit: "cover",
                        }}
                        onClick={() => setShowReportees(false)}
                      />
                    ) : (
                      <img
                        src={
                          user.gender === "female"
                            ? require("../images/femalegeneric.png")
                            : require("../images/malegenric.jpg")
                        }
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "20%",
                          objectFit: "cover",
                        }}
                        onClick={() => setShowReportees(false)}
                      />
                    )}
                  </Col>
                  <Col>
                    <h6 style={{ marginTop: "2vh", marginLeft: "2vh" }}>
                      {user.empCode} - {user.displayName}
                    </h6>
                  </Col>
                </Row>
              )}
              {showReportees && (
                <Row>
                  <>
                    <Col>
                      {user.profileImage ? (
                        <img
                          src={user.profileImage}
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "20%",
                            objectFit: "cover",
                          }}
                          onClick={() => setShowReportees(false)}
                        />
                      ) : (
                        <img
                          src={
                            user.gender === "female"
                              ? require("../images/femalegeneric.png")
                              : require("../images/malegenric.jpg")
                          }
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "20%",
                            objectFit: "cover",
                          }}
                          onClick={() => setShowReportees(false)}
                        />
                      )}
                    </Col>

                    <Col>
                      <RightOutlined
                        style={{
                          color: "#666",
                          fontSize: "12px",
                          marginTop: "3vh",
                          marginLeft: "2vh",
                        }}
                      />
                    </Col>
                    <Col>
                      {profile ? (
                        <img
                          src={profile}
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "20%",
                            objectFit: "cover",
                            marginLeft: "2vh",
                          }}
                        />
                      ) : (
                        <img
                          src={require("../images/malegenric.jpg")}
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "20%",
                            objectFit: "cover",
                            marginLeft: "2vh",
                          }}
                        />
                      )}
                    </Col>
                    <Col>
                      <h6 style={{ marginTop: "2vh", marginLeft: "2vh" }}>
                        {empcode} - {name}
                      </h6>
                    </Col>
                  </>
                </Row>
              )}
              {!showReportees && (
                <Row justify="space-between" style={{ marginTop: "2vh" }}>
                  {reportees.map((emp) => (
                    <Col span={5} style={{ marginTop: "8vh" }}>
                      <Card
                        style={{
                          width: "40vh",
                          height: "35vh",
                          border: "1px solid #fcc00d",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        <Col>
                          {emp.profileImage ? (
                            <img
                              src={emp.profileImage}
                              style={{
                                width: "75px",
                                height: "80px",
                                borderRadius: "20%",
                                objectFit: "cover",
                                top: "-60px",
                                position: "absolute",
                                left: "10vh",
                              }}
                            />
                          ) : (
                            <img
                              src={
                                emp.gender === "female"
                                  ? require("../images/femalegeneric.png")
                                  : require("../images/malegenric.jpg")
                              }
                              style={{
                                width: "75px",
                                height: "80px",
                                borderRadius: "20%",
                                objectFit: "cover",
                                top: "-60px",
                                position: "absolute",
                                left: "10vh",
                              }}
                            />
                          )}
                        </Col>
                        <Col style={{ marginTop: "4vh", textAlign: "center" }}>
                          <span>
                            {emp.empCode} -{" "}
                            {emp.displayName.charAt(0).toUpperCase() +
                              emp.displayName.slice(1).toLowerCase()}
                          </span>
                          <p style={{ color: "#666" }}>
                            {emp.jobTitle.charAt(0).toUpperCase() +
                              emp.jobTitle.slice(1).toLowerCase()}
                          </p>
                        </Col>
                        <Col style={{ textAlign: "center" }}>
                          {checkedIn[emp.displayName]?.length > 0 ? (
                            <>
                              <span style={{ color: "green" }}>
                                Checked In{" "}
                                {
                                  checkedIn[
                                    emp.displayName
                                  ][0].checkInTime.split(" ")[1]
                                }
                              </span>
                            </>
                          ) : (
                            <span style={{ color: "red" }}>
                              Yet to check-in
                            </span>
                          )}
                        </Col>
                        <div style={{ marginTop: "1vh", textAlign: "center" }}>
                          <BranchesOutlined
                            style={{ fontSize: "17px" }}
                            onClick={() =>
                              handleReporteeClick(
                                emp.displayName,
                                emp.profileImage,
                                emp.empCode
                              )
                            }
                          />
                        </div>
                      </Card>
                    </Col>
                  ))}
                </Row>
              )}
              {showReportees && (
                <Row justify="space-between" style={{ marginTop: "2vh" }}>
                  {subReportees && subReportees.length > 0 ? (
                    subReportees.map((item) => (
                      <Col
                        span={5}
                        style={{ marginTop: "5vh" }}
                        key={item.empCode}
                      >
                        <Card
                          style={{
                            width: "40vh",
                            border: "1px solid #fcc00d",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                          }}
                        >
                          <Col>
                            {item.profileImage ? (
                              <img
                                src={item.profileImage}
                                style={{
                                  width: "75px",
                                  height: "80px",
                                  borderRadius: "20%",
                                  objectFit: "cover",
                                  top: "-60px",
                                  position: "absolute",
                                  left: "10vh",
                                }}
                              />
                            ) : (
                              <img
                                src={
                                  item.gender === "female"
                                    ? require("../images/femalegeneric.png")
                                    : require("../images/malegenric.jpg")
                                }
                                style={{
                                  width: "75px",
                                  height: "80px",
                                  borderRadius: "20%",
                                  objectFit: "cover",
                                  top: "-60px",
                                  position: "absolute",
                                  left: "10vh",
                                }}
                              />
                            )}
                          </Col>
                          <Col
                            style={{ marginTop: "4vh", textAlign: "center" }}
                          >
                            <span>
                              {item.empCode} -{" "}
                              {item.displayName.charAt(0).toUpperCase() +
                                item.displayName.slice(1).toLowerCase()}
                            </span>
                            <p style={{ color: "#666" }}>
                              {item.jobTitle.charAt(0).toUpperCase() +
                                item.jobTitle.slice(1).toLowerCase()}
                            </p>
                          </Col>
                          <Col style={{ textAlign: "center" }}>
                            <span style={{ color: "red" }}>Yet to checkin</span>
                          </Col>
                          <div
                            style={{ marginTop: "2vh", textAlign: "center" }}
                          >
                            <BranchesOutlined
                              onClick={() =>
                                handleReporteeClick(
                                  item.displayName,
                                  item.profileImage,
                                  item.empCode
                                )
                              }
                              style={{ fontSize: "17px" }}
                            />
                          </div>
                        </Card>
                      </Col>
                    ))
                  ) : (
                    <>
                      <Col
                        span={24}
                        style={{ textAlign: "center", marginTop: "5vh" }}
                      >
                        <p>No reportees</p>
                      </Col>
                    </>
                  )}
                </Row>
              )}
            </>
          )}
          {activeTabKey === "4" && (
            <>
              <Row justify="space-between">
                <Col span={7}>
                  <Card>
                    <h6>HR</h6>
                    {hrEmployees.length === 0 ? (
                      <img
                        src={require("../images/No data.png")}
                        style={{
                          width: "12vh",
                          height: "12vh",
                          marginLeft: "12vh",
                          marginTop: "4vh",
                        }}
                      />
                    ) : (
                      hrEmployees.map((hr) => (
                        <>
                          <br />
                          <Row>
                            <Col>
                              {hr.profileImage ? (
                                <img
                                  src={hr.profileImage}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "20%",
                                    objectFit: "cover",
                                  }}
                                />
                              ) : (
                                <img
                                  src={
                                    hr.gender === "female"
                                      ? require("../images/femalegeneric.png")
                                      : require("../images/malegenric.jpg")
                                  }
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "20%",
                                    objectFit: "cover",
                                  }}
                                />
                              )}
                              <span style={{ marginLeft: "1vh" }}>
                                {hr.displayName}
                              </span>
                            </Col>
                            {/* <Col>
                                <span style={{ marginLeft: "1vh" }}>
                                  {hr.displayName}
                                </span>
  
                                {/* {checkedIn[hr.displayName]?.length > 0 ? (
                                  <>
                                    <span
                                      style={{
                                        color: "green",
                                        marginLeft: "1vh",
                                      }}
                                    >
                                      Checked In
                                    </span>
                                    <p
                                      style={{ color: "#666", fontSize: "12px" }}
                                    >
                                      {checkedIn[hr.displayName][0].checkInTime}
                                    </p>
                                  </>
                                ) : (
                                  <span
                                    style={{ color: "red", marginLeft: "2vh" }}
                                  >
                                    Yet to check-in
                                  </span>
                                )} 
                              </Col> */}
                          </Row>
                        </>
                      ))
                    )}
                  </Card>
                </Col>

                <Col span={7}>
                  <Card>
                    <h6>Team Members</h6>
                    {teamLeaderEmployees.length === 0 ? (
                      <img
                        src={require("../images/No data.png")}
                        style={{
                          width: "12vh",
                          height: "12vh",
                          marginLeft: "12vh",
                          marginTop: "4vh",
                        }}
                      />
                    ) : (
                      teamLeaderEmployees.map((leader) => (
                        <>
                          <br />
                          <Row>
                            <Col>
                              {leader.profileImage ? (
                                <img
                                  src={leader.profileImage}
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "20%",
                                    objectFit: "cover",
                                  }}
                                />
                              ) : (
                                <img
                                  src={
                                    leader.gender === "female"
                                      ? require("../images/femalegeneric.png")
                                      : require("../images/malegenric.jpg")
                                  }
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "20%",
                                    objectFit: "cover",
                                  }}
                                />
                              )}
                              <span style={{ marginLeft: "1vh" }}>
                                {leader.displayName}
                              </span>
                            </Col>
                            {/* <Col>
                                <span style={{ marginLeft: "1vh" }}>
                                  {leader.displayName}
                                </span>
                                {/* {checkedIn[leader.displayName]?.length > 0 ? (
                                  <>
                                    <span
                                      style={{
                                        color: "green",
                                        marginLeft: "1vh",
                                      }}
                                    >
                                      Checked In
                                    </span>
                                    <p
                                      style={{ color: "#666", fontSize: "12px" }}
                                    >
                                      {
                                        checkedIn[leader.displayName][0]
                                          .checkInTime
                                      }
                                    </p>
                                  </>
                                ) : (
                                  <span
                                    style={{ color: "red", marginLeft: "2vh" }}
                                  >
                                    Yet to check-in
                                  </span>
                                )} 
                                </Col>*/}
                          </Row>
                        </>
                      ))
                    )}
                  </Card>
                </Col>

                <Col span={7}>
                  <Card>
                    <h6>Manager</h6>
                    {managerEmployees.length === 0 ? (
                      <img
                        src={require("../images/No data.png")}
                        style={{
                          width: "12vh",
                          height: "12vh",
                          marginLeft: "12vh",
                          marginTop: "4vh",
                        }}
                      />
                    ) : (
                      managerEmployees.map((manager) => (
                        <>
                          <br />
                          <Row>
                            <Col>
                              {manager.profileImage ? (
                                <img
                                  src={manager.profileImage}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "20%",
                                    objectFit: "cover",
                                  }}
                                />
                              ) : (
                                <img
                                  src={
                                    manager.gender === "female"
                                      ? require("../images/femalegeneric.png")
                                      : require("../images/malegenric.jpg")
                                  }
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "20%",
                                    objectFit: "cover",
                                  }}
                                />
                              )}
                              <span style={{ marginLeft: "1vh" }}>
                                {manager.displayName}
                              </span>
                            </Col>
                            {/* <Col>
                                <span style={{ marginLeft: "1vh" }}>
                                  {manager.displayName}
                                </span>
  
                                 {checkedIn[manager.displayName]?.length > 0 ? (
                                  <>
                                    <span
                                      style={{
                                        color: "green",
                                        marginLeft: "1vh",
                                      }}
                                    >
                                      Checked In
                                    </span>
                                    <p
                                      style={{ color: "#666", fontSize: "12px" }}
                                    >
                                      {
                                        checkedIn[manager.displayName][0]
                                          .checkInTime
                                      }
                                    </p>
                                  </>
                                ) : (
                                  <span
                                    style={{ color: "red", marginLeft: "2vh" }}
                                  >
                                    Yet to check-in
                                  </span>
                                )} 
                              </Col> */}
                          </Row>
                        </>
                      ))
                    )}
                  </Card>
                </Col>
              </Row>
            </>
          )}
          {activeTabKey === "5" && (
            <>
              <Row justify="space-between">
                {leaveType.map((leave, index) => (
                  <Col
                    key={index}
                    justify="space-around"
                    style={{ marginBottom: "2vh" }}
                  >
                    <Card
                      style={{
                        width: "40vh",
                        border: `1px solid ${leave.borderColor}`,
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      }}
                    >
                      <Col>
                        {leave.icon}
                        <span style={{ marginLeft: "2vh", fontSize: "16px" }}>
                          {leave.type}
                        </span>
                      </Col>
                      <Row justify="space-between" style={{ marginTop: "2vh" }}>
                        <Col>
                          <span>Available</span>
                          <p>{leave.available} days</p>
                        </Col>
                        <Col>
                          <span>Booked</span>
                          <p>
                            {leave.booked} day{leave.booked !== 1 ? "s" : ""}
                          </p>
                        </Col>
                      </Row>
                      <Col style={{ textAlign: "center" }}>
                        <Button
                          style={{ color: "red" }}
                          onClick={() => handleLeave(leave.name)}
                        >
                          Apply Leave
                        </Button>
                      </Col>
                    </Card>
                  </Col>
                ))}
              </Row>
            </>
          )}
          {activeTabKey === "6" && (
            <>
              <Card style={{ marginTop: "5vh" }}>
                <Row justify="space-between">
                  <Col span={11}>
                    <Row>
                      <Col>
                        <DashboardOutlined
                          style={{
                            color: "#6f0cf0",
                            fontSize: "25px",
                            border: "1px solid #ccc",
                            padding: "10px",
                            borderRadius: "20%",
                            background: "#e3d8f2",
                          }}
                        />
                      </Col>
                      <Col>
                        <span style={{ marginLeft: "2vh", fontSize: "16px" }}>
                          Work Schedule
                        </span>
                        <p style={{ marginLeft: "2vh", fontSize: "14px" }}>
                          {weekDates[0]} - {weekDates[6]}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={11}>
                    <Row>
                      <Col>
                        <CalendarOutlined
                          style={{
                            color: "#1567eb",
                            fontSize: "25px",
                            border: "1px solid #ccc",
                            padding: "10px",
                            borderRadius: "20%",
                            background: "#bed0ed",
                          }}
                        />
                      </Col>
                      <Col>
                        <span style={{ marginLeft: "2vh", fontSize: "16px" }}>
                          Upcoming Holidays
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row justify="space-between" style={{ marginTop: "2vh" }}>
                  <Col span={12}>
                    <Timeline>
                      {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(
                        (day, index) => {
                          const entry = weekData[day];
                          const checkInTime = entry?.checkInTime
                            ? entry.checkInTime.split(" ")[1]
                            : "09:00.00";
                          const checkOutTime =
                            entry?.checkInTime && !entry?.checkOutTime
                              ? ""
                              : entry?.checkOutTime
                              ? entry.checkOutTime.split(" ")[1]
                              : "18:00:00";

                          const displayDate = weekDates[index];
                          const holidayName = checkForHoliday(displayDate);

                          // If no entry exists for the day, assume default time, Holiday for weekends, or holiday name if it's a holiday
                          const isWeekend = day === "Sun" || day === "Sat";
                          const displayTime = holidayName
                            ? holidayName
                            : isWeekend
                            ? "Holiday"
                            : `${checkInTime} To ${checkOutTime}`;
                          console.log(holidayName, "Govt");

                          return (
                            <Timeline.Item
                              key={day}
                              color={
                                holidayName
                                  ? "#3848f5"
                                  : isWeekend
                                  ? "#fa6132"
                                  : "#f53874"
                              }
                            >
                              <span>
                                {day} - {displayDate}
                              </span>
                              <span
                                style={{
                                  marginLeft: "20px",
                                  color: holidayName
                                    ? "#3848f5"
                                    : isWeekend
                                    ? "#fa6132"
                                    : entry
                                    ? "#a038f5"
                                    : "#f53874",
                                }}
                              >
                                {displayTime}
                              </span>
                            </Timeline.Item>
                          );
                        }
                      )}
                    </Timeline>
                  </Col>
                  {/* <Col span={11}>
                                            <Card style={{ border: "1px solid #6f0cf0", width: "60vh" }}>
                                                <Row style={{ gap: "4vh" }}>
                                                    <Col>
                                                        <img src={require("../images/india.png")} style={{ width: "10vh", height: "10vh" }} />
                                                    </Col>
                                                    <Col>
                                                        <span style={{ display: "flex", justifyContent: "center" }}>Independence Day</span>
                                                        <p className='text-center'>15th Aug, Thursday</p>
                                                    </Col>
                                                </Row>
                                            </Card>
                                            <Card style={{ border: "1px solid #1567eb", width: "60vh", marginTop: "2vh" }}>
                                                <Row style={{ gap: "4vh" }}>
                                                    <Col>
                                                        <img src={require("../images/new-year.png")} style={{ width: "10vh", height: "10vh" }} />
                                                    </Col>
                                                    <Col>
                                                        <span style={{ display: "flex", justifyContent: "center" }}>New Year</span>
                                                        <p className='text-center'>1st Jan, Sunday</p>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col> */}

                  <Col span={11}>
                    {currentMonthHolidays?.length > 0 ? (
                      <>
                        {currentMonthHolidays.map((item) => (
                          <Card
                            key={item.date}
                            style={{
                              border: "1px solid #6f0cf0",
                              width: "60vh",
                              marginBottom: "2vh",
                            }}
                          >
                            <Row style={{ gap: "4vh" }}>
                              <Col>
                                <img
                                  src={require("../images/holiday.jpg")}
                                  style={{ width: "10vh", height: "10vh" }}
                                  alt={item.title}
                                />
                              </Col>
                              <Col>
                                <span
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {item.title}
                                </span>
                                <p className="text-center">{item.date}</p>
                              </Col>
                            </Row>
                          </Card>
                        ))}
                      </>
                    ) : (
                      <p>No holidays for this month.</p>
                    )}
                    <Link onClick={showDrawer}>See more</Link>

                    <Drawer
                      title="Here you can view a list of all holidays in the year."
                      placement="right"
                      onClose={closeDrawer}
                      visible={isDrawerVisible}
                      width={1150}
                      style={{ background: "#f2f5f3" }}
                    >
                      <Row gutter={[16, 16]}>
                        {/* Iterate through all months */}
                        {monthsInYear.map((month) => (
                          <Col span={6} key={month}>
                            <Card
                              style={{
                                height: "28vh",
                                border: "1px solid #fcc00d",
                              }}
                            >
                              <h6
                                className="text-center"
                                style={{
                                  color: "#f25066",
                                  fontWeight: "bold",
                                  top: "-1px",
                                  right: "100px",
                                  position: "absolute",
                                }}
                              >
                                {month} 2024
                              </h6>
                              <div
                                style={{
                                  maxHeight: "20vh",
                                  overflowY: "auto",
                                }}
                              >
                                {/* Check if the current month has holidays */}
                                {holidaysByMonth &&
                                holidaysByMonth[month] &&
                                holidaysByMonth[month].length > 0 ? (
                                  holidaysByMonth[month].map((holiday) => {
                                    const dateObj = new Date(
                                      holiday.date
                                        .split("/")
                                        .reverse()
                                        .join("-")
                                    );
                                    const day = dateObj.getDate();
                                    const dayOfWeek = dateObj.toLocaleString(
                                      "default",
                                      { weekday: "short" }
                                    );

                                    return (
                                      <Row
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginTop: "2vh",
                                        }}
                                        key={holiday._id}
                                      >
                                        <strong style={{ color: "#5a7afa" }}>
                                          {day}
                                        </strong>
                                        <span
                                          style={{
                                            fontSize: "12px",
                                            color: "#888",
                                            marginLeft: "1vh",
                                          }}
                                        >
                                          {dayOfWeek}
                                        </span>
                                        <span
                                          style={{
                                            color: "#7f17ff",
                                            marginLeft: "2vh",
                                          }}
                                        >
                                          {holiday.title}
                                        </span>
                                      </Row>
                                    );
                                  })
                                ) : (
                                  <p>No holidays for this month</p>
                                )}
                              </div>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </Drawer>
                  </Col>
                </Row>
              </Card>
            </>
          )}
          {activeTabKey === "7" && (
            <>
              <Modal
                title={<h6 className="text-center">Post a Message</h6>}
                visible={isModalVisible1}
                onCancel={handleCancel1}
                footer={null}
              >
                <br />
                <Row align="middle" style={{ marginBottom: "16px" }}>
                  <Col flex="none">
                    <Avatar
                      src={user.profileImage} // Replace with the actual user avatar
                      style={{ marginRight: 8 }}
                    />
                  </Col>
                  <Col flex="auto">
                    <TextArea
                      placeholder="Type your message here..."
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      rows={4}
                    />
                  </Col>
                </Row>

                <Radio.Group
                  onChange={(e) => setAudience(e.target.value)}
                  value={audience}
                >
                  <Radio value="allEmployees">All Employees</Radio>
                  <Radio value="department">Department</Radio>
                  <Radio value="specificEmployees">Specific Employees</Radio>
                </Radio.Group>
                <br />
                {audience === "department" && (
                  <Select
                    placeholder="Select Department"
                    style={{ width: "100%", marginTop: "16px" }}
                    onChange={(value) => setSelectedDepartment(value)}
                    value={selectedDepartment}
                  >
                    {department.map((dept) => (
                      <Select.Option key={dept} value={dept}>
                        {dept}
                      </Select.Option>
                    ))}
                  </Select>
                )}
                {audience === "specificEmployees" && (
                  <Select
                    mode="multiple"
                    placeholder="Select Employees"
                    style={{ width: "100%", marginTop: "16px" }}
                    onChange={(value) => setSelectedEmployees(value)}
                    value={selectedEmployees}
                  >
                    {Users.map((emp) => (
                      <Select.Option key={emp} value={emp}>
                        {emp}
                      </Select.Option>
                    ))}
                  </Select>
                )}

                <div style={{ textAlign: "right", marginTop: 10 }}>
                  <Button type="primary" danger onClick={handlePost}>
                    Post
                  </Button>
                </div>
              </Modal>
              <Button
                icon={<PlusOutlined />}
                type="primary"
                onClick={showModal1}
              >
                Message
              </Button>
              {getMessages.length > 0 ? (
                getMessages
                  .filter((message) => {
                    // Check if the message is applicable to the current user
                    const isApplicableToUser =
                      message.applicable.includes("All employees") || // Check for all employees
                      message.applicable.includes(users.displayName) || // Check if the user is specifically included
                      message.applicable.includes(users.department); // Check if the user's department is included

                    return isApplicableToUser; // Only display messages applicable to the user
                  })
                  .map((message, messageIndex) => {
                    const formattedDate = moment(message.createdAt).format(
                      "DD MMMM hh:mm A"
                    );

                    // Count the number of likes for the message
                    const messageLikesCount = message.likes.length;
                    const hasCurrentUserLiked = message.likes.some(
                      (like) => like.userName === user.displayName
                    );

                    return (
                      <Card key={messageIndex} style={{ marginTop: "2vh" }}>
                        <Row style={{ gap: "3vh" }}>
                          <Col>
                            <Avatar
                              style={{
                                backgroundColor: "purple",
                                color: "#fff",
                                width: "7vh",
                                height: "7vh",
                                fontSize: "2.5vh",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {message.user
                                ? `${message.user[0]}${
                                    message.user[message.user.length - 1]
                                  }`.toUpperCase()
                                : ""}
                            </Avatar>
                          </Col>
                          <Col>
                            <span>
                              {message.user
                                ? `${message.user} has posted a message`
                                : "User unknown"}
                            </span>
                            <p style={{ color: "#666" }}>{formattedDate}</p>
                          </Col>
                        </Row>
                        <p>{message.message}</p>
                        <Row style={{ gap: "4vh", color: "#666" }}>
                          <Col>
                            <LikeOutlined
                              onClick={() => handleLikeClick(message.message)}
                              style={{
                                color: hasCurrentUserLiked ? "blue" : "gray",
                                cursor: "pointer",
                              }}
                            />
                            <div
                              style={{
                                position: "relative",
                                display: "inline-block",
                              }}
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                            >
                              <span style={{ marginLeft: "8px" }}>
                                {messageLikesCount} Like
                                {messageLikesCount !== 1 && "s"}
                              </span>

                              {isHovered && message.likes.length > 0 && (
                                <div style={tooltipStyle}>
                                  {message.likes.map((like, index) => (
                                    <div key={index}>{like.userName}</div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </Col>
                          <Col>
                            <MessageOutlined
                              onClick={() => handleComments(messageIndex)}
                            />
                            <span> Comment</span>
                          </Col>
                        </Row>
                        {showComments && messageindex === messageIndex && (
                          <>
                            <Input.TextArea
                              value={commentInput}
                              onChange={(e) => setCommentInput(e.target.value)}
                              placeholder="Enter your comment"
                              rows={3}
                              style={{ width: "100vh", marginTop: "2vh" }}
                            />
                            <Button
                              icon={<SendOutlined />}
                              type="primary"
                              style={{ bottom: "4vh" }}
                              onClick={() =>
                                handleCommentSubmit(message.message)
                              }
                            />
                          </>
                        )}
                        <div>
                          <List
                            style={{
                              marginTop: "16px",
                              maxHeight: "20vh",
                              overflowY: "scroll",
                            }}
                            header={`${message.comments.length} Comment${
                              message.comments.length !== 1 ? "s" : ""
                            }`}
                            dataSource={message.comments}
                            renderItem={(comment, commentIndex) => {
                              const commentUserInitials = comment.userName
                                ? `${comment.userName[0]}${
                                    comment.userName[
                                      comment.userName.length - 1
                                    ]
                                  }`.toUpperCase()
                                : "";

                              return (
                                <List.Item key={commentIndex}>
                                  <List.Item.Meta
                                    avatar={
                                      <Avatar
                                        style={{
                                          backgroundColor: "blue",
                                          color: "#fff",
                                          width: "7vh",
                                          height: "7vh",
                                          fontSize: "2.5vh",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {commentUserInitials}
                                      </Avatar>
                                    }
                                    description={
                                      <div>
                                        <span style={{ color: "black" }}>
                                          {comment.userName || "Anonymous"}
                                        </span>
                                        <p>{comment.comment}</p>
                                      </div>
                                    }
                                  />
                                </List.Item>
                              );
                            }}
                          />
                        </div>
                      </Card>
                    );
                  })
              ) : (
                <h5 className="text-center" style={{ color: "red" }}>
                  No messages to display
                </h5>
              )}
            </>
          )}
          {activeTabKey === "8" && (
            <>
              {console.log(survey, "survey")}

              {survey.map((item) => {
                const currentDate = new Date().toISOString().split("T")[0]; // Format as YYYY-MM-DD
                const periodEndDate = new Date(item.introduction.period[1])
                  .toISOString()
                  .split("T")[0]; // Format as YYYY-MM-DD

                // Log dates to confirm
                console.log(`${currentDate} ${periodEndDate} date comparison`);

                // If the current date matches the period[1] date, skip this survey
                if (currentDate > periodEndDate) {
                  return null; // Skip rendering this survey
                }

                // Check if the applicability includes "All employees" or the user's name/designation
                if (
                  (Array.isArray(item.applicability) &&
                    (item.applicability.includes("All Employees") || // Check for "All employees"
                      item.applicability.includes(user.displayName))) ||
                  item.applicability.includes(user.empDesignation)
                ) {
                  const hasResponded = empSurvey.some(
                    (response) =>
                      response.surveyName === item.introduction.name &&
                      response.userName === user.displayName
                  );

                  return (
                    <React.Fragment key={item._id}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Card
                          style={{
                            width: "100vh",
                            height: "35vh",
                            border: "1px solid #fcc00d",
                          }}
                        >
                          <img
                            src={require("../images/malegenric.jpg")}
                            style={{ width: "10vh", height: "10vh" }}
                            alt="User"
                          />
                          <span>
                            {item.userName} have added a survey called{" "}
                            {item.introduction.name}
                          </span>
                          <br />
                          <div style={{ textAlign: "center" }}>
                            {hasResponded ? (
                              <img
                                src={require("../images/feedback1.avif")}
                                style={{ width: "15vh", height: "15vh" }}
                              />
                            ) : (
                              <img
                                src={require("../images/emoji.png")}
                                style={{ width: "14vh", height: "14vh" }}
                                alt="Emoji"
                              />
                            )}

                            {hasResponded ? (
                              <p style={{ color: "green" }}>
                                Your response has already been submitted.
                              </p>
                            ) : (
                              <Button
                                type="primary"
                                htmlType="button"
                                style={{ background: "#f55d74", left: "10vh" }}
                                onClick={() => showModal(item)}
                              >
                                Start survey
                              </Button>
                            )}
                          </div>
                        </Card>
                      </div>
                      <br />
                    </React.Fragment>
                  );
                } else {
                  return null;
                }
              })}

              <Modal
                visible={isIdentityModalVisible}
                onCancel={() => setIsIdentityModalVisible(false)}
                onOk={postSurveyForm} // Trigger the survey submission with or without identity
                okText="Submit"
                cancelText="Cancel"
              >
                <p>
                  Do you want to disclose your identity while submitting the
                  feedback?
                </p>
                <Checkbox
                  onChange={(e) => setIsIdentityChecked(e.target.checked)}
                >
                  Yes, I want to reveal my identity.
                </Checkbox>
              </Modal>

              <Modal
                title={show?.introduction?.name}
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                width={1000}
                bodyStyle={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundImage: `url(${
                    show?.introduction?.surveyImage
                      ? show.introduction.surveyImage
                      : background1
                  })`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  minHeight: "400px",
                }}
              >
                <Card
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                  }}
                >
                  <h4 style={{ textAlign: "center" }}>
                    {show?.introduction?.name}
                  </h4>
                  <p style={{ textAlign: "center" }}>
                    Dear {user.displayName},
                  </p>
                  <p>
                    This survey is aimed at capturing your honest feedback on
                    your engagement levels in this organization.
                  </p>
                  <p>
                    Your responses mean a lot to us, and will help us improve
                    the employee experience in our organization.
                  </p>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      type="primary"
                      htmlType="button"
                      onClick={() => handleSubmit(show)}
                    >
                      Enter Feedback
                    </Button>
                  </div>
                </Card>
              </Modal>

              {/* Additional modals for feedback */}
              <Modal
                visible={isSubmitModalVisible}
                onCancel={handleCloseSubmitModal}
                footer={null}
                width={1000}
                bodyStyle={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundImage: `url(${
                    question?.introduction?.surveyImage
                      ? question.introduction.surveyImage
                      : background1
                  })`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  minHeight: "400px",
                }}
              >
                <Card
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                    width: "90%",
                  }}
                >
                  <h4>{question.introduction?.name}</h4>
                  {question.questions?.map((data, qIndex) => (
                    <div key={qIndex}>
                      <p>
                        Q{qIndex + 1}. {data.question}
                      </p>

                      {data.type === "scale" && (
                        <Slider
                          min={0}
                          max={10}
                          defaultValue={0}
                          onChange={(value) => setScaleValue(value)}
                        />
                      )}

                      {data.type === "choice" && (
                        <Radio.Group
                          onChange={(e) => setSelectedChoice(e.target.value)}
                        >
                          {data.choices?.map((ans, aIndex) => (
                            <Radio key={aIndex} value={ans}>
                              {ans}
                            </Radio>
                          ))}
                        </Radio.Group>
                      )}

                      {data.type === "rating" && (
                        <Rate
                          defaultValue={0}
                          count={10}
                          onChange={(value) => setRatingValue(value)}
                        />
                      )}
                    </div>
                  ))}

                  <TextArea
                    placeholder="Your additional comments"
                    onChange={(e) => setAdditionalComments(e.target.value)}
                  />
                  <br />
                  <br />

                  <div style={{ textAlign: "center" }}>
                    <Button
                      type="primary"
                      htmlType="button"
                      onClick={handleFormSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                </Card>
              </Modal>

              <Modal
                visible={isSubmitVisible}
                onCancel={handleCancelSubmit}
                footer={null}
                width={1000}
                bodyStyle={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  minHeight: "400px",
                }}
              >
                <Card
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                  }}
                >
                  <img
                    src={require("../images/thumbsup-removebg-preview.png")}
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                    }}
                    alt="Thank You"
                  />
                  <br />
                  <br />
                  <h4 style={{ textAlign: "center" }}>
                    We thank you immensely for your valuable feedback!
                  </h4>
                  <p style={{ textAlign: "center" }}>
                    We will use them to improve your experience.
                  </p>
                </Card>
              </Modal>
            </>
          )}
        </Card>
      </>
    </div>
  );
};

export default Managerdashboard;
