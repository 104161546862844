import { React, useState, useEffect } from "react";
import "./Resume Home.css";

function Resumehome() {
  const [greeting, setGreeting] = useState("Good morning");
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const getGreeting = () => {
      const currentHour = new Date().getHours();

      if (currentHour < 12) {
        setGreeting("Good morning");
      } else if (currentHour >= 12 && currentHour < 18) {
        setGreeting("Good afternoon");
      } else {
        setGreeting("Good evening");
      }
    };

    getGreeting();
  }, []);

  return (
    <div className="Resume">
      <main className="main-content">
        <div className="left-section">
          <h2>
            {greeting}, {user.displayName}.
          </h2>
          <p>Upload your resume to tailor it to a job description.</p>
          <h4>Quick Links</h4>
          <div className="quick-links">
            <div className="quick-link">
              <h5>Upload Resume</h5>
              <div className="link-icon">
                <img
                  src={require("../../images/upload-file.png")}
                  alt="upload-file"
                  className="quick-link-image"
                />
              </div>
              <p>Upload your resume to the database for analysis.</p>
            </div>
            <div className="quick-link">
              <h5>Job-Specific Resume</h5>
              <div className="link-icon">
                <img
                  src={require("../../images/cv-objective.png")}
                  alt="cv-objective"
                  className="quick-link-image"
                />
              </div>
              <p>
                Analyze your resume and match it against job descriptions.
                Receive a score and recommendations for suitable roles.
              </p>
            </div>
          </div>
        </div>
        <div className="right-section">
          <h4>Optimize Your Job Applications</h4>
          <p>Utilize our features to extract key information from your resume, ensuring a better match with job descriptions. Get insights and recommendations to enhance your application.</p>
          <button className="pro-btn">Upgrade Now</button>
        </div>
      </main>
    </div>
  );
}

export default Resumehome;
