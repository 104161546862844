import React, { useState, useEffect } from "react";
import axios from "axios";
import { url } from "../../url/url";
import {
  Table,
  Card,
  Row,
  Col,
  Tooltip,
  message,
  Form,
  Input,
  Select,
  DatePicker,
} from "antd";
import * as msal from "@azure/msal-browser";
import {
  EditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  ExportOutlined,
  FileZipOutlined,
  UploadOutlined,
  FormOutlined,
} from "@ant-design/icons";
import { Modal, Button } from "antd";
import MasterOrgForm from "./MasterOrgForm";
import BillingTypeManager from "./ManageBillingType";
import { Link } from "react-router-dom";
import { decryptRole } from "../../encrypt";
import moment from "moment";
const { Option } = Select;

const MasterOrgTable = () => {
  const today = new Date().toISOString().slice(0, 10);
  const [date] = useState(today);
  const [data, setData] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [signinCount, setSigninCount] = useState(0);
  const [invoices, setInvoices] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const [currentOrg, setCurrentOrg] = useState(null);
  const [currentOrg1, setCurrentOrg1] = useState(null);
  const [isBillingModalVisible, setIsBillingModalVisible] = useState(false);
  const encryptedRoleFromStorage = localStorage.getItem("role");
  const role = decryptRole(encryptedRoleFromStorage);
  const users = JSON.parse(localStorage.getItem("user"));
  const [form] = Form.useForm();
  const [condition, setCondition] = useState(false);
  const [terms, setTerms] = useState();
  // Function to handle opening the Billing Type Manager modal
  const openBillingModal = () => {
    setIsBillingModalVisible(true);
  };

  const closeBillingModal = () => {
    setIsBillingModalVisible(false);
  };

  useEffect(() => {
    fetchData();
    fetchCondition();
  }, []);
  const fetchCondition = async () => {
    try {
      const response = await axios.get(`${url}/api/get-conditions`);
      console.log("Response received:", response);

      if (response.data && response.data.conditions) {
        setTerms(response.data.conditions);
      } else {
        console.error(
          "No conditions found in the response data:",
          response.data
        );
      }
    } catch (error) {
      console.error("There was an error fetching data:", error);
    }
  };
  const fetchData = async () => {
    try {
      const encryptedRoleFromStorage = localStorage.getItem("role");
      const role = decryptRole(encryptedRoleFromStorage);
      const channelName = users.firstName;
      const [orgsResponse, usersResponse, signinResponse] = await Promise.all([
        axios.get(`${url}/api/master-orgs/${role}`, {
          params: { channelName },
        }),
        axios.get(`${url}/api/user-count/${channelName}`),
        axios.get(`${url}/api/login-count`),
      ]);
      const last3Invoices = orgsResponse.data.map((org) => {
        const { billingDetails } = org;
        if (billingDetails && billingDetails.length > 0) {
          const last3 = billingDetails.slice(-3).map((detail) => ({
            invoiceId: detail.invoiceId,
            invoiceDate: detail.invoiceDate,
          }));
          return { orgName: org.orgName, invoices: last3 };
        } else {
          return { orgName: org.orgName, invoices: [] };
        }
      });
      setData(orgsResponse.data);
      //console.log("fetchdata role and data",role,orgsResponse.data);
      setTotalUsers(usersResponse.data.totalUsers);
      setSigninCount(signinResponse.data.signincount);
      console.log(last3Invoices, "invoices");
      setInvoices(last3Invoices);
    } catch (error) {
      console.error("There was an error fetching data:", error);
    }
  };


  const showModal = (record = null) => {
    setCurrentOrg(record);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentOrg(null);
  };

  const handleCancel1 = () => {
    setIsModalVisible1(false);
    setCurrentOrg1(null);
    form.resetFields();
  };

  const totalOrgs = data.length;

  const handleEdit = (record) => {
    showModal(record);
  };

  const handleupdate = (record) => {
    setCurrentOrg1(record);
    setIsModalVisible1(true);
  };

  const handleDelete = (record) => {
    console.log("Deleting record:", record);
    // Here you can add a confirmation dialog, and then delete the record if confirmed
  };

  const getUniqueValues = (data, key) => {
    const unique = new Set(data.map((item) => item[key]));
    return Array.from(unique).map((value) => ({
      text: value,
      value: value,
    }));
  };

  // Usage
  const uniqueChannels = getUniqueValues(data, "channel");
  const uniqueChannelNames = getUniqueValues(data, "channelName");
  const uniqueBillingTypes = getUniqueValues(data, "BillingType");
  const orgNameFilters = getUniqueValues(data, "orgName");

  const tableWrapperStyle = {
    position: "relative",
    marginBottom: "50px",
  };

  const columns = [
    {
      title: "Actions",
      key: "actions",
      fixed: "left",
      width: 180,
      render: (text, record) => (
        <span>
          <Tooltip title="Edit">
            {console.log(record.channelName, users.displayName, "channelName")}
            <EditOutlined
              onClick={() => {
                // if (users.firstName === record.channelName) {
                handleEdit(record);
                // }
              }}
              style={{ color: "blue", marginRight: 14 }}
              // style={{
              //   color: users.firstName === record.channelName ? "blue" : "grey",
              //   marginRight: 16,
              //   cursor:
              //     users.firstName === record.channelName
              //       ? "pointer"
              //       : "not-allowed",
              // }}
            />
          </Tooltip>
          {role === "superAdmin" && (
            <>
              <Tooltip title="Backup XML">
                <FileZipOutlined
                  onClick={() => handleBackup(record)}
                  style={{ color: "blue", marginRight: 14 }}
                />
              </Tooltip>
              <Tooltip title="Backup JSON">
                <ExportOutlined
                  onClick={() => handleDownloadJSON(record)}
                  style={{ color: "blue", marginRight: 14 }}
                />
              </Tooltip>
            </>
          )}
          <Tooltip title="Update Invoice">
            <UploadOutlined
              onClick={() => handleupdate(record)}
              style={{ color: "blue", marginRight: 14 }}
            />
          </Tooltip>
          {record.status === "enable" ? (
            <Tooltip title="Enabled">
              <CheckCircleOutlined
                style={{ color: "green", marginRight: 14 }}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Disabled">
              <CloseCircleOutlined style={{ color: "red" }} />
            </Tooltip>
          )}
        </span>
      ),
    },
    {
      title: "Customer Name",
      dataIndex: "orgName",
      key: "orgName",
      filters: orgNameFilters,
      onFilter: (value, record) => record.orgName === value,
      fixed: "left",
      width: 150,
    },
    {
      title: "Channel",
      dataIndex: "channel",
      key: "channel",
      filters: uniqueChannels,
      onFilter: (value, record) => record.channel === value,
    },
    {
      title: "ChannelName",
      dataIndex: "channelName",
      key: "channelName",
      width: 150,
      filters: uniqueChannelNames,
      onFilter: (value, record) => record.channelName === value,
    },
    {
      title: "License Count",
      dataIndex: "UsersAllowed",
      key: "UsersAllowed",
      align: "center",
      width: 120,
    },
    {
      title: "Billing Type",
      dataIndex: "BillingType",
      key: "BillingType",
      align: "center",
      width: 150,
      filters: uniqueBillingTypes,
      onFilter: (value, record) => record.BillingType === value,
    },

    {
      title: "User Count",
      dataIndex: "userCount",
      key: "userCount",
      align: "center",
      width: 120,
    },
    {
      title: "Domain",
      dataIndex: "domain",
      key: "domain",
      filters: [
        { text: "Native", value: "Native" },
        { text: "All others", value: "allOthers" },
      ],
      onFilter: (value, record) => {
        if (value === "Native") {
          return record.domain === "Native";
        } else if (value === "allOthers") {
          return record.domain !== "Native";
        }
      },
    },
    {
      title: "DB Name",
      dataIndex: "dbName",
      key: "dbName",
    },

    {
      title: "Company Email",
      dataIndex: "companyemail",
      key: "companyemail",
      width: 150,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 150,
    },
  ];
  const handleFormSubmit = async (values) => {
    try {
      console.log(values, "values");
      const response = await axios.post(`${url}/api/update-invoice`, {
        orgName: values.orgName,
        invoiceDate: date,
        userAllowed: values.UsersAllowed,
        invoiceId: values.invoiceId,
      });
      message.success("Invoice updated successfully");
      setIsModalVisible1(false);
      form.resetFields();
      fetchData();
    } catch (error) {
      console.error("Error updating invoice:", error);
      message.error("Failed to update invoice");
    }
  };

  const handleFormSubmitSuccess = () => {
    setIsModalVisible(false);
    fetchData();
  };

  const msalConfig = {
    auth: {
      clientId: "6d029af8-b886-414f-b790-197bfcf663b8",
      authority:
        "https://login.microsoftonline.com/1e72fe4c-cc82-4cfe-9c97-0e34cbb3b8eb",
      redirectUri: "https://myapp.finaran.com/",
    },
  };

  const msalInstance = new msal.PublicClientApplication(msalConfig);
  const userObjectId = "7cd0a2b5-7e87-42e8-b8c8-e7ea1824a578";
  const userAccount = msalInstance.getAccountByHomeId(userObjectId);

  if (userAccount) {
    msalInstance.setActiveAccount(userAccount);
  } else {
    console.error("User account not found or is null.");
  }

  const getToken = async () => {
    const request = {
      scopes: ["Files.ReadWrite", "offline_access"],
    };

    try {
      const response = await msalInstance.acquireTokenSilent(request);
      console.log("Token Response:", response);
      return response.accessToken;
    } catch (error) {
      console.error("Error acquiring token:", error);
      throw error;
    }
  };

  const uploadFileToOneDrive = async (file) => {
    const accessToken = localStorage.getItem("accessToken");
    console.log("insideuploadfile", accessToken);
    if (accessToken) {
      const user = "7cd0a2b5-7e87-42e8-b8c8-e7ea1824a578";
      const folderPath = "/drive/root:/DB Backup/";
      const fileName = "yourfile.zip";

      const apiUrl = `https://graph.microsoft.com/v1.0/users/${user}${folderPath}${fileName}:/content`;

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/octet-stream",
        },
        body: file,
      });

      if (response.ok) {
        console.log("File uploaded successfully!");
      } else {
        console.error("Failed to upload file:", response.statusText);
      }
    } else {
      console.error("Access token is null. Unable to proceed.");
    }
  };
  const handleDownloadJSON = (record) => {
    const { dbName } = record;
    if (!dbName) {
      console.error("Missing dbName in record:", record);
      return;
    }
    axios
      .get(`${url}/api/backup-json/${dbName}`, { responseType: "blob" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/zip" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        const timestamp = new Date().toLocaleString("en-US", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
        });
        a.href = url;
        a.download = `${record.dbName}_${timestamp}.zip`;
        a.click();
        console.log(blob, "download");

        if (blob) {
          uploadFileToOneDrive(blob);
        }
      })
      .catch((error) => {
        console.error("Error downloading JSON:", error);
      });
  };
  const handleSubmit = async (values) => {
    try {
      console.log("inside");
      const response = await axios.post(`${url}/api/update-conditions/type`, {
        conditions: values.conditions,
      });
      message.success("Updated successfully");
      setCondition(false);
      form.resetFields();
      fetchCondition();
    } catch (error) {
      console.error("Error updating terms and conditions:", error);
      message.error("Failed to update");
    }
  };

  const handleBackup = (record) => {
    const { dbName } = record;
    if (!dbName) {
      console.error("Missing dbName in record:", record);
      return;
    }
    axios
      .get(`${url}/api/backup/${record.dbName}`, { responseType: "blob" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/zip" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        const timestamp = new Date().toLocaleString("en-US", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
        });
        a.href = url;
        a.download = `${record.dbName}_${timestamp}.zip`;
        a.click();
      })
      .catch((error) => {
        console.error("Error initiating backup:", error);
      });
  };
  return (
    <div style={{ zoom: "90%" }}>
      <Row gutter={[16, 16]} style={{ marginBottom: 16, textAlign: "center" }}>
        {/* Tiles */}
        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
          <Card title="Total Organizations" bordered={false}>
            {totalOrgs}
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
          <Card title="Total Users" bordered={false}>
            {totalUsers}
          </Card>
        </Col>
        {role !== "channelPartner" && (
          <Col xs={24} sm={12} md={8} lg={6} xl={4}>
            <Card title="Signin Count" bordered={false}>
              {signinCount}
            </Card>
          </Col>
        )}
        {/* <Col xs={24} sm={12} md={8} lg={6} xl={4}>
          <Card title="Demo Users" bordered={false}>{signinCount}</Card>
        </Col> */}
        {/* Add more tiles as needed */}
      </Row>

      <Row gutter={[16, 16]} style={{ marginBottom: 16, textAlign: "center" }}>
        {/* Buttons */}
        <Col span={24} style={{ textAlign: "right" }}>
          <Button
            type="primary"
            onClick={() => setCondition(true)}
            style={{ marginRight: 10 }}
          >
            Terms & Conditions
          </Button>
          <Button
            type="primary"
            onClick={() => showModal()}
            style={{ marginRight: 10 }}
          >
            Add Organization
          </Button>
          {role === "superAdmin" && (
            <Button
              type="primary"
              onClick={() => openBillingModal()}
              style={{ marginRight: 10 }}
            >
              Manage BillingType
            </Button>
          )}
          <Link to="/home/empdetails">
            <Button type="primary">Add Admin User</Button>
          </Link>
        </Col>
      </Row>
      <div style={tableWrapperStyle}>
        <Table
          columns={columns}
          dataSource={data}
          bordered
          scroll={{ x: "max-content" }}
          style={{ boxShadow: "0px 0px 10px rgba(0,0,0,0.1)" }}
          className="custom-table col-lg-12 col-md-12 col-sm-12"
          sticky
        />
      </div>
      <Modal
        title={"Terms & Conditions"}
        visible={condition}
        onCancel={() => {
          setCondition(false);
        }}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{ conditions: terms }}
        >
          <Form.Item
            name="conditions"
            rules={[{ required: true, message: "Enter terms and conditions" }]}
          >
            <Input.TextArea placeholder="Enter terms & conditions" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={currentOrg ? "Edit Organization" : "Add Organization"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <MasterOrgForm
          currentOrg={currentOrg}
          onFormSubmitSuccess={handleFormSubmitSuccess}
        />
      </Modal>
      <Modal
        title={"Update Invoice"}
        visible={isModalVisible1}
        onCancel={handleCancel1}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFormSubmit}
          initialValues={{
            orgName: currentOrg1?.orgName,
            invoiceDate: null,
            license: currentOrg1?.UsersAllowed,
          }}
        >
          <Form.Item
            name="orgName"
            label="Organization Name"
            rules={[
              { required: true, message: "Please select an organization" },
            ]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="invoiceId"
            label="Invoice ID"
            rules={[{ required: true, message: "Please select an invoice" }]}
          >
            <Select placeholder="Select the Invoice ID">
              {invoices
                .find((org) => org.orgName === currentOrg1?.orgName)
                ?.invoices.map((invoice) => (
                  <Select.Option
                    key={invoice.invoiceId}
                    value={invoice.invoiceId}
                  >
                    {moment(invoice.invoiceDate).format("YYYY-MM-DD")} -{" "}
                    {invoice.invoiceId}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Invoice Date"
            rules={[
              { required: true, message: "Please select an invoice date" },
            ]}
          >
            <Input
              value={date}
              name="invoiceDate"
              type="date"
              style={{ height: "40px" }}
              disabled
              className="disabled-input"
            />
          </Form.Item>
          <Form.Item
            name="UsersAllowed"
            label="License Count"
            rules={[{ required: true, message: "Please enter license count" }]}
          >
            <Input type="number" placeholder="Updated License Count" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <BillingTypeManager
        isVisible={isBillingModalVisible}
        handleModalClose={closeBillingModal}
      />
    </div>
  );
};

export default MasterOrgTable;
