import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, DatePicker, Checkbox, Upload, message, Row, Col, Card, Popconfirm } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import JSZip from 'jszip';

const DocUpload = ({ onSave, initialValues }) => {
    const [form] = Form.useForm();
    const [zip, setZip] = useState(new JSZip());

    useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }
    }, [initialValues]);

    const updateZipData = async (jsonId) => {
        try {
            // Generate the final ZIP file content
            const zipContent = await zip.generateAsync({ type: 'blob' });

            // Create a FormData object and append the ZIP content
            const formData = new FormData();
            formData.append('zip_data', zipContent, 'filename.zip'); // Use a filename if required
    
            // Make a POST request to update the ZIP data using the provided JSON ID
            const dbname = localStorage.getItem('dbName');
            await axios.post(`https://myapp.finaran.com:5000/api/update_zip/${jsonId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Use multipart/form-data for FormData
                },
                params: {
                    dbName: dbname,
                },
            });

            console.log('ZIP data updated successfully, Please click on done');
        } catch (error) {
            console.error('Error updating ZIP data:', error);
        }
    };


    const onFinish = async (values) => {
        try {
        console.log('Received values:', values);
        const savedId = await onSave(values);

        updateZipData(initialValues.jsonId || savedId);
            message.success('Zip file uploaded successfully');
        } catch (error) {
            console.error('Error saving form:', error);
            message.error('Error uploading Zip. Please try again.');
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const handleFileChange = (e, fieldName) => {
        const files = e.target.files;
        if (files.length > 0) {
            Object.keys(files).forEach((index) => {
                const file = files[index];
                const reader = new FileReader();

                reader.onload = (event) => {
                    const fileContent = event.target.result;

                    // Push the file to the zip
                    const zipFileName = `${fieldName}_${index + 1}.${file.name.split('.').pop()}`;
                    zip.file(zipFileName, fileContent);
                };

                reader.readAsArrayBuffer(file);
            });
        }
    };

    return (
        <div>
            <Form
                form={form}
                name="DocumentUpload"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Row gutter={10}>
                    <Col span={10}>
                        <Card title="Address Details Proof" style={{ marginBottom: 16, marginLeft: 16 }}>
                            <Form.Item
                                label="Address Proof"
                                name={'AddressProof'}
                                required={[true]}
                            >
                                <Input type="file" accept=".png,.jpg,.jpeg,.pdf" onChange={(e) => handleFileChange(e, 'AddressProof')} />
                            </Form.Item>
                            <Form.Item
                                label="Aadhar"
                                name={'Aadhar'}
                            >
                                <Input type="file" accept=".png,.jpg,.jpeg,.pdf" onChange={(e) => handleFileChange(e, 'Aadhar')} />
                            </Form.Item>
                            <Form.Item
                                label="Driving Licence"
                                name={'DL'}
                            >
                                <Input type="file" accept=".png,.jpg,.jpeg,.pdf" onChange={(e) => handleFileChange(e, 'DL')} />
                            </Form.Item>
                            <Form.Item
                                label="PAN Card"
                                name={'PAN'}
                            >
                                <Input type="file" accept=".png,.jpg,.jpeg,.pdf" onChange={(e) => handleFileChange(e, 'PAN')} />
                            </Form.Item>
                        </Card>
                        <Card title="Educational Proof" style={{ marginBottom: 16, marginLeft: 16 }}>
                            <Form.Item
                                label="Provisional Certificate"
                                name={'ProvisionalCertificate'}
                            >
                                <Input type="file" accept=".png,.jpg,.jpeg,.pdf" onChange={(e) => handleFileChange(e, 'ProvisionalCertificate')} />
                            </Form.Item>
                            <Form.Item
                                label="Sem-Wise Mark Sheets"
                                name={'MarkSheets'}
                            >
                                <Input type="file" accept=".png,.jpg,.jpeg,.pdf" onChange={(e) => handleFileChange(e, 'MarkSheets')} />
                            </Form.Item>
                        </Card>
                        <Card title="Employment Proof" style={{ marginBottom: 16, marginLeft: 16 }}>
                            <Form.Item
                                label="Experience Certificate"
                                name={'ExpCertificate'}
                            >
                                <Input type="file" accept=".png,.jpg,.jpeg,.pdf" onChange={(e) => handleFileChange(e, 'ExpCertificate')} />
                            </Form.Item>
                        </Card>
                    </Col>
                </Row>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default DocUpload;
